import IOperator from "../../interfaces/IOperator";
import CleaningManagementService from "../../services/cleaningManagementService";
import { ActionTypes } from "../actionTypes";
import { actionCreator, AppAction } from "../appAction";

export const fetchedOperators = actionCreator<
  ActionTypes.FETCHED_OPERATORS,
  { customerId: string; operators: IOperator[] }
>(ActionTypes.FETCHED_OPERATORS);

export function getOperatorsAsync(customerId: string): AppAction {
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  return dispatch => {
    const cleaningManagementService = new CleaningManagementService();
    cleaningManagementService.getCustomerOperators(customerId).then(operators => {
      dispatch(fetchedOperators({ customerId, operators }));
    });
  };
}