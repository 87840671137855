export function isNullOrEmpty(text?: string): boolean {
  return text == null || text === "";
}

export function toLocaleString(text?: string | number): string {
  return text == null ? "" : text.toLocaleString();
}

export function replaceStringPlaceholders(str: string, ...val: string[]): string {
  for (let index = 0; index < val.length; index++) {
    str = str.replace(`{${index}}`, val[index]);
  }
  return str;
}

export function capitalizeFirstLetter(value: string): string {
  return value.charAt(0).toUpperCase() + value.slice(1);
}

export function compareIgnoreCase(firstValue: string, secondValue: string): number {
  const firstValueLowerCase = firstValue?.toString().toLowerCase();
  const secondValueLowerCase = secondValue?.toString().toLowerCase();
  if (!secondValueLowerCase || firstValueLowerCase < secondValueLowerCase) {
    return -1;
  }
  if (!firstValueLowerCase || firstValueLowerCase > secondValueLowerCase) {
    return 1;
  }
  return 0;
}