import FlagEN from "assets/img/flag-en.svg";
import FlagNL from "assets/img/flag-nl.svg";
import ILanguage from "interfaces/ILanguage";

import TranslateEN from "../i18n/locales/en/translation.json";
import TranslateNL from "../i18n/locales/nl/translation.json";

export const languages: ILanguage[] = [
  {
    key: "en",
    label: "EN",
    flag: FlagEN,
    resources: {
      translation: TranslateEN,
    },
  },
  {
    key: "nl",
    label: "NL",
    flag: FlagNL,
    defaultLang: true,
    resources: {
      translation: TranslateNL,
    },
  },
];
