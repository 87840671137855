import { Guid } from "guid-typescript";
import Serializable from "models/serializeable";

import IFloor from "../interfaces/IFloor";
import IZone from "../interfaces/IZone";

export default class Floor extends Serializable implements IFloor {
  public id: string;
  public name: string;
  public venueId: string;
  public nfcTagCode?: string;
  public zones: IZone[];

  public constructor() {
    super();
    this.id = Guid.createEmpty().toString();
    this.name = "";
    this.venueId = "";
    this.nfcTagCode = "";
    this.zones = [];
  }
}