import ICustomerNotificationCategory from "interfaces/ICustomerNotificationCategory";
import INotificationCategory from "interfaces/INotificationCategory";
import ICustomerNotificationCategoryResponse from "interfaces/response/ICustomerNotificationCategoryResponse";

export default class CustomerNotificationCategory implements ICustomerNotificationCategory {
  public id: string;
  public customerId: string;
  public categoryId: string;
  public contactEmailAddresses: string[];
  public notificationCategory: INotificationCategory;

  public constructor(customerNotificationCategoryResponse: ICustomerNotificationCategoryResponse) {
    this.id = customerNotificationCategoryResponse.id;
    this.customerId = customerNotificationCategoryResponse.customerId;
    this.categoryId = customerNotificationCategoryResponse.categoryId;
    this.notificationCategory = customerNotificationCategoryResponse.notificationCategory;
    this.contactEmailAddresses = this.parseEmailsToArray(customerNotificationCategoryResponse.contactEmail);
  }

  private parseEmailsToArray(email: string): string[] {
    // Email can contain multiple emailaddresses separated by a semicolon
    const parsed = email?.split(";");
    return parsed ?? [];
  }
}