import * as msal from "@azure/msal-browser";

import MsalFactory from "../factories/msalFactory";
import { MsalAuthenticator } from "./msalAuthenticator";

export default class CleaningUserAuthenticator extends MsalAuthenticator {
  public async authenticateUserAsync(): Promise<msal.AccountInfo | null> {
    const user = await this.signInAsync();
    return user;
  }

  public signOut(): void {
    MsalFactory.logoutAllAccounts(null);
  }

  public getName(): string {
    const account = this.getUser();
    return account.name ?? "";
  }

  public getUser(): msal.AccountInfo {
    const account = MsalFactory.getMsalContext().getAllAccounts();
    return account[0];
  }
}