import ICleaningSpace from "interfaces/ICleaningSpace";
import * as React from "react";
import { withTranslation } from "react-i18next";

import CleaningObjectActivities from "./cleaningObjectActivities";
import ICleaningObjectActivitiesContainerProps from "./interfaces/ICleaningObjectActivitiesContainerProps";
import ICleaningObjectActivitiesContainerState from "./interfaces/ICleaningObjectActivitiesContainerState";

class CleaningObjectActivitiesContainer extends React.Component<
  ICleaningObjectActivitiesContainerProps,
  ICleaningObjectActivitiesContainerState
> {
  public constructor(props: ICleaningObjectActivitiesContainerProps) {
    super(props);

    const state: ICleaningObjectActivitiesContainerState = {
      areZonesOpen: false,
      customerActivities: [],
    };

    this.state = state;

    this.onActivityChange = this.onActivityChange.bind(this);
    this.toggleZonesOverview = this.toggleZonesOverview.bind(this);
  }

  private onActivityChange(routeCleaningObject: ICleaningSpace, activityId: string): void {
    this.props.onActivityChange(routeCleaningObject, activityId);
  }

  private toggleZonesOverview(): void {
    this.setState({
      areZonesOpen: !this.state.areZonesOpen,
    });
  }

  public render(): JSX.Element {
    const accordionId = `accordion${this.props.id}`;

    return (
      <div className="mb-4">
        <label className="form-label">{this.props.floorName}</label>

        <div className="accordion" id={accordionId}>
          {this.props.routeCleaningObjects.map(routeCleaningObject => {
            return (
              <CleaningObjectActivities
                routeCleaningObject={routeCleaningObject}
                onActivityChange={this.onActivityChange}
                key={routeCleaningObject.id}
                customerActivities={this.props.customerActivities}
                accordionId={accordionId}
              />
            );
          })}
        </div>
      </div>
    );
  }
}

export default withTranslation()(CleaningObjectActivitiesContainer);
