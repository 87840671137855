import LoaderTypes from "enums/loaderTypes";
import IFeatureFlag from "interfaces/IFeatureFlag";

export interface IGeneralStoreState {
  loaders: LoaderTypes[];
  featureFlags: IFeatureFlag[];
}

export const initialGeneralStoreState: IGeneralStoreState = {
  loaders: [],
  featureFlags: [],
};
