import CleaningCategory from "../enums/cleaningCategory";

export default class ImageUriProvider {
  public static getActiveImageUri(cleaningCategory: CleaningCategory, active: boolean): string {
    switch (cleaningCategory) {
      case CleaningCategory.Activity:
        if (active) {
          return "_Activity_active";
        } else {
          return "_Activity_inactive";
        }
      case CleaningCategory.NotificationCategory:
        if (active) {
          return "_Notificationcategory_active";
        } else {
          return "_Notificationcategory_inactive";
        }
      default:
        return "";
    }
  }

  public static getNotificationCleaningUri(): string {
    return "_Notificationcategory_cleaning";
  }
}