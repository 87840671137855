import { HFColorCodes } from "constants/colorCodes";

import ColorProvider from "../providers/colorProvider";

export default class SelectBoxUtils {
  public static getDefaultSelectStyles(height?: number): any {
    const styles = SelectBoxUtils.defaultSelectStyles;
    const control = SelectBoxUtils.getControlProps(height);

    styles.control = control;

    return { ...styles, control: control };
  }

  public static getDisabledSelectStyles(height?: number): any {
    const styles = SelectBoxUtils.defaultSelectStyles;
    const control = SelectBoxUtils.getDisabledControlProps(height);

    return {
      ...styles,
      control: control,
      dropdownIndicator: (provided: any, state: any): any => ({
        ...provided,
        color: "#f6f6f6",
        backgroundColor: "transparent",
        cursor: "pointer",
      }),
      indicatorSeparator: (provided: any, state: any): any => ({
        ...provided,
        backgroundColor: "transparent",
      }),
      singleValue: (provided: any, state: any): any => ({
        ...provided,
        backgroundColor: "#f6f6f6",
        color: "#d3d3d3",
      }),
      placeholder: (provided: any, state: any): any => ({
        ...provided,
        color: "#d3d3d3",
      }),
    };
  }

  public static getDefaultSelectColoredStyles(height?: number): any {
    const styles = { ...SelectBoxUtils.defaultSelectStyles };
    const control = SelectBoxUtils.getControlProps(height);
    const multiValueLabel = SelectBoxUtils.getMultiValueLabelProps();
    styles.control = control;
    styles.multiValueLabel = multiValueLabel;
    return { ...styles, control: control };
  }

  public static getVenueSelectStyles(height?: number): any {
    const styles = SelectBoxUtils.getDefaultSelectStyles(height);
    return {
      ...styles,
      control: SelectBoxUtils.getVenueControlProps(height),
      clearIndicator: (provided: any, state: any): any => ({
        ...provided,
        color: "#DEDC02",
        "&:hover": {
          color: "red",
        },
      }),
      dropdownIndicator: (provided: any, state: any): any => ({
        ...provided,
        color: HFColorCodes.White,
        backgroundColor: "transparent",
        cursor: "pointer",
        "&:hover": {
          color: HFColorCodes.White,
        },
      }),
      indicatorSeparator: (provided: any, state: any): any => ({
        ...provided,
        backgroundColor: "transparent",
      }),
      input: (provided: any, state: any): any => ({
        ...provided,
        color: "white",
      }),
      menu: (provided: any, state: any): any => ({
        ...provided,
        zIndex: "100",
        borderRadius: "0",
        marginTop: "11px",
      }),
      multiValueLabel: (provided: any, state: any): any => ({
        ...provided,
        color: "white",
        backgroundColor: "#dedc00",
        fontSize: 12,
        borderRadius: 0,
        paddingLeft: 15,
        paddingRight: 15,
        paddingTop: 5,
        paddingBottom: 5,
      }),
      multiValueRemove: (provided: any, state: any): any => ({
        ...provided,
        backgroundColor: HFColorCodes.Tertiary,
        borderRadius: 0,
        "&:hover": {
          color: "red",
          backgroundColor: HFColorCodes.Tertiary,
        },
      }),
      placeholder: (provided: any, state: any): any => ({
        ...provided,
        color: "white",
      }),
      singleValue: (provided: any, state: any): any => ({
        ...provided,
        color: "white",
        border: "none",
      }),
    };
  }

  private static appendHeightToControl(defaultPropsMethod: (provided: any) => any, height?: number): any {
    if (height) {
      return (provided: any, state: any): any => {
        const result = defaultPropsMethod(provided);
        result.height = height;
        return result;
      };
    } else {
      return (provided: any, state: any): any => defaultPropsMethod(provided);
    }
  }

  private static getVenueControlProps(height?: number): any {
    const defaultControlProps = (provided: any): any => ({
      ...provided,
      color: "#DEDC02",
      // backgroundColor: HFColorCodes.Green,
      background: "linear-gradient(270deg,#00172e,#43bdcf)",
      boxShadow: "none",
      border: "none",
      cursor: "pointer",
      borderRadius: 0,
    });

    return SelectBoxUtils.appendHeightToControl(defaultControlProps, height);
  }

  private static getControlProps(height?: number): any {
    const defaultControlProps = (provided: any): any => ({
      ...provided,
      color: "#212529",
      boxShadow: "none",
      border: "1.5px solid $hf-color-lightest-grey",
      cursor: "pointer",
      borderRadius: 0,
      "&:hover": {
        border: "1.5px solid $hf-color-lightest-grey",
      },
      "&:focus": {
        border: "1.5px solid $hf-color-lightest-grey",
      },
    });

    return SelectBoxUtils.appendHeightToControl(defaultControlProps, height);
  }

  private static getDisabledControlProps(height?: number): any {
    const defaultControlProps = (provided: any): any => ({
      ...provided,
      color: "#d3d3d3",
      backgroundColor: "#f6f6f6",
      boxShadow: "none",
      border: "none",
      borderRadius: 0,
      "&:hover": {
        border: "none",
      },
      "&:focus": {
        border: "none",
      },
    });

    return SelectBoxUtils.appendHeightToControl(defaultControlProps, height);
  }

  private static getMultiValueLabelProps(): any {
    const multiValueLabel = (provided: any, state: any): any => ({
      ...provided,
      color: ColorProvider.getNextSemiRandomTextColorForIndex(state.selectProps.value.indexOf(state.data)),
      backgroundColor: ColorProvider.getNextSemiRandomBackgroundColorForIndex(
        state.selectProps.value.indexOf(state.data)
      ),
      fontSize: 12,
      borderRadius: 0,
      paddingLeft: 15,
      paddingRight: 15,
      paddingTop: 5,
      paddingBottom: 5,
    });

    return multiValueLabel;
  }

  private static defaultSelectStyles: any = {
    clearIndicator: (provided: any, state: any): any => ({
      ...provided,
      color: "red",
      "&:hover": {
        color: "red",
      },
    }),
    container: (provided: any, state: any): any => ({
      ...provided,
    }),
    dropdownIndicator: (provided: any, state: any): any => ({
      ...provided,
      color: HFColorCodes.Primary,
      cursor: "pointer",
      "&:hover": {
        color: HFColorCodes.Primary,
      },
    }),
    group: (provided: any, state: any): any => ({
      ...provided,
    }),
    groupHeading: (provided: any, state: any): any => ({
      ...provided,
    }),
    indicatorsContainer: (provided: any, state: any): any => ({
      ...provided,
    }),
    indicatorSeparator: (provided: any, state: any): any => ({
      ...provided,
      backgroundColor: "#FFFFFF",
    }),
    input: (provided: any, state: any): any => ({
      ...provided,
      color: "#212529",
    }),
    loadingIndicator: (provided: any, state: any): any => ({
      ...provided,
    }),
    loadingMessage: (provided: any, state: any): any => ({
      ...provided,
    }),
    menu: (provided: any, state: any): any => ({
      ...provided,
      zIndex: "100",
      borderRadius: "0px",
    }),
    menuList: (provided: any, state: any): any => ({
      ...provided,
      outlineStyle: "none",
      outlineColor: "$hf-color-lightest-grey",
    }),
    menuPortal: (provided: any, state: any): any => ({
      ...provided,
    }),
    multiValue: (provided: any, state: any): any => ({
      ...provided,
    }),
    multiValueLabel: (provided: any, state: any): any => ({
      ...provided,
      color: "#212529",
      backgroundColor: "#FFFFFF",
      fontSize: 12,
      borderRadius: 0,
      paddingLeft: 15,
      paddingRight: 15,
      paddingTop: 5,
      paddingBottom: 5,
    }),
    multiValueRemove: (provided: any, state: any): any => ({
      ...provided,
      color: "red",
      borderRadius: 0,
      backgroundColor: "#FFFFFF",
      "&:hover": {
        color: "red",
        backgroundColor: "#FFFFFF",
      },
    }),
    noOptionsMessage: (provided: any, state: any): any => ({
      ...provided,
      color: "#212529",
    }),
    option: (provided: any, state: any): any => ({
      ...provided,

      "&:hover": {
        backgroundColor: HFColorCodes.Gray300,
      },
    }),
    placeholder: (provided: any, state: any): any => ({
      ...provided,
      color: "#212529",
    }),
    singleValue: (provided: any, state: any): any => ({
      ...provided,
      color: "#212529",
    }),
    valueContainer: (provided: any, state: any): any => ({
      ...provided,
    }),
  };
}
