import { FunctionComponent } from "react";
import { HeaderGroup } from "react-table";

import { IconName } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import IReactTableHeaderProps from "./interfaces/IReactTableHeaderProps";

export const ReactTableHeader: FunctionComponent<IReactTableHeaderProps> = props => {
  const getSortIcon = (column: HeaderGroup): IconName => {
    if (!column.isSorted) {
      return "arrow-up-arrow-down";
    }

    return column.isSortedDesc ? "arrow-down-wide-short" : "arrow-up-wide-short";
  };

  return (
    <thead>
      {props.headerGroups.map(headerGroup => (
        <tr {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map(column => (
            <th
              {...column.getHeaderProps(column.getSortByToggleProps())}
              style={{
                minWidth: column.minWidth,
                width: column.width,
                maxWidth: column.maxWidth,
              }}
              onMouseDown={(e): void => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              {column.render("Header")}
              {column.canSort && <FontAwesomeIcon icon={["fal", getSortIcon(column)]} fixedWidth />}
            </th>
          ))}
        </tr>
      ))}
    </thead>
  );
};
