import { Button } from "components/material/buttons/buttons";
import TranslationMapper from "i18n/mapper";
import LanguageProvider from "providers/languageProvider";
import { Component, ReactNode } from "react";
import { Col, Form, Row } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { IInputChangeEvent } from "../../components/interfaces/IInputChangeEvent";
import IWizardStep from "../../components/wizard/interfaces/IWizardStep";
import CustomerVenueValidator from "./customerVenueValidator";
import ICustomerVenueNfcTagsStepProps from "./interfaces/ICustomerVenueNfcTagsStepProps";
import ICustomerVenueNfcTagsStepState from "./interfaces/ICustomerVenueNfcTagsStepState";
import { ICustomerVenueWizardStepsEnum } from "./interfaces/ICustomerVenueWizardStepsEnum";

class CustomerVenueNfcTagsStep extends Component<ICustomerVenueNfcTagsStepProps, ICustomerVenueNfcTagsStepState> {
  public constructor(props: ICustomerVenueNfcTagsStepProps) {
    super(props);

    this.state = this.props.value ?? {
      nfcTags: [],
      customerVenue: this.props.customerVenue,
    };

    this.addEmptyNfcTag = this.addEmptyNfcTag.bind(this);
    this.propagateChange = this.propagateChange.bind(this);
    this.removeTag = this.removeTag.bind(this);
  }

  private propagateChange(): void {
    const isValid = CustomerVenueValidator.areNfcTagsValid(this.props.customerVenue.isCheckInRequired ?? false); //venue
    this.props.onChange({ target: { value: this.state, name: this.props.name }, isValid });
  }

  private addEmptyNfcTag(): void {
    const nfcTags = [...this.state.nfcTags];
    nfcTags.push("");
    this.setState(
      {
        nfcTags,
      },
      this.propagateChange
    );
  }

  private removeTag(index: number): void {
    const nfcTags = [...this.state.nfcTags];
    nfcTags.splice(index, 1);
    this.setState(
      {
        nfcTags,
      },
      this.propagateChange
    );
  }

  private onChange(event: IInputChangeEvent<any>, index: number): void {
    const value = event?.target.value;

    const updatedNfcTags = this.state.nfcTags;
    updatedNfcTags[index] = value;

    this.setState(
      {
        nfcTags: updatedNfcTags,
      },
      this.propagateChange
    );
  }

  private renderNfcTagFields(tag: string, index: number): ReactNode {
    const value = this.state.nfcTags[index];
    return (
      <div className="input-group mb-3">
        <input
          type="text"
          className="form-control"
          id={`${tag}-nfctags`}
          onChange={(event): void => this.onChange(event, index)}
          value={value}
        />
        <button type="button" className="input-group-text" onClick={(): void => this.removeTag(index)}>
          <FontAwesomeIcon icon={["fal", "trash"]} fixedWidth size="lg" />
        </button>
      </div>
    );
  }

  public render(): ReactNode {
    const formName = "customer-venue-nfctags-edit-form";
    return (
      <Form data-test={formName} id={formName}>
        <Row>
          <Col xs={12} className="modal__col-mb">
            <Form.Label className="customer-venue-nfc-title" htmlFor={`customer-venue-details-edit-form-venue-name`}>
              {LanguageProvider.t(TranslationMapper.pages.customer_venue_edit.connect_nfc_tags)}
            </Form.Label>
            {this.state.nfcTags.map((tag, index) => {
              return this.renderNfcTagFields(tag, index);
            })}
            <Button
              className="btn-outline-secondary w-100"
              resourceLabel={LanguageProvider.t(TranslationMapper.pages.customer_venue_edit.add_nfc_tag)}
              onClick={this.addEmptyNfcTag}
              iconEnd="plus"
            />
          </Col>
        </Row>
      </Form>
    );
  }
}

const wizardStep: IWizardStep = {
  form: CustomerVenueNfcTagsStep,
  titleResource: "",
  name: ICustomerVenueWizardStepsEnum.Step2,
};

export default wizardStep;
