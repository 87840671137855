import { ILogbookStoreState, initialLogbookStoreState } from "store/state/logbookStoreState";

import { ActionTypes, ActionTypesCollection } from "../actionTypes";

export default function logbookReducer(
  state: ILogbookStoreState = initialLogbookStoreState,
  action: ActionTypesCollection
): ILogbookStoreState {
  switch (action.type) {
    case ActionTypes.FETCHED_LOGBOOK:
      return {
        ...state,
        logbook: action.payload,
      };
    case ActionTypes.FETCHED_LOGBOOK_CHANNEL:
      return {
        ...state,
        selectedLogbookChannel: action.payload,
      };
    default:
      return state;
  }
}
