import { IReactTableData } from "components/material/table/interfaces/IReactTableProps";
import { ReactTable } from "components/material/table/reactTable";
import ReactTableHelper from "components/material/table/reactTableHelper";
import TranslationMapper from "i18n/mapper";
import INotificationCategoryContact from "interfaces/INotificationCategoryContact";
import LanguageProvider from "providers/languageProvider";
import * as React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Column, Row } from "react-table";
import {
  deleteNotificationCategoryContact,
  getNotificationCategoryContacts,
  upsertNotificationCategoryContact,
} from "store/actions/notificationActions";
import { RootState } from "store/reducers/rootReducer";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import CreateEmailItemModal from "./createEmailItemModal";
import INotificationCategoryContactsProps, {
  INotificationCategoryContactsDispatchProps,
  INotificationCategoryContactsStateProps,
} from "./interfaces/INotificationCategoryContactsProps";
import INotificationCategoryContactsState from "./interfaces/INotificationCategoryContactsState";

type notificationCategoryContactTableData = INotificationCategoryContact & IReactTableData;

class NotificationCategoryContacts extends React.Component<
  INotificationCategoryContactsProps,
  INotificationCategoryContactsState
> {
  private readonly tableDataPropertyIndex: notificationCategoryContactTableData = {
    // Used to create typesafety for accessors in tableData
    id: "",
    key: "",
    notificationCategoryId: "",
    projectIds: [],
    contactEmail: "",
    hasAllProjects: false,
  };

  public constructor(props: INotificationCategoryContactsProps) {
    super(props);

    this.state = {
      showInvalidEmailMessage: false,
      modalOpen: false,
    };

    this.props.onGetContacts(this.props.notificationCategoryId);

    this.toggleShowInvalidEmailMessage = this.toggleShowInvalidEmailMessage.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.onSubmitModal = this.onSubmitModal.bind(this);
  }

  private toggleShowInvalidEmailMessage(): void {
    this.setState({
      showInvalidEmailMessage: !this.state.showInvalidEmailMessage,
    });
  }

  private openModal(): void {
    this.setState({
      modalOpen: true,
    });
  }

  private closeModal(): void {
    this.setState({
      modalOpen: false,
      selectedContact: undefined,
    });
  }

  private onSubmitModal(email: string, selectedProjectIds: string[], allProjectsSelected: boolean): void {
    const contact = {
      id: this.state.selectedContact?.id ?? undefined,
      notificationCategoryId: this.props.notificationCategoryId,
      projectIds: selectedProjectIds,
      contactEmail: email,
      hasAllProjects: allProjectsSelected,
    } as INotificationCategoryContact;

    this.props.onUpsertContact(contact);
    this.closeModal();

    this.setState({
      selectedContact: undefined,
    });
  }

  private deleteContact(contact: INotificationCategoryContact): void {
    this.props.onDeleteContact(this.props.notificationCategoryId, contact.id);
  }

  private editContact(contact: INotificationCategoryContact): void {
    this.setState(
      {
        selectedContact: contact,
      },
      () => this.openModal()
    );
  }

  private createContact(): void {
    this.setState(
      {
        selectedContact: undefined,
      },
      () => this.openModal()
    );
  }

  private get notificationCategoryContactData(): notificationCategoryContactTableData[] {
    return this.props.notificationCategoryContacts.map(c => {
      const contact: notificationCategoryContactTableData = {
        ...c,
        key: c.id,
      };
      return contact;
    }) as notificationCategoryContactTableData[];
  }

  private getRowData(row: Row): notificationCategoryContactTableData {
    return row.original as notificationCategoryContactTableData;
  }

  public get columns(): Column[] {
    return [
      {
        Header: LanguageProvider.t(TranslationMapper.pages.customerdata.receiver),
        id: ReactTableHelper.getPropertyNameAsString(this.tableDataPropertyIndex, x => x.contactEmail),
        accessor: ReactTableHelper.getPropertyNameAsString(this.tableDataPropertyIndex, x => x.contactEmail),
        disableSortBy: true,
        width: "auto",
      },
      {
        Header: LanguageProvider.t(TranslationMapper.pages.customerdata.number_of_buildings),
        id: ReactTableHelper.getPropertyNameAsString(this.tableDataPropertyIndex, x => x.projectIds),
        accessor: ReactTableHelper.getPropertyNameAsString(this.tableDataPropertyIndex, x => x.projectIds),
        disableSortBy: true,
        width: "auto",
        Cell: ({ row, value }): JSX.Element => {
          let buildingsText: string;

          if (this.getRowData(row).hasAllProjects) {
            buildingsText = LanguageProvider.t(TranslationMapper.pages.customerdata.all_buildings);
          } else {
            buildingsText = `${value.length} ${
              value.length === 1
                ? LanguageProvider.t(TranslationMapper.pages.customerdata.building)
                : LanguageProvider.t(TranslationMapper.pages.customerdata.buildings)
            }`;
          }

          return <span>{buildingsText}</span>;
        },
      },
      {
        id: "actions",
        disableSortBy: true,
        Cell: ({ row }): JSX.Element => (
          <div className="d-flex justify-content-end">
            <button className="btn btn-link btn-sm" onClick={(): void => this.editContact(this.getRowData(row))}>
              <FontAwesomeIcon icon={["fal", "pen"]} size="lg" fixedWidth />
            </button>
            <button className="btn btn-link btn-sm" onClick={(): void => this.deleteContact(this.getRowData(row))}>
              <FontAwesomeIcon icon={["fal", "trash"]} size="lg" fixedWidth />
            </button>
          </div>
        ),
      },
    ];
  }

  public render(): JSX.Element {
    return (
      <>
        <div className="col-12">
          {this.props.notificationCategoryContacts && this.props.notificationCategoryContacts.length > 0 && (
            <ReactTable
              isLoading={false}
              data={this.notificationCategoryContactData}
              columns={this.columns}
              className="table-borderless"
            />
          )}
        </div>

        <div className="col-12 mb-4">
          <button className="btn btn-outline-secondary btn--element-end" onClick={(): void => this.createContact()}>
            {LanguageProvider.t(TranslationMapper.pages.customerdata.add_receiver)}
            <FontAwesomeIcon icon={["fal", "plus"]} fixedWidth />
          </button>
        </div>

        {this.state.modalOpen && (
          <CreateEmailItemModal
            onClose={this.closeModal}
            onSubmit={this.onSubmitModal}
            projects={this.props.projects}
            selectedContact={this.state.selectedContact}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state: RootState): INotificationCategoryContactsStateProps => ({
  projects: state.customerState.customerLocations ?? [],
});

const mapDispatchToProps: INotificationCategoryContactsDispatchProps = {
  onUpsertContact: upsertNotificationCategoryContact,
  onDeleteContact: deleteNotificationCategoryContact,
  onGetContacts: getNotificationCategoryContacts,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(NotificationCategoryContacts));
