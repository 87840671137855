import TranslationMapper from "i18n/mapper";
import LanguageProvider from "providers/languageProvider";
import React, { Component, ReactNode } from "react";
import { withTranslation } from "react-i18next";
import { MultiSelect } from "react-multi-select-component";

import IFilterValue from "../../../components/filterMenu/interfaces/IFilterValue";
import {
    ISearchableMultiSelectOption, ISearchableMultiSelectProps
} from "./interfaces/ISearchableSelectProps";

class SearchableMultiSelect extends Component<ISearchableMultiSelectProps> {
  public constructor(props: ISearchableMultiSelectProps) {
    super(props);

    this.onSelectChange = this.onSelectChange.bind(this);
    this.translateToSelectOption = this.translateToSelectOption.bind(this);
  }

  private get multiSelectOptions(): ISearchableMultiSelectOption[] {
    return this.translateToSelectOption(this.props.options);
  }

  private get multiSelectValues(): ISearchableMultiSelectOption[] {
    return this.translateToSelectOption(this.props.value);
  }

  private translateToSelectOption(values: any[] | undefined): ISearchableMultiSelectOption[] {
    if (this.props.getItemAsSelectOptionFn == null) {
      return (values ?? []).map(option => ({
        value: option.id,
        label: option.name,
      }));
    }
    return (values ?? []).map(option => this.props.getItemAsSelectOptionFn!(option));
  }

  private onSelectChange(event: any[]): void {
    if (this.props.onChange != null) {
      const value = this.props.options.filter(option =>
        event.some(eventOption => eventOption.value === this.props.getItemAsSelectOptionFn!(option).value)
      );

      this.props.onChange({
        target: {
          name: this.props.name,
          value,
        },
      });
    }
  }

  private renderMultiSelectArrow(): JSX.Element {
    return <div className="dropdown-arrow"></div>;
  }

  private get renderMultiSelectClearIcon(): JSX.Element {
    return <div className="clear-selected-button"></div>;
  }

  private renderMultiSelectValue(selected: IFilterValue[]): React.ReactNode {
    if (selected && selected.length > 0) {
      return <div>{`${selected.length} ${LanguageProvider.t(TranslationMapper.searchable_multiselect.selected)}`}</div>;
    }
    return <></>;
  }

  public render(): ReactNode {
    return (
      <MultiSelect
        options={this.multiSelectOptions}
        value={this.multiSelectValues}
        onChange={this.onSelectChange}
        labelledBy={this.props.labelledBy ?? this.props.name}
        overrideStrings={this.props.localizations}
        ArrowRenderer={this.renderMultiSelectArrow}
        ClearIcon={this.renderMultiSelectClearIcon}
        ClearSelectedIcon={this.renderMultiSelectClearIcon}
        valueRenderer={this.renderMultiSelectValue}
      />
    );
  }
}

export default withTranslation()(SearchableMultiSelect);
