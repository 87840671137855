import IRequiredFloorNFCTags from "components/customerVenueWizard/interfaces/IRequiredFloorNFCTagObject";
import LoaderTypes from "enums/loaderTypes";
import TranslationMapper from "i18n/mapper";
import i18next from "i18next";
import ICustomerVenue from "interfaces/ICustomerVenue";
import IVenue from "interfaces/IVenue";
import IZone from "interfaces/IZone";
import { NotificationManager } from "react-notifications";
import CleaningManagementService from "services/cleaningManagementService";
import LocationService from "services/locationService";
import { ActionTypes } from "store/actionTypes";
import { actionCreator, AppAction } from "store/appAction";

import { startLoading, unsetAsLoading } from "./loaderActions";

export const fetchedCustomerLocations = actionCreator<ActionTypes.FETCHED_CUSTOMER_LOCATIONS, IVenue[]>(
  ActionTypes.FETCHED_CUSTOMER_LOCATIONS
);

export const fetchedCustomerLocationOverview = actionCreator<ActionTypes.FETCHED_CUSTOMER_LOCATION_OVERVIEW, IVenue[]>(
  ActionTypes.FETCHED_CUSTOMER_LOCATION_OVERVIEW
);

export const fetchedCleaningObjectsForLocation = actionCreator<ActionTypes.FETCHED_CLEANING_OBJECTS, IZone[]>(
  ActionTypes.FETCHED_CLEANING_OBJECTS
);

export const updateSelectedVenueIsFloorNfcRequired = actionCreator<
  ActionTypes.SELECTED_VENUE_IS_FLOOR_NFCTAGS_REQUIRED,
  boolean
>(ActionTypes.SELECTED_VENUE_IS_FLOOR_NFCTAGS_REQUIRED);

export const updateSelectedVenueIsCheckInRequired = actionCreator<
  ActionTypes.SELECTED_VENUE_IS_CHECKIN_REQUIRED,
  boolean
>(ActionTypes.SELECTED_VENUE_IS_CHECKIN_REQUIRED);

export const updateSelectedVenueOnGroupByFloors = actionCreator<ActionTypes.SELECTED_VENUE_GROUP_BY_FLOORS, boolean>(
  ActionTypes.SELECTED_VENUE_GROUP_BY_FLOORS
);
export const fetchedRequiredLocationFloorNFCList = actionCreator<
  ActionTypes.FETCHED_REQUIRED_LOCATION_FLOOR_NFC_LIST,
  IRequiredFloorNFCTags | undefined
>(ActionTypes.FETCHED_REQUIRED_LOCATION_FLOOR_NFC_LIST);

export const emptyRequiredLocationFloorNFCList = actionCreator<
  ActionTypes.EMPTY_REQUIRED_LOCATION_FLOOR_NFC_LIST,
  IRequiredFloorNFCTags | undefined
>(ActionTypes.EMPTY_REQUIRED_LOCATION_FLOOR_NFC_LIST);

export const fetchedSelectedVenue = actionCreator<ActionTypes.FETCHED_SELECTED_VENUE, ICustomerVenue>(
  ActionTypes.FETCHED_SELECTED_VENUE
);

export function getCustomerLocations(customerId: string): AppAction {
  return (dispatch): void => {
    const cleaningManagementService = new CleaningManagementService();

    dispatch(startLoading(LoaderTypes.Locations));

    cleaningManagementService
      .getCustomerLocations(customerId)
      .then((locations: IVenue[]) => {
        dispatch(fetchedCustomerLocations(locations));
      })
      .catch(() => NotificationManager.error(i18next.t(TranslationMapper.errors.get_locations)))
      .finally(() => dispatch(unsetAsLoading(LoaderTypes.Locations)));
  };
}

export function setSelectedVenueIsFloorNfcRequired(isFloorNFCRequired: boolean): AppAction {
  return (dispatch): void => {
    dispatch(updateSelectedVenueIsFloorNfcRequired(isFloorNFCRequired));
  };
}
export function setIsCheckInRequired(isCheckInRequired: boolean): AppAction {
  return (dispatch): void => {
    dispatch(updateSelectedVenueIsCheckInRequired(isCheckInRequired));
  };
}
export function setGroupByFloors(groupByFloors: boolean): AppAction {
  return (dispatch): void => {
    dispatch(updateSelectedVenueOnGroupByFloors(groupByFloors));
  };
}

export function getCustomerLocationOverview(customerId: string): AppAction {
  return (dispatch): void => {
    const cleaningManagementService = new CleaningManagementService();

    dispatch(startLoading(LoaderTypes.Locations));
    dispatch(fetchedCustomerLocationOverview([]));

    cleaningManagementService
      .getCustomerLocations(customerId)
      .then((locations: IVenue[]) => {
        dispatch(fetchedCustomerLocationOverview(locations));
      })
      .catch(() => NotificationManager.error(i18next.t(TranslationMapper.errors.get_locations)))
      .finally(() => {
        dispatch(unsetAsLoading(LoaderTypes.Locations));
      });
  };
}

export function getCleaningObjectsForLocationAsync(locationId: string): AppAction {
  return (dispatch): void => {
    const locationService = new LocationService();

    dispatch(startLoading(LoaderTypes.CleaningObjects));

    locationService
      .getCleaningObjectsForLocationAsync(locationId)
      .then((cleaningObjects: IZone[]) => {
        dispatch(fetchedCleaningObjectsForLocation(cleaningObjects));
      })
      .catch(() =>
        NotificationManager.error(i18next.t(TranslationMapper.pages.notificationwizard.error_cleaning_objects))
      )
      .finally(() => dispatch(unsetAsLoading(LoaderTypes.CleaningObjects)));
  };
}

export function clearCleaningObjects(): AppAction {
  return (dispatch): void => {
    dispatch(fetchedCleaningObjectsForLocation([]));
  };
}

export function emptyRequiredLocationFloorNFCListAction(): AppAction {
  return (dispatch): void => {
    dispatch(emptyRequiredLocationFloorNFCList({} as IRequiredFloorNFCTags));
  };
}

export function getRequiredLocationFloorNFCList(customerId: string, externalVenueId: string): AppAction {
  return (dispatch): void => {
    const cleaningManagementService = new CleaningManagementService();

    dispatch(startLoading(LoaderTypes.SelectedVenue));

    cleaningManagementService
      .getRequiredLocationFloorNFCList(customerId, externalVenueId)
      .then((floor: IRequiredFloorNFCTags) => {
        dispatch(fetchedRequiredLocationFloorNFCList({ ...floor }));
      })
      .catch(() => {
        // empty list if not found
        dispatch(fetchedRequiredLocationFloorNFCList({} as IRequiredFloorNFCTags));
      })
      .finally(() => {
        dispatch(unsetAsLoading(LoaderTypes.SelectedVenue));
      });
  };
}

export function emptyCustomerVenue(): AppAction {
  return (dispatch): void => {
    dispatch(fetchedSelectedVenue({} as ICustomerVenue));
  };
}

export function getCustomerVenue(customerId: string, venueId: string): AppAction {
  return (dispatch): void => {
    const cleaningManagementService = new CleaningManagementService();

    dispatch(startLoading(LoaderTypes.SelectedVenue));

    cleaningManagementService
      .getCustomerVenue(customerId, venueId)
      .then((venue: ICustomerVenue) => {
        dispatch(fetchedSelectedVenue({ ...venue }));

        if (venue.isFloorNFCRequired) {
          cleaningManagementService
            .getRequiredLocationFloorNFCList(customerId, venue.externalVenueId)
            .then((requiredFloorNFCTagObject) => {
              dispatch(fetchedRequiredLocationFloorNFCList({ ...requiredFloorNFCTagObject }));
            })
            .catch(() => {
              // empty list if not found
              dispatch(fetchedRequiredLocationFloorNFCList({} as IRequiredFloorNFCTags));
            });
        }
      })
      .catch(() => {
        NotificationManager.error(i18next.t(TranslationMapper.pages.customer_venue_edit.error_fetching_venue));
      })
      .finally(() => {
        dispatch(unsetAsLoading(LoaderTypes.SelectedVenue));
      });
  };
}
