import isDeepEqual from "lodash.isequal";
import memoizeOne, { MemoizedFn } from "memoize-one";

import LoaderTypes from "../enums/loaderTypes";
import { RootState } from "./reducers/rootReducer";

export default function isLoadingResolver(state: RootState, loaderType: LoaderTypes): boolean {
  return state.generalState.loaders.some(l => l === loaderType);
}

/**
 * Memoization function to be used as a resolver for objects and arrays in a mapStateToProps function
 *
 * @returns a memoization function, that can be used to compare a new value
 *  with a memorized value and if equal returns the memorized value which will not trigger a re-render
 *
 * @see https://www.npmjs.com/package/memoize-one
 *
 * @remarks define the memomization function on module level and not within a class or function,
 *  otherwise it wil not memoize values
 */
export function objectOrArrayPropertyResolver<T>(): MemoizedFn<(value: T) => T> {
  return memoizeOne((propsValue: T): T => propsValue, isDeepEqual);
}