import IActivityAction from "interfaces/IActivityAction";
import IActivityActionFeedbackDetails from "interfaces/IActivityActionFeedbackDetails";

export interface IActivityStoreState {
  activityActions?: IActivityAction[];
  activityActionsTotalResultsCount?: number;
  selectedActivityAction?: IActivityActionFeedbackDetails[];
}

export const initialActivityStoreState: IActivityStoreState = {};
