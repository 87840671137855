import { Component } from "react";

import IActiveSwitchProps from "./interfaces/IActiveSwitchProps";

class ActiveSwitch extends Component<IActiveSwitchProps> {
  public render(): JSX.Element {
    return (
      <div className="form-check form-switch">
        <input
          className="form-check-input"
          type="checkbox"
          role="switch"
          checked={this.props.checked}
          disabled={this.props.disabled}
          onChange={this.props.onChange}
          name={this.props.name}
          id={this.props.id}
          data-testid={this.props["data-testid"]}
        />
      </div>
    );
  }
}

export default ActiveSwitch;
