import * as msal from "@azure/msal-browser";

import CleaningUserAuthenticator from "../authenticators/cleaningUserAuthenticator";
import CleaningManagementService from "./cleaningManagementService";

export default class AccessService {
  private readonly userAuthenticator: CleaningUserAuthenticator;

  private readonly cleaningManagementService: CleaningManagementService;

  public constructor() {
    this.userAuthenticator = new CleaningUserAuthenticator();
    this.cleaningManagementService = new CleaningManagementService();
  }

  public async ensureAccessAsync(): Promise<boolean> {
    return this.userAuthenticator
      .authenticateUserAsync()
      .then((user: msal.AccountInfo | null) => {
        if (user == null) {
          return false;
        }

        return this.cleaningManagementService.ensureAccess();
      })
      .catch(_exc => {
        return false;
      });
  }
}
