import IRoute from "components/routeWizard/interfaces/IRoute";
import ICleaningPlanningOverview from "interfaces/ICleaningPlanningOverview";
import IExpandedRouteLogging from "interfaces/IExpandedRouteLogging";
import ISimpleRouteLogging from "interfaces/ISimpleRouteLogging";
import Dictionary from "utils/dictionary";

export interface IScheduleManagementStoreState {
  routes?: ICleaningPlanningOverview[];
  selectedRoute?: IRoute;
  customerRouteLogging?: ISimpleRouteLogging[];
  expandedLogs?: Dictionary<IExpandedRouteLogging>;
}

export const initialScheduleManagementStoreState: IScheduleManagementStoreState = {};
