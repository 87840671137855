import { Button } from "components/material/buttons/buttons";
import * as React from "react";

import ISelectDayItemProps from "./interfaces/ISelectDayItemProps";

export default class SelectDayItem extends React.Component<ISelectDayItemProps> {
  public constructor(props: ISelectDayItemProps) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  private onClick(): void {
    this.props.onItemClick(this.props.dayAbbreviation);
  }

  public render(): JSX.Element {
    return (
      <Button
        key={this.props.dayAbbreviation}
        onClick={this.onClick}
        className={`btn btn--rounded ${
          this.props.activeDay ? "btn-primary" : "btn-outline-primary btn--rounded-inactive"
        } ${this.props.className ?? ""}`}
        resourceLabel={`${this.props.dayAbbreviation}`}
      />
    );
  }
}
