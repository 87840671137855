import { CmApiAuthenticator } from "authenticators/cmApiAuthenticator";
import { ApplicationConfig } from "config";
import IUser from "interfaces/IUser";

import BaseService from "./baseService";

export default class UserService extends BaseService {
  public constructor() {
    super(ApplicationConfig.endpoints.winvisionCleaningManagementApi, new CmApiAuthenticator());
  }

  public getUserAsync(): Promise<IUser> {
    return this.get<IUser>(`user`);
  }
}