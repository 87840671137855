import "moment/locale/nl";

import { FilterMenu } from "components/filterMenu/filterMenu";
import IFilterValue from "components/filterMenu/interfaces/IFilterValue";
import ISingleSelectDropdownField from "components/filterMenu/interfaces/ISingleSelectDropdownField";
import { IInputChangeEvent } from "components/interfaces/IInputChangeEvent";
import NotificationWizard from "components/notificationWizard/notificationWizard";
import LoaderTypes from "enums/loaderTypes";
import NotificationStatus from "enums/notificationStatus";
import Role from "enums/role";
import { TranslationMapper } from "i18n/mapper";
import INotificationOverview from "interfaces/INotificationOverview";
import INotificationReaction from "interfaces/INotificationReaction";
import INotificationReactionResponse from "interfaces/INotificationReactionResponse";
import INotificationResponse from "interfaces/INotificationResponse";
import CustomerProvider from "providers/customerProvider";
import LanguageProvider from "providers/languageProvider";
import { Component } from "react";
import { withTranslation } from "react-i18next";
import { NotificationManager } from "react-notifications";
import { connect } from "react-redux";
import { ValueType } from "react-select";
import { withTelemetry } from "services/telemetryService";
import { showCustomerSelection } from "store/actions/customerActions";
import { getNotificationsForCustomer, updateNotificationStatus } from "store/actions/notificationActions";
import { RootState } from "store/reducers/rootReducer";
import AppEventHub, { AppEvents } from "utils/appEventHub";
import Dictionary from "utils/dictionary";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import INotificationsOverviewProps, {
  INotificationsOverviewDispatchProps,
  INotificationsOverviewStateProps,
} from "./interfaces/INotificationsOverviewProps";
import INotificationsOverviewState from "./interfaces/INotificationsOverviewState";
import NotificationResponseModal from "./notificationResponseModal";
import { NotificationsExport } from "./notificationsExport";
import NotificationsTable from "./notificationsTable";

export class NotificationsOverview extends Component<INotificationsOverviewProps, INotificationsOverviewState> {
  private readonly filterNameNotificationBy: string = "NotificationBy";
  private readonly filterNameStatus: string = "Status";
  private readonly filterNameCategories: string = "Categories";
  private readonly clearCurrentOption: string = "ClearCurrentOption";

  public constructor(props: INotificationsOverviewProps) {
    super(props);

    const startDate = new Date();
    startDate.setHours(0, 0, 0, 0);

    const dateDiff = startDate.getDay() === 1 ? 7 : (startDate.getDay() + 6) % 7;
    startDate.setDate(startDate.getDate() - dateDiff);

    const endDate = new Date();
    endDate.setHours(23, 59, 59, 999);

    const customer = CustomerProvider.getActiveCustomer();

    const state: INotificationsOverviewState = {
      activeItemLabel: customer ? customer.name : "",
      selectedNotificationIds: [],
      customerId: customer ? customer.externalCustomerId : "",
      startDate: startDate,
      endDate: endDate,
      selectedFilters: [],
      clickedNotifications: new Dictionary(),
      notificationModalId: "",
      showCreateNotificationModal: false,
      showResponseModal: false,
    };

    this.state = state;

    this.setActiveCustomer = this.setActiveCustomer.bind(this);
    this.onSelectedNotificationChange = this.onSelectedNotificationChange.bind(this);
    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
    this.onResponseButtonClick = this.onResponseButtonClick.bind(this);
    this.updateReactions = this.updateReactions.bind(this);
    this.openNotificationModal = this.openNotificationModal.bind(this);
    this.onCloseNotificationModal = this.onCloseNotificationModal.bind(this);
    this.onUpdateNotificationStatus = this.onUpdateNotificationStatus.bind(this);
    this.onSelectFilterChange = this.onSelectFilterChange.bind(this);
    this.closeResponseModal = this.closeResponseModal.bind(this);

    AppEventHub.on(AppEvents.CustomerSelected, this.setActiveCustomer);
  }

  public componentWillUnmount(): void {
    AppEventHub.off(AppEvents.CustomerSelected, this.setActiveCustomer);
  }

  public componentDidMount(): void {
    this.props.showCustomerSelection(true);
    this.setActiveCustomer();
  }

  private async setActiveCustomer(): Promise<void> {
    const activeCustomer = await CustomerProvider.getActiveCustomer();

    if (!activeCustomer) {
      NotificationManager.error(LanguageProvider.t(TranslationMapper.pages.notifications.errormessageid));
      return;
    }

    this.setState(
      {
        activeItemLabel: activeCustomer.name,
        customerId: activeCustomer.id,
      },
      this.getNotifications
    );
  }

  private async getNotifications(): Promise<void> {
    if (this.state.customerId.length <= 0) {
      NotificationManager.error(LanguageProvider.t(TranslationMapper.pages.notifications.errormessageid));
      return;
    }
    this.props.getNotifications(
      this.state.customerId,
      this.state.startDate.toISOString(),
      this.state.endDate.toISOString()
    );
  }

  public updateReactions(notificationResponse: INotificationReactionResponse, notificationId: string): void {
    const clickedNotifications = this.state.clickedNotifications;
    const notification = clickedNotifications.item(notificationId);
    const reactions = notification.comments;
    const dbReaction: INotificationReaction = {
      createdBy: notificationResponse.createdBy,
      createdOn: notificationResponse.createdOn,
      description: notificationResponse.description,
      id: notificationResponse.id,
      notification: notification,
      notificationId: notificationId,
    };
    reactions.push(dbReaction);
    this.setState({
      clickedNotifications: clickedNotifications,
    });
  }

  private onSelectedNotificationChange(selectedNotificationIds: string[]): void {
    this.setState({
      selectedNotificationIds,
    });
  }

  private onSelectFilterChange(filterType: string, option: ValueType<IFilterValue, boolean>): void {
    let newFilters = [...this.state.selectedFilters];
    const selectedOption = option as IFilterValue;

    if (selectedOption == null) {
      newFilters = newFilters.filter(cf => cf.filterType !== filterType);
    } else if (newFilters.findIndex(f => f.filterType === filterType) !== -1) {
      const index = newFilters.findIndex(f => f.filterType === filterType);
      newFilters[index] = selectedOption;
    } else {
      newFilters.push(selectedOption);
    }

    this.setState({
      selectedFilters: newFilters,
    });
  }

  private get selectFilterOptions(): ISingleSelectDropdownField[] {
    const distinct = (value, index, self): boolean => {
      return self.indexOf(value) === index;
    };

    const notificationList = this.props.notifications;
    // gemeld door
    const filterNotificationBySelectOptions = notificationList
      .map(notification => notification.createdBy)
      .filter(distinct);
    const dropdownListNotificationBy = filterNotificationBySelectOptions.map(option => ({
      value: option,
      label: option,
      filterType: this.filterNameNotificationBy,
    }));
    // categorie
    const filterNotificationCategorieSelectOptions = notificationList
      .map(notification => notification.notificationCategoryName)
      .filter(distinct);
    const dropdownListNotificationCategories = filterNotificationCategorieSelectOptions.map(option => ({
      value: option,
      label: option,
      filterType: this.filterNameCategories,
    }));
    // status
    const filterNotificationStatusSelectOptions = notificationList
      .map(notification => notification.status)
      .filter(distinct);
    const dropdownListStatusSelectOptions = filterNotificationStatusSelectOptions.map(option => ({
      value: NotificationStatus[option],
      label: LanguageProvider.t(
        TranslationMapper.pages.notifications.statusnames[NotificationStatus[option].toLowerCase()]
      ),
      filterType: this.filterNameStatus,
    }));

    return [
      {
        // categorie
        filterName: LanguageProvider.t(TranslationMapper.pages.notifications.categorie),
        filterList: dropdownListNotificationCategories,
        onSelect: (optionSelected: ValueType<IFilterValue, boolean>): void =>
          this.onSelectFilterChange(this.filterNameCategories, optionSelected),
      },
      {
        // gemeld door
        filterName: LanguageProvider.t(TranslationMapper.pages.notifications.notificationby),
        filterList: dropdownListNotificationBy,
        onSelect: (optionSelected: ValueType<IFilterValue, boolean>): void =>
          this.onSelectFilterChange(this.filterNameNotificationBy, optionSelected),
      },
      {
        // status
        filterName: LanguageProvider.t(TranslationMapper.pages.notifications.status),
        filterList: dropdownListStatusSelectOptions,
        onSelect: (optionSelected: ValueType<IFilterValue, boolean>): void =>
          this.onSelectFilterChange(this.filterNameStatus, optionSelected),
      },
    ];
  }

  private async onResponseButtonClick(notificationId: string): Promise<void> {
    this.setState({
      notificationModalId: notificationId,
      showResponseModal: true,
    });
  }

  private closeResponseModal(): void {
    this.setState({
      showResponseModal: false,
    });
  }

  private async handleStartDateChange(state: Date): Promise<void> {
    if (this.state.customerId.length > 1) {
      this.setState(
        {
          startDate: state,
        },
        this.getNotifications
      );
    } else {
      this.setState(
        {
          startDate: state,
        },
        this.setActiveCustomer
      );
    }
  }

  private async handleEndDateChange(state: Date): Promise<void> {
    this.setState({
      endDate: state,
    });
    if (this.state.customerId.length > 1) {
      this.setState(
        {
          endDate: state,
        },
        this.getNotifications
      );
    } else {
      this.setState(
        {
          endDate: state,
        },
        this.setActiveCustomer
      );
    }
  }

  private get exportNotifications(): INotificationOverview[] {
    if (this.state.selectedNotificationIds.length > 0) {
      return this.props.notifications.filter(n => this.state.selectedNotificationIds.includes(n.id));
    } else if (this.filteredNotifications.length > 0) {
      return this.filteredNotifications;
    } else if (this.props.notifications.length > 0) {
      return this.props.notifications;
    }

    return [];
  }

  private onUpdateNotificationStatus(event: IInputChangeEvent<any>, notification: INotificationResponse): void {
    const status = event.target.value;

    this.props.onUpdateNotificationStatus(
      this.state.customerId,
      notification.id,
      status,
      this.state.startDate.toISOString(),
      this.state.endDate.toISOString()
    );

    const clickedNotifications = this.state.clickedNotifications;
    const clickedNotification = clickedNotifications.item(notification.id);
    clickedNotification.status = status;

    this.setState({
      clickedNotifications: clickedNotifications,
    });
  }

  private openNotificationModal(): void {
    this.setState({
      showCreateNotificationModal: true,
    });
  }

  private onCloseNotificationModal(): void {
    this.setState(
      {
        showCreateNotificationModal: false,
      },
      this.getNotifications
    );
  }

  private get filteredNotifications(): INotificationOverview[] {
    let notifications = [...this.props.notifications];

    this.state.selectedFilters.forEach(selectedFilter => {
      switch (selectedFilter.filterType) {
        case this.filterNameNotificationBy:
          notifications = notifications.filter(notification => notification.createdBy === selectedFilter.value);
          break;
        case this.filterNameStatus:
          notifications = notifications.filter(
            notification => NotificationStatus[notification.status].toLowerCase() === selectedFilter.value.toLowerCase()
          );
          break;
        case this.filterNameCategories:
          notifications = notifications.filter(
            notification => notification.notificationCategoryName === selectedFilter.value
          );
          break;
        default:
          break;
      }
    });

    return notifications;
  }

  public render(): JSX.Element {
    return (
      <>
        <header className="header-actions">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 header-actions__content">
                <h1>{this.state.activeItemLabel}</h1>
                <div className="header-actions__buttons">
                  <NotificationsExport
                    customerId={this.state.customerId}
                    notifications={this.exportNotifications}
                    disabled={this.props.isLoading}
                    startDate={this.state.startDate.toString()}
                    endDate={this.state.endDate.toString()}
                  />
                  <button
                    className="btn btn-primary btn--rounded"
                    onClick={this.openNotificationModal}
                    onKeyDown={this.openNotificationModal}
                  >
                    <FontAwesomeIcon icon={["fal", "plus"]} fixedWidth />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </header>

        <FilterMenu
          startDate={this.state.startDate}
          endDate={this.state.endDate}
          handleEndDateChange={this.handleEndDateChange}
          handleStartDateChange={this.handleStartDateChange}
          filterFields={this.selectFilterOptions}
        />

        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 mb-2 block-content--px">
                <NotificationsTable
                  isLoading={this.props.isLoading}
                  data={this.filteredNotifications}
                  userRole={this.props.user.role}
                  selectedNotificationIds={this.state.selectedNotificationIds}
                  clickedNotifications={this.state.clickedNotifications}
                  onUpdateSelectedNotifications={this.onSelectedNotificationChange}
                  onResponseButtonClick={this.onResponseButtonClick}
                  onUpdateNotificationStatus={this.onUpdateNotificationStatus}
                />
              </div>
            </div>
          </div>
        </div>

        {this.state.showCreateNotificationModal && <NotificationWizard onClose={this.onCloseNotificationModal} />}

        {this.state.showResponseModal && (
          <NotificationResponseModal
            notificationId={this.state.notificationModalId}
            updateReactions={this.updateReactions}
            onClose={this.closeResponseModal}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state: RootState): INotificationsOverviewStateProps => ({
  isLoading: state.generalState.loaders.some(l => l === LoaderTypes.Notifications),
  notifications: state.notificationState.notifications ?? [],
  user: state.userState.user ?? { role: Role.None },
});

const mapDispatchToProps: INotificationsOverviewDispatchProps = {
  onUpdateNotificationStatus: updateNotificationStatus,
  getNotifications: getNotificationsForCustomer,
  showCustomerSelection: showCustomerSelection,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withTelemetry(NotificationsOverview, "NotificationsOverview")));
