import TranslationMapper from "i18n/mapper";
import LanguageProvider from "providers/languageProvider";
import { Component } from "react";
import { isNullOrEmpty } from "utils/stringUtils";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ICustomSelectProps, { ICustomSelectOption } from "./interfaces/ICustomSelectProps";
import ICustomSelectState from "./interfaces/ICustomSelectState";

export default class CustomSelect extends Component<ICustomSelectProps, ICustomSelectState> {
  public constructor(props: ICustomSelectProps) {
    super(props);

    const state: ICustomSelectState = {
      isOpen: false,
    };

    this.state = state;

    this.handleClick = this.handleClick.bind(this);
    this.optionsToggle = this.optionsToggle.bind(this);
    this.renderOption = this.renderOption.bind(this);
    this.onOptionSelect = this.onOptionSelect.bind(this);
    this.onChangeSearchTerm = this.onChangeSearchTerm.bind(this);
  }

  public componentDidMount(): void {
    // Ensure clicks next to dropdown will also close dropdown
    document.addEventListener("mousedown", this.handleClick);
  }

  public componentWillUnmount(): void {
    document.removeEventListener("mousedown", this.handleClick);
  }

  private handleClick(event: any): void {
    if (!event.target.closest(".select-custom")) {
      this.optionsClose();
    }
  }

  private optionsToggle(): void {
    if (!this.props.isDisabled) {
      this.setState({
        isOpen: !this.state.isOpen,
      });
    }
  }

  private optionsClose(): void {
    this.setState({
      isOpen: false,
      searchText: undefined,
    });
  }

  private onOptionSelect(key: string): void {
    this.props.onChange(key);
    this.optionsClose();
  }

  private renderOption(option: ICustomSelectOption): JSX.Element {
    const isSelected = this.props.selectedKey != null && this.props.selectedKey === option.key;

    return (
      <div
        className={`select-custom__option${isSelected ? " selected" : ""}`}
        onClick={(): void => this.onOptionSelect(option.key)}
      >
        {option.optionName}
      </div>
    );
  }

  private get selectedOption(): ICustomSelectOption | undefined {
    if (!this.props.selectedKey) {
      return undefined;
    }

    return this.props.options.find(o => o.key === this.props.selectedKey);
  }

  private onChangeSearchTerm(value: string): void {
    this.setState({
      searchText: value,
    });
  }

  private get filteredOptions(): ICustomSelectOption[] {
    const searchText = this.state.searchText;
    if (!this.props.enableSearch || !searchText || isNullOrEmpty(searchText)) {
      return this.props.options;
    }

    return this.props.options.filter(o => o.optionName.toLowerCase().includes(searchText.toLowerCase()));
  }

  public render(): JSX.Element {
    return (
      <div
        className={`select-custom${this.props.isDisabled ? " disabled" : ""}${
          this.props.isInvalid ? " is-invalid" : ""
        }${this.state.isOpen ? " select-custom--is-open" : ""}`}
      >
        <div className="select-custom__toggle" onClick={this.optionsToggle}>
          <div className="select-custom__toggle-value">{this.selectedOption?.optionName ?? this.props.placeholder}</div>
          <div className="select-custom__toggle-indicator">
            <FontAwesomeIcon icon={["fal", `${this.state.isOpen ? "chevron-up" : "chevron-down"}`]} />
          </div>
        </div>
        <div className="select-custom__options">
          {this.props.enableSearch && (
            <div className="select-custom__option select-custom__option--search">
              <input
                type="text"
                className="form-control form-control--icon--end form-control--icon--magnifying-glass"
                onChange={(e): void => this.onChangeSearchTerm(e.currentTarget.value)}
                placeholder={LanguageProvider.t(TranslationMapper.controls.placeholders.search)}
              />
            </div>
          )}

          {this.filteredOptions.map(o => this.renderOption(o))}
        </div>
      </div>
    );
  }
}
