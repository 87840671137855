import ActiveSwitch from "components/material/activeSwitch/activeSwitch";
import LanguageProvider from "providers/languageProvider";
import { Component, ReactNode } from "react";
import { Col, Form, Row } from "react-bootstrap";

import SelectWeekdays from "../../components/selectWeekdays/selectWeekdays";
import TranslationMapper from "../../i18n/mapper";
import SelectTimePeriod from "../material/select/selectTimePeriod";
import SelectIncidentTypes from "../selectIncidentTypes/selectIncidentTypes";
import SelectOperator from "../selectOperator/selectOperator";
import SelectVenue from "../selectVenue/selectVenue";
import IWizardStep from "../wizard/interfaces/IWizardStep";
import IRouteDetailsStepProps from "./interfaces/IRouteDetailsStepProps";
import IRouteDetailsStepState from "./interfaces/IRouteDetailsStepState";
import RouteValidator from "./routeValidator";

class RouteDetailsStep extends Component<IRouteDetailsStepProps, IRouteDetailsStepState> {
  public constructor(props: IRouteDetailsStepProps) {
    super(props);

    const state = this.props.value ?? {
      isRouteActive: true,
      routeName: "",
      timePeriod: {
        startTime: "08:00",
        endTime: "12:00",
      },
      weekdays: {
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false,
      },
      incidentTypes: [],
    };

    this.state = state;

    this.onChange = this.onChange.bind(this);
    this.propagateChange = this.propagateChange.bind(this);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public onChange(e: any, isCheckbox?: boolean): void {
    const name = e.target.name;
    let value;

    if (isCheckbox) {
      value = e.target.checked ? true : false;
    } else {
      value = e.target.value;
    }

    this.setState(current => ({ ...current, [name]: value }), this.propagateChange);
  }

  private propagateChange(): void {
    const isValid = RouteValidator.areDetailsValid(this.state);
    this.props.onChange({ target: { value: this.state, name: this.props.name }, isValid });
  }

  public render(): ReactNode {
    const incidentTypes = this.props.value?.incidentTypes ?? [];

    return (
      <Form data-testid="route-wizard-details-step">
        <Row>
          <Col md={6} className="modal__col-mb">
            <Form.Group>
              <Form.Label>{LanguageProvider.t(TranslationMapper.pages.routewizard.routeactive)}</Form.Label>
              <ActiveSwitch
                name="isRouteActive"
                checked={this.state.isRouteActive}
                data-testid="is-route-active"
                onChange={(e): void => this.onChange(e, true)}
              />
            </Form.Group>
          </Col>
          <Col md={6} className="modal__col-mb">
            <Form.Group>
              <Form.Label>{LanguageProvider.t(TranslationMapper.pages.routewizard.venue)}</Form.Label>
              {this.props.isInEditModus && <p>{this.state.venue?.name}</p>}
              {!this.props.isInEditModus && (
                <SelectVenue id="venue" name="venue" value={this.state.venue} onChange={this.onChange} />
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={6} className="modal__col-mb">
            <Form.Group>
              <Form.Label>{LanguageProvider.t(TranslationMapper.pages.routewizard.routename)}</Form.Label>
              <Form.Control id="route-name" name="routeName" onChange={this.onChange} value={this.state.routeName} />
            </Form.Group>
          </Col>
          <Col md={6} className="modal__col-mb">
            <Form.Group>
              <Form.Label>{LanguageProvider.t(TranslationMapper.pages.routewizard.operator)}</Form.Label>
              <SelectOperator
                id="operators"
                name="operators"
                value={this.state.operators}
                multiSelect={true}
                onChange={this.onChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={6} className="modal__col-mb">
            <Form.Group>
              <Form.Label>{LanguageProvider.t(TranslationMapper.pages.routewizard.period)}</Form.Label>
              <SelectTimePeriod
                id="time-period"
                name="timePeriod"
                value={this.state.timePeriod}
                onChange={this.onChange}
              />
            </Form.Group>
          </Col>
          <Col md={6} className="modal__col-mb">
            <Form.Group>
              <Form.Label>{LanguageProvider.t(TranslationMapper.pages.routewizard.weekdays)}</Form.Label>
              <SelectWeekdays
                id="select-weekdays"
                name="weekdays"
                onChange={this.onChange}
                value={this.state.weekdays}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group>
              <Form.Label>{LanguageProvider.t(TranslationMapper.pages.routewizard.incidenttypes)}</Form.Label>
              <p>{LanguageProvider.t(TranslationMapper.pages.routewizard.incidenttypestooltip)}</p>
              <SelectIncidentTypes name="incidentTypes" value={incidentTypes} onChange={this.onChange} />
            </Form.Group>
          </Col>
        </Row>
      </Form>
    );
  }
}

const wizardStep: IWizardStep = {
  form: RouteDetailsStep,
  titleResource: TranslationMapper.pages.routewizard.routedetails.title,
  subtitleResource: TranslationMapper.pages.routewizard.routedetails.subtitle,
  name: "details",
};

export default wizardStep;
