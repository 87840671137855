import i18next from "i18next";
import ICustomerNotificationCategory from "interfaces/ICustomerNotificationCategory";
import INotificationCategoryContact from "interfaces/INotificationCategoryContact";
import { cloneDeep } from "lodash";
import CustomerNotificationCategoryRequest from "models/request/customerNotificationCategoryRequest";
import { NotificationManager } from "react-notifications";
import { ActionTypes } from "store/actionTypes";

import LoaderTypes from "../../enums/loaderTypes";
import TranslationMapper from "../../i18n/mapper";
import CleaningManagementService from "../../services/cleaningManagementService";
import { actionCreator, AppAction } from "../appAction";
import { getCustomerActivityTypes } from "./activityActions";
import { setAsLoading, unsetAsLoading } from "./loaderActions";
import { fetchedCustomerNotificationContacts } from "./notificationActions";

export const fetchedCustomerNotificationCategories = actionCreator<
  ActionTypes.FETCHED_CUSTOMER_NOTIFICATION_CATEGORIES,
  ICustomerNotificationCategory[]
>(ActionTypes.FETCHED_CUSTOMER_NOTIFICATION_CATEGORIES);

export function updateCustomerActivities(customerId: string, customerActivityIds: string[]): AppAction {
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  return dispatch => {
    const cleaningManagementService = new CleaningManagementService();
    dispatch(setAsLoading(LoaderTypes.CustomerActivities));

    cleaningManagementService
      .updateCustomerActivities(customerId, customerActivityIds)
      .then(() => {
        NotificationManager.success(i18next.t(TranslationMapper.pages.masterdata.savedchanges));
        dispatch(getCustomerActivityTypes(customerId));
      })
      .catch(() => {
        NotificationManager.error(i18next.t(TranslationMapper.pages.customerdata.activityerror));
        dispatch(unsetAsLoading(LoaderTypes.CustomerActivities));
      });
  };
}

export function getCustomerNotificationCategories(customerId: string): AppAction {
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  return dispatch => {
    const cleaningManagementService = new CleaningManagementService();
    dispatch(setAsLoading(LoaderTypes.CustomerNotificationCategory));

    cleaningManagementService
      .getCustomerNotificationCategories(customerId)
      .then(cnc => {
        dispatch(fetchedCustomerNotificationCategories(cnc));
      })
      .catch(() => {
        NotificationManager.error(i18next.t(TranslationMapper.pages.customerdata.activityerror));
      })
      .finally(() => {
        dispatch(unsetAsLoading(LoaderTypes.CustomerNotificationCategory));
      });
  };
}

export function updateCustomerNotificationCategories(
  customerId: string,
  notificationCategories: ICustomerNotificationCategory[]
): AppAction {
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  return dispatch => {
    const cleaningManagementService = new CleaningManagementService();
    dispatch(setAsLoading(LoaderTypes.CustomerNotificationCategory));

    const notificationCategoriesRequest = notificationCategories.map(nc => new CustomerNotificationCategoryRequest(nc));

    cleaningManagementService
      .updateCustomerNotificationCategories(customerId, notificationCategoriesRequest)
      .then(() => {
        NotificationManager.success(i18next.t(TranslationMapper.pages.masterdata.savedchanges));
        dispatch(getCustomerNotificationCategories(customerId));
      })
      .catch(error => {
        if (error.message.includes("InvalidEmailException")) {
          NotificationManager.error(i18next.t(TranslationMapper.pages.customerdata.invalidemail));
        } else {
          NotificationManager.error(i18next.t(TranslationMapper.pages.customerdata.invalidconfiguration));
        }
      })
      .finally(() => dispatch(unsetAsLoading(LoaderTypes.CustomerNotificationCategory)));
  };
}

export function disableCustomerNotificationCategory(customerId: string, categoryId: string): AppAction {
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  return (dispatch, getState) => {
    const cleaningManagementService = new CleaningManagementService();
    dispatch(setAsLoading(LoaderTypes.CustomerNotificationCategory));

    cleaningManagementService
      .disableCustomerNotificationCategory(customerId, categoryId)
      .then(() => {
        NotificationManager.success(i18next.t(TranslationMapper.pages.masterdata.succesfullydeleted));

        const currentCustomerNotificationContacts: Map<string, INotificationCategoryContact[]> =
          cloneDeep(getState().customerState.customerNotificationCategoryContacts) ?? new Map();
        currentCustomerNotificationContacts.delete(categoryId);
        dispatch(fetchedCustomerNotificationContacts(currentCustomerNotificationContacts));
      })
      .catch(() => {
        NotificationManager.error(i18next.t(TranslationMapper.pages.masterdata.deleteerror));
      })
      .finally(() => dispatch(unsetAsLoading(LoaderTypes.CustomerNotificationCategory)));
  };
}
