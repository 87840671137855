import { Button } from "components/material/buttons/buttons";
import { TranslationMapper } from "i18n/mapper";
import LanguageProvider from "providers/languageProvider";
import { Component } from "react";
import { withTranslation } from "react-i18next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import CleaningUserAuthenticator from "../../authenticators/cleaningUserAuthenticator";
import LanguageToggle from "../../components/languageToggle/languageToggle";
import IUserSettingsProps from "./interfaces/IUserSettingsProps";

class UserSettings extends Component<IUserSettingsProps> {
  private userAuthenticator: CleaningUserAuthenticator;

  public constructor(props: IUserSettingsProps) {
    super(props);

    this.userAuthenticator = new CleaningUserAuthenticator();

    this.renderDropdownMenu = this.renderDropdownMenu.bind(this);
    this.logoutUser = this.logoutUser.bind(this);
  }

  private logoutUser(): void {
    this.userAuthenticator.signOut();
  }

  private get selectedLanguageLabel(): string {
    const label: string | undefined = TranslationMapper.settings[`language_option_${LanguageProvider.language}`];
    return label != null ? LanguageProvider.t(label) : "";
  }

  private renderDropdownMenu(): JSX.Element {
    return (
      <ul className="dropdown-menu dropdown-menu--account p-0" aria-labelledby="user-settings-menu-button">
        <li>
          <div className="d-flex justify-content-between p-3-5">
            <div>
              <h3 className="mb-1">{LanguageProvider.t(TranslationMapper.settings.account_title)}</h3>
              <h5 className="mb-0">{this.userAuthenticator.getName()}</h5>
            </div>
            <div>
              <Button
                className="btn btn-outline-secondary"
                onClick={this.logoutUser}
                data-test="logout-button"
                resourceLabel={LanguageProvider.t(TranslationMapper.buttons.logout)}
              />
            </div>
          </div>
        </li>

        <li>
          <div className="d-flex justify-content-between p-3-5">
            <div>
              <h3 className="mb-1">{LanguageProvider.t(TranslationMapper.settings.language_title)}</h3>
              <h5 className="mb-0">{this.selectedLanguageLabel}</h5>
            </div>
            <LanguageToggle />
          </div>
        </li>
      </ul>
    );
  }

  public render(): JSX.Element {
    return (
      <div className="dropdown">
        <button
          className="btn btn-link btn--element-start"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <FontAwesomeIcon icon={["fal", "circle-user"]} fixedWidth />
          <span className="d-none d-md-inline">
            {LanguageProvider.t(TranslationMapper.usersettingsmenu.your_account)}
          </span>
        </button>
        {this.renderDropdownMenu()}
      </div>
    );
  }
}

export default withTranslation()(UserSettings);
