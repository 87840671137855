import ErrorTypes from "enums/errorTypes";
import LoaderTypes from "enums/loaderTypes";
import TranslationMapper from "i18n/mapper";
import LanguageProvider from "providers/languageProvider";
import { Component } from "react";
import { connect } from "react-redux";
import { RootState } from "store/reducers/rootReducer";

import NotificationCreatedImg from "../../assets/img/illustration/notification-created.svg";
import NotificationFailedImg from "../../assets/img/illustration/notification-failed.svg";
import Loader from "../../components/loader/loader";
import INotificationFinishedProps, { INotificationFinishedStateProps } from "./interfaces/INotificationFinishedProps";

class NotificationFinished extends Component<INotificationFinishedProps> {
  public render(): JSX.Element {
    return (
      <>
        {this.props.isLoading && (
          <div className="d-flex justify-content-center">
            <Loader isLoading={this.props.isLoading} />
          </div>
        )}
        {!this.props.isLoading && !this.props.showError && (
          <div className="modal__message">
            <img src={NotificationCreatedImg} alt="" />
            <div className="modal__message__block">
              <h2 className="text-infographic">
                {LanguageProvider.t(TranslationMapper.pages.notificationwizard.created)}
              </h2>
              <p>{LanguageProvider.t(TranslationMapper.pages.notificationwizard.shown)}</p>
            </div>
          </div>
        )}
        {!this.props.isLoading && this.props.showError && (
          <div className="modal__message">
            <img src={NotificationFailedImg} alt="" />
            <div className="modal__message__block">
              <h2 className="text-infographic">
                {LanguageProvider.t(TranslationMapper.pages.notificationwizard.failed)}
              </h2>
              <p>{LanguageProvider.t(TranslationMapper.pages.notificationwizard.try_again)}</p>
            </div>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state: RootState): INotificationFinishedStateProps => ({
  isLoading: state.generalState.loaders.some(loader => loader === LoaderTypes.NotificationCreate),
  showError: state.errorState.errors.some(error => error === ErrorTypes.NotificationCreate),
});

export default connect(mapStateToProps)(NotificationFinished);
