import "moment/locale/nl";

import { FilterMenu } from "components/filterMenu/filterMenu";
import IFilterValue from "components/filterMenu/interfaces/IFilterValue";
import IMultiSelectDropdownField from "components/filterMenu/interfaces/IMultiSelectDropdownField";
import { IReactTableData } from "components/material/table/interfaces/IReactTableProps";
import {
  IReactTableRowSubComponentColumnProps,
  IReactTableRowSubComponentRowProps,
} from "components/material/table/interfaces/IReactTableRowSubComponentProps";
import { ReactTable } from "components/material/table/reactTable";
import ReactTableHelper from "components/material/table/reactTableHelper";
import { ReactTableRowExpander } from "components/material/table/reactTableRowExpander";
import { ReactTableRowSelector } from "components/material/table/reactTableRowSelector";
import { ReactTableRowSubComponent } from "components/material/table/reactTableRowSubComponent";
import Role from "enums/role";
import TranslationMapper from "i18n/mapper";
import ICleaningPlanningOverview from "interfaces/ICleaningPlanningOverview";
import IOperator from "interfaces/IOperator";
import moment from "moment";
import LanguageProvider from "providers/languageProvider";
import * as React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Column, Row } from "react-table";
import { RootState } from "store/reducers/rootReducer";
import FilterUtils from "utils/filterUtils";
import { capitalizeFirstLetter, replaceStringPlaceholders } from "utils/stringUtils";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import PageHeader from "../../../components/header/pageHeader";
import RouteWizard from "../../../components/routeWizard/routeWizard";
import { RoutingLinks } from "../../../constants/routingLinks";
import LoaderTypes from "../../../enums/loaderTypes";
import PageHeaderManager from "../../../models/pageHeaderManager";
import CustomerProvider from "../../../providers/customerProvider";
import { withTelemetry } from "../../../services/telemetryService";
import { showCustomerSelection } from "../../../store/actions/customerActions";
import { deactivateRoute, getRoutesForCustomer } from "../../../store/actions/scheduleActions";
import AppEventHub, { AppEvents } from "../../../utils/appEventHub";
import IRoutesOverviewProps, {
  IRoutesOverviewDispatchProps,
  IRoutesOverviewStateProps,
} from "./interfaces/IRoutesOverviewProps";
import IRoutesOverviewState, { IScheduleFilter } from "./interfaces/IRoutesOverviewState";

type routesTableData = ICleaningPlanningOverview & IReactTableData;

class RoutesOverview extends React.Component<IRoutesOverviewProps, IRoutesOverviewState> {
  private readonly tableDataPropertyIndex: routesTableData = {
    // Used to create typesafety for accessors in tableData
    id: "",
    name: "",
    active: false,
    operators: [],
    locationName: "",
    plannedMonday: false,
    plannedTuesday: false,
    plannedWednesday: false,
    plannedThursday: false,
    plannedFriday: false,
    plannedSaturday: false,
    plannedSunday: false,
    scheduledTime: "",
    scheduledEndTime: "",
    key: "",
  };

  public constructor(props: IRoutesOverviewProps) {
    super(props);

    const customer = CustomerProvider.getActiveCustomer();

    const state: IRoutesOverviewState = {
      customer: customer,
      activeItemLabel: customer ? customer.name : LanguageProvider.t(TranslationMapper.pages.routes.title),
      filter: {},
      showAddRouteWizard: false,
    };

    this.state = state;

    this.copyRoute = this.copyRoute.bind(this);
    this.deactivateRoute = this.deactivateRoute.bind(this);
    this.onEditButtonClicked = this.onEditButtonClicked.bind(this);
    this.setActiveCustomer = this.setActiveCustomer.bind(this);
    this.selectRoute = this.selectRoute.bind(this);
    this.renderTableOperations = this.renderTableOperations.bind(this);
    this.renderRouteItem = this.renderRouteItem.bind(this);
    this.renderRouteItemRowHeaders = this.renderRouteItemRowHeaders.bind(this);
    this.renderRouteItemRowValues = this.renderRouteItemRowValues.bind(this);
    this.createRoutePage = this.createRoutePage.bind(this);
    this.onCloseWizard = this.onCloseWizard.bind(this);
    this.updateFilter = this.updateFilter.bind(this);
    this.getRouteById = this.getRouteById.bind(this);
    this.getFrequencies = this.getFrequencies.bind(this);

    AppEventHub.on(AppEvents.CustomerSelected, this.setActiveCustomer);
    this.setPageHeader();
  }

  public componentDidMount(): void {
    this.setActiveCustomer();
    this.props.showCustomerSelection(true);
    this.setPageHeader();
  }

  public componentWillUnmount(): void {
    AppEventHub.off(AppEvents.CustomerSelected, this.setActiveCustomer);
    PageHeaderManager.clear();
  }

  private updateFilter(propertyName: string, selectedOptions: IFilterValue[] | undefined): void {
    const filter: IScheduleFilter = {
      ...this.state.filter,
      [propertyName]: selectedOptions,
    };

    this.setState({
      filter,
      checkedRouteId: undefined,
    });
  }

  private setPageHeader(): void {
    PageHeaderManager.clear();
    PageHeaderManager.add({
      pageName: "planning",
      tabs: [
        {
          id: "routes",
          relativeUrl: RoutingLinks.planningRoutes,
          translationLabel: TranslationMapper.pages.routes.title,
          roles: [Role.FunctionalAdministrator, Role.Employee],
        },
        {
          id: "activities",
          relativeUrl: RoutingLinks.planningActivities,
          translationLabel: TranslationMapper.pages.activityplanning.title,
          roles: [Role.FunctionalAdministrator, Role.Employee],
        },
      ],
    });
  }

  private get filteredSchedules(): routesTableData[] {
    let filteredSchedules = this.props.routes;

    if (this.state.filter.locations != null && this.state.filter.locations.length > 0) {
      filteredSchedules = filteredSchedules.filter(({ locationName }) =>
        this.state.filter.locations?.some(location => location.value === locationName)
      );
    }

    if (this.state.filter.operators != null && this.state.filter.operators.length > 0) {
      filteredSchedules = filteredSchedules.filter(({ operators }) =>
        this.state.filter.operators?.some(operator => operators.some(o => o.id === operator.value))
      );
    }

    if (this.state.filter.statuses != null && this.state.filter.statuses.length > 0) {
      filteredSchedules = filteredSchedules.filter(({ active }) =>
        this.state.filter.statuses?.some(status => status.value === `${active}`)
      );
    }

    if (this.state.filter.name != null && this.state.filter.name.length > 0) {
      filteredSchedules = filteredSchedules.filter(({ name }) =>
        this.state.filter.name?.some(nameSearch => name.toLowerCase().includes(nameSearch.value.toLowerCase()))
      );
    }

    return filteredSchedules.map(fs => {
      const schedule: routesTableData = {
        ...fs,
        key: fs.id,
      };

      return schedule;
    });
  }

  private get dropdownFields(): IMultiSelectDropdownField[] {
    const locationMultiSelect = FilterUtils.createMultiSelect(
      this.props.locations.map(a => ({ value: a.name, label: a.name })),
      LanguageProvider.t(TranslationMapper.pages.routes.filternames.location),
      (selectedOptions: IFilterValue[]) => {
        this.updateFilter("locations", selectedOptions);
      },
      this.state.filter.locations ?? []
    );

    const operatorMultiSelect = FilterUtils.createMultiSelect(
      this.props.operators.map(a => ({ value: a.id, label: a.name })),
      LanguageProvider.t(TranslationMapper.pages.routes.filternames.operator),
      (selectedOptions: IFilterValue[]) => {
        this.updateFilter("operators", selectedOptions);
      },
      this.state.filter.operators ?? []
    );

    const statusMultiSelect = FilterUtils.createMultiSelect(
      [
        {
          value: "true",
          label: LanguageProvider.t(TranslationMapper.pages.routes.active),
        },
        {
          value: "false",
          label: LanguageProvider.t(TranslationMapper.pages.routes.inactive),
        },
      ],
      LanguageProvider.t(TranslationMapper.pages.routes.filternames.status),
      (selectedOptions: IFilterValue[]) => {
        this.updateFilter("statuses", selectedOptions);
      },
      this.state.filter.statuses ?? []
    );

    const nameSearch = FilterUtils.createSearchFilter(
      LanguageProvider.t(TranslationMapper.pages.routes.filternames.name),
      (keyword: string) => {
        const searchFilterValue: IFilterValue[] | undefined = [{ label: "", value: keyword }];
        this.updateFilter("name", searchFilterValue);
      }
    );

    return [locationMultiSelect, operatorMultiSelect, statusMultiSelect, nameSearch];
  }

  private async copyRoute(): Promise<void> {
    if (this.state.checkedRouteId == null) {
      return;
    }

    const route = this.getRouteById(this.state.checkedRouteId);
    const confirmationMessage = replaceStringPlaceholders(
      LanguageProvider.t(TranslationMapper.pages.routes.copyconfirmation),
      route?.name ?? ""
    );

    const confirmation = window.confirm(confirmationMessage);
    if (confirmation === true) {
      this.setState({
        showAddRouteWizard: true,
        isCopiedRoute: true,
      });
    }
  }

  public createRoutePage(): void {
    this.setState({
      checkedRouteId: undefined,
      showAddRouteWizard: true,
      isCopiedRoute: false,
    });
  }

  public onCloseWizard(): void {
    this.setState({
      showAddRouteWizard: false,
      isCopiedRoute: false,
    });
  }

  public async deactivateRoute(): Promise<void> {
    const activeCustomer = CustomerProvider.getActiveCustomer();

    if (this.state.checkedRouteId == null || !activeCustomer) {
      return;
    }

    const route = this.getRouteById(this.state.checkedRouteId);
    const confirmationMessage = replaceStringPlaceholders(
      LanguageProvider.t(TranslationMapper.pages.routes.deactivateconfirmation),
      route?.name ?? ""
    );

    const confirmation = window.confirm(confirmationMessage);

    if (!confirmation) {
      return;
    }

    this.props.deactivateRoute(activeCustomer.id, this.state.checkedRouteId);
    this.setState({
      checkedRouteId: undefined,
      isCopiedRoute: false,
    });
  }

  private getRouteById(id: string): routesTableData | undefined {
    return this.filteredSchedules.find(fs => fs.id === id);
  }

  public onEditButtonClicked(): void {
    if (!this.state.checkedRouteId) {
      return;
    }

    const route = this.getRouteById(this.state.checkedRouteId);
    if (!route) {
      return;
    }

    this.setState({
      checkedRouteId: this.state.checkedRouteId,
      showAddRouteWizard: true,
      isCopiedRoute: false,
    });
  }

  private async setActiveCustomer(): Promise<void> {
    const activeCustomer = CustomerProvider.getActiveCustomer();

    if (!activeCustomer) {
      return;
    }

    this.props.getRoutesForCustomer(activeCustomer.id);
    this.setState({
      customer: activeCustomer,
      activeItemLabel: activeCustomer.name,
      checkedRouteId: undefined,
      filter: {},
    });
  }

  private selectRoute(routeId: string): void {
    const isRowCurrentlyChecked = this.state.checkedRouteId != null && routeId === this.state.checkedRouteId;

    this.setState({
      checkedRouteId: isRowCurrentlyChecked ? undefined : routeId,
    });
  }

  private getFrequencies(rowData: routesTableData): string {
    let frequencies = "";
    frequencies += rowData.plannedMonday
      ? `${LanguageProvider.t(TranslationMapper.pages.routes.frequencies.monday)} `
      : "";
    frequencies += rowData.plannedTuesday
      ? `${LanguageProvider.t(TranslationMapper.pages.routes.frequencies.tuesday)} `
      : "";
    frequencies += rowData.plannedWednesday
      ? `${LanguageProvider.t(TranslationMapper.pages.routes.frequencies.wednesday)} `
      : "";
    frequencies += rowData.plannedThursday
      ? `${LanguageProvider.t(TranslationMapper.pages.routes.frequencies.thursday)} `
      : "";
    frequencies += rowData.plannedFriday
      ? `${LanguageProvider.t(TranslationMapper.pages.routes.frequencies.friday)} `
      : "";
    frequencies += rowData.plannedSaturday
      ? `${LanguageProvider.t(TranslationMapper.pages.routes.frequencies.saturday)} `
      : "";
    frequencies += rowData.plannedSunday
      ? `${LanguageProvider.t(TranslationMapper.pages.routes.frequencies.sunday)} `
      : "";

    frequencies = frequencies.slice(0, -1);
    return frequencies;
  }

  public get columns(): Column[] {
    return [
      {
        id: "td-selector",
        width: 36,
        disableSortBy: true,
        Cell: ({ row }): JSX.Element => (
          <ReactTableRowSelector onChange={this.selectRoute} row={row} selectedRowKey={this.state.checkedRouteId} />
        ),
      },
      {
        Header: LanguageProvider.t(TranslationMapper.pages.routes.routename),
        id: ReactTableHelper.getPropertyNameAsString(this.tableDataPropertyIndex, x => x.name),
        accessor: (rowOriginal): string => capitalizeFirstLetter((rowOriginal as routesTableData).name),
        width: "auto",
      },
      {
        Header: LanguageProvider.t(TranslationMapper.pages.routes.location),
        id: ReactTableHelper.getPropertyNameAsString(this.tableDataPropertyIndex, x => x.locationName),
        accessor: (rowOriginal): string => capitalizeFirstLetter((rowOriginal as routesTableData).locationName),
        width: "auto",
      },
      {
        Header: LanguageProvider.t(TranslationMapper.pages.routes.starttime),
        id: ReactTableHelper.getPropertyNameAsString(this.tableDataPropertyIndex, x => x.scheduledTime),
        accessor: ReactTableHelper.getPropertyNameAsString(this.tableDataPropertyIndex, x => x.scheduledTime),
        width: "auto",
        Cell: ({ value }): JSX.Element => <>{moment(value, "HH:mm:ss").format("HH:mm")}</>,
      },
      {
        id: "frequency",
        Header: LanguageProvider.t(TranslationMapper.pages.routes.frequence),
        width: "auto",
        accessor: (originalRow): string => this.getFrequencies(originalRow),
      },
      {
        id: "active",
        width: 100,
        Header: LanguageProvider.t(TranslationMapper.pages.routes.active),
        accessor: (originalRow): string[] => this.getRouteStatusIconClass(originalRow.active),
        Cell: ({ value }): JSX.Element => (
          <div className={`text-${value[1]}`}>
            <FontAwesomeIcon icon={["fas", value[0]]} fixedWidth size="lg" />
          </div>
        ),
      },
      {
        id: "expander",
        disableSortBy: true,
        width: 36,
        Cell: ({ row }): JSX.Element => <ReactTableRowExpander row={row} />,
      },
    ];
  }

  private getRouteStatusIconClass(active: boolean): string[] {
    return active ? ["circle-check", "success"] : ["circle-xmark", "warning"];
  }

  private hasOperators(routesTableData: routesTableData): boolean {
    return routesTableData.operators != null && routesTableData.operators.length > 0;
  }

  private renderRouteItemRowHeaders(routesTableData: routesTableData): IReactTableRowSubComponentColumnProps[] {
    const columns: IReactTableRowSubComponentColumnProps[] = [
      {
        content: <>&nbsp;</>,
      },
      {
        className: ReactTableHelper.ContentHeader,
        colSpan: 5,
        content: <h3>{LanguageProvider.t(TranslationMapper.pages.routes.operator)}</h3>,
      },
      {
        content: <>&nbsp;</>,
      },
    ];

    return columns;
  }

  private renderRouteItemRowValues(routesTableData: routesTableData): IReactTableRowSubComponentColumnProps[] {
    const columns: IReactTableRowSubComponentColumnProps[] = [
      {
        content: <>&nbsp;</>,
      },
      {
        className: ReactTableHelper.ContentField,
        colSpan: 5,
        content: (
          <ul>
            {this.hasOperators(routesTableData)
              ? routesTableData.operators.map(o => <li key={o.id}>{o.name}</li>)
              : "-"}
          </ul>
        ),
      },
      {
        content: <>&nbsp;</>,
      },
    ];

    return columns;
  }

  private renderRouteItem(row: Row): JSX.Element {
    const rowData = row.original as routesTableData;

    const subComponentRows: IReactTableRowSubComponentRowProps[] = [
      {
        columns: this.renderRouteItemRowHeaders(rowData),
      },
      {
        columns: this.renderRouteItemRowValues(rowData),
      },
    ];

    return <ReactTableRowSubComponent rows={subComponentRows} />;
  }

  private renderTableOperations(): JSX.Element | void {
    if (!this.state.customer) {
      return;
    }

    return (
      <div className="header-actions__buttons">
        <button
          className="btn btn-primary btn--rounded"
          disabled={this.state.checkedRouteId == null}
          onClick={this.deactivateRoute}
        >
          <FontAwesomeIcon icon={["fal", "trash"]} fixedWidth />
        </button>
        <button
          className="btn btn-primary btn--rounded"
          disabled={this.state.checkedRouteId == null}
          onClick={this.copyRoute}
        >
          <FontAwesomeIcon icon={["fal", "copy"]} fixedWidth />
        </button>
        <button
          className="btn btn-primary btn--rounded"
          disabled={this.state.checkedRouteId == null}
          onClick={this.onEditButtonClicked}
        >
          <FontAwesomeIcon icon={["fal", "pen"]} fixedWidth />
        </button>
        <button className="btn btn-primary btn--rounded" onClick={this.createRoutePage}>
          <FontAwesomeIcon icon={["fal", "plus"]} fixedWidth />
        </button>
      </div>
    );
  }

  public render(): JSX.Element {
    return (
      <>
        <header className="header-actions">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 header-actions__content">
                <h1>{LanguageProvider.t(TranslationMapper.pages.routes.pagetitle)}</h1>
                {this.renderTableOperations()}
              </div>
            </div>
          </div>
        </header>

        <PageHeader pageName="planning" />

        <FilterMenu filterFields={this.dropdownFields} />

        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 mb-2 block-content--px">
                <ReactTable
                  isLoading={this.props.isLoading}
                  columns={this.columns}
                  data={this.filteredSchedules ?? []}
                  renderRowSubComponent={(data): JSX.Element => this.renderRouteItem(data)}
                  className="table--bordered table--has-checkbox table--has-expander"
                />
              </div>
            </div>
          </div>
        </div>

        {this.state.showAddRouteWizard && (
          <RouteWizard
            onClose={this.onCloseWizard}
            selectedRouteId={this.state.checkedRouteId}
            isCopiedRoute={this.state.isCopiedRoute}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state: RootState): IRoutesOverviewStateProps => {
  const operators: IOperator[] =
    state.operatorState.operators != null && state.customerState.selectedCustomerId != null
      ? state.operatorState.operators[state.customerState.selectedCustomerId] ?? []
      : [];

  const routes = state.scheduleManagementState.routes ?? [];

  return {
    isLoading: state.generalState.loaders.some(l => l === LoaderTypes.Routes),
    routes,
    locations: state.customerState.customerLocations ?? [],
    operators,
  };
};

const mapDispatchToProps: IRoutesOverviewDispatchProps = {
  getRoutesForCustomer: getRoutesForCustomer,
  deactivateRoute: deactivateRoute,
  showCustomerSelection: showCustomerSelection,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withTelemetry(RoutesOverview, "RoutesOverview")));
