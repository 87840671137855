import ICustomer from "interfaces/ICustomer";
import ICustomerActivity from "interfaces/ICustomerActivity";
import ICustomerNotificationCategory from "interfaces/ICustomerNotificationCategory";
import ICustomerProspect from "interfaces/ICustomerProspect";
import INotificationCategoryContact from "interfaces/INotificationCategoryContact";
import IVenue from "interfaces/IVenue";

export interface ICustomerStoreState {
  customers: ICustomer[];
  customerProspects?: ICustomerProspect[];
  customerNotificationCategories?: ICustomerNotificationCategory[];
  customerNotificationCategoryContacts?: Map<string, INotificationCategoryContact[]>;
  customerActivities?: ICustomerActivity[];
  customerLocations?: IVenue[];
  customerLocationOverview?: IVenue[];
  hasLogbook?: boolean;
  hasOperation?: boolean;
  selectedCustomerId?: string;
  showCustomerSelection: boolean;
}

export const initialCustomerStoreState: ICustomerStoreState = {
  customers: [],
  showCustomerSelection: false,
};
