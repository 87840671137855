import LanguageProvider from "providers/languageProvider";
import * as React from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import IAsideNavItemProps from "./interfaces/IAsideNavItemProps";
import UnreadMessages from "./unreadMessages/unreadMessages";

class AsideNavItem extends React.Component<IAsideNavItemProps> {
  public render(): JSX.Element {
    return (
      <>
        {this.props.sideBarItem.show && (
          <li className="nav-item">
            <Link
              to={this.props.sideBarItem.linkTo}
              className={`nav-link${this.props.sideBarItem.active ? " active" : ""}`}
              data-test={this.props.sideBarItem.dataTestAttr}
            >
              <FontAwesomeIcon icon={this.props.sideBarItem.icon} fixedWidth size="lg" />
              {LanguageProvider.t(this.props.sideBarItem.translateId)}
              <UnreadMessages linkTo={this.props.sideBarItem.linkTo} />
            </Link>
            {this.props.sideBarItem.subItems && this.props.sideBarItem.subItems.find(i => i.show) && (
              <ul className="nav nav--sub flex-column">
                {this.props.sideBarItem.subItems
                  .filter(i => i.show)
                  .map(item => (
                    <li className="nav-item" key={item.translateId}>
                      <Link
                        key={item.linkTo}
                        className={`nav-link${item.active ? " active" : ""}`}
                        to={item.linkTo}
                        data-test={item.dataTestAttr}
                      >
                        {LanguageProvider.t(item.translateId)}
                      </Link>
                    </li>
                  ))}
              </ul>
            )}
          </li>
        )}
      </>
    );
  }
}

export default withTranslation()(AsideNavItem);
