import { TranslationMapper } from "i18n/mapper";
import moment, { Moment } from "moment";
import LanguageProvider from "providers/languageProvider";

export default class DateUtils {
  public static getMonthShortName(date: Date): string {
    const monthLabels = [
      TranslationMapper.global.date_formats.months_shorthand.january,
      TranslationMapper.global.date_formats.months_shorthand.february,
      TranslationMapper.global.date_formats.months_shorthand.march,
      TranslationMapper.global.date_formats.months_shorthand.april,
      TranslationMapper.global.date_formats.months_shorthand.may,
      TranslationMapper.global.date_formats.months_shorthand.june,
      TranslationMapper.global.date_formats.months_shorthand.july,
      TranslationMapper.global.date_formats.months_shorthand.august,
      TranslationMapper.global.date_formats.months_shorthand.september,
      TranslationMapper.global.date_formats.months_shorthand.october,
      TranslationMapper.global.date_formats.months_shorthand.november,
      TranslationMapper.global.date_formats.months_shorthand.december,
    ];
    const monthLabel = monthLabels[date.getMonth()];
    return LanguageProvider.t(monthLabel);
  }

  public static IsNullOrUndefined(date?: Date): boolean {
    return date === undefined || date === null;
  }

  public static getToday(): Date {
    return moment(new Date()).startOf("day").toDate();
  }

  public static setTime(date: Date, hour: number, minute: number): Date {
    return moment(date).hour(hour).minute(minute).second(0).millisecond(0).toDate();
  }

  public static getTimeDifferenceInMinutes(dateTimeA: Date, dateTimeB: Date): number {
    const diffMinutes = Math.round((dateTimeA.getTime() - dateTimeB.getTime()) / 1000 / 60);

    return diffMinutes;
  }

  public static combineDateAndTime(date: Date, time: Date): Date {
    const newDate = new Date(time);
    newDate.setFullYear(date.getFullYear());
    newDate.setMonth(date.getMonth());
    newDate.setDate(date.getDate());

    return newDate;
  }

  public static formatDateAsISO(date: Date): string {
    return moment(date).toISOString(false);
  }

  public static formatDateForApi(date: Date): string {
    return moment(date).format("YYYY-MM-DD");
  }

  public static formatInputToTime(input: string | undefined): string {
    return moment(input, "HH:mm").format("HH:mm");
  }

  public static addDurationToDate(date: Date, duration: moment.Duration): Date {
    const dateAsMoment = moment(date);
    const result = dateAsMoment.add(duration).toDate();
    return result;
  }

  public static convertMinutesToMs(minutes: number): number {
    return minutes * 60000;
  }

  public static getTimeString(date: Date): string {
    return moment(date).format("H:mm");
  }

  public static getDateString(date: Date): string {
    return moment(date).format("DD-MM-YYYY");
  }

  public static getShorthandDateTimeString(date: Date): string {
    const formatDate = (d: Moment | Date): string => moment(d).format("D");
    const formattedTime = this.getTimeString(date);
    return `${formatDate(date)} ${this.getMonthShortName(date).toLowerCase()} ${formattedTime}`;
  }

  public static getDateStringOrLabel(date: Date): string {
    const getDateWithoutTime = (d: Date): number => new Date(d).setUTCHours(0, 0, 0, 0);

    const isToday = getDateWithoutTime(new Date()) === getDateWithoutTime(date);
    const todayText = LanguageProvider.t(TranslationMapper.global.date_formats.today);
    const time = this.getTimeString(date);

    return isToday ? `${todayText} ${time}` : DateUtils.getShorthandDateTimeString(date);
  }
}
