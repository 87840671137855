import { IInputChangeEvent } from "components/interfaces/IInputChangeEvent";
import { Button } from "components/material/buttons/buttons";
import RadioButton from "components/material/radiobutton/radiobutton";
import TranslationMapper from "i18n/mapper";
import LanguageProvider from "providers/languageProvider";
import * as React from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import ReactDOM from "react-dom";
import { withTranslation } from "react-i18next";

import { ExportType } from "./interfaces/ExportType";
import IActivityExportModalProps from "./interfaces/IActivityExportModalProps";
import IActivityExportModalState from "./interfaces/IActivityExportModalState";

class ActivityExportModal extends React.Component<IActivityExportModalProps, IActivityExportModalState> {
  private readonly formName: string = "activity-export-form";
  private readonly datatest: string = "activity-export-modal";

  public constructor(props: IActivityExportModalProps) {
    super(props);
    const state: IActivityExportModalState = {
      show: true,
      selectedExportType: ExportType.ActivityLogging,
    };

    this.state = state;

    this.handleSave = this.handleSave.bind(this);
    this.cancelForm = this.cancelForm.bind(this);
    this.clearForm = this.clearForm.bind(this);
  }

  private cancelForm(): void {
    this.clearForm();
    this.props.onClose();
  }

  private clearForm(): void {
    this.setState({
      show: false,
    });
  }

  private handleSave(): void {
    this.props.onSubmit(this.state.selectedExportType);
    this.clearForm();
  }

  private onRadioButtonChange(event: IInputChangeEvent<any>): void {
    if (!event.target.value) {
      return;
    }

    this.setState({
      selectedExportType: event.target.value as ExportType,
    });
  }

  private get modalHook(): HTMLElement {
    let modalHook = document.getElementById("modal");
    if (!modalHook) {
      modalHook = document.createElement("div");
      modalHook.setAttribute("id", "portal");
      document.body.appendChild(modalHook);
    }

    return modalHook;
  }

  private get isActivityExportTypeSelected(): boolean {
    return this.state.selectedExportType === ExportType.ActivityLogging;
  }

  private get isFeedbackExportTypeSelected(): boolean {
    return this.state.selectedExportType === ExportType.ActivityFeedbackLogging;
  }

  public renderFields(): JSX.Element {
    return (
      <Row>
        <Col sm={8}>
          <Form.Group>
            <Form.Label>{LanguageProvider.t(TranslationMapper.pages.activitylogging.exportmodal.subtitle)}</Form.Label>
            <Row>
              <Col sm={6}>
                <RadioButton
                  id={ExportType.ActivityLogging.toString()}
                  name="radiobutton-exporttype-group"
                  checked={this.isActivityExportTypeSelected}
                  label={LanguageProvider.t(TranslationMapper.pages.activitylogging.exportmodal.activities_label)}
                  onChange={(event): void => this.onRadioButtonChange(event)}
                  value={ExportType.ActivityLogging}
                />
              </Col>
              <Col sm={6}>
                <RadioButton
                  id={ExportType.ActivityFeedbackLogging.toString()}
                  name="radiobutton-exporttype-group"
                  checked={this.isFeedbackExportTypeSelected}
                  label={LanguageProvider.t(TranslationMapper.pages.activitylogging.exportmodal.feedback_label)}
                  onChange={(event): void => this.onRadioButtonChange(event)}
                  value={ExportType.ActivityFeedbackLogging}
                />
              </Col>
            </Row>
          </Form.Group>
        </Col>
      </Row>
    );
  }

  public render(): JSX.Element {
    return (
      <>
        {this.modalHook &&
          ReactDOM.createPortal(
            <Modal
              backdrop="static"
              show={this.state.show}
              onHide={this.cancelForm}
              data-testid={this.datatest}
              centered
            >
              <Modal.Header closeButton>
                <div className="modal-header__info">
                  <h1 className="modal-title">
                    {LanguageProvider.t(TranslationMapper.pages.activitylogging.exportmodal.title)}
                  </h1>
                </div>
              </Modal.Header>
              <Modal.Body>
                <Form data-test={this.datatest} id={this.formName}>
                  {this.renderFields()}
                </Form>
              </Modal.Body>
              <Modal.Footer className="d-flex justify-content-between">
                <Button
                  className="btn-outline-secondary"
                  onClick={this.cancelForm}
                  resourceLabel={LanguageProvider.t(TranslationMapper.buttons.cancel)}
                  iconEnd="times"
                />
                <Button
                  className="btn-primary"
                  onClick={(): void => this.handleSave()}
                  iconEnd="arrow-down-to-line"
                  resourceLabel={LanguageProvider.t(TranslationMapper.buttons.export)}
                />
              </Modal.Footer>
            </Modal>,
            this.modalHook
          )}
      </>
    );
  }
}

export default withTranslation()(ActivityExportModal);
