import { Guid } from "guid-typescript";
import ICustomerVenueDetails from "./interfaces/ICustomerVenueDetails";

export default class CustomerVenueValidator {
  public static areDetailsValid(details: ICustomerVenueDetails): boolean {
    const externalVenueIdMustBeValid = details.externalVenueId.trim() === "";
    const isExternalVenueIdValid = details.externalVenueId.length === 0 || Guid.isGuid(details.externalVenueId);
    const isNameValid = details.name.trim() !== "";
    const isValid = isNameValid && (externalVenueIdMustBeValid ? isExternalVenueIdValid : true);
    return isValid;
  }

  public static areNfcTagsValid(isScanningRequired: boolean, nfcTags?: string[]): boolean {
    if (isScanningRequired && (nfcTags == null || nfcTags.length === 0)) {
      return false;
    }

    if (nfcTags == null || nfcTags?.length === 0) {
      return true;
    }

    if (nfcTags?.some((n) => n?.length < 6)) {
      return false;
    }

    const findDuplicates = nfcTags.filter((item, index) => nfcTags.indexOf(item) !== index);

    return findDuplicates.length === 0;
  }
}
