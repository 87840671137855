import Source from "constants/sources";
import { Flags } from "featureFlags/flags";
import CustomerProvider from "providers/customerProvider";
import FeatureFlagProvider from "providers/featureFlagProvider";
import DateUtils from "utils/dateUtils";
import { ScriptUtil } from "utils/scriptUtil";

import { getCustomerActivityTypes } from "./actions/activityActions";
import {
    getCustomerProspects, getCustomers, updateHasOperation, updateSelectedCustomer
} from "./actions/customerActions";
import {
    checkFlagsExpiration, getFeatureFlagsAsync, updateFlagsWithStorageValue
} from "./actions/featureflagActions";
import { getCustomerIncidentTypes } from "./actions/incidentTypeActions";
import { getCustomerLocations } from "./actions/locationActions";
import { fetchLogbookForUser } from "./actions/logbookActions";
import { getOperatorsAsync } from "./actions/operatorActions";
import { fetchUser } from "./actions/userActions";
import { AppAction } from "./appAction";

export function fetchInitialConfiguration(): any {
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const result = async dispatch => {
    dispatch(fetchUser());
    dispatch(getCustomers());
    dispatch(getCustomerProspects(Source.DigitalCleaningPlatform));

    const activeCustomer = CustomerProvider.getActiveCustomer();

    if (activeCustomer != null) {
      dispatch(getCustomerActivityTypes(activeCustomer.id));
      dispatch(getCustomerLocations(activeCustomer.id));
      dispatch(getOperatorsAsync(activeCustomer.id));
      dispatch(getCustomerIncidentTypes(activeCustomer.id));
      dispatch(updateSelectedCustomer(activeCustomer.id));
      dispatch(updateHasOperation(activeCustomer.hasOperation));
    }

    dispatch(ensureFlagsUpToDate());
    dispatch(getFeatureFlagsAsync(false)).then(() => {
      dispatch(fetchLogbookForUser(false));
      if (FeatureFlagProvider.isFeatureActive(Flags.CleaningManagement_FF_Is_Clarity_Active)) {
        ScriptUtil.addClarityScript();
      }
    }); // this fetchLogbookForUser is a failsafe because first fetch can be skipped due to flags
  };

  return result;
}

function ensureFlagsUpToDate(): AppAction {
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const result = dispatch => {
    // Check for storage updates and update flags on change
    window.addEventListener("storage", (e: StorageEvent) => dispatch(updateFlagsWithStorageValue(e)));

    // Check validity of flags on time interval. SetInterval isn't possible on dispatches
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    (function _r() {
      setTimeout(() => {
        dispatch(checkFlagsExpiration());
        _r();
      }, DateUtils.convertMinutesToMs(5));
    })();
  };
  return result;
}