import { CmApiAuthenticator } from "authenticators/cmApiAuthenticator";
import { ApplicationConfig } from "config";
import IZone from "interfaces/IZone";

import BaseService from "./baseService";

export default class LocationService extends BaseService {
  public constructor() {
    super(ApplicationConfig.endpoints.winvisionCleaningManagementApi, new CmApiAuthenticator());
  }

  public async getCleaningObjectsForLocationAsync(locationId: string): Promise<IZone[]> {
    return this.get<IZone[]>(`location/${locationId}/cleaningobjects`);
  }
}