import {
  displayCustomerSelection,
  fetchedCustomerProspects,
  fetchedCustomers,
  updateHasLogbook,
  updateHasOperation,
  updateSelectedCustomer,
} from "./actions/customerActions";
import {
  emptyRequiredLocationFloorNFCList,
  fetchedCleaningObjectsForLocation,
  fetchedCustomerLocationOverview,
  fetchedCustomerLocations,
  fetchedRequiredLocationFloorNFCList,
  fetchedSelectedVenue,
  updateSelectedVenueIsCheckInRequired,
  updateSelectedVenueIsFloorNfcRequired,
  updateSelectedVenueOnGroupByFloors,
} from "./actions/locationActions";
import {
  fetchedActivities,
  fetchedNotificationCategories,
  fetchedSelectedActivity,
  fetchedSelectedNotificationCategory,
} from "./actions/masterDataActions";
import {
  fetchedCustomerActivities,
  fetchedCustomerActivityAction,
  fetchedCustomerActivityTypes,
  fetchedCustomerVenueOperators,
} from "./actions/activityActions";
import { fetchedCustomerNotificationContacts, fetchedNotifications } from "./actions/notificationActions";
import {
  fetchedCustomerRouteLogging,
  fetchedExpandedRouteLogging,
  fetchedRoutes,
  fetchedSelectedRoute,
} from "./actions/scheduleActions";
import { fetchedExternalVenue, uploadedLocationFile } from "./actions/externalVenueActions";
import { fetchedHasRouteLoggingDeletePermission, fetchedUser, updatedUserSettings } from "./actions/userActions";
import { fetchedLogbookChannelForUser, fetchedLogbookForUser } from "./actions/logbookActions";
import { finishLoading, startLoading } from "./actions/loaderActions";
import { resetError, setError } from "./actions/errorActions";

import { fetchedCustomerIncidentTypes } from "./actions/incidentTypeActions";
import { fetchedCustomerNotificationCategories } from "./actions/customerDataActions";
import { fetchedFeatureFlags } from "./actions/featureflagActions";
import { fetchedOperators } from "./actions/operatorActions";
import { interceptedError } from "../error/errorAction";

export enum ActionTypes {
  START_LOADING = "START_LOADING",
  FINISH_LOADING = "FINISH_LOADING",
  FETCHED_FEATUREFLAGS = "FETCHED_FEATUREFLAGS",
  FETCHED_CUSTOMERS = "FETCHED_CUSTOMERS",
  FETCHED_CUSTOMER_PROSPECTS = "FETCHED_CUSTOMER_PROSPECTS",
  FETCHED_OPERATORS = "FETCHED_OPERATORS",
  FETCHED_CUSTOMER_INCIDENT_TYPES = "FETCHED_CUSTOMER_INCIDENT_TYPES",
  FETCHED_EXTERNAL_VENUE = "FETCHED_EXTERNAL_VENUE",
  FETCHED_ROUTES = "FETCHED_ROUTES",
  FETCHED_SELECTED_ROUTE = "FETCHED_SELECTED_ROUTE",
  FETCHED_SELECTED_VENUE = "FETCHED_SELECTED_VENUE",
  FETCHED_NOTIFICATIONS = "FETCHED_NOTIFICATIONS",
  FETCHED_USER = "FETCHED_USER",
  INTERCEPTED_ERROR = "INTERCEPTED_ERROR",
  UPDATE_SELECTED_CUSTOMER = "UPDATE_SELECTED_CUSTOMER",
  UPDATE_USER_SETTINGS = "UPDATE_USER_SETTINGS",
  FETCHED_ACTIVITIES = "FETCHED_ACTIVITIES",
  FETCHED_SELECTED_ACTIVITY = "FETCHED_SELECTED_ACTIVITY",
  FETCHED_CUSTOMERACTIVITIES = "FETCHED_CUSTOMERACTIVITIES",
  FETCHED_CUSTOMER_NOTIFICATION_CATEGORIES = "FETCHED_CUSTOMER_NOTIFICATION_CATEGORIES",
  FETCHED_CUSTOMERACTIVITYACTION = "FETCHED_CUSTOMERACTIVITYACTION",
  FETCHED_CUSTOMERVENUE_OPERATORS = "FETCHED_CUSTOMERVENUE_OPERATORS",
  FETCHED_CLEANING_OBJECTS = "FETCHED_CLEANING_OBJECTS",
  FETCHED_CUSTOMER_LOCATIONS = "FETCHED_CUSTOMER_LOCATIONS",
  FETCHED_CUSTOMER_LOCATION_OVERVIEW = "FETCHED_CUSTOMER_LOCATION_OVERVIEW",
  FETCHED_CUSTOMER_ACTIVITYTYPES = "FETCHED_CUSTOMER_ACTIVITYTYPES",
  FETCHED_CUSTOMER_ROUTE_LOGGING = "FETCHED_CUSTOMER_ROUTE_LOGGING",
  FETCHED_EXPANDED_CUSTOMER_ROUTE_LOGGING = "FETCHED_EXPANDED_CUSTOMER_ROUTE_LOGGING",
  SET_ERROR = "SET_ERROR",
  RESET_ERROR = "RESET_ERROR",
  SHOW_CUSTOMER_SELECTION = "SHOW_CUSTOMER_SELECTION",
  FETCHED_NOTIFICATION_CATEGORIES = "FETCHED_NOTIFICATION_CATEGORIES",
  FETCHED_SELECTED_NOTIFICATION_CATEGORY = "FETCHED_SELECTED_NOTIFICATION_CATEGORY",
  UPLOADED_LOCATION_FILE = "UPLOADED_LOCATION_FILE",
  FETCHED_LOGBOOK = "FETCHED_LOGBOOK",
  FETCHED_LOGBOOK_CHANNEL = "FETCHED_LOGBOOK_CHANNEL",
  UPDATE_HAS_LOGBOOK = "UPDATE_HAS_LOGBOOK",
  UPDATE_HAS_OPERATION = "UPDATE_HAS_OPERATION",
  FETCHED_HAS_ROUTE_LOGGING_DELETE_PERMISSION = "FETCHED_HAS_ROUTE_LOGGING_DELETE_PERMISSION",
  FETCHED_CUSTOMER_NOTIFICATION_CONTACTS = "FETCHED_CUSTOMER_NOTIFICATION_CONTACTS",
  FETCHED_REQUIRED_LOCATION_FLOOR_NFC_LIST = "FETCHED_REQUIRED_LOCATION_FLOOR_NFC_LIST",
  SELECTED_VENUE_IS_FLOOR_NFCTAGS_REQUIRED = "SELECTED_VENUE_ISFLOORNFCTAGSREQUIRED",
  EMPTY_REQUIRED_LOCATION_FLOOR_NFC_LIST = "EMPTY_REQUIRED_LOCATION_FLOOR_NFC_LIST",
  SELECTED_VENUE_IS_CHECKIN_REQUIRED = "SELECTED_VENUE_IS_CHECKIN_REQUIRED",
  SELECTED_VENUE_GROUP_BY_FLOORS = "SELECTED_VENUE_GROUP_BY_FLOORS",
}

export type ActionTypesCollection =
  | ReturnType<typeof startLoading>
  | ReturnType<typeof finishLoading>
  | ReturnType<typeof setError>
  | ReturnType<typeof resetError>
  | ReturnType<typeof fetchedFeatureFlags>
  | ReturnType<typeof fetchedCustomers>
  | ReturnType<typeof fetchedCustomerProspects>
  | ReturnType<typeof fetchedOperators>
  | ReturnType<typeof fetchedCustomerIncidentTypes>
  | ReturnType<typeof fetchedExternalVenue>
  | ReturnType<typeof fetchedRoutes>
  | ReturnType<typeof fetchedSelectedRoute>
  | ReturnType<typeof fetchedSelectedVenue>
  | ReturnType<typeof updateSelectedCustomer>
  | ReturnType<typeof updatedUserSettings>
  | ReturnType<typeof interceptedError>
  | ReturnType<typeof fetchedActivities>
  | ReturnType<typeof fetchedSelectedActivity>
  | ReturnType<typeof fetchedCustomerActivities>
  | ReturnType<typeof fetchedCustomerActivityAction>
  | ReturnType<typeof fetchedCustomerActivityTypes>
  | ReturnType<typeof fetchedCustomerNotificationCategories>
  | ReturnType<typeof fetchedCustomerLocations>
  | ReturnType<typeof fetchedCustomerVenueOperators>
  | ReturnType<typeof fetchedCustomerLocationOverview>
  | ReturnType<typeof fetchedCustomerRouteLogging>
  | ReturnType<typeof fetchedExpandedRouteLogging>
  | ReturnType<typeof fetchedNotifications>
  | ReturnType<typeof fetchedCleaningObjectsForLocation>
  | ReturnType<typeof displayCustomerSelection>
  | ReturnType<typeof fetchedNotificationCategories>
  | ReturnType<typeof fetchedSelectedNotificationCategory>
  | ReturnType<typeof fetchedUser>
  | ReturnType<typeof uploadedLocationFile>
  | ReturnType<typeof fetchedLogbookForUser>
  | ReturnType<typeof fetchedLogbookChannelForUser>
  | ReturnType<typeof updateHasLogbook>
  | ReturnType<typeof updateHasOperation>
  | ReturnType<typeof fetchedHasRouteLoggingDeletePermission>
  | ReturnType<typeof fetchedCustomerNotificationContacts>
  | ReturnType<typeof fetchedRequiredLocationFloorNFCList>
  | ReturnType<typeof updateSelectedVenueIsFloorNfcRequired>
  | ReturnType<typeof emptyRequiredLocationFloorNFCList>
  | ReturnType<typeof updateSelectedVenueIsCheckInRequired>
  | ReturnType<typeof updateSelectedVenueOnGroupByFloors>;
