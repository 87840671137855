import LanguageProvider from "providers/languageProvider";
import * as React from "react";

import IconImageNotFound from "../../assets/img/image-not-found.svg";
import IIncidentTypeItemProps from "./interfaces/IIncidentTypeItemProps";

export default class IncidentTypeItem extends React.Component<IIncidentTypeItemProps> {
  public constructor(props: IIncidentTypeItemProps) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  public render(): JSX.Element {
    const name = this.props.name !== "" ? this.props.name : LanguageProvider.t("images.namenotfound");
    const imageUri = this.props.active ? this.props.incidentType.icons.active : this.props.incidentType.icons.inActive;
    const imageExists =
      (this.props.active && this.props.incidentType.icons.activeImageExists) ||
      (!this.props.active && this.props.incidentType.icons.inactiveImageExists);

    return (
      <button
        onClick={this.onClick}
        type="button"
        title={name}
        className={`btn btn--image-label mb-3 ${this.props.active ? "active" : "inactive"}`}
      >
        <img src={imageExists ? imageUri : IconImageNotFound} alt="" />
        <div>{name}</div>
      </button>
    );
  }

  private onClick(): void {
    if (this.props.selectingDisabled) {
      return;
    }

    this.props.onItemClick(this.props.incidentType);
  }
}
