import ICustomerNotificationCategoryContacts from "interfaces/ICustomerNotificationCategoryContacts";
import INotificationCategoryContact from "interfaces/INotificationCategoryContact";
import INotificationRequest from "interfaces/INotificationRequest";

import { CmApiAuthenticator } from "../authenticators/cmApiAuthenticator";
import { ApplicationConfig } from "../config";
import BaseService from "./baseService";

export default class NotificationService extends BaseService {
  public constructor() {
    super(ApplicationConfig.endpoints.winvisionCleaningManagementApi, new CmApiAuthenticator());
  }

  public async createNotificationAsync(notification: INotificationRequest): Promise<Response> {
    return this.post(`notifications`, notification);
  }

  public async upsertNotificationCategoryContactAsync(
    customerId: string,
    data: INotificationCategoryContact
  ): Promise<Response> {
    return this.post(`customers/${customerId}/notificationcategorycontacts`, data);
  }

  public async getNotificationCategoryContactsByCategoryIdAsync(
    customerId: string,
    categoryId: string
  ): Promise<INotificationCategoryContact[]> {
    return this.get(`customers/${customerId}/notificationcategorycontacts/${categoryId}`);
  }

  public async getCustomerNotificationCategoryContactsAsync(
    customerId: string
  ): Promise<ICustomerNotificationCategoryContacts[]> {
    return this.get(`customers/${customerId}/notificationcategorycontacts`);
  }

  public async deleteNotificationCategoryContactAsync(customerId: string, contactId: string): Promise<Response> {
    return this.delete(`customers/${customerId}/notificationcategorycontacts/${contactId}`);
  }
}