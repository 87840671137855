import { IReactTableData } from "components/material/table/interfaces/IReactTableProps";
import { ReactTable } from "components/material/table/reactTable";
import ReactTableHelper from "components/material/table/reactTableHelper";
import { ReactTableRowExpander } from "components/material/table/reactTableRowExpander";
import { ReactTableRowSelector } from "components/material/table/reactTableRowSelector";
import NotificationStatus from "enums/notificationStatus";
import Role from "enums/role";
import TranslationMapper from "i18n/mapper";
import INotificationOverview from "interfaces/INotificationOverview";
import IReactSelectValue from "interfaces/IReactSelectValue";
import moment from "moment";
import LanguageProvider from "providers/languageProvider";
import { Component } from "react";
import { Column, Row } from "react-table";

import ExportFormatter from "../components/exportFormatter";
import INotificationsTableProps from "./interfaces/INotificationsTableProps";
import NotificationOverviewSubcomponent from "./notificationOverviewSubcomponent";

export type NotificationTableData = INotificationOverview & IReactTableData;

export default class NotificationsTable extends Component<INotificationsTableProps> {
  private readonly tableDataPropertyIndex: NotificationTableData = {
    // Used to create typesafety for accessors in tableData
    cleaningObjectName: "",
    createdBy: "",
    createdOn: new Date(),
    finishedOn: new Date(),
    id: "",
    key: "",
    notificationCategoryName: "",
    routeName: "",
    status: NotificationStatus.Open,
  };

  public constructor(props: INotificationsTableProps) {
    super(props);

    this.setActiveRow = this.setActiveRow.bind(this);
    this.renderSubComponent = this.renderSubComponent.bind(this);
  }

  private get columns(): Column[] {
    return [
      {
        id: "table-row-selector",
        width: 36,
        disableSortBy: true,
        Cell: ({ row }): JSX.Element => (
          <ReactTableRowSelector
            onChange={this.setActiveRow}
            row={row}
            multiSelectRowKeys={this.props.selectedNotificationIds}
          />
        ),
      },
      {
        Header: LanguageProvider.t(TranslationMapper.pages.notifications.routename),
        id: ReactTableHelper.getPropertyNameAsString(this.tableDataPropertyIndex, x => x.routeName),
        accessor: ReactTableHelper.getPropertyNameAsString(this.tableDataPropertyIndex, x => x.routeName),
        width: "auto",
      },
      {
        Header: LanguageProvider.t(TranslationMapper.pages.notifications.zone),
        id: ReactTableHelper.getPropertyNameAsString(this.tableDataPropertyIndex, x => x.cleaningObjectName),
        accessor: ReactTableHelper.getPropertyNameAsString(this.tableDataPropertyIndex, x => x.cleaningObjectName),
        width: "auto",
      },
      {
        Header: LanguageProvider.t(TranslationMapper.pages.notifications.datenotification),
        id: ReactTableHelper.getPropertyNameAsString(this.tableDataPropertyIndex, x => x.createdOn),
        accessor: ReactTableHelper.getPropertyNameAsString(this.tableDataPropertyIndex, x => x.createdOn),
        width: "auto",
        hideColumn: !this.showColumn([Role.FunctionalAdministrator, Role.Employee, Role.Customer]),
        Cell: ({ value }): string => moment(value).format("DD-MM-YYYY"),
      },
      {
        Header: LanguageProvider.t(TranslationMapper.pages.notifications.notificationby),
        accessor: ReactTableHelper.getPropertyNameAsString(this.tableDataPropertyIndex, x => x.createdBy),
        id: ReactTableHelper.getPropertyNameAsString(this.tableDataPropertyIndex, x => x.createdBy),
        width: "auto",
        hideColumn: !this.showColumn([Role.FunctionalAdministrator, Role.Employee, Role.Customer]),
      },
      {
        Header: LanguageProvider.t(TranslationMapper.pages.notifications.categorie),
        id: ReactTableHelper.getPropertyNameAsString(this.tableDataPropertyIndex, x => x.notificationCategoryName),
        accessor: ReactTableHelper.getPropertyNameAsString(
          this.tableDataPropertyIndex,
          x => x.notificationCategoryName
        ),
        width: "auto",
        hideColumn: !this.showColumn([Role.FunctionalAdministrator, Role.Employee, Role.Customer]),
      },
      {
        Header: LanguageProvider.t(TranslationMapper.pages.notifications.status),
        id: ReactTableHelper.getPropertyNameAsString(this.tableDataPropertyIndex, x => x.status),
        accessor: (rowOriginal): string => {
          const status = NotificationStatus[rowOriginal.status];
          return LanguageProvider.t(TranslationMapper.pages.notifications.statusnames[status.toLowerCase()]);
        },
        width: "auto",
        hideColumn: !this.showColumn([Role.FunctionalAdministrator, Role.Employee, Role.Customer]),
      },
      {
        id: "expander",
        disableSortBy: true,
        width: 36,
        Cell: ({ row }): JSX.Element => <ReactTableRowExpander row={row} />,
      },
    ];
  }

  private get notificationData(): NotificationTableData[] {
    const notifications = this.props.data;

    return notifications.map(n => {
      const notification: NotificationTableData = {
        ...n,
        key: n.id,
      };

      return notification;
    });
  }

  private get notificationStatusOptions(): IReactSelectValue[] {
    return Object.entries(NotificationStatus)
      .filter(entry => !isNaN(entry[0] as any))
      .map(entry => ({
        value: Number.parseInt(entry[0]),
        label: ExportFormatter.getNotificationStatusTranslation(parseInt(entry[0])),
      }));
  }

  private renderSubComponent(row: Row): JSX.Element {
    const rowData = row.original as NotificationTableData;
    const notification = this.props.clickedNotifications.item(rowData.id);

    return (
      <NotificationOverviewSubcomponent
        notification={notification}
        row={row}
        clickedNotifications={this.props.clickedNotifications}
        notifications={this.props.data}
        notificationStatusOptions={this.notificationStatusOptions}
        visibleColumnLength={this.visibleColumnLength}
        onResponseButtonClick={this.props.onResponseButtonClick}
        onUpdateNotificationStatus={this.props.onUpdateNotificationStatus}
      />
    );
  }

  private showColumn(roles: Role[]): boolean {
    return roles && roles.some(r => r === this.props.userRole);
  }

  private get visibleColumnLength(): number {
    return ReactTableHelper.getNumberOfVisibleColumns(this.columns);
  }

  private setActiveRow(rowKey: string): void {
    const selectedNotificationIds = [...this.props.selectedNotificationIds];
    const indexOfSelectedNotification = selectedNotificationIds.findIndex(
      ni => ni.toLowerCase() === rowKey.toLowerCase()
    );

    if (indexOfSelectedNotification === -1) {
      selectedNotificationIds.push(rowKey);
    } else {
      selectedNotificationIds.splice(indexOfSelectedNotification, 1);
    }

    this.props.onUpdateSelectedNotifications(selectedNotificationIds);
  }

  public render(): JSX.Element {
    return (
      <ReactTable
        columns={this.columns}
        data={this.notificationData}
        defaultColumnToSortBy={{
          id: "createdOn",
          desc: true,
        }}
        isLoading={this.props.isLoading}
        renderRowSubComponent={this.renderSubComponent}
        className="table--bordered table--has-checkbox table--has-expander"
      />
    );
  }
}
