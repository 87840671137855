import { ActionTypes, ActionTypesCollection } from "../actionTypes";
import { ILocationStoreState, initialLocationStoreState } from "store/state/locationStoreState";

import produce from "immer";

export default function locationReducer(
  state: ILocationStoreState = initialLocationStoreState,
  action: ActionTypesCollection
): ILocationStoreState {
  switch (action.type) {
    case ActionTypes.FETCHED_SELECTED_VENUE:
      return {
        ...state,
        selectedVenue: action.payload,
      };
    case ActionTypes.FETCHED_EXTERNAL_VENUE: {
      let updatedExternalVenues = [...state.externalVenues] ?? [];
      const index = updatedExternalVenues.findIndex((e) => e.id === action.payload.id);

      if (index >= 0) {
        updatedExternalVenues[index] = action.payload;
      } else {
        updatedExternalVenues = [...updatedExternalVenues, action.payload];
      }

      return { ...state, externalVenues: updatedExternalVenues };
    }
    case ActionTypes.FETCHED_CUSTOMERVENUE_OPERATORS:
      return {
        ...state,
        customerVenueOperators: action.payload,
      };
    case ActionTypes.UPLOADED_LOCATION_FILE:
      return {
        ...state,
        uploadLocationResult: action.payload,
      };
    case ActionTypes.SELECTED_VENUE_IS_FLOOR_NFCTAGS_REQUIRED:
      return produce(state, (draft) => {
        if (draft.selectedVenue) {
          draft.selectedVenue.isFloorNFCRequired = action.payload ?? false;
        }
      });
    case ActionTypes.SELECTED_VENUE_IS_CHECKIN_REQUIRED:
      return produce(state, (draft) => {
        if (draft.selectedVenue) {
          draft.selectedVenue.isCheckInRequired = action.payload ?? false;
        }
      });
    case ActionTypes.SELECTED_VENUE_GROUP_BY_FLOORS:
      return produce(state, (draft) => {
        if (draft.selectedVenue) {
          draft.selectedVenue.groupByFloors = action.payload ?? false;
        }
      });
    case ActionTypes.FETCHED_REQUIRED_LOCATION_FLOOR_NFC_LIST:
      return produce(state, (draft) => {
        draft.requiredFloorNFCTagObject = action.payload ?? undefined;
      });
    case ActionTypes.EMPTY_REQUIRED_LOCATION_FLOOR_NFC_LIST:
      return produce(state, (draft) => {
        draft.requiredFloorNFCTagObject = undefined;
      });
    default:
      return state;
  }
}
