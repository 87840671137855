import Loader from "components/loader/loader";
import { Button } from "components/material/buttons/buttons";
import TranslationMapper from "i18n/mapper";
import LanguageProvider from "providers/languageProvider";
import * as React from "react";
import { Form, Modal } from "react-bootstrap";
import ReactDOM from "react-dom";
import { withTranslation } from "react-i18next";

import INotificationReactionPost from "../../../interfaces/INotificationReactionPost";
import CleaningManagementService from "../../../services/cleaningManagementService";
import INotificationResponseModalProps from "./interfaces/INotificationResponseModalProps";
import INotificationResponseModalState from "./interfaces/INotificationResponseModalState";

class NotificationResponseModal extends React.Component<
  INotificationResponseModalProps,
  INotificationResponseModalState
> {
  private readonly cleaningManagementService: CleaningManagementService;

  public constructor(props: INotificationResponseModalProps) {
    super(props);
    this.state = {
      responseText: "",
      sendingResponse: false,
      responseSend: false,
      responsePosted: false,
    };

    this.cleaningManagementService = new CleaningManagementService();

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleTextChange = this.handleTextChange.bind(this);
    this.resetForm = this.resetForm.bind(this);
  }

  private handleTextChange(event: any): void {
    this.setState({ responseText: event.target.value });
  }
  private async handleSubmit(event: any): Promise<void> {
    event.preventDefault();
    const response: INotificationReactionPost = {
      description: this.state.responseText,
    };

    this.setState({ sendingResponse: true });

    const notificationResponse = await this.cleaningManagementService.postNotificationReaction(
      this.props.notificationId,
      response
    );

    const responsePosted = notificationResponse != null && notificationResponse.id != null;

    this.setState({
      sendingResponse: false,
      responseSend: true,
      responsePosted: responsePosted,
    });
    if (responsePosted) {
      this.props.updateReactions(notificationResponse, this.props.notificationId);
    }
  }

  private get modalHook(): HTMLElement {
    let modalHook = document.getElementById("modal");
    if (!modalHook) {
      modalHook = document.createElement("div");
      modalHook.setAttribute("id", "portal");
      document.body.appendChild(modalHook);
    }

    return modalHook;
  }

  private resetForm(): void {
    this.setState({
      responseSend: false,
      responseText: "",
    });
    this.props.onClose();
  }

  public render(): JSX.Element {
    return (
      <>
        {this.modalHook &&
          ReactDOM.createPortal(
            <Modal backdrop="static" show={true} onHide={this.resetForm} centered>
              <Modal.Header closeButton>
                <div className="modal-header__info">
                  <h1 className="modal-title">
                    {!this.state.responseSend &&
                      LanguageProvider.t(TranslationMapper.pages.notifications.responsedialog.title)}
                    {this.state.responseSend &&
                      !this.state.sendingResponse &&
                      LanguageProvider.t(
                        TranslationMapper.pages.notifications.responsedialog[
                          this.state.responsePosted ? "responsesend" : "responsefailed"
                        ]
                      )}
                  </h1>
                </div>
              </Modal.Header>
              <Form>
                {!this.state.responseSend && !this.state.sendingResponse && (
                  <>
                    <Modal.Body>
                      <textarea
                        className="form-control"
                        maxLength={2000}
                        rows={7}
                        placeholder={LanguageProvider.t(
                          TranslationMapper.pages.notifications.responsedialog.placeholder
                        )}
                        value={this.state.responseText}
                        onChange={this.handleTextChange}
                      />
                    </Modal.Body>
                    <Modal.Footer className="d-flex justify-content-between">
                      <Button
                        onClick={this.resetForm}
                        resourceLabel={LanguageProvider.t(TranslationMapper.buttons.cancel)}
                        className="btn-outline-secondary"
                        iconEnd="xmark"
                      />
                      <Button
                        onClick={this.handleSubmit}
                        resourceLabel={LanguageProvider.t(
                          TranslationMapper.pages.notifications.responsedialog.sendresponse
                        )}
                        className="btn-primary"
                        iconEnd="arrow-right"
                      />
                    </Modal.Footer>
                  </>
                )}
                {!this.state.responseSend && this.state.sendingResponse && (
                  <Modal.Body className="d-flex justify-content-center">
                    <Loader isLoading={this.state.sendingResponse} size={8} />
                  </Modal.Body>
                )}
                {this.state.responseSend && !this.state.sendingResponse && (
                  <Modal.Footer className="d-flex justify-content-end">
                    <Button
                      onClick={this.resetForm}
                      resourceLabel={LanguageProvider.t(TranslationMapper.pages.notifications.responsedialog.close)}
                      className="btn-primary"
                      iconEnd="xmark"
                    />
                  </Modal.Footer>
                )}
              </Form>
            </Modal>,
            this.modalHook
          )}
      </>
    );
  }
}

export default withTranslation()(NotificationResponseModal);
