import "moment/locale/nl";

import i18next from "i18next";
import ILanguage from "interfaces/ILanguage";
import * as moment from "moment";
import LanguageProvider from "providers/languageProvider";
import { Component } from "react";

export default class LanguageToggle extends Component {
  private toggleLanguage(lang: string): void {
    LanguageProvider.language = lang;
    moment.locale(lang);
  }

  public render(): JSX.Element {
    return (
      <div className="d-flex">
        {LanguageProvider.languages.map((language: ILanguage) => (
          <button
            className={`btn language-option${language.key === i18next.language ? " selected" : ""}`}
            key={language.key}
            onClick={(): void => this.toggleLanguage(language.key)}
          >
            <img src={language.flag} alt={language.label} />
            <h4 className="mb-0">{language.label}</h4>
          </button>
        ))}
      </div>
    );
  }
}
