import * as msal from "@azure/msal-browser";

import IApplicationConfig from "./interfaces/IApplicationConfig";

export const AuthenticationConfig: msal.Configuration = {
  auth: {
    clientId: process.env.REACT_APP_clientId || "",
    authority: process.env.REACT_APP_authority || "",
    redirectUri: process.env.REACT_APP_rootUrl || "",
  },
  cache: {
    cacheLocation: "localStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO.
    storeAuthStateInCookie: false, // If you wish to store cache items in cookies as well as browser cache, set this to "true".
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: msal.LogLevel, message: string, containsPii: boolean): void => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case msal.LogLevel.Error:
            console.error(message);
            return;
        }
      },
      piiLoggingEnabled: false,
    },
  },
};

export const ApplicationConfig: IApplicationConfig = {
  endpoints: {
    winvisionCleaningApi: process.env.REACT_APP_endpoints_cleaningApi || "",
    winvisionCleaningManagementApi: process.env.REACT_APP_endpoints_cleaningManagementApi || "",
  },
  scopes: {
    app: [process.env.REACT_APP_cm_App_scope || ""],
    cmApi: [process.env.REACT_APP_cm_Api_scope || ""],
  },
  environment: process.env.REACT_APP_environment || "",
  tenant: "common",
  clientId: process.env.REACT_APP_clientId || "",
  cacheLocation: "localStorage",
  // "true".toLowerCase since this makes it a non-constant string. When we use variable injection on the .env values
  // we need this, since otherwise constant string === constant string is optimized to a boolean during build (before we inject the .env values).
  disableCosmeticPermissions: process.env.REACT_APP_disableCosmeticPermissions === "true".toLowerCase() || false,
};