import ReactNotification from "components/reactNotification/reactNotification";
import TranslationMapper from "i18n/mapper";
import ISimpleRouteLogging from "interfaces/ISimpleRouteLogging";
import moment from "moment";
import React from "react";
import { CSVLink } from "react-csv";
import CleaningManagementService from "services/cleaningManagementService";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ExportFormatter from "../../components/exportFormatter";
import IRouteLoggingExportProps from "./interfaces/IRouteLoggingExportProps";
import IRouteLoggingExportState from "./interfaces/IRouteLoggingExportState";

export class RoutesLoggingExport extends React.Component<IRouteLoggingExportProps, IRouteLoggingExportState> {
  private readonly cleaningManagementService: CleaningManagementService;
  public CSVDownloadRef: React.RefObject<{ link: { click: () => void } }>;

  public constructor(props: IRouteLoggingExportProps) {
    super(props);

    this.cleaningManagementService = new CleaningManagementService();

    const state: IRouteLoggingExportState = {
      exportDisabled: this.props.recordIds.length <= 0,
      isDownloading: false,
      csvData: [],
    };

    this.CSVDownloadRef = React.createRef();

    this.state = state;
    this.getRouteLogging = this.getRouteLogging.bind(this);
    this.download = this.download.bind(this);
    this.onExportClick = this.onExportClick.bind(this);
  }

  public componentDidUpdate(prevProps: IRouteLoggingExportProps): void {
    const loggingChanged = this.props.recordIds.length !== prevProps.recordIds.length;
    if (loggingChanged) {
      this.setState({
        exportDisabled: this.props.recordIds.length <= 0,
      });
    }
  }

  private async getRouteLogging(): Promise<void> {
    const records = this.props.recordIds;
    if (records.length <= 0) {
      return;
    }

    if (records.length > 50) {
      ReactNotification.error(TranslationMapper.pages.logging.downloadlimitreached, 30000);

      return;
    }

    ReactNotification.info(TranslationMapper.pages.logging.downloadstartedmessage);
    this.setState({
      exportDisabled: true,
      isDownloading: true,
    });

    try {
      const expandedLogsForExport = await this.cleaningManagementService.getLoggingForExport(records.join(","));
      if (expandedLogsForExport.length <= 0) {
        ReactNotification.error(TranslationMapper.pages.logging.downloaderrormessage);
      }

      const logging = this.props.logging as ISimpleRouteLogging[];
      const formattedData = ExportFormatter.formatRouteLogging(expandedLogsForExport, logging);

      this.setState(
        {
          csvData: formattedData,
          exportDisabled: false,
          isDownloading: false,
        },
        () => this.download()
      );
    } catch (error) {
      ReactNotification.error(TranslationMapper.pages.logging.downloaderrormessage);
      this.setState({
        exportDisabled: false,
        isDownloading: false,
      });
    }
  }

  private download(): void {
    const CSVDownloadLink = this.CSVDownloadRef.current;
    if (CSVDownloadLink && this.state.csvData.length > 0) {
      // Initiates download on in browser after data is loaded
      CSVDownloadLink.link.click();
    }
  }

  private onExportClick(): void {
    if (this.state.exportDisabled) {
      return;
    }

    if (this.state.isDownloading) {
      ReactNotification.info(TranslationMapper.pages.logging.downloadbeingpreparedmessage);
      return;
    }

    this.getRouteLogging();
  }

  private get exportButton(): JSX.Element {
    return (
      <button
        className="btn btn-primary btn--rounded"
        disabled={this.state.exportDisabled}
        onClick={this.onExportClick}
      >
        <FontAwesomeIcon icon={["fal", "arrow-down-to-line"]} fixedWidth />
      </button>
    );
  }

  public render(): JSX.Element {
    const startDateExport = moment(this.props.startDate).format("DD.MM.YY");
    const endDateExport = moment(this.props.endDate).format("DD.MM.YY");

    return (
      <>
        {this.exportButton}

        <CSVLink
          ref={this.CSVDownloadRef}
          data={this.state.csvData}
          filename={`HiFive.RouteLogging.${startDateExport}-${endDateExport}.csv`}
          className=""
          target="_blank"
          separator={";"}
        />
      </>
    );
  }
}
