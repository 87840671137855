import ReactNotification from "components/reactNotification/reactNotification";
import LoaderTypes from "enums/loaderTypes";
import TranslationMapper from "i18n/mapper";
import IChannelMessages from "interfaces/IChannelMessages";
import ILogbook from "interfaces/ILogbook";
import ILogbookMessageRequest from "interfaces/request/ILogbookMessageRequest";
import LogbookService from "services/logbookService";
import { ActionTypes } from "store/actionTypes";
import { actionCreator, AppAction } from "store/appAction";

import { startLoading, unsetAsLoading } from "./loaderActions";

export const fetchedLogbookForUser = actionCreator<ActionTypes.FETCHED_LOGBOOK, ILogbook>(ActionTypes.FETCHED_LOGBOOK);
export const fetchedLogbookChannelForUser = actionCreator<
  ActionTypes.FETCHED_LOGBOOK_CHANNEL,
  IChannelMessages | undefined
>(ActionTypes.FETCHED_LOGBOOK_CHANNEL);

export function fetchLogbookForUser(setLoader: boolean): any {
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  return (dispatch) => {
    const logbookService = new LogbookService();

    if (setLoader) {
      dispatch(startLoading(LoaderTypes.Logbook));
    }

    logbookService
      .getLogbookAsync()
      .then((logbook: ILogbook) => {
        dispatch(fetchedLogbookForUser(logbook));
        fetchedLogbookForUser(logbook);
      })
      .catch(() => ReactNotification.error(TranslationMapper.errors.get_locations, undefined, true))
      .finally(() => dispatch(unsetAsLoading(LoaderTypes.Logbook)));
  };
}

export function fetchLogbookChannelForUser(topicId: string): AppAction {
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  return (dispatch, getState) => {
    const logbookService = new LogbookService();
    const currentTopicId = getState().logbookState.selectedLogbookChannel?.topicId;

    if (currentTopicId == null) {
      dispatch(startLoading(LoaderTypes.LogbookChannel));
    }

    if (currentTopicId != null && currentTopicId !== topicId) {
      // Clear messages on change of topic so we don't show wrong messages while loading
      dispatch(startLoading(LoaderTypes.LogbookChannel));
      dispatch(fetchedLogbookChannelForUser(undefined));
    }

    logbookService
      .getLogbookMessagesByTopicIdAsync(topicId)
      .then((cm) => {
        dispatch(fetchedLogbookChannelForUser(cm));
        dispatch(fetchLogbookForUser(true));
      })
      .catch(() => ReactNotification.error(TranslationMapper.pages.logbook.messages_component.error_fetch_messages))
      .finally(() => dispatch(unsetAsLoading(LoaderTypes.LogbookChannel)));
  };
}

export function sendMessageForUser(topicId: string, message: string, onSuccess: () => void): AppAction {
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  return (dispatch) => {
    const logbookService = new LogbookService();

    dispatch(startLoading(LoaderTypes.LogbookChannel));
    const messageRequest: ILogbookMessageRequest = {
      content: message,
      topicId: topicId,
    };

    logbookService
      .sendLogbookMessageAsync(messageRequest)
      .then(() => {
        onSuccess();
        dispatch(fetchLogbookForUser(true));
      })
      .catch(() => ReactNotification.error(TranslationMapper.pages.logbook.messages_component.error_sending_message))
      .finally(() => dispatch(unsetAsLoading(LoaderTypes.LogbookChannel)));
  };
}
