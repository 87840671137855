import { IInputChangeEvent } from "components/interfaces/IInputChangeEvent";
import IWizardStep from "components/wizard/interfaces/IWizardStep";
import TranslationMapper from "i18n/mapper";
import { Component } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { RootState } from "store/reducers/rootReducer";

import CustomerVenueValidator from "./customerVenueValidator";
import ICustomerFloorNfcTagsStepProps, {
  ICustomerFloorNfcTagsStepStateProps,
} from "./interfaces/ICustomerFloorNfcTagsStepProps";
import ICustomerFloorNfcTagsStepState from "./interfaces/ICustomerFloorNfcTagsStepState";
import { ICustomerVenueWizardStepsEnum } from "./interfaces/ICustomerVenueWizardStepsEnum";
import IRequiredFloorNFCTag from "./interfaces/IRequiredFloorNFCTag";
import IRequiredFloorNFCTagObject from "./interfaces/IRequiredFloorNFCTagObject";

class CustomerFloorNfcTagStep extends Component<ICustomerFloorNfcTagsStepProps, ICustomerFloorNfcTagsStepState> {
  public constructor(props: ICustomerFloorNfcTagsStepProps) {
    super(props);

    this.state = this.props.value ?? {
      isFloorNFCRequired: false,
      requiredFloorNFCTagObject: {} as IRequiredFloorNFCTagObject,
    };

    this.propagateChange = this.propagateChange.bind(this);
  }

  public componentDidMount(): void {
    this.setState({
      isFloorNFCRequired: this.props.isFloorNFCRequired,
      requiredFloorNFCTagObject: this.props.requiredFloorNFCTagObject,
    });
  }

  private propagateChange(): void {
    const isValid = CustomerVenueValidator.areNfcTagsValid(
      this.state.isFloorNFCRequired ?? false,
      (this.state.requiredFloorNFCTagObject?.requiredFloorNFCTags?.map(x => x.checkInCode) as string[]) ?? []
    );
    this.props.onChange({ target: { value: this.state, name: this.props.name }, isValid });
  }

  private onChange(event: IInputChangeEvent<any>, index: number): void {
    // update the value in the correct position of the array
    const updatedNfcTags = [
      ...((this.state.requiredFloorNFCTagObject?.requiredFloorNFCTags ?? []) as IRequiredFloorNFCTag[]),
    ];
    if (updatedNfcTags?.length === 0) {
      return;
    }
    const updatedNfcTag = { ...updatedNfcTags[index] };
    updatedNfcTag.checkInCode = event?.target.value;
    updatedNfcTags[index] = updatedNfcTag;

    this.setState(
      {
        requiredFloorNFCTagObject: { requiredFloorNFCTags: [...updatedNfcTags] },
      },
      this.propagateChange
    );
  }

  private renderNfcTagFields(tag: IRequiredFloorNFCTag, index: number): JSX.Element {
    const requiredFloorNFCTag = this.state.requiredFloorNFCTagObject?.requiredFloorNFCTags
      ? this.state.requiredFloorNFCTagObject.requiredFloorNFCTags[index]
      : undefined;
    return (
      <Form.Group className="mb-3">
        <Form.Label>{tag.floorName}</Form.Label>
        <div key={index}>
          <Form.Control
            onChange={(event): void => this.onChange(event, index)}
            value={requiredFloorNFCTag?.checkInCode ?? ""}
          />
        </div>
      </Form.Group>
    );
  }

  public render(): JSX.Element {
    const formName = "customer-venue-nfctags-edit-form";
    return (
      <Form data-test={formName} id={formName}>
        <Row>
          <Col>
            {this.state.requiredFloorNFCTagObject?.requiredFloorNFCTags?.map((requiredFloorNFCTag, index) => {
              return this.renderNfcTagFields(requiredFloorNFCTag, index);
            })}
          </Col>
        </Row>
      </Form>
    );
  }
}

const mapStateToProps = (state: RootState): ICustomerFloorNfcTagsStepStateProps => {
  return {
    requiredFloorNFCTagObject: state.locationState.requiredFloorNFCTagObject ?? {},
    isFloorNFCRequired: state.locationState.selectedVenue?.isFloorNFCRequired ?? false,
  };
};

const wizardStep: IWizardStep = {
  form: connect(mapStateToProps)(CustomerFloorNfcTagStep),
  titleResource: "",
  subtitleResource: TranslationMapper.pages.customer_venue_edit.wizard.step_3.subtitle,
  name: ICustomerVenueWizardStepsEnum.Step3,
};

export default wizardStep;
