import { HFColorCodes } from "constants/colorCodes";
import CSS from "csstype";
import Slider from "rc-slider";
import { Component } from "react";

import CleaningFeedbackLevel1 from "../../../assets/img/cleaning-feedback-level-1.svg";
import CleaningFeedbackLevel2 from "../../../assets/img/cleaning-feedback-level-2.svg";
import CleaningFeedbackLevel3 from "../../../assets/img/cleaning-feedback-level-3.svg";
import CleaningFeedbackLevel4 from "../../../assets/img/cleaning-feedback-level-4.svg";
import ISliderInputProps from "./interfaces/ISliderInputProps";

const trackStyle: CSS.Properties = {
  backgroundColor: HFColorCodes.Primary,
  height: "10px",
};

const railStyle: CSS.Properties = {
  backgroundColor: HFColorCodes.Gray300,
  height: "10px",
};

const handleStyle: CSS.Properties = {
  backgroundColor: "#ffffff",
  height: "26px",
  width: "26px",
  boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
  marginTop: "-8.5px",
  border: "0px",
};

export class SliderInput extends Component<ISliderInputProps> {
  private generalStyle: CSS.Properties = {
    height: "10px",
    margin: "auto",
    width: this.props.sliderWidth ?? "90%",
  };

  private renderPollutionImage(): string {
    const maxThresholdClean = 25;
    const maxThresholdNeutral = 50;
    const maxThresholdDirty = 75;

    if (!this.props.answer) {
      return CleaningFeedbackLevel1;
    }

    if (this.props.answer <= maxThresholdClean) {
      return CleaningFeedbackLevel1;
    } else if (this.props.answer <= maxThresholdNeutral) {
      return CleaningFeedbackLevel2;
    } else if (this.props.answer <= maxThresholdDirty) {
      return CleaningFeedbackLevel3;
    } else {
      return CleaningFeedbackLevel4;
    }
  }

  public render(): JSX.Element {
    return (
      <>
        <img src={this.renderPollutionImage()} className="modal--activity__pollution-image" />
        <Slider
          min={this.props.min}
          max={this.props.max}
          onAfterChange={this.props.onSelectionChange}
          onChange={this.props.onSliderChange}
          trackStyle={trackStyle}
          railStyle={railStyle}
          handleStyle={handleStyle}
          style={this.generalStyle}
          value={this.props.answer}
          defaultValue={this.props.defaultAnswer}
        />
      </>
    );
  }
}

export default SliderInput;
