import CleaningUserAuthenticator from "authenticators/cleaningUserAuthenticator";
import MainHeader from "components/header/mainHeader";
import { RoutingLinks } from "constants/routingLinks";
import TranslationMapper from "i18n/mapper";
import LanguageProvider from "providers/languageProvider";
import * as React from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import RoutingProvider from "../../../providers/routingProvider";
import IErrorDisplayProps from "./interfaces/IErrorDisplayProps";

class ErrorDisplay extends React.Component<IErrorDisplayProps> {
  private readonly cleaningUserAuthenticator: CleaningUserAuthenticator;

  public constructor(props: IErrorDisplayProps) {
    super(props);

    this.logoutUser = this.logoutUser.bind(this);
    this.retryLoadingPage = this.retryLoadingPage.bind(this);

    this.cleaningUserAuthenticator = new CleaningUserAuthenticator();
  }

  public logoutUser(): void {
    this.cleaningUserAuthenticator.signOut();
  }

  private retryLoadingPage(): void {
    RoutingProvider.invokePushNewRouteWithRefresh(RoutingLinks.home);
  }

  public render(): JSX.Element {
    return (
      <>
        {this.props.showErrorHeader && <MainHeader showCustomerPicker={false} />}

        <header className="header-actions">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 header-actions__content">
                <h1>{LanguageProvider.t(TranslationMapper.errors.uncaught_exception_title)}</h1>
              </div>
            </div>
          </div>
        </header>

        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 mb-2 block-content--px block-content--py">
                <h2>
                  {LanguageProvider.t(
                    this.props && this.props.errorMessage ? this.props.errorMessage : TranslationMapper.errors.general
                  )}
                </h2>
                <small>
                  <p>
                    Error code <span>{this.props.errorCode}</span>&nbsp; (
                    {this.cleaningUserAuthenticator.getUser() && this.cleaningUserAuthenticator.getUser().username
                      ? this.cleaningUserAuthenticator.getUser().username
                      : "Unknown UPN"}
                    )
                  </p>
                  <p>
                    <a
                      className="btn btn-link btn--element-start ps-0"
                      data-test="refresh-page"
                      onClick={this.retryLoadingPage}
                    >
                      <FontAwesomeIcon icon={["fal", "arrow-left"]} fixedWidth className="me-2" />
                      {LanguageProvider.t(TranslationMapper.errors.reload_home)}
                    </a>
                  </p>
                </small>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(withTranslation()(ErrorDisplay));
