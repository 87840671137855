import { IUserStoreState, initialUserStoreState } from "store/state/userStoreState";

import { ActionTypes, ActionTypesCollection } from "../actionTypes";

export default function userReducer(
  state: IUserStoreState = initialUserStoreState,
  action: ActionTypesCollection
): IUserStoreState {
  switch (action.type) {
    case ActionTypes.FETCHED_USER:
      return {
        ...state,
        user: action.payload,
      };
    case ActionTypes.FETCHED_HAS_ROUTE_LOGGING_DELETE_PERMISSION:
      return {
        ...state,
        hasRouteLoggingDeletePermission: action.payload,
      };
    case ActionTypes.UPDATE_USER_SETTINGS:
      return {
        ...state,
        userSettings: action.payload,
      };
    default:
      return state;
  }
}
