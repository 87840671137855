enum Role {
  None = 0,
  FunctionalAdministrator = 1,
  Operator = 2,
  Sync = 3,
  Customer = 4,
  Employee = 5,
  FacilityManager = 6,
  DistrictManager = 7,
  RayonManager = 8,
  ObjectManager = 9,
}

export default Role;