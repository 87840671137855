import IChannelMessages from "interfaces/IChannelMessages";
import ILogbook from "interfaces/ILogbook";
import ILogbookMessageRequest from "interfaces/request/ILogbookMessageRequest";

import { CmApiAuthenticator } from "../authenticators/cmApiAuthenticator";
import { ApplicationConfig } from "../config";
import BaseService from "./baseService";

export default class LogbookService extends BaseService {
  private readonly endpoint: string = "logbook";

  public constructor() {
    super(ApplicationConfig.endpoints.winvisionCleaningManagementApi, new CmApiAuthenticator());
  }

  public async getLogbookAsync(): Promise<ILogbook> {
    await this.get<ILogbook>(this.endpoint);
    const logbook = await this.get<ILogbook>(this.endpoint);
    logbook.channelResponse
      .filter((c) => c.dateTimeLastMessage != null)
      .forEach((c) => (c.dateTimeLastMessage = c.dateTimeLastMessage !== undefined ? new Date(c.dateTimeLastMessage).toISOString(): ""));
    return logbook;
  }

  public async sendLogbookMessageAsync(messageRequest: ILogbookMessageRequest): Promise<Response> {
    return this.post(`${this.endpoint}/message`, messageRequest);
  }

  public async getLogbookMessagesByTopicIdAsync(topicId: string): Promise<IChannelMessages> {
    const response = await this.get<IChannelMessages>(`${this.endpoint}/messages/${topicId}`);
    response.topicId = topicId;
    return response;
  }
}
