import ExternalVenue from "models/externalVenue";
import ICustomerVenue from "interfaces/ICustomerVenue";
import IOperator from "interfaces/IOperator";
import IRequiredFloorNFCTagObject from "components/customerVenueWizard/interfaces/IRequiredFloorNFCTagObject";
import IUploadResponse from "interfaces/IUploadResponse";

export interface ILocationStoreState {
  externalVenues: ExternalVenue[];
  customerVenueOperators?: IOperator[];
  requiredFloorNFCTagObject?: IRequiredFloorNFCTagObject;
  selectedVenue?: ICustomerVenue;
  uploadLocationResult?: IUploadResponse;
}

export const initialLocationStoreState: ILocationStoreState = {
  externalVenues: [],
};
