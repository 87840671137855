import { ChangeEvent, Component, ReactNode } from "react";

import { ICheckboxProps } from "./interfaces/ICheckboxProps";

export default class CheckBox extends Component<ICheckboxProps> {
  public constructor(props: ICheckboxProps) {
    super(props);

    this.onChange = this.onChange.bind(this);
  }

  public onChange(event: ChangeEvent<HTMLInputElement>): void {
    if (this.props.onChange != null) {
      this.props.onChange({
        target: {
          name: this.props["name"],
          value: event.target.checked,
        },
      });
    }
  }

  private get className(): string {
    const baseClass = "custom-control custom-checkbox";

    return this.props.className != null ? `${baseClass} ${this.props.className}` : baseClass;
  }

  public render(): ReactNode {
    return (
      <>
        {this.props.header && <h3 className="form-label">{this.props.header}</h3>}
        <div className={`form-check${this.className ? ` ${this.className}` : ""}`} key={this.props["key"]}>
          <input
            type="checkbox"
            className="form-check-input"
            id={this.props.id}
            onChange={this.onChange}
            checked={this.props.value}
            name={this.props.name}
            disabled={this.props.disabled}
          />
          <label className="form-check-label" htmlFor={this.props.id}>
            {this.props.description}
          </label>
        </div>
      </>
    );
  }
}
