import { IInputChangeEvent } from "components/interfaces/IInputChangeEvent";
import { Button } from "components/material/buttons/buttons";
import FileTypes from "enums/fileTypes";
import { Guid } from "guid-typescript";
import TranslationMapper from "i18n/mapper";
import IFile from "interfaces/IFile";
import INotificationCategoryPost from "interfaces/INotificationCategoryPost";
import _ from "lodash";
import LanguageProvider from "providers/languageProvider";
import { Component, ReactNode } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import ReactDOM from "react-dom";
import { withTranslation } from "react-i18next";
import { NotificationManager } from "react-notifications";
import FileUtils from "utils/fileUtils";
import ValidationUtils from "utils/validationUtils";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import INotificationCategoryDetails from "./interfaces/INotificationCategoryDetails";
import INotificationCategoryModalProps from "./interfaces/INotificationCategoryModalProps";
import INotificationCategoryModalState from "./interfaces/INotificationCategoryModalState";

class NotificationCategoryModal extends Component<INotificationCategoryModalProps, INotificationCategoryModalState> {
  private initialState: INotificationCategoryModalState;

  private initialNotificationCategoryDetails: INotificationCategoryDetails = {
    id: Guid.createEmpty().toString(),
    name: "",
    imageUri: "",

    cleaningImageData: "",
    inactiveImageData: "",
    activeImageData: "",

    cleaningImageName: "",
    inactiveImageName: "",
    activeImageName: "",

    cleaningImageUrl: "",
    activeImageUrl: "",
    inactiveImageUrl: "",

    cleaningImageWrongFormat: false,
    activeImageWrongFormat: false,
    inactiveImageWrongFormat: false,
  };

  public constructor(props: INotificationCategoryModalProps) {
    super(props);

    this.initialState = {
      loading: false,
      notificationCategoryDetails: this.props.value ?? this.initialNotificationCategoryDetails,
      isValid: false,
    };

    this.state = _.cloneDeep(this.initialState);

    this.setActiveImageState = this.setActiveImageState.bind(this);
    this.setInactiveImageState = this.setInactiveImageState.bind(this);
    this.setCleaningImageState = this.setCleaningImageState.bind(this);

    this.onActiveImageChange = this.onActiveImageChange.bind(this);
    this.onCleaningImageChange = this.onCleaningImageChange.bind(this);
    this.onInactiveImageChange = this.onInactiveImageChange.bind(this);

    this.onSave = this.onSave.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  private get details(): INotificationCategoryDetails {
    return this.state?.notificationCategoryDetails ?? this.initialNotificationCategoryDetails;
  }

  private get title(): string {
    return this.props.value
      ? LanguageProvider.t(TranslationMapper.pages.masterdata.notificationcategories.edit)
      : LanguageProvider.t(TranslationMapper.pages.masterdata.notificationcategories.create);
  }

  private get isAllDataValid(): boolean {
    return (
      !ValidationUtils.checkStringIsNullOrEmpty(this.details.name) &&
      !ValidationUtils.checkStringIsNullOrEmpty(this.details.cleaningImageUrl) &&
      !ValidationUtils.checkStringIsNullOrEmpty(this.details.activeImageUrl) &&
      !ValidationUtils.checkStringIsNullOrEmpty(this.details.inactiveImageUrl)
    );
  }

  private get modalHook(): HTMLElement {
    let modalHook = document.getElementById("modal");
    if (!modalHook) {
      modalHook = document.createElement("div");
      modalHook.setAttribute("id", "portal");
      document.body.appendChild(modalHook);
    }

    return modalHook;
  }

  private onChange(event: IInputChangeEvent<any>): void {
    const name = event.target.name;
    const value = event.target.value;

    const details = _.cloneDeep(this.details);
    details[name] = value;

    this.setState({
      notificationCategoryDetails: details,
    });
  }

  private setCleaningImageState(imageFile: IFile): void {
    const details = _.cloneDeep(this.details);
    details.cleaningImageData = imageFile.data;
    details.cleaningImageName = imageFile.name;
    details.cleaningImageUrl = imageFile.url;
    details.cleaningImageWrongFormat = imageFile.wrongFormat;

    this.setState({
      notificationCategoryDetails: details,
    });
  }

  private setActiveImageState(imageFile: IFile): void {
    const details = _.cloneDeep(this.details);
    details.activeImageData = imageFile.data;
    details.activeImageName = imageFile.name;
    details.activeImageUrl = imageFile.url;
    details.activeImageWrongFormat = imageFile.wrongFormat;

    this.setState({
      notificationCategoryDetails: details,
    });
  }

  private setInactiveImageState(imageFile: IFile): void {
    const details = _.cloneDeep(this.details);
    details.inactiveImageData = imageFile.data;
    details.inactiveImageName = imageFile.name;
    details.inactiveImageUrl = imageFile.url;
    details.inactiveImageWrongFormat = imageFile.wrongFormat;

    this.setState({
      notificationCategoryDetails: details,
    });
  }

  private onCleaningImageChange(event: any): void {
    FileUtils.handleFileChange(event, [FileTypes.SVG, FileTypes.PNG], this.setCleaningImageState);
  }

  private onActiveImageChange(event: any): void {
    FileUtils.handleFileChange(event, [FileTypes.SVG, FileTypes.PNG], this.setActiveImageState);
  }

  private onInactiveImageChange(event: any): void {
    FileUtils.handleFileChange(event, [FileTypes.SVG, FileTypes.PNG], this.setInactiveImageState);
  }

  private closeModal(): void {
    this.setState({ ...this.initialState }, () => this.props.onClose());
  }

  private onSave(): void {
    if (!this.isAllDataValid) {
      NotificationManager.error(TranslationMapper.pages.routewizard.error_not_all_required_data);
      return;
    }

    this.upsertNotificationCategory();
    this.closeModal();
  }

  private upsertNotificationCategory(): void {
    const categoryItem: INotificationCategoryPost = {
      id: this.details.id,
      name: this.details.name,
      cleaningImageData: this.details.cleaningImageData ?? null,
      activeImageData: this.details.activeImageData ?? null,
      inActiveImageData: this.details.inactiveImageData ?? null,
    };
    this.props.onSave(categoryItem);
  }

  public render(): ReactNode {
    return (
      <>
        {this.modalHook &&
          ReactDOM.createPortal(
            <Modal
              backdrop="static"
              show={true}
              onHide={this.closeModal}
              dialogClassName="modal__notification-category modal-800w"
              centered
            >
              <Modal.Header closeButton>
                <div className="modal-header__info">
                  <div>
                    <h1 className="modal-title">{this.title}</h1>
                    <h5 className="modal-title">
                      {LanguageProvider.t(TranslationMapper.pages.masterdata.notificationcategories.subtitle)}
                    </h5>
                  </div>
                </div>
              </Modal.Header>
              <Modal.Body>
                <Form data-testid="notification-category-modal-form" id="notification-category-modal-form">
                  <Row>
                    <Col className="modal__col-mb">
                      <Form.Group>
                        <Form.Label>{LanguageProvider.t("pages.masterdata.name")}</Form.Label>
                        <Form.Control
                          id="notification-category-name"
                          name="name"
                          onChange={this.onChange}
                          value={this.details.name}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="modal__col-mb">
                      <label htmlFor="cleaningImageUpload" className="form-label">
                        {LanguageProvider.t(TranslationMapper.pages.masterdata.cleaningimage)}
                      </label>
                      <div className="input-file--container d-flex">
                        {this.details.cleaningImageUrl && (
                          <div className="input-file--item input-file--item--sm rounded-0">
                            <img src={this.details.cleaningImageUrl} alt="" />
                          </div>
                        )}
                        <label htmlFor="cleaningImageUpload" className="input-file--item input-file--item--add">
                          <FontAwesomeIcon icon={["fal", "images"]} fixedWidth size="3x" />
                          <input
                            type="file"
                            className="form-control"
                            id="cleaningImageUpload"
                            name="cleaningImageUpload"
                            accept=".svg,.png"
                            multiple={false}
                            onChange={this.onCleaningImageChange}
                          />
                        </label>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="modal__col-mb">
                      <label htmlFor="activeImageUpload" className="form-label">
                        {LanguageProvider.t(TranslationMapper.pages.masterdata.activeimage)}
                      </label>
                      <div className="input-file--container d-flex">
                        {this.details.activeImageUrl && (
                          <div className="input-file--item input-file--item--sm rounded-0">
                            <img src={this.details.activeImageUrl} alt="" />
                          </div>
                        )}
                        <label htmlFor="activeImageUpload" className="input-file--item input-file--item--add">
                          <FontAwesomeIcon icon={["fal", "images"]} fixedWidth size="3x" />
                          <input
                            type="file"
                            className="form-control"
                            id="activeImageUpload"
                            name="activeImageUpload"
                            accept=".svg,.png"
                            multiple={false}
                            onChange={this.onActiveImageChange}
                          />
                        </label>
                      </div>
                      {this.details.inactiveImageWrongFormat && (
                        <div className="invalid-feedback d-block">
                          {LanguageProvider.t(TranslationMapper.pages.masterdata.imagewrongformat)}
                        </div>
                      )}
                    </Col>
                    <Col className="modal__col-mb">
                      <label htmlFor="inactiveImageUpload" className="form-label">
                        {LanguageProvider.t(TranslationMapper.pages.masterdata.inactiveimage)}
                      </label>
                      <div className="input-file--container d-flex">
                        {this.details.inactiveImageUrl && (
                          <div className="input-file--item input-file--item--sm rounded-0">
                            <img src={this.details.inactiveImageUrl} alt="" />
                          </div>
                        )}
                        <label htmlFor="inactiveImageUpload" className="input-file--item input-file--item--add">
                          <FontAwesomeIcon icon={["fal", "images"]} fixedWidth size="3x" />
                          <input
                            type="file"
                            className="form-control"
                            id="inactiveImageUpload"
                            name="inactiveImageUpload"
                            accept=".svg,.png"
                            multiple={false}
                            onChange={this.onInactiveImageChange}
                          />
                        </label>
                      </div>
                      {this.details.inactiveImageWrongFormat && (
                        <div className="invalid-feedback d-block">
                          {LanguageProvider.t(TranslationMapper.pages.masterdata.imagewrongformat)}
                        </div>
                      )}
                    </Col>
                  </Row>
                </Form>
              </Modal.Body>
              <Modal.Footer className="d-flex justify-content-between">
                <Button
                  className="btn-outline-secondary"
                  onClick={this.closeModal}
                  resourceLabel={LanguageProvider.t(TranslationMapper.buttons.cancel)}
                  iconEnd="times"
                />
                <Button
                  className="btn-primary"
                  disabled={!this.isAllDataValid}
                  resourceLabel={LanguageProvider.t(TranslationMapper.buttons.save)}
                  onClick={this.onSave}
                  iconEnd="floppy-disk"
                />
              </Modal.Footer>
            </Modal>,
            this.modalHook
          )}
      </>
    );
  }
}

export default withTranslation()(NotificationCategoryModal);
