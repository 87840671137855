import { FilterMenu } from "components/filterMenu/filterMenu";
import ISearchField from "components/filterMenu/interfaces/ISearchField";
import { IReactTableData } from "components/material/table/interfaces/IReactTableProps";
import { ReactTable } from "components/material/table/reactTable";
import { ReactTableRowSelector } from "components/material/table/reactTableRowSelector";
import TranslationMapper from "i18n/mapper";
import ICustomer from "interfaces/ICustomer";
import LanguageProvider from "providers/languageProvider";
import { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Column } from "react-table";
import { getCustomerLocationOverview } from "store/actions/locationActions";
import { RootState } from "store/reducers/rootReducer";
import FilterUtils from "utils/filterUtils";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import LoaderTypes from "../../enums/loaderTypes";
import { deleteCustomer, showCustomerSelection } from "../../store/actions/customerActions";
import CustomerEditModal from "./components/customerEditModal";
import ICustomerManagementProps, {
  ICustomerManagementDispatchProps,
  ICustomerManagementStateProps,
} from "./interfaces/ICustomerManagementProps";
import ICustomerManagementState from "./interfaces/ICustomerManagementState";

type customerManagementTableData = ICustomer & IReactTableData;

export class CustomerManagement extends Component<ICustomerManagementProps, ICustomerManagementState> {
  public constructor(props: ICustomerManagementProps) {
    super(props);

    const state: ICustomerManagementState = {
      dialogOpen: false,
      filterText: undefined,
    };

    this.state = state;

    this.onRowClick = this.onRowClick.bind(this);
    this.editCustomerPage = this.editCustomerPage.bind(this);
    this.deleteCustomer = this.deleteCustomer.bind(this);
    this.openEditCustomerModal = this.openEditCustomerModal.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.saveCustomer = this.saveCustomer.bind(this);
    this.updateFilter = this.updateFilter.bind(this);
    this.onRowSelectionChange = this.onRowSelectionChange.bind(this);
  }

  public componentDidMount(): void {
    this.props.showCustomerSelection(false);
  }

  private openEditCustomerModal(): void {
    this.setState({
      dialogOpen: true,
      selectedCustomerId: undefined,
    });
  }

  private updateFilter(keyword: string): void {
    this.setState({
      filterText: keyword,
      selectedCustomerId: undefined,
    });
  }

  private get searchBar(): ISearchField[] {
    const searchBar = FilterUtils.createSearchFilter(
      LanguageProvider.t(TranslationMapper.controls.placeholders.search_customer_name),
      this.updateFilter
    );

    return [searchBar];
  }

  private get customers(): customerManagementTableData[] {
    const data: customerManagementTableData[] = this.props.customers.map(c => {
      const customer: customerManagementTableData = {
        ...c,
        key: c.id,
      };
      return customer;
    });

    const filterText = this.state.filterText;

    if (filterText) {
      return data.filter(customer => customer.name.toLowerCase().includes(filterText.toLowerCase()));
    }

    return data;
  }

  private async deleteCustomer(): Promise<void> {
    const customer = this.customers.find(c => c.id === this.state.selectedCustomerId);
    if (this.state.selectedCustomerId == null || customer == null) {
      return;
    }

    const confirmation = window.confirm(`${LanguageProvider.t(TranslationMapper.pages.masterdata.deleteconfirmation)}
            ${customer.name}`);
    if (!confirmation) {
      return;
    }

    this.props.onDeleteCustomer(this.state.selectedCustomerId);

    this.setState({
      selectedCustomerId: undefined,
    });
  }

  private editCustomerPage(): void {
    if (this.state.selectedCustomerId == null || this.selectedCustomer == null) {
      return;
    }

    this.setState({
      dialogOpen: true,
    });
  }

  public onRowClick(customerId: string): void {
    const clickedCustomer = this.customers.find(c => c.id === customerId);

    if (!clickedCustomer) {
      return;
    }

    this.props.onSelectCustomer(customerId);
    this.props.history.push(`/customerManagement/customerDetails/${customerId}/buildings`);
  }

  private get columns(): Column[] {
    return [
      {
        id: "table-row-selector",
        width: 36,
        disableSortBy: true,
        Cell: ({ row }): JSX.Element => (
          <ReactTableRowSelector
            onChange={this.onRowSelectionChange}
            row={row}
            selectedRowKey={this.state.selectedCustomerId}
          />
        ),
      },
      {
        Header: LanguageProvider.t(TranslationMapper.pages.customermanagement.customername),
        id: "name",
        accessor: "name",
        width: "auto",
      },
      {
        Header: LanguageProvider.t(TranslationMapper.pages.customermanagement.buildings),
        id: "numberOfLocations",
        accessor: "numberOfLocations",
        disableSortBy: true,
        width: "auto",
      },
    ];
  }

  private onRowSelectionChange(customerId: string): void {
    let selectedCustomerId: string | undefined = customerId;

    if (customerId === this.state.selectedCustomerId) {
      selectedCustomerId = undefined;
    }

    this.setState({
      selectedCustomerId: selectedCustomerId,
    });
  }

  private get selectedCustomer(): ICustomer | undefined {
    return this.customers.find(c => c.id === this.state.selectedCustomerId);
  }

  public render(): JSX.Element {
    return (
      <>
        <header className="header-actions">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 header-actions__content">
                <h1>{LanguageProvider.t(TranslationMapper.navigation.customermanagement)}</h1>
                <div className="header-actions__buttons">
                  <button
                    className="btn btn-primary btn--rounded"
                    onClick={this.deleteCustomer}
                    disabled={this.state.selectedCustomerId == null}
                  >
                    <FontAwesomeIcon icon={["fal", "trash"]} fixedWidth />
                  </button>
                  <button
                    className="btn btn-primary btn--rounded"
                    onClick={this.editCustomerPage}
                    disabled={this.state.selectedCustomerId == null}
                  >
                    <FontAwesomeIcon icon={["fal", "pen"]} fixedWidth />
                  </button>
                  <button className="btn btn-primary btn--rounded" onClick={this.openEditCustomerModal}>
                    <FontAwesomeIcon icon={["fal", "plus"]} fixedWidth />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </header>

        <FilterMenu filterFields={this.searchBar} />

        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 block-content--px">
                <ReactTable
                  isLoading={this.props.isLoading}
                  columns={this.columns}
                  data={this.customers ?? []}
                  onRowClick={this.onRowClick}
                  noResultsMessage={LanguageProvider.t(TranslationMapper.pages.routes.norawdatafound)}
                />
              </div>
            </div>
          </div>
        </div>

        {this.state.dialogOpen && (
          <CustomerEditModal customerId={this.state.selectedCustomerId} onClose={this.onCloseModal} />
        )}
      </>
    );
  }

  private saveCustomer(): void {
    this.setState({
      selectedCustomerId: undefined,
    });
  }

  public onCloseModal(): void {
    this.setState({
      dialogOpen: false,
    });
  }
}

const mapStateToProps = (state: RootState): ICustomerManagementStateProps => ({
  isLoading: state.generalState.loaders.some(l => l === LoaderTypes.Customers),
  customers: state.customerState.customers,
});

const mapDispatchToProps: ICustomerManagementDispatchProps = {
  onDeleteCustomer: deleteCustomer,
  showCustomerSelection: showCustomerSelection,
  onSelectCustomer: getCustomerLocationOverview,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(CustomerManagement)));
