import Serializable from "models/serializeable";

import IExternalVenue from "../interfaces/IExternalVenue";
import IFloor from "../interfaces/IFloor";

export default class ExternalVenue extends Serializable implements IExternalVenue {
  public id: string;
  public name: string;
  public floors: IFloor[];
  public isFloorNFCRequired: boolean;

  public constructor() {
    super();
    this.id = "";
    this.name = "";
    this.floors = [];
    this.isFloorNFCRequired = false;
  }
}
