import ActivityItem from "components/categories/activityItem";
import TranslationMapper from "i18n/mapper";
import LanguageProvider from "providers/languageProvider";
import { Component } from "react";

import ICleaningObjectActivitiesProps from "./interfaces/ICleaningObjectActivitiesProps";
import ICleaningObjectActivitiesState from "./interfaces/ICleaningObjectActivitiesState";

export default class CleaningObjectActivities extends Component<
  ICleaningObjectActivitiesProps,
  ICleaningObjectActivitiesState
> {
  public constructor(props: ICleaningObjectActivitiesProps) {
    super(props);

    const state: ICleaningObjectActivitiesState = {
      areActivitiesOpen: false,
    };

    this.state = state;

    this.toggleActivitiesOverview = this.toggleActivitiesOverview.bind(this);
  }

  private onToggleActivity(activityId: string): void {
    this.props.onActivityChange(this.props.routeCleaningObject, activityId);
  }

  private get toggleActivitiesClassName(): string {
    const open = this.state.areActivitiesOpen ? "open" : "";
    return `toggle-zones ${open}`;
  }

  private get activityText(): string {
    const length = this.props.routeCleaningObject.activityIds?.length ?? 0;
    return length === 1
      ? `${length} ${LanguageProvider.t(TranslationMapper.pages.routewizard.cleaning_activities.name)}`
      : `${length} ${LanguageProvider.t(TranslationMapper.pages.routewizard.cleaning_activities.name_plural)}`;
  }

  private toggleActivitiesOverview(): void {
    this.setState({
      areActivitiesOpen: !this.state.areActivitiesOpen,
    });
  }

  private get customerCleaningActivities(): JSX.Element[] {
    return this.props.customerActivities.map(ca => {
      const onToggle = (): void => this.onToggleActivity(ca.activity.id);

      const activitySelected = this.props.routeCleaningObject.activityIds?.find(id => id === ca.activity.id) != null;

      const selectedString = activitySelected ? "_Activity_active" : "_Activity_inactive";
      const imageUri = `${ca.activity.imageUri}${selectedString}`;

      return (
        <ActivityItem
          selectingDisabled={false}
          activity={ca.activity}
          active={activitySelected}
          key={ca.id}
          onItemClick={onToggle}
          name={ca.activity.name}
          imageUri={imageUri}
        />
      );
    });
  }

  public render(): JSX.Element {
    return (
      <>
        <div className="accordion-item">
          <h2 className="accordion-header" id={`heading${this.props.accordionId}`}>
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#collapse${this.props.routeCleaningObject.id}`}
              aria-expanded="false"
              aria-controls={`collapse${this.props.routeCleaningObject.id}`}
            >
              <div className="row w-100">
                <div className="col-6">{this.props.routeCleaningObject.name}</div>
                <div className="col-5">{this.activityText}</div>
              </div>
            </button>
          </h2>
          <div
            id={`collapse${this.props.routeCleaningObject.id}`}
            className="accordion-collapse collapse"
            aria-labelledby={this.props.routeCleaningObject.id}
            data-bs-parent={`#${this.props.accordionId}`}
          >
            <div className="accordion-body" key={this.props.accordionId}>
              {this.customerCleaningActivities}
            </div>
          </div>
        </div>
      </>
    );
  }
}
