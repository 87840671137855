import { FilterMenu } from "components/filterMenu/filterMenu";
import IFilterValue from "components/filterMenu/interfaces/IFilterValue";
import IMultiSelectDropdownField from "components/filterMenu/interfaces/IMultiSelectDropdownField";
import PageHeader from "components/header/pageHeader";
import { RoutingLinks } from "constants/routingLinks";
import ActivityStatus from "enums/activityStatus";
import LoaderTypes from "enums/loaderTypes";
import Role from "enums/role";
import TranslationMapper from "i18n/mapper";
import IActivityAction from "interfaces/IActivityAction";
import IActivityFilter from "interfaces/IActivityFilter";
import PageHeaderManager from "models/pageHeaderManager";
import { ExportType } from "pages/home/components/interfaces/ExportType";
import LanguageProvider from "providers/languageProvider";
import * as React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withTelemetry } from "services/telemetryService";
import { getCustomerActivities } from "store/actions/activityActions";
import { showCustomerSelection } from "store/actions/customerActions";
import { RootState } from "store/reducers/rootReducer";
import DateUtils from "utils/dateUtils";
import FilterUtils from "utils/filterUtils";
import TranslationUtils from "utils/translationUtils";

import { ActivitiesExport } from "../../components/activitiesExport";
import ILoggingActivitiesOverviewProps, {
  ILoggingActivitiesOverviewDispatchProps,
  ILoggingActivitiesOverviewStateProps,
} from "./interfaces/ILoggingActivitiesOverviewProps";
import ILoggingActivitiesOverviewState, {
  ILoggingActivitiesFilter,
} from "./interfaces/ILoggingActivitiesOverviewState";
import LoggingActivitiesTable from "./loggingActivitiesTable";

class LoggingActivitiesOverview extends React.Component<
  ILoggingActivitiesOverviewProps,
  ILoggingActivitiesOverviewState
> {
  public constructor(props: ILoggingActivitiesOverviewProps) {
    super(props);

    const state: ILoggingActivitiesOverviewState = {
      selectedActivityActionIds: [],
      filter: this.defaultFilter,
    };

    this.state = state;

    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
    this.updateFilter = this.updateFilter.bind(this);
    this.onUpdateSelectedActivityIds = this.onUpdateSelectedActivityIds.bind(this);
    this.onUpdateSorting = this.onUpdateSorting.bind(this);

    this.setPageHeader();
  }

  public componentDidMount(): void {
    if (this.props.activeCustomerId != null) {
      this.props.getCustomerActivities(this.props.activeCustomerId, this.activityFilter());
    }

    this.props.showCustomerSelection(true);
    this.setPageHeader();
  }

  public componentWillUnmount(): void {
    PageHeaderManager.clear();
  }

  public componentDidUpdate(prevProps: ILoggingActivitiesOverviewProps): void {
    const newActiveCustomerId = this.props.activeCustomerId;

    if (newActiveCustomerId && prevProps.activeCustomerId && prevProps.activeCustomerId !== newActiveCustomerId) {
      this.setState(
        {
          selectedActivityActionIds: [],
          filter: this.defaultFilter,
        },
        () => this.props.getCustomerActivities(newActiveCustomerId, this.activityFilter())
      );
    }
  }

  private get defaultFilter(): ILoggingActivitiesFilter {
    const startDate = new Date();
    startDate.setHours(0, 0, 0, 0);
    startDate.setDate(startDate.getDate());
    const endDate = new Date();
    endDate.setHours(23, 59, 59, 999);

    return {
      startDate: startDate,
      endDate: endDate,
      feedbacksOverwrite: [], // Allow overwrite values
    };
  }

  private activityFilter(filter: ILoggingActivitiesFilter = this.state.filter): IActivityFilter {
    return {
      activityIds: filter.activities?.map(a => a.value) ?? [],
      locationIds: filter.locations?.map(a => a.value) ?? [],
      statuses: [ActivityStatus.Finished, ActivityStatus.Cancelled, ActivityStatus.Completed],
      dateFrom: DateUtils.formatDateForApi(filter.startDate),
      dateTo: DateUtils.formatDateForApi(filter.endDate),
      activityReference: filter.activityReference != null ? filter.activityReference[0].value : "",
      cleaningObjectName: filter.cleaningObjectNames != null ? filter.cleaningObjectNames[0].value : "",
      cleaningObjectReference: filter.cleaningObjectReferences != null ? filter.cleaningObjectReferences[0].value : "",
      operatorIds: filter.operators?.map(f => f.value) ?? [],
      feedbackRequestIds: filter.feedbacksOverwrite?.map(f => f.value) ?? [],
      takeLimited: true,
      sortColumn: filter.sortColumn,
      sortDirection: filter.sortDescending ? "descending" : "ascending",
    };
  }

  private setPageHeader(): void {
    PageHeaderManager.clear();
    PageHeaderManager.add({
      pageName: "activities",
      tabs: [
        {
          id: "routes",
          relativeUrl: RoutingLinks.loggingRoutes,
          translationLabel: "pages.logging.title",
          roles: [Role.FunctionalAdministrator, Role.Employee],
        },
        {
          id: "activities",
          relativeUrl: RoutingLinks.loggingActivities,
          translationLabel: "pages.activitylogging.title",
          roles: [Role.FunctionalAdministrator, Role.Customer, Role.Employee],
        },
      ],
    });
  }

  private onUpdateSelectedActivityIds(ids: string[]): void {
    this.setState({
      selectedActivityActionIds: ids,
    });
  }

  private updateFilter(
    propertyName: string,
    filterValues: Date | IFilterValue[],
    filterValuesOverwrite?: IFilterValue[]
  ): void {
    const activeCustomerId = this.props.activeCustomerId;

    if (!activeCustomerId) {
      return;
    }

    // Default
    let filter: ILoggingActivitiesFilter;
    if (this.state.filter[`${propertyName}Overwrite`]) {
      filter = {
        ...this.state.filter,
        [propertyName]: filterValues,
        [`${propertyName}Overwrite`]: filterValuesOverwrite,
      };
    } else {
      filter = {
        ...this.state.filter,
        [propertyName]: filterValues,
      };
    }

    this.setState(
      {
        selectedActivityActionIds: [],
        filter: filter,
      },
      () => this.updateCustomerActivities()
    );
  }

  private onUpdateSorting(sortedColumn: string, sortDescending: boolean): void {
    const filter = {
      ...this.state.filter,
      sortColumn: sortedColumn,
      sortDescending: sortDescending,
    };
    this.setState(
      {
        filter: filter,
      },
      () => this.updateCustomerActivities()
    );
  }

  private updateCustomerActivities(): void {
    const activeCustomerId = this.props.activeCustomerId;

    if (!activeCustomerId) {
      return;
    }

    const filterValues: IActivityFilter = this.activityFilter();
    this.props.getCustomerActivities(activeCustomerId, filterValues);
  }

  private handleStartDateChange(startDate: Date): void {
    this.updateFilter("startDate", startDate);
  }

  private handleEndDateChange(endDate: Date): void {
    this.updateFilter("endDate", endDate);
  }

  private removeUnwantedCharactersAndToLowerString(input: string): string {
    return input.toLocaleLowerCase().replace(/[^a-z0-9 ]/gi, "");
  }

  private get dropdownFields(): IMultiSelectDropdownField[] {
    const locationMultiSelect = FilterUtils.createMultiSelect(
      this.props.locations.map(a => ({ value: a.id, label: a.name })),
      LanguageProvider.t(TranslationMapper.pages.activitylogging.filternames.location),
      (selectedOptions: IFilterValue[]) => {
        this.updateFilter("locations", selectedOptions);
      },
      this.state.filter.locations ?? []
    );

    const activityMultiSelect = FilterUtils.createMultiSelect(
      this.props.activities.map(a => ({
        value: a.activityId,
        label: TranslationUtils.getActivityDescription(a.activity.name),
      })),
      LanguageProvider.t(TranslationMapper.pages.activitylogging.filternames.activity),
      (selectedOptions: IFilterValue[]) => {
        this.updateFilter("activities", selectedOptions);
      },
      this.state.filter.activities ?? []
    );

    const operatorMultiSelect = FilterUtils.createMultiSelect(
      this.props.operators.map(a => ({ value: a.id, label: a.name })),
      LanguageProvider.t(TranslationMapper.pages.activitylogging.filternames.checkoutoperator),
      (selectedOptions: IFilterValue[]) => {
        this.updateFilter("operators", selectedOptions);
      },
      this.state.filter.operators ?? []
    );

    const cleaningObjectNameSearch = FilterUtils.createSearchFilter(
      LanguageProvider.t(TranslationMapper.pages.activitylogging.filternames.cleaningobject),
      (keyword: string) => {
        const searchFilterValue: IFilterValue[] | undefined = [{ label: "", value: keyword }];
        this.updateFilter("cleaningObjectNames", searchFilterValue);
      }
    );

    const cleaningObjectReferenceSearch = FilterUtils.createSearchFilter(
      LanguageProvider.t(TranslationMapper.pages.activitylogging.filternames.cleaningobjectreference),
      (keyword: string) => {
        const searchFilterValue: IFilterValue[] | undefined = [{ label: "", value: keyword }];
        this.updateFilter("cleaningObjectReferences", searchFilterValue);
      }
    );

    const activityReferenceSearch = FilterUtils.createSearchFilter(
      LanguageProvider.t(TranslationMapper.pages.activitylogging.filternames.activityreference),
      (keyword: string) => {
        const searchFilterValue: IFilterValue[] | undefined = [{ label: "", value: keyword }];
        this.updateFilter("activityReference", searchFilterValue);
      }
    );

    const feedbacks = this.props.activities
      .flatMap(a => (a.activity.feedbackRequests ? a.activity.feedbackRequests : []))
      .map(feedback => ({
        value: feedback.id,
        label: TranslationUtils.getFeedbackQuestionDescription(feedback.request),
      }));

    const feedbackFilterOptions: IFilterValue[] = [];
    feedbacks.forEach(fb => {
      if (
        feedbackFilterOptions.filter(
          fbo =>
            this.removeUnwantedCharactersAndToLowerString(fbo.label) ===
            this.removeUnwantedCharactersAndToLowerString(fb.label)
        ).length === 0
      ) {
        feedbackFilterOptions.push({
          value: feedbackFilterOptions.length,
          label: fb.label,
        });
      }
    });

    const feedbackMultiSelect = FilterUtils.createMultiSelect(
      feedbackFilterOptions,
      LanguageProvider.t(TranslationMapper.pages.activitylogging.filternames.feedback),
      (selectedOptions: IFilterValue[]) => {
        const filterValuesOverwrite: IFilterValue[] = feedbacks.filter(fb =>
          selectedOptions.map(s => s.label.toLocaleLowerCase()).includes(fb.label.toLocaleLowerCase())
        );
        this.updateFilter("feedbacks", selectedOptions, filterValuesOverwrite);
      },
      this.state.filter.feedbacks ?? []
    );

    return [
      locationMultiSelect,
      cleaningObjectNameSearch,
      cleaningObjectReferenceSearch,
      operatorMultiSelect,
      activityMultiSelect,
      activityReferenceSearch,
      feedbackMultiSelect,
    ];
  }

  private get selectedActivityActions(): IActivityAction[] {
    if (this.state.selectedActivityActionIds.length === 0) {
      return [];
    }

    return this.props.activityActions.filter(aa => this.state.selectedActivityActionIds.includes(aa.activityId));
  }

  public render(): JSX.Element {
    return (
      <>
        <header className="header-actions">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 header-actions__content">
                <h1>{LanguageProvider.t(TranslationMapper.pages.activitylogging.pagetitle)}</h1>
                <div className="header-actions__buttons">
                  <ActivitiesExport
                    disabled={this.props.isLoading || this.props.activityActions.length === 0}
                    selectedActivities={this.selectedActivityActions}
                    startDate={this.state.filter.startDate.toString()}
                    endDate={this.state.filter.endDate.toString()}
                    activeCustomerId={this.props.activeCustomerId}
                    filter={this.activityFilter()}
                    useModal={true}
                    defaultExportType={ExportType.ActivityLogging}
                  />
                </div>
              </div>
            </div>
          </div>
        </header>

        <PageHeader pageName="activities" />

        <FilterMenu
          startDate={this.state.filter.startDate}
          endDate={this.state.filter.endDate}
          handleEndDateChange={this.handleEndDateChange}
          handleStartDateChange={this.handleStartDateChange}
          filterFields={this.dropdownFields}
        />

        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 mb-2 block-content--px">
                <LoggingActivitiesTable
                  activityActions={this.props.activityActions}
                  isLoading={this.props.isLoading}
                  selectedActivityActionIds={this.state.selectedActivityActionIds}
                  onUpdateSelectedActivities={this.onUpdateSelectedActivityIds}
                  onUpdateSorting={this.onUpdateSorting}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: RootState): ILoggingActivitiesOverviewStateProps => ({
  isLoading: state.generalState.loaders.some(l => l === LoaderTypes.ActivityActions),
  activityActions: state.activityState.activityActions ?? [],
  totalResultsCount: state.activityState.activityActionsTotalResultsCount ?? 0,
  activeCustomerId: state.customerState.selectedCustomerId,
  locations: state.customerState.customerLocations ?? [],
  activities: state.customerState.customerActivities ?? [],
  operators:
    state.operatorState.operators != null && state.customerState.selectedCustomerId != null
      ? state.operatorState.operators[state.customerState.selectedCustomerId] ?? []
      : [],
});

const mapDispatchToProps: ILoggingActivitiesOverviewDispatchProps = {
  getCustomerActivities: getCustomerActivities,
  showCustomerSelection: showCustomerSelection,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withTelemetry(LoggingActivitiesOverview, "LoggingActivitiesOverview")));
