export default class ColorProvider {
  private static backgroundBaseColors: string[] = [
    "#009FE3",
    "#DEDC00",
    "#485156",
    "#8A96A0",
    "#D3D3D3",
    "#EB5F5D",
    "#BB1542",
    "#702283",
    "#73AE3E",
    "#FABC74",
  ];
  private static textBaseColors: string[] = [
    "#505457",
    "#505457",
    "#FFFFFF",
    "#505457",
    "#505457",
    "#505457",
    "#FFFFFF",
    "#FFFFFF",
    "#505457",
    "#505457",
  ];

  public static getNextSemiRandomBackgroundColorForIndex(index: any): string {
    let color: string;
    // Start by using the default colors:
    if (index < this.backgroundBaseColors.length) {
      color = this.backgroundBaseColors[index];
    } else {
      // Otherwise generate a color using an algorithm:
      const red = (index % 7) * Math.floor(155 / 6);
      const blue = (index % 5) * Math.floor(235 / 4);
      const green = (index % 3) * Math.floor(235 / 2);
      color = `rgba(${red}, ${green}, ${blue}, 255)`;
    }

    index++;
    return color;
  }

  public static getNextSemiRandomTextColorForIndex(index: any): string {
    let color: string;
    // Start by using the default colors:
    if (index < this.textBaseColors.length) {
      color = this.textBaseColors[index];
    } else {
      color = "#FFFFFF";
    }

    index++;
    return color;
  }
}