import { TranslationMapper } from "i18n/mapper";
import IChannelReadStatus from "interfaces/IChannelReadStatus";
import LanguageProvider from "providers/languageProvider";
import { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withTelemetry } from "services/telemetryService";
import { showCustomerSelection } from "store/actions/customerActions";
import { fetchLogbookChannelForUser, fetchLogbookForUser } from "store/actions/logbookActions";
import { RootState } from "store/reducers/rootReducer";

import LogbookMessages from "./components/logbookMessages";
import LogbookSidebar from "./components/logbookSidebar";
import ILogbookProps, { ILogbookDispatchProps, ILogbookStateProps } from "./interfaces/ILogbookProps";
import ILogbookState from "./interfaces/ILogbookState";

class Logbook extends Component<ILogbookProps, ILogbookState> {
  public constructor(props: ILogbookProps) {
    super(props);

    const state: ILogbookState = {};

    this.state = state;

    this.onSelectChannel = this.onSelectChannel.bind(this);
  }

  public componentDidMount(): void {
    this.props.showCustomerSelection(false);

    // Fetching channels is handled by store
    // this fetch is a failsafe because first fetch can be skipped due to flags
    this.props.onFetchLogbook(true);
  }

  private onSelectChannel(selectedTopicId: string): void {
    this.setState({
      selectedTopicId: selectedTopicId,
    });
  }

  private get selectedChannelReadStatus(): IChannelReadStatus | undefined {
    return this.props.logbook?.channelResponse.find(c => c.topicId === this.state.selectedTopicId);
  }

  public render(): JSX.Element {
    return (
      <>
        <header className="header-actions">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 header-actions__content logbook__header">
                <h1>{LanguageProvider.t(TranslationMapper.pages.logbook.header)}</h1>
              </div>
            </div>
          </div>
        </header>

        <div className="logbook">
          <LogbookSidebar
            channels={this.props.logbook?.channelResponse ?? []}
            onSelectChannel={this.onSelectChannel}
            selectedChannelTopicId={this.state.selectedTopicId}
          />

          <div className="logbook__content">
            {this.state.selectedTopicId && this.selectedChannelReadStatus != null && (
              <LogbookMessages
                selectedTopicId={this.state.selectedTopicId}
                selectedChannelReadStatus={this.selectedChannelReadStatus}
              />
            )}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: RootState): ILogbookStateProps => ({
  logbook: state.logbookState.logbook,
});

const mapDispatchToProps: ILogbookDispatchProps = {
  showCustomerSelection: showCustomerSelection,
  onSelectChannel: fetchLogbookChannelForUser,
  onFetchLogbook: fetchLogbookForUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withTelemetry(Logbook, "Logbook")));
