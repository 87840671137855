import IRoute from "components/routeWizard/interfaces/IRoute";
import i18next from "i18next";
import ICleaningObjectContainer from "interfaces/ICleaningObjectContainer";
import ICleaningPlanningOverview from "interfaces/ICleaningPlanningOverview";
import IExpandedRouteLogging from "interfaces/IExpandedRouteLogging";
import IIncidentType from "interfaces/IIncidentType";
import ISimpleRouteLogging from "interfaces/ISimpleRouteLogging";
import RouteRequest from "models/routeRequest";
import { NotificationManager } from "react-notifications";
import { ActionTypes } from "store/actionTypes";

import LoaderTypes from "../../enums/loaderTypes";
import TranslationMapper from "../../i18n/mapper";
import CleaningManagementService from "../../services/cleaningManagementService";
import { actionCreator, AppAction } from "../appAction";
import { setAsLoading, startLoading, unsetAsLoading } from "./loaderActions";

export const fetchedRoutes = actionCreator<ActionTypes.FETCHED_ROUTES, ICleaningPlanningOverview[]>(
  ActionTypes.FETCHED_ROUTES
);
export const fetchedSelectedRoute = actionCreator<ActionTypes.FETCHED_SELECTED_ROUTE, IRoute | undefined>(
  ActionTypes.FETCHED_SELECTED_ROUTE
);
export const fetchedCustomerRouteLogging = actionCreator<
  ActionTypes.FETCHED_CUSTOMER_ROUTE_LOGGING,
  ISimpleRouteLogging[]
>(ActionTypes.FETCHED_CUSTOMER_ROUTE_LOGGING);
export const fetchedExpandedRouteLogging = actionCreator<
  ActionTypes.FETCHED_EXPANDED_CUSTOMER_ROUTE_LOGGING,
  { loggingId: string; loggings: IExpandedRouteLogging }
>(ActionTypes.FETCHED_EXPANDED_CUSTOMER_ROUTE_LOGGING);

export function getRoutesForCustomer(customerId: string): AppAction {
  return (dispatch): void => {
    const cleaningManagementService = new CleaningManagementService();
    const getRouteLoader = LoaderTypes.Routes;

    dispatch(startLoading(getRouteLoader));
    cleaningManagementService
      .getCustomerRoutes(customerId)
      .then((routes: ICleaningPlanningOverview[]) => {
        dispatch(fetchedRoutes(routes));
      })
      .catch(() => {
        NotificationManager.error(i18next.t(TranslationMapper.pages.route.summary.routesaveerror));
        dispatch(fetchedRoutes([]));
      })
      .finally(() => dispatch(unsetAsLoading(getRouteLoader)));
  };
}

export function getRoute(routeId: string, isCopy?: boolean): AppAction {
  return (dispatch, getState): void => {
    const cleaningManagementService = new CleaningManagementService();
    const getRouteLoader = LoaderTypes.SelectedRoute;

    dispatch(startLoading(getRouteLoader));

    cleaningManagementService
      .getRouteById(routeId)
      .then((route: IRoute) => {
        const incidentTypes: IIncidentType[] =
          getState().notificationState.incidentTypes![getState().customerState.selectedCustomerId!] ?? [];
        const filteredInitialValues = incidentTypes.filter((x) => route.notificationCategoryIds?.includes(x.id));
        route.routeDetails.incidentTypes = filteredInitialValues;

        let containers: ICleaningObjectContainer[] = [];
        route.cleaningObjects.cleaningObjectsContainers.forEach((element) => {
          // move startZone to top of array
          if (element.cleaningObjects?.some((x) => x.isStartZone)) {
            containers = route.cleaningObjects.cleaningObjectsContainers.filter((item) => item.id !== element.id);
            containers.unshift(element);
          }
        });

        route.cleaningObjects.cleaningObjectsContainers = containers;

        if (isCopy) {
          route.routeDetails.routeName = `${route.routeDetails.routeName} ${i18next.t("pages.route.copy")}`;
        }

        dispatch(fetchedSelectedRoute(route));
      })
      .catch(() => NotificationManager.error(i18next.t(TranslationMapper.pages.route.summary.routesaveerror)))
      .finally(() => dispatch(unsetAsLoading(getRouteLoader)));
  };
}

export function resetRoute(): AppAction {
  return (dispatch): void => {
    dispatch(fetchedSelectedRoute(undefined));
  };
}

export function upsertRoute(route: RouteRequest): AppAction {
  return (dispatch): void => {
    const cleaningManagementService = new CleaningManagementService();
    const saveRouteLoader = LoaderTypes.Routes;

    dispatch(startLoading(saveRouteLoader));

    cleaningManagementService
      .upsertRouteFromWizard(route)
      .then(() => {
        NotificationManager.success(i18next.t(TranslationMapper.pages.route.summary.routesaving));
        dispatch(getRoutesForCustomer(route.customerId));
      })
      .catch(() => NotificationManager.error(i18next.t(TranslationMapper.pages.route.summary.routesaveerror)))
      .finally(() => dispatch(unsetAsLoading(saveRouteLoader)));
  };
}

export function deactivateRoute(customerId: string, routeId: string): AppAction {
  return (dispatch): void => {
    const cleaningManagementService = new CleaningManagementService();
    const deactivateRouteLoader = LoaderTypes.Routes;

    dispatch(startLoading(deactivateRouteLoader));

    cleaningManagementService
      .deactivateRoute(routeId)
      .then(() => {
        NotificationManager.success(i18next.t(TranslationMapper.pages.routes.successfullydeactivated));
        dispatch(getRoutesForCustomer(customerId));
      })
      .catch(() => NotificationManager.error(i18next.t(TranslationMapper.pages.routes.routedeactivateerror)))
      .finally(() => dispatch(unsetAsLoading(deactivateRouteLoader)));
  };
}

export function getCustomerRouteLogging(customerId: string, fromDate: string, toDate: string): AppAction {
  return (dispatch): void => {
    const cleaningManagementService = new CleaningManagementService();
    dispatch(setAsLoading(LoaderTypes.RouteLogging));

    cleaningManagementService
      .getCustomerLogging(customerId, fromDate, toDate)
      .then((logging: ISimpleRouteLogging[]) => {
        dispatch(fetchedCustomerRouteLogging(logging));
      })
      .catch(() => {
        NotificationManager.error(i18next.t(TranslationMapper.errors.general));
      })
      .finally(() => {
        dispatch(unsetAsLoading(LoaderTypes.RouteLogging));
      });
  };
}

export function getExpandedCustomerRouteLogging(loggingId: string): AppAction {
  return (dispatch): void => {
    const cleaningManagementService = new CleaningManagementService();

    cleaningManagementService
      .getCustomerLogData(loggingId)
      .then((loggings: IExpandedRouteLogging) => {
        dispatch(fetchedExpandedRouteLogging({ loggingId, loggings }));
      })
      .catch(() => NotificationManager.error(i18next.t(TranslationMapper.pages.logging.expanded_logging_error)));
  };
}

export function deleteRouteLogging(loggingIds: string[]): AppAction {
  return (dispatch): void => {
    const cleaningManagementService = new CleaningManagementService();

    dispatch(startLoading(LoaderTypes.RouteLogging));

    cleaningManagementService
      .deleteRouteData(loggingIds)
      .then(() => {
        NotificationManager.success(i18next.t(TranslationMapper.pages.logging.delete_success));
      })
      .catch(() => NotificationManager.error(i18next.t(TranslationMapper.pages.logging.deleteerror)))
      .finally(() => dispatch(unsetAsLoading(LoaderTypes.RouteLogging)));
  };
}
