import Loader from "components/loader/loader";
import ActiveSwitch from "components/material/activeSwitch/activeSwitch";
import LoaderTypes from "enums/loaderTypes";
import TranslationMapper from "i18n/mapper";
import CustomerProvider from "providers/customerProvider";
import LanguageProvider from "providers/languageProvider";
import { Component } from "react";
import { NotificationManager } from "react-notifications";
import { connect } from "react-redux";
import {
  disableCustomerNotificationCategory,
  updateCustomerNotificationCategories,
} from "store/actions/customerDataActions";
import { RootState } from "store/reducers/rootReducer";

import ICustomerNotificationSubComponentProps, {
  ICustomerNotificationSubComponentDispatchProps,
  ICustomerNotificationSubComponentStateProps,
} from "./interfaces/ICustomerNotificationSubComponentProps";
import ICustomerNotificationSubComponentState from "./interfaces/ICustomerNotificationSubComponentState";
import NotificationCategoryContacts from "./notificationCategoryContacts";

class CustomerNotificationSubComponent extends Component<
  ICustomerNotificationSubComponentProps,
  ICustomerNotificationSubComponentState
> {
  public constructor(props: ICustomerNotificationSubComponentProps) {
    super(props);

    this.state = {};

    this.onToggleChange = this.onToggleChange.bind(this);
    this.onMailUpdate = this.onMailUpdate.bind(this);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private onToggleChange(e: any): void {
    const activeCustomer = CustomerProvider.getActiveCustomer();

    if (activeCustomer == null) {
      NotificationManager.error(LanguageProvider.t(TranslationMapper.pages.customerdata.nocustomerselected));
      return;
    }

    if (e.target.checked === false && this.props.isCategoryActive === true) {
      if (
        this.props.currentCustomerNotificationCategoryContacts &&
        this.props.currentCustomerNotificationCategoryContacts.length > 0
      ) {
        this.props.onDisableCustomerNotificationCategory(
          activeCustomer.id,
          this.props.customerNotificationCategory.categoryId
        );
      } else {
        this.onMailUpdate(this.props.customerNotificationCategory.categoryId, []);
      }

      this.setState({
        isToggled: undefined,
      });

      return;
    }

    this.setState({
      isToggled: e.target.checked,
    });
  }

  private onMailUpdate(id: string, emailAddresses: string[]): void {
    const activeCustomer = CustomerProvider.getActiveCustomer();

    if (activeCustomer == null) {
      NotificationManager.error(LanguageProvider.t(TranslationMapper.pages.customerdata.nocustomerselected));
      return;
    }

    const categories = this.props.currentCustomerNotificationCategories;
    const categoryIndex = categories.findIndex(c => c.categoryId === id);

    if (categoryIndex === -1) {
      const notificationCategory = this.props.customerNotificationCategory.notificationCategory ?? {
        id: id,
        imageUri: "",
        name: "",
      };

      categories.push({
        categoryId: id,
        contactEmailAddresses: emailAddresses,
        customerId: activeCustomer.id,
        notificationCategory: notificationCategory,
        id: "",
      });
    } else if (emailAddresses == null || emailAddresses.length === 0) {
      categories.splice(categoryIndex, 1);
    } else {
      categories[categoryIndex].id = id;
      categories[categoryIndex].contactEmailAddresses = emailAddresses;
    }

    this.props.onUpdateCustomerNotificationCategories(activeCustomer.id, categories);

    this.setState({
      isToggled: undefined,
    });
  }

  private get isNotificationActive(): boolean {
    if (this.state.isToggled != null) {
      return this.state.isToggled;
    }

    return (
      this.props.currentCustomerNotificationCategoryContacts &&
      this.props.currentCustomerNotificationCategoryContacts.length > 0
    );
  }

  public render(): JSX.Element {
    return (
      <tr>
        <td colSpan={1}></td>
        <td colSpan={4}>
          <Loader isLoading={this.props.isLoading} />
          {!this.props.isLoading && (
            <div className="row">
              <div className="col-12 col-md-4 modal__col-mb">
                <h2 className="mb-3">{LanguageProvider.t(TranslationMapper.pages.customerdata.notifications)}</h2>
                <h3>{LanguageProvider.t(TranslationMapper.pages.customerdata.notification_active_toggle_header)}</h3>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="me-3">
                    {LanguageProvider.t(TranslationMapper.pages.customerdata.notification_active_toggle_subtext)}
                  </div>
                  <ActiveSwitch checked={this.isNotificationActive} onChange={this.onToggleChange} />
                </div>
              </div>
              {this.isNotificationActive && (
                <NotificationCategoryContacts
                  key={this.props.customerNotificationCategory.id}
                  notificationCategoryId={this.props.customerNotificationCategory.categoryId}
                  notificationCategoryContacts={this.props.currentCustomerNotificationCategoryContacts}
                />
              )}
            </div>
          )}
        </td>
        <td colSpan={1}></td>
      </tr>
    );
  }
}

const mapStateToProps = (state: RootState): ICustomerNotificationSubComponentStateProps => ({
  currentCustomerNotificationCategories: state.customerState.customerNotificationCategories ?? [],
  isLoading: state.generalState.loaders.some(l => l === LoaderTypes.CustomerNotificationCategory),
});

const mapDispatchToProps: ICustomerNotificationSubComponentDispatchProps = {
  onDisableCustomerNotificationCategory: disableCustomerNotificationCategory,
  onUpdateCustomerNotificationCategories: updateCustomerNotificationCategories,
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerNotificationSubComponent);
