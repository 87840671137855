import LoaderTypes from "enums/loaderTypes";
import TranslationMapper from "i18n/mapper";
import i18next from "i18next";
import IUser from "interfaces/IUser";
import IUserSettings from "interfaces/IUserSettings";
import { NotificationManager } from "react-notifications";
import UserService from "services/userService";
import { ActionTypes } from "store/actionTypes";
import { AppAction, actionCreator } from "store/appAction";

import { setAsLoading, unsetAsLoading } from "./loaderActions";

export const fetchedUser = actionCreator<ActionTypes.FETCHED_USER, IUser>(ActionTypes.FETCHED_USER);
export const fetchedHasRouteLoggingDeletePermission = actionCreator<
  ActionTypes.FETCHED_HAS_ROUTE_LOGGING_DELETE_PERMISSION,
  boolean
>(ActionTypes.FETCHED_HAS_ROUTE_LOGGING_DELETE_PERMISSION);
export const updatedUserSettings = actionCreator<ActionTypes.UPDATE_USER_SETTINGS, IUserSettings>(
  ActionTypes.UPDATE_USER_SETTINGS
);

// Dirty hack to set any because otherwise it can't be used in Store.Create method
export function fetchUser(): AppAction {
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  return dispatch => {
    dispatch(setAsLoading(LoaderTypes.User));
    const userService = new UserService();

    userService
      .getUserAsync()
      .then((user: IUser) => {
        dispatch(fetchedUser(user));
      })
      .catch(() => NotificationManager.error(i18next.t(TranslationMapper.errors.fetching_user)))
      .finally(() => dispatch(unsetAsLoading(LoaderTypes.User)));
  };
}
