import { Button } from "components/material/buttons/buttons";
import IWizardStep from "components/wizard/interfaces/IWizardStep";
import { IWizardStepOnChangeEvent } from "components/wizard/interfaces/IWizardStepOnChange";
import TranslationMapper from "i18n/mapper";
import INotificationRequest from "interfaces/INotificationRequest";
import LanguageProvider from "providers/languageProvider";
import { Component } from "react";
import { Modal } from "react-bootstrap";
import ReactDOM from "react-dom";
import { withTranslation } from "react-i18next";
import { NotificationManager } from "react-notifications";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { clearCleaningObjects } from "store/actions/locationActions";
import { createNotificationAsync } from "store/actions/notificationActions";
import { RootState } from "store/reducers/rootReducer";
import Dictionary from "utils/dictionary";

import INotificationWizardProps, {
  INotificationWizardDispatchProps,
  INotificationWizardStateProps,
} from "./interfaces/INotificationWizardProps";
import INotificationWizardState from "./interfaces/INotificationWizardState";
import NotificationCategoryStep from "./notificationCategoryStep";
import NotificationDetailsStep from "./notificationDetailsStep";
import NotificationFinished from "./notificationFinished";
import NotificationValidator from "./notificationValidator";

class NotificationWizard extends Component<INotificationWizardProps, INotificationWizardState> {
  private initialWizardState: INotificationWizardState = {
    isValid: new Dictionary<boolean>(),
    numberOfSteps: 2,
    activeStepIndex: 0,
    showFinishedStep: false,
  };

  private wizardSteps: IWizardStep[] = [NotificationCategoryStep, NotificationDetailsStep];

  public constructor(props: INotificationWizardProps) {
    super(props);

    const isValid = new Dictionary<boolean>();

    for (let index = 0; index < this.wizardSteps.length; index++) {
      isValid.add(index.toString(), false); // by default all steps contain invalid data
    }

    this.state = {
      numberOfSteps: this.wizardSteps.length,
      activeStepIndex: 0,
      isValid,
      showFinishedStep: false,
    };

    this.updateSteps = this.updateSteps.bind(this);
    this.onChange = this.onChange.bind(this);
    this.goToNextStep = this.goToNextStep.bind(this);
    this.goToPreviousStep = this.goToPreviousStep.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.onCreateNotification = this.onCreateNotification.bind(this);
    this.createNotification = this.createNotification.bind(this);
  }

  public onChange(event: IWizardStepOnChangeEvent): void {
    const name = event.target.name;
    const value = event.target.value;

    const isValid = this.state.isValid;
    isValid.add(this.state.activeStepIndex.toString(), event.isValid);
    this.setState(current => ({ ...current, isValid, [name]: value }));
  }

  private goToNextStep(): void {
    const currentStep = this.state.activeStepIndex;

    if (currentStep < this.state.numberOfSteps - 1 && this.state.isValid.item(currentStep.toString())) {
      this.setState({
        activeStepIndex: currentStep + 1,
      });
    }
  }

  private goToPreviousStep(): void {
    const currentStep = this.state.activeStepIndex;

    if (currentStep > 0) {
      this.setState({
        activeStepIndex: currentStep - 1,
      });
    }
  }

  private updateSteps(): void {
    this.setState({
      isValid: this.isValidDictionary,
    });
  }

  private get isValidDictionary(): Dictionary<boolean> {
    const isValidDictionary = new Dictionary<boolean>();

    isValidDictionary.add(
      "0",
      this.state.category ? NotificationValidator.isCategoryValid(this.state.category) : false
    );
    isValidDictionary.add("1", this.state.details ? NotificationValidator.areDetailsValid(this.state.details) : false);

    return isValidDictionary;
  }

  private getStepValue(): any {
    switch (this.state.activeStepIndex) {
      case 0:
        return this.state.category;
      case 1:
        return this.state.details;
    }
  }

  private get currentStep(): number {
    return this.state.activeStepIndex + 1;
  }

  private get totalNumberOfSteps(): number {
    return this.wizardSteps.length;
  }

  private get isAllDataValid(): boolean {
    return !this.state.isValid.getValues().some(value => !value);
  }

  private get isCurrentStepValid(): boolean {
    return this.state.isValid.item(this.state.activeStepIndex.toString());
  }

  private get showPreviousButton(): boolean {
    return this.state.activeStepIndex > 0;
  }

  private get showNextButton(): boolean {
    return this.state.activeStepIndex < this.state.numberOfSteps - 1;
  }

  private get showSaveButton(): boolean {
    return this.state.activeStepIndex === this.state.numberOfSteps - 1;
  }

  private get modalHook(): HTMLElement {
    let modalHook = document.getElementById("modal");
    if (!modalHook) {
      modalHook = document.createElement("div");
      modalHook.setAttribute("id", "portal");
      document.body.appendChild(modalHook);
    }

    return modalHook;
  }

  private onCreateNotification(): void {
    if (!this.isAllDataValid) {
      NotificationManager.error(LanguageProvider.t(TranslationMapper.pages.notificationwizard.invalid_data));
      return;
    }

    this.createNotification();
    this.setState({
      showFinishedStep: true,
    });
  }

  private createNotification(): void {
    if (
      this.isAllDataValid &&
      this.state.details != null &&
      this.state.category != null &&
      this.state.category.cleaningObject != null
    ) {
      const notification: INotificationRequest = {
        categoryId: this.state.category?.categoryId,
        cleaningObjectId: this.state.category.cleaningObject.id,
        description: this.state.details.description,
        pictures: this.state.details.pictures,
      };

      this.props.onCreateNotification(notification, this.state.category.cleaningObject.id, this.props.history);
    }
  }

  private closeModal(): void {
    this.props.onCloseNotificationWizard();
    this.setState({ ...this.initialWizardState }, () => this.props.onClose());
  }

  public render(): React.ReactNode {
    const wizardStep = this.wizardSteps[this.state.activeStepIndex];

    return (
      <>
        {this.modalHook &&
          ReactDOM.createPortal(
            <Modal backdrop="static" show={true} onHide={this.closeModal} centered>
              <Modal.Header closeButton>
                <div className="flex-fill d-flex justify-content-between">
                  <div className="modal-header__info">
                    <h1 className="modal-title">
                      {LanguageProvider.t(TranslationMapper.pages.notificationwizard.new)}
                    </h1>
                  </div>
                  {!this.state.showFinishedStep && (
                    <div className="modal__steps" data-testid="notification-wizard-steps">
                      {this.currentStep}/{this.totalNumberOfSteps}
                    </div>
                  )}
                </div>
              </Modal.Header>
              <Modal.Body>
                {!this.state.showFinishedStep && (
                  <wizardStep.form
                    onChange={this.onChange}
                    name={wizardStep.name}
                    value={this.getStepValue()}
                    categories={this.props.notificationCategories}
                    locations={this.props.locations}
                  />
                )}
                {this.state.showFinishedStep && <NotificationFinished />}
              </Modal.Body>
              {!this.state.showFinishedStep && (
                <Modal.Footer className="d-flex justify-content-between">
                  {!this.showPreviousButton && (
                    <Button
                      onClick={this.goToPreviousStep}
                      resourceLabel={LanguageProvider.t(TranslationMapper.buttons.cancel)}
                      className="btn-outline-secondary"
                      iconEnd="xmark"
                    />
                  )}
                  {this.showPreviousButton && (
                    <Button
                      onClick={this.goToPreviousStep}
                      resourceLabel={LanguageProvider.t(TranslationMapper.buttons.previous)}
                      className="btn-outline-secondary"
                      iconStart="arrow-left"
                    />
                  )}
                  {this.showNextButton && (
                    <Button
                      onClick={this.goToNextStep}
                      resourceLabel={LanguageProvider.t(TranslationMapper.buttons.next)}
                      className="btn-primary"
                      disabled={!this.isCurrentStepValid}
                      iconEnd={`${this.showSaveButton ? "floppy-disk" : "arrow-right"}`}
                    />
                  )}
                  {this.showSaveButton && (
                    <Button
                      onClick={this.onCreateNotification}
                      resourceLabel={LanguageProvider.t(TranslationMapper.buttons.save)}
                      className="btn-primary"
                      disabled={!this.isCurrentStepValid}
                      iconEnd="floppy-disk"
                    />
                  )}
                </Modal.Footer>
              )}
              {this.state.showFinishedStep && (
                <Modal.Footer className="d-flex justify-content-end">
                  <Button
                    onClick={this.closeModal}
                    resourceLabel={LanguageProvider.t(TranslationMapper.buttons.close)}
                    className="btn-primary"
                    iconEnd="xmark"
                  />
                </Modal.Footer>
              )}
            </Modal>,
            this.modalHook
          )}
      </>
    );
  }
}

const mapStateToProps = (state: RootState): INotificationWizardStateProps => {
  const incidentTypes =
    state.notificationState.incidentTypes != null && state.customerState.selectedCustomerId != null
      ? state.notificationState.incidentTypes[state.customerState.selectedCustomerId] ?? []
      : [];

  return {
    notificationCategories: incidentTypes,
    locations: state.customerState.customerLocations ?? [],
  };
};

const mapDispatchToProps: INotificationWizardDispatchProps = {
  onCreateNotification: createNotificationAsync,
  onCloseNotificationWizard: clearCleaningObjects,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(NotificationWizard)));
