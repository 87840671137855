import Source from "constants/sources";
import ICustomer from "interfaces/ICustomer";
import Serializable from "models/serializeable";

export default class Customer extends Serializable implements ICustomer {
  public id: string;
  public name: string;
  public externalCustomerId: string;
  public source: string;
  public numberOfLocations: number;
  public hasLogbook: boolean;
  public hasOperation: boolean;
  public isConfigurable: boolean;

  public constructor() {
    super();
    this.id = "00000000-0000-0000-0000-000000000000";
    this.name = "";
    this.externalCustomerId = "00000000-0000-0000-0000-000000000000";
    this.source = Source.DigitalCleaningPlatform;
    this.numberOfLocations = 0;
    this.hasLogbook = false;
    this.hasOperation = false;
    this.isConfigurable = true;
  }
}