import { IInputChangeEvent } from "components/interfaces/IInputChangeEvent";
import { Component, ReactNode } from "react";

import { IRadioButtonProps } from "./interfaces/IRadiobuttonProps";

export default class RadioButton extends Component<IRadioButtonProps> {
  public constructor(props: IRadioButtonProps) {
    super(props);

    this.onChange = this.onChange.bind(this);
  }

  public onChange(event: IInputChangeEvent<string>): void {
    if (this.props.onChange != null) {
      this.props.onChange({
        target: {
          name: this.props["name"],
          value: event.target.value,
        },
      });
    }
  }

  public render(): ReactNode {
    return (
      <div className="form-check">
        <input
          className="form-check-input"
          type="radio"
          name={this.props.name}
          id={this.props.id}
          onChange={this.onChange}
          disabled={this.props.disabled ?? false}
          checked={this.props.checked}
          value={this.props.value}
        />
        <label className="form-check-label" htmlFor={this.props.id}>
          {this.props.label}
        </label>
      </div>
    );
  }
}
