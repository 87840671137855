import TranslationMapper from "i18n/mapper";
import LanguageProvider from "providers/languageProvider";

export default class TranslationUtils {
  public static getActivityDescription(description: string): string {
    const translationMapper = TranslationMapper.activityactions[LanguageProvider.keyToMapperKey(description)];

    if (translationMapper == null) {
      return description;
    }
    return LanguageProvider.t(translationMapper);
  }

  public static getFeedbackQuestionDescription(description: string): string {
    const translationMapper = TranslationMapper.activityactions.feedback[LanguageProvider.keyToMapperKey(description)];

    if (translationMapper == null) {
      return description;
    }
    return LanguageProvider.t(translationMapper);
  }
}
