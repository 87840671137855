import IUser from "interfaces/IUser";
import IUserSettings from "interfaces/IUserSettings";

export interface IUserStoreState {
  user?: IUser;
  hasRouteLoggingDeletePermission?: boolean;
  userSettings?: IUserSettings;
}

export const initialUserStoreState: IUserStoreState = {};
