import "moment/locale/nl";

import { IReactTableData } from "components/material/table/interfaces/IReactTableProps";
import {
  IReactTableRowSubComponentColumnProps,
  IReactTableRowSubComponentRowProps,
} from "components/material/table/interfaces/IReactTableRowSubComponentProps";
import { ReactTable } from "components/material/table/reactTable";
import ReactTableHelper from "components/material/table/reactTableHelper";
import { ReactTableRowExpander } from "components/material/table/reactTableRowExpander";
import { ReactTableRowSelector } from "components/material/table/reactTableRowSelector";
import { ReactTableRowSubComponent } from "components/material/table/reactTableRowSubComponent";
import ActivityStatus from "enums/activityStatus";
import CheckoutTriggerType from "enums/checkOutTriggerType";
import TranslationMapper from "i18n/mapper";
import IActivityAction from "interfaces/IActivityAction";
import IActivityActionFeedback from "interfaces/IActivityActionFeedback";
import moment from "moment";
import LanguageProvider from "providers/languageProvider";
import { Component } from "react";
import { Column, Row } from "react-table";
import { isNullOrEmpty, toLocaleString } from "utils/stringUtils";
import TranslationUtils from "utils/translationUtils";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ILoggingActivitiesTableProps from "./interfaces/ILoggingActivitiesTableProps";

type loggingActivitiesTableData = IActivityAction & IReactTableData;

export default class LoggingActivitiesTable extends Component<ILoggingActivitiesTableProps> {
  private readonly tableDataPropertyIndex: loggingActivitiesTableData = {
    // Used to create typesafety for accessors in tableData
    operatorName: "",
    externalObjectId: "",
    externalActivityId: "",
    externalActivityReference: "",
    activityStatus: ActivityStatus.Uncleaned,
    activityId: "",
    activityTypeId: "",
    description: "",
    cleaningObjectId: "",
    cleaningObjectName: "",
    customerVenueId: "",
    locationName: "",
    floorName: "",
    checkoutTrigger: CheckoutTriggerType.Unknown,
    statusUpdatedBy: "",
    date: "",
    failureDescription: "",
    realisedStartDateTime: "",
    startSlaDateTime: "",
    operatorId: "",
    isAssignable: false,
    key: "",
  };

  public constructor(props: ILoggingActivitiesTableProps) {
    super(props);

    this.setActiveRow = this.setActiveRow.bind(this);
    this.renderActivityLoggingItemThirdRowValues = this.renderActivityLoggingItemThirdRowValues.bind(this);
    this.renderActivityLoggingItem = this.renderActivityLoggingItem.bind(this);
  }

  private getFormattedDateTimeString(date?: string | Date): string {
    return date != null ? moment(date).format("DD-MM-YYYY, HH:mm") : "-";
  }

  private hasSLAInformation(activityAction: IActivityAction): boolean {
    return (
      activityAction.startSlaDateTime != null ||
      activityAction.failureDescription != null ||
      activityAction.realisedStartDateTime != null
    );
  }

  private getFeedbackTranslation(label: string): string {
    const text = toLocaleString(label);

    if (isNullOrEmpty(text)) {
      return "";
    }

    const translationMapper = TranslationMapper.activityactions.feedback[LanguageProvider.keyToMapperKey(text)];

    if (translationMapper == null) {
      return text;
    }

    return LanguageProvider.t(translationMapper);
  }

  private setActiveRow(rowKey: string): void {
    const selectedActivityIds = [...this.props.selectedActivityActionIds];
    const indexOfSelectedActivityAction = selectedActivityIds.findIndex(
      sa => sa.toLowerCase() === rowKey.toLowerCase()
    );

    if (indexOfSelectedActivityAction === -1) {
      selectedActivityIds.push(rowKey);
    } else {
      selectedActivityIds.splice(indexOfSelectedActivityAction, 1);
    }

    this.props.onUpdateSelectedActivities(selectedActivityIds);
  }

  private getActivityStatusIconElement(activity: IActivityAction): JSX.Element {
    if (activity.checkoutTrigger === CheckoutTriggerType.ExternalApi) {
      return (
        <div className="badge bg-warning">
          {LanguageProvider.t(TranslationMapper.pages.activitylogging.activitystatus.cancelled)}
        </div>
      );
    }

    if (
      activity.checkoutTrigger === CheckoutTriggerType.Unknown &&
      activity.activityStatus === ActivityStatus.Cancelled
    ) {
      const cancellationFeedback = activity.activityActionFeedback?.map(
        feedback => feedback.response !== "" && feedback.required
      );
      if (cancellationFeedback?.length === 0) {
        return (
          <div className="badge bg-warning">
            {LanguageProvider.t(TranslationMapper.pages.activitylogging.activitystatus.cancelled)}
          </div>
        );
      }
      return <FontAwesomeIcon icon={["fas", "circle-xmark"]} fixedWidth size="lg" className="text-warning" />;
    }

    if (activity.activityStatus === ActivityStatus.Cancelled) {
      return <FontAwesomeIcon icon={["fas", "circle-xmark"]} fixedWidth size="lg" className="text-warning" />;
    }

    if (activity.activityStatus === ActivityStatus.Completed || activity.activityStatus === ActivityStatus.Finished) {
      return <FontAwesomeIcon icon={["fas", "circle-check"]} fixedWidth size="lg" className="text-success" />;
    }

    return <></>;
  }

  private get columns(): Column[] {
    return [
      {
        id: "table-row-selector",
        width: 36,
        disableSortBy: true,
        Cell: ({ row }): JSX.Element => (
          <ReactTableRowSelector
            onChange={this.setActiveRow}
            row={row}
            multiSelectRowKeys={this.props.selectedActivityActionIds}
          />
        ),
      },
      {
        Header: LanguageProvider.t(TranslationMapper.pages.activitylogging.locationheader),
        id: ReactTableHelper.getPropertyNameAsString(this.tableDataPropertyIndex, x => x.locationName),
        accessor: ReactTableHelper.getPropertyNameAsString(this.tableDataPropertyIndex, x => x.locationName),
        width: "30%",
      },
      {
        Header: LanguageProvider.t(TranslationMapper.pages.activitylogging.activityheader),
        id: "activity",
        accessor: (rowOriginal): string => TranslationUtils.getActivityDescription(rowOriginal.description),
        width: "20%",
      },
      {
        Header: LanguageProvider.t(TranslationMapper.pages.activitylogging.cleaningobjectheader),
        id: ReactTableHelper.getPropertyNameAsString(this.tableDataPropertyIndex, x => x.cleaningObjectName),
        accessor: ReactTableHelper.getPropertyNameAsString(this.tableDataPropertyIndex, x => x.cleaningObjectName),
        width: "20%",
      },
      {
        Header: LanguageProvider.t(TranslationMapper.pages.activitylogging.dateheader),
        id: ReactTableHelper.getPropertyNameAsString(this.tableDataPropertyIndex, x => x.date),
        accessor: ReactTableHelper.getPropertyNameAsString(this.tableDataPropertyIndex, x => x.date),
        width: "15%",
        Cell: ({ value }): string => this.getFormattedDateTimeString(value),
      },
      {
        Header: LanguageProvider.t(TranslationMapper.pages.activitylogging.statusheader),
        id: ReactTableHelper.getPropertyNameAsString(this.tableDataPropertyIndex, x => x.activityStatus),
        accessor: ReactTableHelper.getPropertyNameAsString(this.tableDataPropertyIndex, x => x.activityStatus),
        width: "auto",
        Cell: ({ row }): JSX.Element => this.getActivityStatusIconElement(row.original as IActivityAction),
      },
      {
        id: "expander",
        disableSortBy: true,
        width: 36,
        Cell: ({ row }): JSX.Element => <ReactTableRowExpander row={row} />,
      },
    ];
  }

  private renderActivityLoggingItemFirstRowHeaders(
    activityAction: loggingActivitiesTableData
  ): IReactTableRowSubComponentColumnProps[] {
    const statusUpdatedBy = activityAction.statusUpdatedBy ?? activityAction.operatorName ?? "-";
    const columns = [
      {
        className: "",
        content: <>&nbsp;</>,
      },
      {
        className: ReactTableHelper.ContentHeader,
        content: <h3>{LanguageProvider.t(TranslationMapper.pages.activitylogging.cleaningobjectreferenceheader)}</h3>,
      },
      {
        className: ReactTableHelper.ContentHeader,
        content: <h3>{LanguageProvider.t(TranslationMapper.pages.activitylogging.activityreferenceheader)}</h3>,
      },
    ];

    if (statusUpdatedBy != null && statusUpdatedBy !== "") {
      columns.push({
        className: ReactTableHelper.ContentHeader,
        content: <h3>{LanguageProvider.t(TranslationMapper.pages.activitylogging.checkoutoperatorheader)}</h3>,
      });
    }

    if (activityAction.checkoutTrigger != null) {
      columns.push({
        className: ReactTableHelper.ContentHeader,
        content: <h3>{LanguageProvider.t(TranslationMapper.pages.activitylogging.checkouttriggerheader)}</h3>,
      });
    }

    return columns;
  }

  private renderActivityLoggingItemFirstRowValues(
    activityAction: loggingActivitiesTableData
  ): IReactTableRowSubComponentColumnProps[] {
    const statusUpdatedBy = activityAction.statusUpdatedBy ?? activityAction.operatorName ?? "-";
    const columns = [
      {
        className: "",
        content: <>&nbsp;</>,
      },
      {
        className: ReactTableHelper.ContentField,
        content: <>{activityAction.externalObjectId ?? "-"}</>,
      },
      {
        className: ReactTableHelper.ContentField,
        content: <>{activityAction.externalActivityReference ?? "-"}</>,
      },
    ];

    if (statusUpdatedBy != null && statusUpdatedBy !== "") {
      columns.push({
        className: ReactTableHelper.ContentField,
        content: <>{statusUpdatedBy}</>,
      });
    }

    if (activityAction.checkoutTrigger != null) {
      columns.push({
        className: ReactTableHelper.ContentField,
        content: <>{LanguageProvider.t(`checkouttriggers.${CheckoutTriggerType[activityAction.checkoutTrigger]}`)}</>,
      });
    }

    return columns;
  }

  private renderActivityLoggingItemSecondRowHeaders(): IReactTableRowSubComponentColumnProps[] {
    return [
      {
        className: "",
        content: <>&nbsp;</>,
      },
      {
        content: <h3>{LanguageProvider.t(TranslationMapper.pages.activitylogging.realizedstartdateheader)}</h3>,
      },
      {
        content: <h3>{LanguageProvider.t(TranslationMapper.pages.activitylogging.deadlineheader)}</h3>,
      },
      {
        content: <h3>{LanguageProvider.t(TranslationMapper.pages.activitylogging.failuredescriptionheader)}</h3>,
      },
    ];
  }

  private renderActivityLoggingItemSecondRowValues(
    activityAction: loggingActivitiesTableData
  ): IReactTableRowSubComponentColumnProps[] {
    return [
      {
        className: "",
        content: <>&nbsp;</>,
      },
      {
        className: ReactTableHelper.ContentField,
        content: <>{this.getFormattedDateTimeString(activityAction.realisedStartDateTime)}</>,
      },
      {
        className: ReactTableHelper.ContentField,
        content: <>{this.getFormattedDateTimeString(activityAction.startSlaDateTime)}</>,
      },
      {
        className: ReactTableHelper.ContentField,
        content: <>{activityAction.failureDescription ?? "-"}</>,
      },
    ];
  }

  private renderActivityLoggingItemThirdRowHeaders(): IReactTableRowSubComponentColumnProps[] {
    return [
      {
        content: <>&nbsp;</>,
      },
      {
        className: ReactTableHelper.ContentHeader,
        content: (
          <h3>{LanguageProvider.t(TranslationMapper.pages.masterdata.activities.columnheaders.feedbackrowquestion)}</h3>
        ),
      },
      {
        className: ReactTableHelper.ContentHeader,
        content: (
          <h3>
            {LanguageProvider.t(TranslationMapper.pages.masterdata.activities.columnheaders.feedbackrowanswertype)}
          </h3>
        ),
      },
    ];
  }

  private renderActivityLoggingItemThirdRowValues(
    activityFeedback: IActivityActionFeedback
  ): IReactTableRowSubComponentColumnProps[] {
    return [
      {
        className: "",
        content: <>&nbsp;</>,
      },
      {
        className: "td-content-pb-3-5",
        content: <div>{this.getFeedbackTranslation(activityFeedback.question)}</div>,
      },
      {
        className: "td-content-pb-3-5",
        content: <div className="text-break">{this.getFeedbackTranslation(activityFeedback.response)}</div>,
      },
    ];
  }

  private renderActivityLoggingItem(row: Row): JSX.Element {
    const rowData = row.original as loggingActivitiesTableData;
    const activityAction = rowData;

    const subComponentRows: IReactTableRowSubComponentRowProps[] = [
      {
        columns: this.renderActivityLoggingItemFirstRowHeaders(rowData),
      },
      {
        columns: this.renderActivityLoggingItemFirstRowValues(rowData),
      },
    ];

    if (this.hasSLAInformation(activityAction)) {
      subComponentRows.push(
        {
          columns: this.renderActivityLoggingItemSecondRowHeaders(),
        },
        {
          columns: this.renderActivityLoggingItemSecondRowValues(rowData),
        }
      );
    }

    if (activityAction.activityActionFeedback != null && activityAction.activityActionFeedback.length > 0) {
      subComponentRows.push({
        columns: this.renderActivityLoggingItemThirdRowHeaders(),
      });
      activityAction.activityActionFeedback.forEach(item => {
        subComponentRows.push({
          className: "feedback-row",
          columns: this.renderActivityLoggingItemThirdRowValues(item),
        });
      });
    }

    return <ReactTableRowSubComponent rows={subComponentRows} />;
  }

  private get activityLoggingData(): loggingActivitiesTableData[] {
    return this.props.activityActions.map(log => {
      const logging: loggingActivitiesTableData = {
        ...log,
        key: log.activityId,
      };

      return logging;
    });
  }

  public render(): JSX.Element {
    return (
      <div className="row">
        <div className="col-12 overview-table-container">
          <ReactTable
            columns={this.columns}
            data={this.activityLoggingData ?? []}
            isLoading={this.props.isLoading}
            renderRowSubComponent={this.renderActivityLoggingItem}
            onUpdateSorting={this.props.onUpdateSorting}
          />
        </div>
      </div>
    );
  }
}
