import { FilterMenu } from "components/filterMenu/filterMenu";
import IMultiSelectDropdownField from "components/filterMenu/interfaces/IMultiSelectDropdownField";
import { IReactTableData } from "components/material/table/interfaces/IReactTableProps";
import ITableExpandedRows from "components/material/table/interfaces/ITableExpandedRows";
import { ReactTable } from "components/material/table/reactTable";
import ReactTableHelper from "components/material/table/reactTableHelper";
import { ReactTableRowExpander } from "components/material/table/reactTableRowExpander";
import LoaderTypes from "enums/loaderTypes";
import { TranslationMapper } from "i18n/mapper";
import INotificationCategory from "interfaces/INotificationCategory";
import INotificationCategoryContact from "interfaces/INotificationCategoryContact";
import LanguageProvider from "providers/languageProvider";
import * as React from "react";
import { connect } from "react-redux";
import { Column, Row } from "react-table";
import { getCustomerNotificationCategories } from "store/actions/customerDataActions";
import { getNotificationCategories } from "store/actions/masterDataActions";
import { getAllNotificationCategoryContacts } from "store/actions/notificationActions";
import { RootState } from "store/reducers/rootReducer";
import FilterUtils from "utils/filterUtils";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import CleaningCategory from "../../enums/cleaningCategory";
import CustomerProvider from "../../providers/customerProvider";
import ImageUriProvider from "../../providers/imageUriProvider";
import { withTelemetry } from "../../services/telemetryService";
import { getCustomers, showCustomerSelection } from "../../store/actions/customerActions";
import AppEventHub, { AppEvents } from "../../utils/appEventHub";
import CustomerNotificationSubComponent from "./components/customerNotificationSubComponents";
import ICustomerNotificationCategoriesState from "./interfaces/ICustomerNotificationCategoriesState";
import ICustomerNotificationCategoriesProps, {
  ICustomerNotificationCategoriesDispatchProps,
  ICustomerNotificationCategoriesStateProps,
} from "./interfaces/ICustomerNotificationCategoryProps";

type customerNotificationCategoryTableData = INotificationCategory & IReactTableData;

class CustomerNotificationCategories extends React.Component<
  ICustomerNotificationCategoriesProps,
  ICustomerNotificationCategoriesState
> {
  private readonly tableDataPropertyIndex: customerNotificationCategoryTableData = {
    // Used to create typesafety for accessors in tableData
    id: "",
    imageUri: "",
    key: "",
    name: "",
    selected: false,
  };

  public constructor(props: ICustomerNotificationCategoriesProps) {
    super(props);

    const state: ICustomerNotificationCategoriesState = {
      rowsExpandedState: [],
      selectedFilters: [],
      searchText: "",
    };

    this.state = state;

    this.setActiveCustomer = this.setActiveCustomer.bind(this);
    this.renderCategorySubComponent = this.renderCategorySubComponent.bind(this);
    this.isCategorySelected = this.isCategorySelected.bind(this);
    this.getCategoryActiveIcon = this.getCategoryActiveIcon.bind(this);
    this.getData = this.getData.bind(this);
    this.onRowToggle = this.onRowToggle.bind(this);
    this.updateFilter = this.updateFilter.bind(this);

    AppEventHub.on(AppEvents.CustomerSelected, this.setActiveCustomer);
  }

  public componentDidMount(): void {
    this.props.showCustomerSelection(true);
    this.getData();
  }

  public componentWillUnmount(): void {
    AppEventHub.off(AppEvents.CustomerSelected, this.setActiveCustomer);
  }

  private setActiveCustomer(): void {
    const activeCustomer = CustomerProvider.getActiveCustomer();

    if (!activeCustomer) {
      return;
    }

    this.props.onGetCustomerNotificationCategories(activeCustomer.id);
    this.props.onGetCustomerNotificationCategoryContacts(activeCustomer.id);
  }

  private getData(): void {
    const customer = CustomerProvider.getActiveCustomer();
    if (customer == null) {
      return;
    }

    this.props.onGetMasterDataNotificationCategories();
    this.props.onGetCustomerNotificationCategories(customer.id);
    this.props.onGetCustomerNotificationCategoryContacts(customer.id);
  }

  private getNotificationCategoryImage(imageUri: string, isSelected: boolean): string {
    const selectedString = ImageUriProvider.getActiveImageUri(CleaningCategory.NotificationCategory, isSelected);
    return `${imageUri}${selectedString}`;
  }

  private isCategorySelected(id: string): boolean {
    const categoryContacts = this.props.currentCustomerNotificationCategoryContacts.get(id);
    return categoryContacts != null;
  }

  private getCategoryActiveIcon(categoryId: string): JSX.Element {
    if (this.isCategorySelected(categoryId)) {
      return <FontAwesomeIcon icon={["fas", "circle-check"]} fixedWidth size="lg" className="text-success" />;
    } else {
      return <FontAwesomeIcon icon={["fas", "circle-check"]} fixedWidth size="lg" className="text-gray-300" />;
    }
  }

  private onRowToggle(newExpanded: ITableExpandedRows): void {
    this.setState({
      rowsExpandedState: newExpanded,
    });
  }

  private get categories(): customerNotificationCategoryTableData[] {
    if (!this.props.masterDataNotificationCategories || this.props.masterDataNotificationCategories?.length === 0) {
      return [];
    }

    const categories: customerNotificationCategoryTableData[] = this.props.masterDataNotificationCategories.map(cnc => {
      const category: customerNotificationCategoryTableData = {
        ...cnc,
        key: cnc.id,
      };

      return category;
    });

    return categories.filter(s => s.name.toLowerCase().includes(this.state.searchText.toLowerCase()));
  }

  private getRowData(row: Row): customerNotificationCategoryTableData {
    return row.original as customerNotificationCategoryTableData;
  }

  private get searchBar(): IMultiSelectDropdownField[] {
    const searchBar = FilterUtils.createSearchFilter(
      LanguageProvider.t(TranslationMapper.pages.notifications.filternames.type),
      (keyword: string) => {
        this.updateFilter(keyword);
      }
    );

    return [searchBar];
  }

  private updateFilter(value: string): void {
    this.setState({
      searchText: value,
    });
  }

  private get columns(): Column[] {
    return [
      {
        id: "spacer",
        width: 10,
      },
      {
        Header: LanguageProvider.t(TranslationMapper.pages.customerdata.column_header_notification_category),
        id: ReactTableHelper.getPropertyNameAsString(this.tableDataPropertyIndex, x => x.name),
        accessor: ReactTableHelper.getPropertyNameAsString(this.tableDataPropertyIndex, x => x.name),
        width: "auto",
      },
      {
        Header: LanguageProvider.t(TranslationMapper.pages.customerdata.column_header_notification_icon_active),
        accessor: (rowOriginal): JSX.Element =>
          rowOriginal.activeImageExists ? (
            <img className="table__img-thumbnail" src={this.getNotificationCategoryImage(rowOriginal.imageUri, true)} />
          ) : (
            <span>{LanguageProvider.t(TranslationMapper.pages.masterdata.activities.no_image)}</span>
          ),
        disableSortBy: true,
        width: "auto",
      },
      {
        Header: LanguageProvider.t(TranslationMapper.pages.customerdata.column_header_notification_icon_inactive),

        accessor: (rowOriginal): JSX.Element =>
          rowOriginal.inactiveImageExists ? (
            <img
              className="table__img-thumbnail"
              src={this.getNotificationCategoryImage(rowOriginal.imageUri, false)}
              alt=""
            />
          ) : (
            <span>{LanguageProvider.t(TranslationMapper.pages.masterdata.activities.no_image)}</span>
          ),
        disableSortBy: true,
        width: "auto",
      },
      {
        Header: LanguageProvider.t(TranslationMapper.pages.customerdata.column_header_notification_active),
        accessor: ReactTableHelper.getPropertyNameAsString(this.tableDataPropertyIndex, x => x.id),
        disableSortBy: true,
        width: "auto",
        Cell: ({ row }): JSX.Element => this.getCategoryActiveIcon(this.getRowData(row).id),
      },
      {
        id: "expander",
        disableSortBy: true,
        width: 36,
        Cell: ({ row }): JSX.Element => <ReactTableRowExpander row={row} />,
      },
    ];
  }

  private renderCategorySubComponent(rowInfo: Row): JSX.Element {
    const rowData = rowInfo.original as INotificationCategory;

    const notificationCategories = this.props.masterDataNotificationCategories;
    const customerNotificationCategories =
      this.props.customerNotificationCategories != null ? [...this.props.customerNotificationCategories] : [];
    let customerNotificationCategory = customerNotificationCategories?.find(cnc => cnc.categoryId === rowData.id);
    const notificationCategory = notificationCategories?.find(cnc => cnc.id === rowData.id);

    if (notificationCategory == null) {
      return <></>;
    }

    if (customerNotificationCategory == null) {
      customerNotificationCategory = {
        categoryId: rowData.id,
        contactEmailAddresses: [],
        customerId: "",
        notificationCategory: rowData,
        id: "",
      };
    }

    const customerNotificationCategoryContacts: INotificationCategoryContact[] =
      this.props.currentCustomerNotificationCategoryContacts.get(rowData.id) ?? [];

    // Subcomponent needs to be rendered using a separate component, otherwise state changes will toggle expanded state of row
    return (
      <CustomerNotificationSubComponent
        customerNotificationCategory={customerNotificationCategory}
        currentCustomerNotificationCategoryContacts={customerNotificationCategoryContacts}
        validateInput={
          this.isCategorySelected(rowData.id) && customerNotificationCategory.contactEmailAddresses.length === 0
        }
        isCategoryActive={this.isCategorySelected(rowData.id)}
      />
    );
  }

  public render(): JSX.Element {
    return (
      <>
        <header className="header-actions">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 header-actions__content">
                <h1>{LanguageProvider.t(TranslationMapper.masterdata.incidents)}</h1>
              </div>
            </div>
          </div>
        </header>

        <FilterMenu filterFields={this.searchBar} />

        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 mb-2 block-content--px">
                <ReactTable
                  isLoading={this.props.isLoading}
                  columns={this.columns}
                  data={this.categories}
                  renderRowSubComponent={this.renderCategorySubComponent}
                  className="table--bordered table--has-checkbox table--has-expander"
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: RootState): ICustomerNotificationCategoriesStateProps => ({
  customerNotificationCategories: state.customerState.customerNotificationCategories,
  masterDataNotificationCategories: state.notificationState.notificationCategories,
  isLoading: state.generalState.loaders.some(l => l === LoaderTypes.NotificationCategory),
  currentCustomerNotificationCategoryContacts: state.customerState.customerNotificationCategoryContacts ?? new Map(),
});

const mapDispatchToProps: ICustomerNotificationCategoriesDispatchProps = {
  showCustomerSelection: showCustomerSelection,
  getCustomers: getCustomers,
  onGetMasterDataNotificationCategories: getNotificationCategories,
  onGetCustomerNotificationCategories: getCustomerNotificationCategories,
  onGetCustomerNotificationCategoryContacts: getAllNotificationCategoryContacts,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTelemetry(CustomerNotificationCategories, "CustomerNotificationCategories"));
