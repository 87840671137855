import ActivityItem from "components/categories/activityItem";
import TranslationMapper from "i18n/mapper";
import ICleaningObjectContainer from "interfaces/ICleaningObjectContainer";
import ICleaningSpace from "interfaces/ICleaningSpace";
import ICustomerActivity from "interfaces/ICustomerActivity";
import LanguageProvider from "providers/languageProvider";
import { Component, ReactNode } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { RootState } from "store/reducers/rootReducer";

import CleaningObjectActivitiesContainer from "../setCleaningObjectActivities/cleaningObjectActivitiesContainer";
import IWizardStep from "../wizard/interfaces/IWizardStep";
import IRouteActivitiesStepProps, { IRouteActivitiesStepStateProps } from "./interfaces/IRouteActivitiesStepProps";
import IRouteActivitiesStepState from "./interfaces/IRouteActivitiesStepState";
import RouteValidator from "./routeValidator";

class RouteActivitiesStep extends Component<IRouteActivitiesStepProps, IRouteActivitiesStepState> {
  public constructor(props: IRouteActivitiesStepProps) {
    super(props);

    this.state = {
      venue: this.props.value != null ? this.props.value : { cleaningObjectsContainers: [] },
    };

    this.propagateChange = this.propagateChange.bind(this);
    this.onToggleTopActivity = this.onToggleTopActivity.bind(this);
    this.onZoneActivityChange = this.onZoneActivityChange.bind(this);
    this.allActivitiesToggled = this.allActivitiesToggled.bind(this);
    this.anyActivityInZoneToggled = this.anyActivityInZoneToggled.bind(this);
    this.getCleaningObjectsForFloor = this.getCleaningObjectsForFloor.bind(this);
  }

  private propagateChange(): void {
    const isValid = RouteValidator.areActivitiesValid(this.state.venue.cleaningObjectsContainers);
    this.props.onChange({ target: { value: this.state, name: this.props.name }, isValid });
  }

  private onToggleTopActivity(ca: ICustomerActivity): void {
    const venue = this.state.venue;
    const unSelectAllActivities = this.anyActivityInZoneToggled(ca.activity.id);

    venue.cleaningObjectsContainers.forEach((floor) => {
      floor.cleaningObjects?.forEach((zone) => {
        if (!zone.activityIds) {
          zone.activityIds = [];
        }

        if (unSelectAllActivities) {
          const existingActivityId = zone.activityIds.find((id) => id === ca.activity.id);
          if (existingActivityId) {
            const index = zone.activityIds.indexOf(existingActivityId);
            zone.activityIds.splice(index, 1);
          }
          return;
        }
        zone.activityIds.push(ca.activity.id);
      });
    });

    this.setState(
      {
        venue: venue,
      },
      () => this.propagateChange()
    );
  }

  private onZoneActivityChange(routeCleaningObject: ICleaningSpace, activityId: string): void {
    const venue = this.state.venue;
    const zones = venue.cleaningObjectsContainers.flatMap((x) => x.cleaningObjects);

    const zone = zones.find((x) => x?.id === routeCleaningObject.id);
    if (!zone) {
      return;
    }

    if (!zone.activityIds) {
      zone.activityIds = [];
    }

    const existingActivityId = zone.activityIds.find((id) => id === activityId);
    if (existingActivityId) {
      const index = zone.activityIds.indexOf(existingActivityId);
      zone.activityIds.splice(index, 1);
    } else {
      zone.activityIds.push(activityId);
    }

    this.setState(
      {
        venue: venue,
      },
      this.propagateChange
    );
  }

  private allActivitiesToggled(activityId: string): boolean {
    return this.state.venue.cleaningObjectsContainers.every((selectedCleaningObject) => {
      return selectedCleaningObject.cleaningObjects
        ? selectedCleaningObject.cleaningObjects.every((zone) => zone.activityIds?.find((id) => id === activityId))
        : false;
    });
  }

  private anyActivityInZoneToggled(activityId: string): boolean {
    return this.state.venue.cleaningObjectsContainers.some((selectedCleaningObject) => {
      return selectedCleaningObject.cleaningObjects
        ? selectedCleaningObject.cleaningObjects.some((zone) => zone.activityIds?.find((id) => id === activityId))
        : false;
    });
  }

  private get customerCleaningActivities(): JSX.Element[] {
    return this.props.customerActivities.map((ca) => {
      const selectedString = this.topActivitySelected(ca.activity.id);
      const imageUri = `${ca.activity.imageUri}${selectedString}`;
      const onToggle = (): void => this.onToggleTopActivity(ca);

      return (
        <ActivityItem
          selectingDisabled={false}
          activity={ca.activity}
          active={this.allActivitiesToggled(ca.activity.id)}
          key={ca.id}
          onItemClick={onToggle}
          name={ca.activity.name}
          imageUri={imageUri}
        />
      );
    });
  }

  private topActivitySelected(activityId: string): string {
    const activitySelected = this.allActivitiesToggled(activityId);
    return activitySelected ? "_Activity_active" : "_Activity_inactive";
  }

  private getCleaningObjectsForFloor(floor: ICleaningObjectContainer): ICleaningSpace[] {
    const cleaningObjects = this.state.venue.cleaningObjectsContainers.find(
      (c) => c.name === floor.name
    )?.cleaningObjects;
    return cleaningObjects ? cleaningObjects : [];
  }

  private get selectedCleaningObjectsCount(): number {
    return this.state.venue.cleaningObjectsContainers.flatMap((x) => x.cleaningObjects).length;
  }

  public render(): ReactNode {
    const formName = "wizard__route-activities-step";

    return (
      <Form data-testid="route-wizard-activities-step" className={formName}>
        <Row>
          <Col className="modal__col-mb">
            <Form.Label>
              {LanguageProvider.t(TranslationMapper.pages.routewizard.cleaning_activities.default_activities)}
            </Form.Label>
            <p>{LanguageProvider.t(TranslationMapper.pages.routewizard.cleaning_activities.general_activities)}</p>
            <div className="row">{this.customerCleaningActivities}</div>
          </Col>
        </Row>
        <Row>
          <Col className="modal__col-mb">
            <hr className="my-0" />
          </Col>
        </Row>
        <Row>
          <Col className="modal__col-mb">
            <Form.Label>
              {LanguageProvider.t(TranslationMapper.pages.routewizard.cleaningobjects.selectedobjects)} (
              {this.selectedCleaningObjectsCount})
            </Form.Label>
            <p>{LanguageProvider.t(TranslationMapper.pages.routewizard.cleaning_activities.zone_activities)}</p>

            {this.state.venue.cleaningObjectsContainers.map((floor, index) => {
              return (
                <CleaningObjectActivitiesContainer
                  floorName={floor.name}
                  id={index}
                  routeCleaningObjects={this.getCleaningObjectsForFloor(floor)}
                  onActivityChange={this.onZoneActivityChange}
                  key={floor.name}
                  customerActivities={this.props.customerActivities}
                />
              );
            })}
          </Col>
        </Row>
      </Form>
    );
  }
}

const mapStateToProps = (state: RootState): IRouteActivitiesStepStateProps => {
  return {
    customerActivities: state.customerState.customerActivities ?? [],
  };
};

const wizardStep: IWizardStep = {
  form: connect(mapStateToProps)(RouteActivitiesStep),
  titleResource: TranslationMapper.pages.routewizard.cleaning_activities.title,
  subtitleResource: TranslationMapper.pages.routewizard.cleaning_activities.subtitle,
  name: "activities",
};

export default wizardStep;
