enum LoaderTypes {
  Customers = "Customers",
  CustomerProspects = "CustomerProspects",
  CustomerActivities = "CustomerActivities",
  ExternalLocation = "ExternalLocation",
  Routes = "Routes",
  RouteLogging = "RouteLogging",
  RouteLoggingDeletePermission = "RouteLoggingDeletePermission",
  SelectedRoute = "SelectedRoute",
  Activities = "Activities",
  SelectedActivity = "SelectedActivity",
  Notifications = "Notifications",
  NotificationCreate = "NotificationCreate",
  CleaningObjects = "CleaningObjects",
  ActivityActions = "ActivityActions",
  NotificationCategory = "NotificationCategory",
  CustomerNotificationCategory = "CustomerNotificationCategory",
  SelectedNotificationCategory = "SelectedNotificationCategory",
  Locations = "Locations",
  SelectedVenue = "SelectedVenue",
  User = "User",
  UploadFile = "UploadFile",
  Logbook = "Logbook",
  LogbookChannel = "LogbookChannel",
  NotificationContacts = "NotificationContacts",
}

export default LoaderTypes;
