import IOperator from "interfaces/IOperator";
import { initialOperatorStoreState, IOperatorStoreState } from "store/state/operatorStoreState";
import Dictionary from "utils/dictionary";
import produce from "immer";

import { ActionTypes, ActionTypesCollection } from "../actionTypes";
import { cloneDeep } from "lodash";

export default function operatorReducer(
  state: IOperatorStoreState = initialOperatorStoreState,
  action: ActionTypesCollection
): IOperatorStoreState {
  switch (action.type) {
    case ActionTypes.FETCHED_OPERATORS: {
      const updatedOperators = cloneDeep(state.operators) ??  new Dictionary<IOperator[]>()

      updatedOperators[action.payload.customerId] = action.payload.operators;

      return produce(state, (draftState) => {
        draftState.operators = updatedOperators;
      });
    }
    default:
      return state;
  }
}


