import { ICleaningObjectStoreState, initialCleaningObjectStoreState } from "store/state/cleaningObjectStoreState";

import { ActionTypes, ActionTypesCollection } from "../actionTypes";

export default function cleaningObjectReducer(
  state: ICleaningObjectStoreState = initialCleaningObjectStoreState,
  action: ActionTypesCollection
): ICleaningObjectStoreState {
  switch (action.type) {
    case ActionTypes.FETCHED_CLEANING_OBJECTS:
      return {
        ...state,
        cleaningObjects: action.payload,
      };
    default:
      return state;
  }
}
