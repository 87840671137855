import "moment/locale/nl";

import { nl } from "date-fns/esm/locale";
import TranslationMapper from "i18n/mapper";
import LanguageProvider from "providers/languageProvider";
import * as React from "react";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import { MultiSelect } from "react-multi-select-component";
import Select from "react-select";
import FilterUtils from "utils/filterUtils";
import Sorter from "utils/sorter";

import SelectBoxUtils from "../../utils/selectBoxUtils";
import IFilterField from "./interfaces/IFilterField";
import IFilterMenuProps from "./interfaces/IFilterMenuProps";
import IMultiSelectDropdownField from "./interfaces/IMultiSelectDropdownField";
import ISearchField from "./interfaces/ISearchField";
import ISingleSelectDropdownField from "./interfaces/ISingleSelectDropdownField";

export class FilterMenu extends React.Component<IFilterMenuProps> {
  public constructor(props: IFilterMenuProps) {
    super(props);
    this.renderSelectBox = this.renderSelectBox.bind(this);
  }

  private isSingleSelect(
    dropdown: ISingleSelectDropdownField | IMultiSelectDropdownField | ISearchField
  ): dropdown is ISingleSelectDropdownField {
    return (dropdown as ISingleSelectDropdownField).onSelect != null;
  }

  private isMultiSelect(
    dropdown: ISingleSelectDropdownField | IMultiSelectDropdownField | ISearchField
  ): dropdown is IMultiSelectDropdownField {
    return (dropdown as IMultiSelectDropdownField).onSelectMulti != null;
  }

  private isSearchField(
    dropdown: ISingleSelectDropdownField | IMultiSelectDropdownField | ISearchField
  ): dropdown is ISearchField {
    return (dropdown as ISearchField).onKeywordChange != null;
  }

  private renderSelectBox(filterField: IFilterField, index: number): JSX.Element {
    const noOptionsMessage = (): string => LanguageProvider.t(TranslationMapper.filtermenu.no_options);

    if (this.isSingleSelect(filterField)) {
      return (
        <Select
          defaultValue={filterField.defaultValue}
          onChange={filterField.onSelect}
          options={filterField.filterList}
          noOptionsMessage={noOptionsMessage}
          placeholder={filterField.filterName}
          styles={SelectBoxUtils.getDefaultSelectStyles(40)}
          isClearable={true}
          key={index}
          className="single-select"
          classNamePrefix="single-select"
        />
      );
    } else if (this.isMultiSelect(filterField)) {
      const localisation = FilterUtils.getMultiSelectLocalisations(
        filterField.filterName,
        filterField.selected?.length
      );

      return (
        <MultiSelect
          options={filterField.filterList}
          value={filterField.selected ? filterField.selected : []}
          onChange={filterField.onSelectMulti}
          labelledBy={filterField.filterName}
          overrideStrings={localisation}
          ArrowRenderer={this.renderMultiSelectArrow}
          ClearIcon={this.renderMultiSelectClearIcon()}
          ClearSelectedIcon={this.renderMultiSelectClearIcon()}
          valueRenderer={this.renderMultiSelectValue}
          key={index}
        />
      );
    } else if (this.isSearchField(filterField)) {
      return (
        <div key={index}>
          <input
            type="text"
            placeholder={filterField.filterName}
            onChange={(e): void => filterField.onKeywordChange(e.target.value)}
            className="form-control form-control--icon--end form-control--icon--magnifying-glass"
          />
        </div>
      );
    }

    return <div />;
  }

  private renderMultiSelectValue(selected: any): React.ReactNode {
    if (selected && selected.length > 0) {
      return <div>{`${selected.length} ${LanguageProvider.t(TranslationMapper.searchable_multiselect.selected)}`}</div>;
    }
    return null;
  }

  private renderMultiSelectArrow(): JSX.Element {
    return <div className="dropdown-arrow"></div>;
  }

  private renderMultiSelectClearIcon(): JSX.Element {
    return <div className="clear-selected-button"></div>;
  }

  public componentDidMount(): void {
    registerLocale("nl", nl);
    setDefaultLocale("nl");
  }

  public render(): JSX.Element {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 filter-menu">
            <div className="d-flex justify-content-start flex-wrap filter-menu__containter">
              {this.props.handleStartDateChange && (
                <div>
                  <DatePicker
                    selected={this.props.startDate}
                    onChange={this.props.handleStartDateChange}
                    minDate={this.props.minDate ? this.props.minDate : undefined}
                    maxDate={this.props.endDate}
                    placeholderText={LanguageProvider.t(TranslationMapper.datepicker.startdate)}
                    className={`form-control form-control--icon--end form-control--icon--calendar${
                      this.props.dateFilterClassName ? ` ${this.props.dateFilterClassName}` : ""
                    }`}
                    dateFormat="dd-MM-yyyy"
                    todayButton={LanguageProvider.t(TranslationMapper.datepicker.today)}
                  />
                </div>
              )}
              {this.props.handleEndDateChange && (
                // div is necessary to fix extra space issue by created sibling element when focused
                <div>
                  <DatePicker
                    selected={this.props.endDate}
                    onChange={this.props.handleEndDateChange}
                    minDate={this.props.startDate}
                    maxDate={this.props.maxDate ? this.props.maxDate : undefined}
                    placeholderText={LanguageProvider.t(TranslationMapper.datepicker.enddate)}
                    className={`form-control form-control--icon--end form-control--icon--calendar${
                      this.props.dateFilterClassName ? ` ${this.props.dateFilterClassName}` : ""
                    }`}
                    dateFormat="dd-MM-yyyy"
                    todayButton={LanguageProvider.t(TranslationMapper.datepicker.today)}
                  />
                </div>
              )}
              {this.props.filterFields?.map((filterField, index) => {
                filterField.filterList = Sorter.sortByProperty(filterField.filterList, "label");
                return this.renderSelectBox(filterField, index);
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
