import { ReactTable } from "components/material/table/reactTable";
import ReactTableHelper from "components/material/table/reactTableHelper";
import { ReactTableRowExpander } from "components/material/table/reactTableRowExpander";
import { ReactTableRowSelector } from "components/material/table/reactTableRowSelector";
import TranslationMapper from "i18n/mapper";
import moment from "moment";
import LanguageProvider from "providers/languageProvider";
import { Component } from "react";
import { withTranslation } from "react-i18next";
import { Column, Row } from "react-table";

import ILoggingTableProps from "./interfaces/ILoggingTableProps";
import { LoggingTableData } from "./loggingOverview";
import RouteLoggingSubComponent from "./routeLoggingSubComponent";

class LoggingTable extends Component<ILoggingTableProps> {
  private readonly tableDataPropertyIndex: LoggingTableData = {
    // Used to create typesafety for accessors in tableData
    operatorName: "",
    key: "",
    pauseTime: 0,
    plannedTime: 0,
    realTime: 0,
    routeDataId: "",
    routeName: "",
    routeStatus: 0,
    startDate: new Date(),
  };

  public constructor(props: ILoggingTableProps) {
    super(props);

    this.setActiveRow = this.setActiveRow.bind(this);
    this.subComponent = this.subComponent.bind(this);
  }

  private get columns(): Column[] {
    return [
      {
        id: "table-row-selector",
        width: 36,
        disableSortBy: true,
        Cell: ({ row }): JSX.Element => (
          <ReactTableRowSelector
            onChange={this.setActiveRow}
            row={row}
            multiSelectRowKeys={this.props.selectedRouteIds}
          />
        ),
      },
      {
        Header: LanguageProvider.t(TranslationMapper.pages.logging.routename),
        id: ReactTableHelper.getPropertyNameAsString(this.tableDataPropertyIndex, x => x.routeName),
        accessor: ReactTableHelper.getPropertyNameAsString(this.tableDataPropertyIndex, x => x.routeName),
        width: "auto",
      },
      {
        Header: LanguageProvider.t(TranslationMapper.pages.logging.startdate),
        id: ReactTableHelper.getPropertyNameAsString(this.tableDataPropertyIndex, x => x.startDate),
        accessor: ReactTableHelper.getPropertyNameAsString(this.tableDataPropertyIndex, x => x.startDate),
        width: "auto",
        Cell: ({ value }): string => (value ? moment(value).format("DD-MM-YYYY") : ""),
      },
      {
        Header: LanguageProvider.t(TranslationMapper.pages.logging.pausetime),
        id: ReactTableHelper.getPropertyNameAsString(this.tableDataPropertyIndex, x => x.pauseTime),
        width: "auto",
        accessor: (rowOriginal): string => {
          return `${rowOriginal.pauseTime} min`;
        },
      },
      {
        Header: LanguageProvider.t(TranslationMapper.pages.logging.plannedduration),
        id: ReactTableHelper.getPropertyNameAsString(this.tableDataPropertyIndex, x => x.plannedTime),
        width: "auto",
        accessor: (rowOriginal): string => {
          return `${rowOriginal.plannedTime} min`;
        },
      },
      {
        Header: LanguageProvider.t(TranslationMapper.pages.logging.actualduration),
        id: ReactTableHelper.getPropertyNameAsString(this.tableDataPropertyIndex, x => x.realTime),
        width: "auto",
        accessor: (rowOriginal): string => {
          return `${rowOriginal.realTime} min`;
        },
      },
      {
        Header: LanguageProvider.t(TranslationMapper.pages.logging.deviation),
        id: "deviation",
        width: "auto",
        accessor: (rowOriginal): JSX.Element => {
          const deviation = rowOriginal.realTime - rowOriginal.plannedTime;
          const deviationClass: string = deviation > 0 ? "text-danger" : "";

          if (deviation > 0) {
            return <div className={deviationClass}>{`+${deviation} min`}</div>;
          }
          return <div className={deviationClass}>{`${deviation} min`}</div>;
        },
      },
      {
        Header: LanguageProvider.t(TranslationMapper.pages.logging.total),
        id: "totaltime",
        width: "auto",
        accessor: (rowOriginal): string => {
          const totalTime = rowOriginal.pauseTime + rowOriginal.realTime;
          return `${totalTime} min`;
        },
      },
      {
        id: "expander",
        disableSortBy: true,
        width: 36,
        Cell: ({ row }): JSX.Element => <ReactTableRowExpander row={row} />,
      },
    ];
  }

  private setActiveRow(rowKey: string): void {
    const selectedRouteIds = [...this.props.selectedRouteIds];
    const indexOfSelectedRoute = selectedRouteIds.findIndex(r => r.toLowerCase() === rowKey.toLowerCase());

    if (indexOfSelectedRoute === -1) {
      selectedRouteIds.push(rowKey);
    } else {
      selectedRouteIds.splice(indexOfSelectedRoute, 1);
    }

    this.props.onUpdateSelectedRouteIds(selectedRouteIds);
  }

  private subComponent(row: Row): JSX.Element {
    const routeDataId = (row.original as LoggingTableData).key;
    this.props.onGetExpandedCustomerRouteLogging(routeDataId);

    return <RouteLoggingSubComponent routeDataId={routeDataId} />;
  }

  public render(): JSX.Element {
    return (
      <ReactTable
        isLoading={this.props.isLoading}
        columns={this.columns}
        data={this.props.tableData ?? []}
        renderRowSubComponent={this.subComponent}
        className="table--bordered table--has-checkbox table--has-expander"
      />
    );
  }
}

export default withTranslation()(LoggingTable);
