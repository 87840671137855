import { combineReducers } from "redux";

import activityReducer from "./activityReducer";
import activityTypeReducer from "./activityTypeReducer";
import cleaningObjectReducer from "./cleaningObjectReducer";
import customerReducer from "./customerReducer";
import errorReducer from "./errorReducer";
import generalReducer from "./generalReducer";
import locationReducer from "./locationReducer";
import logbookReducer from "./logbookReducer";
import notificationReducer from "./notificationReducer";
import operatorReducer from "./operatorReducer";
import scheduleManagementReducer from "./scheduleManagementReducer";
import userReducer from "./userReducer";

const rootReducer = combineReducers({
  activityState: activityReducer,
  activityTypeState: activityTypeReducer,
  operatorState: operatorReducer,
  cleaningObjectState: cleaningObjectReducer,
  customerState: customerReducer,
  errorState: errorReducer,
  generalState: generalReducer,
  locationState: locationReducer,
  logbookState: logbookReducer,
  notificationState: notificationReducer,
  scheduleManagementState: scheduleManagementReducer,
  userState: userReducer,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
