// https://www.dustinhorne.com/post/2016/06/09/implementing-a-dictionary-in-typescript
export interface IKeyedCollection<T> {
  count: number;
  add(key: string, value: T): void;
  containsKey(key: string): boolean;
  item(key: string): T;
  getKeys(): string[];
  remove(key: string): void;
  getValues(): T[];
}

export default class Dictionary<T> implements IKeyedCollection<T> {
  private items: { [index: string]: T } = {};

  private countBackingField: number = 0;

  public containsKey(key: string): boolean {
    return this.items.hasOwnProperty(key);
  }

  public get count(): number {
    return this.countBackingField;
  }

  public add(key: string, value: T): void {
    if (!this.items.hasOwnProperty(key)) {
      this.countBackingField++;
    }

    this.items[key] = value;
  }

  public remove(key: string): void {
    const val = this.items[key];
    if (!val && typeof val !== "boolean") {
      return;
    }

    delete this.items[key];
    this.countBackingField--;
  }

  public item(key: string): T {
    return this.items[key];
  }

  public getKeys(): string[] {
    const keySet: string[] = [];

    // todo: add caching to improve read performance
    for (const prop in this.items) {
      if (this.items.hasOwnProperty(prop)) {
        keySet.push(prop);
      }
    }

    return keySet;
  }

  public getValues(): T[] {
    const values: T[] = [];

    for (const prop in this.items) {
      if (this.items.hasOwnProperty(prop)) {
        values.push(this.items[prop]);
      }
    }

    return values;
  }

  public toJsonDictionary(): { [index: string]: T } {
    return this.items;
  }
}