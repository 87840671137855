import TranslationMapper from "i18n/mapper";
import LanguageProvider from "providers/languageProvider";
import { Component, ReactNode } from "react";
import Select, { ValueType } from "react-select";

import IReactSelectValue from "../../../interfaces/IReactSelectValue";
import ISearchableSelectProps from "./interfaces/ISearchableSelectProps";

export default class SearchableSelect extends Component<ISearchableSelectProps> {
  public constructor(props: ISearchableSelectProps) {
    super(props);

    this.onChange = this.onChange.bind(this);
  }

  public onChange(event: ValueType<IReactSelectValue, boolean>): void {
    if (this.props.onChange != null) {
      const item = event as IReactSelectValue;
      const value = this.props.options.find(option => {
        const optionAsSelectValue = this.getReactSelectValue(option);

        return optionAsSelectValue.value === item.value;
      });

      this.props.onChange({
        target: {
          name: this.props["name"],
          value: value,
        },
      });
    }
  }

  private get options(): IReactSelectValue[] {
    return this.props.options.map(option => {
      return this.getReactSelectValue(option);
    });
  }

  private get value(): IReactSelectValue | null {
    if (this.props.value != null) {
      return this.getReactSelectValue(this.props.value);
    }
    return null;
  }

  private getReactSelectValue(value: any): IReactSelectValue {
    if (this.props.getItemAsSelectOptionFn != null) {
      const selectOption = this.props.getItemAsSelectOptionFn(value);

      return {
        value: selectOption.id,
        label: selectOption.label,
      };
    } else {
      return {
        value: value.id,
        label: value.label,
      };
    }
  }

  public render(): ReactNode {
    return (
      <Select
        options={this.options}
        className={`single-select${this.props["className"] ? ` ${this.props["className"]}` : ""}`}
        classNamePrefix="single-select"
        id={this.props.id}
        name={this.props.name}
        onChange={this.onChange}
        data-testid={this.props["testId"]}
        value={this.value}
        defaultValue={this.value}
        noOptionsMessage={(): string => LanguageProvider.t(TranslationMapper.searchable_select.no_options)}
        placeholder={LanguageProvider.t(TranslationMapper.searchable_select.placeholder)}
      />
    );
  }
}
