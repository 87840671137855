import IFile from "interfaces/IFile";
import IUploadResponse from "interfaces/IUploadResponse";
import ExternalVenue from "models/externalVenue";
import Floor from "models/floor";
import Zone from "models/zone";

import { CmApiAuthenticator } from "../authenticators/cmApiAuthenticator";
import { ApplicationConfig } from "../config";
import BaseService from "./baseService";

export default class CustomerLocationService extends BaseService {
  private endpoint: string = "customerlocation";

  public constructor() {
    super(ApplicationConfig.endpoints.winvisionCleaningManagementApi, new CmApiAuthenticator());
  }

  public async getAccesTokenAsync(): Promise<string> {
    const accessToken = await this.authenticator?.getTokenAsync();
    return `Bearer ${accessToken}`;
  }

  public async getExternalLocation(customerId: string, externalLocationId: string): Promise<ExternalVenue> {
    return this.get<ExternalVenue>(`${this.endpoint}/${customerId}/location/${externalLocationId}`);
  }

  public async createFloor(customerId: string, locationId: string, floor: Floor): Promise<Response> {
    return this.post(`${this.endpoint}/${customerId}/location/${locationId}/floor`, floor);
  }

  public async updateFloor(customerId: string, locationId: string, floor: Floor): Promise<Response> {
    return this.put(`${this.endpoint}/${customerId}/location/${locationId}/floor`, floor);
  }

  public async deleteFloor(customerId: string, locationId: string, floorId: string): Promise<Response> {
    return this.delete(`${this.endpoint}/${customerId}/location/${locationId}/floor/${floorId}`);
  }

  public async createZone(customerId: string, locationId: string, zone: Zone): Promise<Response> {
    return this.post(`${this.endpoint}/${customerId}/location/${locationId}/zone`, zone);
  }

  public async updateZone(customerId: string, locationId: string, zone: Zone): Promise<Response> {
    return this.put(`${this.endpoint}/${customerId}/location/${locationId}/zone`, zone);
  }

  public async deleteZone(customerId: string, locationId: string, zoneId: string): Promise<Response> {
    return this.delete(`${this.endpoint}/${customerId}/location/${locationId}/zone/${zoneId}`);
  }

  public async uploadFile(locationId: string, file: IFile): Promise<IUploadResponse> {
    return this.postWithResponse<IUploadResponse>(`location/${locationId}/upload`, file);
  }
}