import React from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import IGoToPageProps from "./interfaces/IGoToPageProps";

class GoToPage extends React.Component<IGoToPageProps> {
  public constructor(props: IGoToPageProps) {
    super(props);

    this.onGoBack = this.onGoBack.bind(this);
  }

  private onGoBack(): void {
    this.props.history.push(this.props.page);
  }

  public render(): JSX.Element {
    return (
      <button className="btn btn-primary btn--rounded me-3" onClick={this.onGoBack}>
        <FontAwesomeIcon icon={["fal", "arrow-left"]} />
      </button>
    );
  }
}

export default withTranslation()(withRouter(GoToPage));
