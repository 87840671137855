import { FilterMenu } from "components/filterMenu/filterMenu";
import IMultiSelectDropdownField from "components/filterMenu/interfaces/IMultiSelectDropdownField";
import { IReactTableData } from "components/material/table/interfaces/IReactTableProps";
import { IReactTableRowSubComponentRowProps } from "components/material/table/interfaces/IReactTableRowSubComponentProps";
import { ReactTable } from "components/material/table/reactTable";
import ReactTableHelper from "components/material/table/reactTableHelper";
import { ReactTableRowExpander } from "components/material/table/reactTableRowExpander";
import { ReactTableRowSelector } from "components/material/table/reactTableRowSelector";
import { ReactTableRowSubComponent } from "components/material/table/reactTableRowSubComponent";
import TranslationMapper from "i18n/mapper";
import LanguageProvider from "providers/languageProvider";
import * as React from "react";
import { withTranslation } from "react-i18next";
import { NotificationManager } from "react-notifications";
import { connect } from "react-redux";
import { Column, Row as ReactTableRow } from "react-table";
import { RootState } from "store/reducers/rootReducer";
import FilterUtils from "utils/filterUtils";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import FeedbackRequestType from "../../enums/feedbackRequestType";
import LoaderTypes from "../../enums/loaderTypes";
import IActivity from "../../interfaces/IActivity";
import IActivityFeedbackItem from "../../interfaces/IActivityFeedbackItem";
import ICategoryItem from "../../interfaces/ICategoryItem";
import { withTelemetry } from "../../services/telemetryService";
import { showCustomerSelection } from "../../store/actions/customerActions";
import { updateCustomerActivities } from "../../store/actions/customerDataActions";
import { getMasterDataActivities } from "../../store/actions/masterDataActions";
import AppEventHub, { AppEvents } from "../../utils/appEventHub";
import ICustomerActivitiesProps, {
  ICustomerActivitiesDispatchProps,
  ICustomerActivitiesStateProps,
} from "./interfaces/ICustomerActivitiesProps";
import ICustomerActivitiesState from "./interfaces/ICustomerActivitiesState";

type customerActivitiesTableData = IReactTableData & IActivity;

export class CustomerActivities extends React.Component<ICustomerActivitiesProps, ICustomerActivitiesState> {
  private readonly tableDataPropertyIndex: customerActivitiesTableData = {
    // Used to create typesafety for accessors in tableData
    id: "",
    imageUri: "",
    key: "",
    name: "",
    canBeCheckedOutMultipleTimes: false,
    feedbackRequests: [],
  };

  public constructor(props: ICustomerActivitiesProps) {
    super(props);

    const state: ICustomerActivitiesState = {
      selectedCustomerActivityIds: undefined,
      selectedRowIndex: 0,
      searchText: "",
    };

    this.state = state;

    this.toggleActivity = this.toggleActivity.bind(this);
    this.saveForm = this.saveForm.bind(this);
    this.selectRow = this.selectRow.bind(this);
    this.updateCustomerActivityRow = this.updateCustomerActivityRow.bind(this);
    this.resetActiveCustomer = this.resetActiveCustomer.bind(this);

    AppEventHub.on(AppEvents.CustomerSelected, this.resetActiveCustomer);
  }

  private resetActiveCustomer(): void {
    this.setState({
      selectedCustomerActivityIds: undefined,
    });
  }

  public componentWillUnmount(): void {
    AppEventHub.off(AppEvents.CustomerSelected, this.resetActiveCustomer);
  }

  public async componentDidMount(): Promise<void> {
    this.props.showCustomerSelection(true);

    this.props.getMasterDataActivities();
  }

  private selectRow(activityId: string): void {
    this.updateCustomerActivityRow(activityId);
  }

  private getHasFeedback(feedback: IActivityFeedbackItem[]): boolean {
    return feedback && feedback.length > 0;
  }

  private get activeCustomerActivities(): string[] {
    return this.props.customerActivities.map(ca => ca.activityId);
  }

  private getCheckOutText(canBeCheckedOutMultipleTimes: boolean): string {
    return canBeCheckedOutMultipleTimes
      ? LanguageProvider.t(TranslationMapper.pages.masterdata.activities.multiplecheckout)
      : LanguageProvider.t(TranslationMapper.pages.masterdata.activities.singlecheckout);
  }

  private get getColumns(): Column[] {
    return [
      {
        id: "table-row-selector",
        width: 36,
        disableSortBy: true,
        Cell: ({ row }): JSX.Element => (
          <ReactTableRowSelector
            onChange={this.selectRow}
            row={row}
            multiSelectRowKeys={this.activeCustomerActivities}
          />
        ),
      },
      {
        Header: LanguageProvider.t(TranslationMapper.pages.masterdata.activities.columnheaders.title),
        id: ReactTableHelper.getPropertyNameAsString(this.tableDataPropertyIndex, x => x.name),
        accessor: ReactTableHelper.getPropertyNameAsString(this.tableDataPropertyIndex, x => x.name),
        width: "40%",
      },
      {
        Header: LanguageProvider.t(TranslationMapper.pages.masterdata.activities.columnheaders.activeicon),
        id: "imageUriActive",
        accessor: (rowOriginal): JSX.Element =>
          rowOriginal.activeImageExists ? (
            <img className="table__img-thumbnail" src={rowOriginal.imageUri + "_Activity_active"} />
          ) : (
            <span>{LanguageProvider.t(TranslationMapper.pages.masterdata.activities.no_image)}</span>
          ),
        disableSortBy: true,
        width: "auto",
      },
      {
        Header: LanguageProvider.t(TranslationMapper.pages.masterdata.activities.columnheaders.inactiveicon),
        id: "imageUriInactive",
        accessor: (rowOriginal): JSX.Element =>
          rowOriginal.inactiveImageExists ? (
            <img className="table__img-thumbnail" src={rowOriginal.imageUri + "_Activity_inactive"} />
          ) : (
            <span>{LanguageProvider.t(TranslationMapper.pages.masterdata.activities.no_image)}</span>
          ),
        disableSortBy: true,
        width: "auto",
      },
      {
        Header: LanguageProvider.t(TranslationMapper.pages.masterdata.activities.columnheaders.checkout),
        id: ReactTableHelper.getPropertyNameAsString(this.tableDataPropertyIndex, x => x.canBeCheckedOutMultipleTimes),
        accessor: (rowOriginal): string => this.getCheckOutText(rowOriginal.canBeCheckedOutMultipleTimes),
        disableSortBy: true,
        width: "auto",
        Cell: ({ value }): JSX.Element => (
          <div>
            <span>{value}</span>
          </div>
        ),
      },
      {
        Header: LanguageProvider.t(TranslationMapper.pages.masterdata.activities.columnheaders.feedback),
        id: ReactTableHelper.getPropertyNameAsString(this.tableDataPropertyIndex, x => x.feedbackRequests),
        accessor: (rowOriginal): boolean => this.getHasFeedback(rowOriginal.feedbackRequests),
        disableSortBy: true,
        width: "auto",
        Cell: ({ value }): JSX.Element => this.getFeedbackIcon(value),
      },
      {
        id: "expander",
        disableSortBy: true,
        width: 36,
        Cell: ({ row }): JSX.Element => {
          if ((row.original as customerActivitiesTableData).feedbackRequests?.length === 0) {
            return <></>;
          }
          return <ReactTableRowExpander row={row} />;
        },
      },
    ];
  }

  private getFeedbackIcon(value: boolean): JSX.Element {
    if (value) {
      return <FontAwesomeIcon icon={["fas", "circle-check"]} fixedWidth size="lg" className="text-success" />;
    } else {
      return <FontAwesomeIcon icon={["fas", "circle-check"]} fixedWidth size="lg" className="text-gray-300" />;
    }
  }

  private async saveForm(): Promise<void> {
    if (!this.props.activeCustomer || this.state.selectedCustomerActivityIds === undefined) {
      NotificationManager.error(LanguageProvider.t(TranslationMapper.pages.customerdata.nocustomerselected));
      return;
    }

    this.props.updateCustomerActivities(this.props.activeCustomer.id, this.state.selectedCustomerActivityIds);
  }

  private toggleActivity(item: ICategoryItem): void {
    if (!this.props.activeCustomer || this.props.isLoading) {
      return;
    }

    if (this.state.selectedCustomerActivityIds === undefined) {
      this.setState({
        selectedCustomerActivityIds: this.props.customerActivities.map(ca => ca.activityId),
      });
    }

    const selectedCustomerActivityIds =
      this.state.selectedCustomerActivityIds != null
        ? this.state.selectedCustomerActivityIds
        : this.props.customerActivities.map(ca => ca.activityId);

    if (selectedCustomerActivityIds.find(ca => ca === item.id) != null) {
      const customerActivities = selectedCustomerActivityIds.filter(ca => ca !== item.id);
      this.setState({
        selectedCustomerActivityIds: customerActivities,
      });
    } else {
      selectedCustomerActivityIds.push(item.id);
      this.setState({
        selectedCustomerActivityIds: selectedCustomerActivityIds,
      });
    }
  }

  private updateCustomerActivityRow(activityId: string): void {
    if (!this.props.activeCustomer || this.props.isLoading) {
      return;
    }

    const customerActivityIds = this.props.customerActivities.map(ca => ca.activityId);

    if (this.props.customerActivities.find(sa => sa.activityId === activityId) != null) {
      this.props.updateCustomerActivities(
        this.props.activeCustomer.id,
        customerActivityIds.filter(caId => caId !== activityId)
      );
    } else {
      customerActivityIds.push(activityId);
      this.props.updateCustomerActivities(this.props.activeCustomer.id, customerActivityIds);
    }
  }

  private get searchBar(): IMultiSelectDropdownField[] {
    const searchBar = FilterUtils.createSearchFilter(
      LanguageProvider.t(TranslationMapper.pages.masterdata.searchactivity),
      (keyword: string) => {
        this.updateFilter(keyword);
      }
    );

    return [searchBar];
  }

  private updateFilter(value: string): void {
    this.setState({
      searchText: value,
    });
  }

  private get activities(): customerActivitiesTableData[] {
    const activities: customerActivitiesTableData[] = this.props.activities.map(a => {
      const activity: customerActivitiesTableData = {
        ...a,
        key: a.id,
      };

      return activity;
    });

    return activities.filter(s => s.name.toLowerCase().includes(this.state.searchText.toLowerCase()));
  }

  private renderActivitiesListExpandedContent(row: ReactTableRow): JSX.Element {
    const rowData = row.original as customerActivitiesTableData;
    const activityToggleHeaderClass = "td-content-pt-3-5";

    const subComponentRows: IReactTableRowSubComponentRowProps[] = [
      {
        columns: [
          {
            className: activityToggleHeaderClass,
            content: <>&nbsp;</>,
          },
          {
            className: activityToggleHeaderClass,
            content: (
              <h3>
                {LanguageProvider.t(TranslationMapper.pages.masterdata.activities.columnheaders.feedbackrowquestion)}
              </h3>
            ),
          },
          {
            className: activityToggleHeaderClass,
            colSpan: 2,
            content: (
              <h3>
                {LanguageProvider.t(TranslationMapper.pages.masterdata.activities.columnheaders.feedbackrowanswertype)}
              </h3>
            ),
          },
          {
            className: activityToggleHeaderClass,
            content: (
              <h3>{LanguageProvider.t(TranslationMapper.pages.masterdata.activities.columnheaders.showwhen)}</h3>
            ),
          },
          {
            className: activityToggleHeaderClass,
            content: (
              <h3>{LanguageProvider.t(TranslationMapper.pages.masterdata.activities.columnheaders.required)}</h3>
            ),
          },
          {
            className: activityToggleHeaderClass,
            content: <div></div>,
          },
        ],
      },
    ];

    rowData.feedbackRequests?.forEach((item: IActivityFeedbackItem) => {
      const subComponentRow: IReactTableRowSubComponentRowProps = {
        columns: [
          {
            content: <>&nbsp;</>,
          },
          {
            content: (
              <ul>
                <li>{item.request}</li>
              </ul>
            ),
          },
          {
            colSpan: 2,
            content: (
              <ul>
                <li>{LanguageProvider.t(FeedbackRequestType[item.requestType])}</li>
              </ul>
            ),
          },
          {
            content: (
              <div className="d-flex">
                {item.whenCancelled && (
                  <FontAwesomeIcon icon={["fas", "circle-xmark"]} fixedWidth size="lg" className="text-warning" />
                )}
                {item.whenFinished && (
                  <FontAwesomeIcon icon={["fas", "circle-check"]} fixedWidth size="lg" className="text-success" />
                )}
              </div>
            ),
          },
          {
            content: (
              <div>
                {item.required
                  ? LanguageProvider.t(TranslationMapper.global.yes)
                  : LanguageProvider.t(TranslationMapper.global.no)}
              </div>
            ),
          },
          {
            content: <div></div>,
          },
        ],
      };

      subComponentRows.push(subComponentRow);
    });

    return <ReactTableRowSubComponent rows={subComponentRows} />;
  }

  public render(): JSX.Element {
    return (
      <>
        <header className="header-actions">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 header-actions__content">
                <h1>{LanguageProvider.t(TranslationMapper.pages.masterdata.activities.title)}</h1>
              </div>
            </div>
          </div>
        </header>

        <FilterMenu filterFields={this.searchBar} />

        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 mb-2 block-content--px">
                <ReactTable
                  isLoading={this.props.isLoading}
                  columns={this.getColumns}
                  data={this.activities ?? []}
                  noResultsMessage={LanguageProvider.t(TranslationMapper.pages.routes.norawdatafound)}
                  renderRowSubComponent={this.renderActivitiesListExpandedContent}
                  className="table--bordered table--has-checkbox table--has-expander"
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: RootState): ICustomerActivitiesStateProps => ({
  isLoading: state.generalState.loaders.some(l => l === LoaderTypes.Activities),
  activities: state.activityTypeState.activities ?? [],
  customerActivities: state.customerState.customerActivities ?? [],
  activeCustomer: state.customerState.customers.find(c => c.id === state.customerState.selectedCustomerId),
});

const mapDispatchToProps: ICustomerActivitiesDispatchProps = {
  updateCustomerActivities: updateCustomerActivities,
  getMasterDataActivities: getMasterDataActivities,
  showCustomerSelection: showCustomerSelection,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTelemetry(withTranslation()(CustomerActivities), "CustomerActivities"));
