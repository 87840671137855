import { TranslationMapper } from "i18n/mapper";
import LanguageProvider from "providers/languageProvider";
import { Component, ReactNode } from "react";

import IWeekdays from "../../interfaces/IWeekdays";
import { ISelectWeekdaysProps } from "./interfaces/ISelectWeekdaysProps";
import ISelectIncidentTypesState from "./interfaces/ISelectWeekdaysState";
import SelectDayItem from "./selectDayItem";

class SelectWeekdays extends Component<ISelectWeekdaysProps, ISelectIncidentTypesState> {
  public constructor(props: ISelectWeekdaysProps) {
    super(props);

    const initialValues: IWeekdays = this.props.value || {
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      sunday: false,
    };

    this.state = initialValues;

    this.propagateChange = this.propagateChange.bind(this);
    this.toggleDay = this.toggleDay.bind(this);
  }

  private propagateChange(): void {
    this.props.onChange({
      target: {
        name: this.props["name"],
        value: this.state,
      },
    });
  }

  private toggleDay(dayAbbreviation: string): void {
    switch (dayAbbreviation) {
      case LanguageProvider.t(TranslationMapper.pages.routes.frequencies.monday): {
        this.setState(
          {
            monday: !this.state.monday,
          },
          this.propagateChange
        );
        break;
      }
      case LanguageProvider.t(TranslationMapper.pages.routes.frequencies.tuesday): {
        this.setState(
          {
            tuesday: !this.state.tuesday,
          },
          this.propagateChange
        );
        break;
      }
      case LanguageProvider.t(TranslationMapper.pages.routes.frequencies.wednesday): {
        this.setState(
          {
            wednesday: !this.state.wednesday,
          },
          this.propagateChange
        );
        break;
      }
      case LanguageProvider.t(TranslationMapper.pages.routes.frequencies.thursday): {
        this.setState(
          {
            thursday: !this.state.thursday,
          },
          this.propagateChange
        );
        break;
      }
      case LanguageProvider.t(TranslationMapper.pages.routes.frequencies.friday): {
        this.setState(
          {
            friday: !this.state.friday,
          },
          this.propagateChange
        );
        break;
      }
      case LanguageProvider.t(TranslationMapper.pages.routes.frequencies.saturday): {
        this.setState(
          {
            saturday: !this.state.saturday,
          },
          this.propagateChange
        );
        break;
      }
      case LanguageProvider.t(TranslationMapper.pages.routes.frequencies.sunday): {
        this.setState(
          {
            sunday: !this.state.sunday,
          },
          this.propagateChange
        );
        break;
      }
    }
  }

  public render(): ReactNode {
    return (
      <div id={this.props.id}>
        <div className="d-flex justify-content-between">
          <SelectDayItem
            activeDay={!!this.state.monday}
            data-testid="monday"
            dayAbbreviation={LanguageProvider.t(TranslationMapper.pages.routes.frequencies.monday)}
            onItemClick={this.toggleDay}
          />
          <SelectDayItem
            activeDay={!!this.state.tuesday}
            data-testid="tuesday"
            dayAbbreviation={LanguageProvider.t(TranslationMapper.pages.routes.frequencies.tuesday)}
            onItemClick={this.toggleDay}
          />
          <SelectDayItem
            activeDay={!!this.state.wednesday}
            data-testid="wednesday"
            dayAbbreviation={LanguageProvider.t(TranslationMapper.pages.routes.frequencies.wednesday)}
            onItemClick={this.toggleDay}
          />
          <SelectDayItem
            activeDay={!!this.state.thursday}
            data-testid="thursday"
            dayAbbreviation={LanguageProvider.t(TranslationMapper.pages.routes.frequencies.thursday)}
            onItemClick={this.toggleDay}
          />
          <SelectDayItem
            activeDay={!!this.state.friday}
            data-testid="friday"
            dayAbbreviation={LanguageProvider.t(TranslationMapper.pages.routes.frequencies.friday)}
            onItemClick={this.toggleDay}
          />
          <SelectDayItem
            activeDay={!!this.state.saturday}
            data-testid="saturday"
            dayAbbreviation={LanguageProvider.t(TranslationMapper.pages.routes.frequencies.saturday)}
            onItemClick={this.toggleDay}
          />
          <SelectDayItem
            activeDay={!!this.state.sunday}
            data-testid="sunday"
            dayAbbreviation={LanguageProvider.t(TranslationMapper.pages.routes.frequencies.sunday)}
            onItemClick={this.toggleDay}
          />
        </div>
      </div>
    );
  }
}

export default SelectWeekdays;
