import "moment/locale/nl";

import { IReactTableData } from "components/material/table/interfaces/IReactTableProps";
import {
  IReactTableRowSubComponentColumnProps,
  IReactTableRowSubComponentRowProps,
} from "components/material/table/interfaces/IReactTableRowSubComponentProps";
import { ReactTable } from "components/material/table/reactTable";
import ReactTableHelper from "components/material/table/reactTableHelper";
import { ReactTableRowExpander } from "components/material/table/reactTableRowExpander";
import { ReactTableRowSelector } from "components/material/table/reactTableRowSelector";
import { ReactTableRowSubComponent } from "components/material/table/reactTableRowSubComponent";
import ReactNotification from "components/reactNotification/reactNotification";
import ActivityStatus from "enums/activityStatus";
import TranslationMapper from "i18n/mapper";
import IActivityAction from "interfaces/IActivityAction";
import moment from "moment";
import LanguageProvider from "providers/languageProvider";
import { Component } from "react";
import { Column, Row } from "react-table";
import { isNullOrEmpty } from "utils/stringUtils";
import TranslationUtils from "utils/translationUtils";

import IPlanningActivitiesTableProps from "./interfaces/IPlanningActivitiesTableProps";

type planningActivitiesTableData = IReactTableData & IActivityAction;

export default class PlanningActivitiesTable extends Component<IPlanningActivitiesTableProps> {
  private readonly tableDataPropertyIndex: planningActivitiesTableData = {
    // Used to create typesafety for accessors in tableData
    activityId: "",
    activityStatus: ActivityStatus.Uncleaned,
    activityTypeId: "",
    checkoutTrigger: 0,
    operatorName: "",
    cleaningObjectId: "",
    cleaningObjectName: "",
    customerVenueId: "",
    locationName: "",
    floorName: "",
    statusUpdatedBy: "",
    date: "",
    failureDescription: "",
    realisedStartDateTime: "",
    startSlaDateTime: "",
    operatorId: "",
    isAssignable: false,
    description: "",
    externalActivityId: "",
    externalActivityReference: "",
    externalObjectId: "",
    key: "",
  };

  public constructor(props: IPlanningActivitiesTableProps) {
    super(props);

    this.getActivityById = this.getActivityById.bind(this);
    this.selectActivity = this.selectActivity.bind(this);
  }

  private getRowData(row: Row): planningActivitiesTableData {
    return row.original as planningActivitiesTableData;
  }

  private getActivityById(id: string): IActivityAction | undefined {
    return this.props.activityActions.find(aa => aa.activityId === id);
  }

  private selectActivity(rowKey: string): void {
    const selectedRowKeys = [...this.props.selectedActivityIds];
    const selectedIndex = selectedRowKeys.indexOf(rowKey);
    const selectedAction = this.getActivityById(rowKey);
    // Replace with typeId when available
    const typeOfSelectedActivities: string | undefined =
      selectedRowKeys.length > 0 ? this.getActivityById(selectedRowKeys[0])?.activityTypeId : undefined;

    if (
      !selectedAction ||
      (typeOfSelectedActivities != null && selectedAction.activityTypeId !== typeOfSelectedActivities)
    ) {
      ReactNotification.error(TranslationMapper.pages.activityplanning.selecttypeerror);
      return;
    }

    if (selectedIndex >= 0) {
      selectedRowKeys.splice(selectedIndex, 1);
    } else {
      selectedRowKeys.push(rowKey);
    }

    this.props.onUpdateSelectedActivities(selectedRowKeys);
  }

  private get columns(): Column[] {
    return [
      {
        id: "table-row-selector",
        width: 36,
        disableSortBy: true,
        Cell: ({ row }): JSX.Element => (
          <ReactTableRowSelector
            onChange={this.selectActivity}
            row={row}
            multiSelectRowKeys={this.props.selectedActivityIds}
          />
        ),
      },
      {
        Header: LanguageProvider.t(TranslationMapper.pages.activityplanning.locationheader),
        id: ReactTableHelper.getPropertyNameAsString(this.tableDataPropertyIndex, x => x.locationName),
        accessor: ReactTableHelper.getPropertyNameAsString(this.tableDataPropertyIndex, x => x.locationName),
        width: "auto",
      },
      {
        Header: LanguageProvider.t(TranslationMapper.pages.activityplanning.cleaningobjectheader),
        id: ReactTableHelper.getPropertyNameAsString(this.tableDataPropertyIndex, x => x.cleaningObjectName),
        accessor: ReactTableHelper.getPropertyNameAsString(this.tableDataPropertyIndex, x => x.cleaningObjectName),
        width: "auto",
      },
      {
        Header: LanguageProvider.t(TranslationMapper.pages.activityplanning.activityheader),
        id: ReactTableHelper.getPropertyNameAsString(this.tableDataPropertyIndex, x => x.description),
        accessor: ReactTableHelper.getPropertyNameAsString(this.tableDataPropertyIndex, x => x.description),
        disableSortBy: true,
        width: "auto",
        Cell: ({ value }): string => {
          return TranslationUtils.getActivityDescription(value);
        },
      },
      {
        Header: LanguageProvider.t(TranslationMapper.pages.activityplanning.dateheader),
        id: ReactTableHelper.getPropertyNameAsString(this.tableDataPropertyIndex, x => x.date),
        accessor: ReactTableHelper.getPropertyNameAsString(this.tableDataPropertyIndex, x => x.date),
        disableSortBy: true,
        width: "auto",
        Cell: ({ value }): string => {
          return value != null ? moment(value).format("DD-MM-YYYY") : "-";
        },
      },
      {
        Header: LanguageProvider.t(TranslationMapper.pages.activitylogging.statusheader),
        id: ReactTableHelper.getPropertyNameAsString(this.tableDataPropertyIndex, x => x.activityStatus),
        accessor: ReactTableHelper.getPropertyNameAsString(this.tableDataPropertyIndex, x => x.activityStatus),
        disableSortBy: true,
        width: "auto",
        Cell: ({ row }): JSX.Element => {
          const rowData = this.getRowData(row);
          return this.getActivityStatusLabel(rowData);
        },
      },
      {
        id: "expander",
        disableSortBy: true,
        width: 36,
        Cell: ({ row }): JSX.Element => <ReactTableRowExpander row={row} />,
      },
    ];
  }

  private getActivityStatusLabel(activityAction: IActivityAction): JSX.Element {
    if (activityAction.isAssignable && activityAction.activityStatus === ActivityStatus.Scheduled) {
      if (this.hasRealisedStartDateTime(activityAction)) {
        return (
          <div className="badge bg-green">
            {LanguageProvider.t(TranslationMapper.pages.activityplanning.statusnames.started)}
          </div>
        );
      } else if (!isNullOrEmpty(activityAction.operatorName)) {
        return (
          <div className="badge bg-secondary">
            {LanguageProvider.t(TranslationMapper.pages.activityplanning.statusnames.scheduled)}
          </div>
        );
      }
    }

    return <></>;
  }

  private renderActivityPlanningItemRowHeaders(
    activityAction: planningActivitiesTableData
  ): IReactTableRowSubComponentColumnProps[] {
    const columns = [
      {
        className: "activity-toggle-header",
        content: <>&nbsp;</>,
      },
    ];

    if (this.hasExternalObjectId(activityAction)) {
      columns.push({
        className: ReactTableHelper.ContentHeader,
        content: <h3>{LanguageProvider.t(TranslationMapper.pages.activitylogging.cleaningobjectreferenceheader)}</h3>,
      });
    }
    if (this.hasFloorName(activityAction)) {
      columns.push({
        className: ReactTableHelper.ContentHeader,
        content: <h3>{LanguageProvider.t(TranslationMapper.pages.activitylogging.objectlocation)}</h3>,
      });
    }
    if (this.hasExternalActivityReference(activityAction)) {
      columns.push({
        className: ReactTableHelper.ContentHeader,
        content: <h3>{LanguageProvider.t(TranslationMapper.pages.activitylogging.activityreferenceheader)}</h3>,
      });
    }
    if (this.hasOperatorName(activityAction)) {
      columns.push({
        className: ReactTableHelper.ContentHeader,
        content: <h3>{LanguageProvider.t(TranslationMapper.pages.activityplanning.assignedto)}</h3>,
      });
    }
    if (this.hasRealisedStartDateTime(activityAction)) {
      columns.push({
        className: ReactTableHelper.ContentHeader,
        content: <h3>{LanguageProvider.t(TranslationMapper.pages.activityplanning.startedon)}</h3>,
      });
    }

    return columns;
  }

  private renderActivityPlanningItemRowValues(
    activityAction: planningActivitiesTableData
  ): IReactTableRowSubComponentColumnProps[] {
    const columns = [
      {
        className: "activity-toggle-header",
        content: <>&nbsp;</>,
      },
    ];

    if (this.hasExternalObjectId(activityAction)) {
      columns.push({
        className: ReactTableHelper.ContentField,
        content: <>{activityAction.externalObjectId}</>,
      });
    }
    if (this.hasFloorName(activityAction)) {
      columns.push({
        className: ReactTableHelper.ContentField,
        content: <>{activityAction.floorName}</>,
      });
    }
    if (this.hasExternalActivityReference(activityAction)) {
      columns.push({
        className: ReactTableHelper.ContentField,
        content: <>{activityAction.externalActivityReference}</>,
      });
    }
    if (this.hasOperatorName(activityAction)) {
      columns.push({
        className: ReactTableHelper.ContentField,
        content: <>{activityAction.operatorName}</>,
      });
    }
    if (this.hasRealisedStartDateTime(activityAction)) {
      columns.push({
        className: ReactTableHelper.ContentField,
        content: <>{activityAction.realisedStartDateTime}</>,
      });
    }

    return columns;
  }

  private renderActivityPlanningItem(row: Row): JSX.Element {
    const rowData = row.original as planningActivitiesTableData;
    const activityAction = rowData;

    const subComponentRows: IReactTableRowSubComponentRowProps[] = [
      {
        columns: this.renderActivityPlanningItemRowHeaders(rowData),
      },
      {
        columns: this.renderActivityPlanningItemRowValues(rowData),
      },
    ];

    if (this.hasStartSlaDateTime(activityAction) || this.hasFailureDescription(activityAction)) {
      subComponentRows.push(
        {
          columns: [
            {
              className: "",
              content: <>&nbsp;</>,
            },
            {
              className: ReactTableHelper.ContentHeader,
              content: <h3>{LanguageProvider.t(TranslationMapper.pages.activityplanning.startsladatetimeheader)}</h3>,
            },
            {
              className: ReactTableHelper.ContentHeader,
              content: <h3>{LanguageProvider.t(TranslationMapper.pages.activityplanning.failuredescriptionheader)}</h3>,
            },
          ],
        },
        {
          columns: [
            {
              className: "",
              content: <>&nbsp;</>,
            },
            {
              className: ReactTableHelper.ContentField,
              content: <>{this.getFormattedDateTimeString(activityAction.startSlaDateTime)}</>,
            },
            {
              className: ReactTableHelper.ContentField,
              content: <>{activityAction.failureDescription ?? "-"}</>,
            },
          ],
        }
      );
    }

    return <ReactTableRowSubComponent rows={subComponentRows} />;
  }

  private getFormattedDateTimeString(date?: string | Date): string {
    const hasValidDateInput = date != null && date !== "" && date !== "-";
    return hasValidDateInput ? moment(date).format("DD-MM-YYYY, HH:mm") : "-";
  }

  private hasExternalObjectId(activityAction: IActivityAction): boolean {
    return activityAction.externalObjectId != null && activityAction.externalObjectId !== "";
  }

  private hasFloorName(activityAction: IActivityAction): boolean {
    return activityAction.floorName != null && activityAction.floorName !== "";
  }

  private hasExternalActivityReference(activityAction: IActivityAction): boolean {
    return activityAction.externalActivityReference != null && activityAction.externalActivityReference !== "";
  }

  private hasStartSlaDateTime(activityAction: IActivityAction): boolean {
    return (
      activityAction.startSlaDateTime != null &&
      activityAction.startSlaDateTime !== "" &&
      activityAction.startSlaDateTime !== "-"
    );
  }

  private hasRealisedStartDateTime(activityAction: IActivityAction): boolean {
    return (
      activityAction.realisedStartDateTime != null &&
      activityAction.realisedStartDateTime !== "" &&
      activityAction.realisedStartDateTime !== "-"
    );
  }

  private hasOperatorName(activityAction: IActivityAction): boolean {
    return activityAction.operatorName != null && activityAction.operatorName !== "";
  }

  private hasFailureDescription(activityAction: IActivityAction): boolean {
    return activityAction.failureDescription != null && activityAction.failureDescription !== "";
  }

  private get tableData(): planningActivitiesTableData[] {
    return this.props.activityActions.map(aa => {
      const activityAction: planningActivitiesTableData = {
        ...aa,
        key: aa.activityId,
      };

      return activityAction;
    });
  }

  public render(): JSX.Element {
    return (
      <ReactTable
        isLoading={this.props.isLoading}
        columns={this.columns}
        data={this.tableData}
        renderRowSubComponent={(data): JSX.Element => this.renderActivityPlanningItem(data)}
        defaultColumnToSortBy={{ id: "locationName" }}
      />
    );
  }
}
