import { Button } from "components/material/buttons/buttons";
import TranslationMapper from "i18n/mapper";
import LanguageProvider from "providers/languageProvider";
import * as React from "react";
import { withTranslation } from "react-i18next";

import INotificationResponseModalButtonProps from "./interfaces/INotificationResponseModalButtonProps";
import INotificationResponseModalButtonState from "./interfaces/INotificationResponseModalButtonState";

class NotificationResponseModalButton extends React.Component<
  INotificationResponseModalButtonProps,
  INotificationResponseModalButtonState
> {
  public constructor(props: INotificationResponseModalButtonProps) {
    super(props);

    this.onButtonClick = this.onButtonClick.bind(this);
  }

  private onButtonClick(): void {
    this.props.onClick(this.props.notificationId);
  }

  public render(): JSX.Element {
    return (
      <Button
        className="btn btn-outline-secondary mt-3"
        resourceLabel={LanguageProvider.t(TranslationMapper.pages.notifications.respond)}
        onClick={this.onButtonClick}
      />
    );
  }
}

export default withTranslation()(NotificationResponseModalButton);
