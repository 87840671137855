import { Guid } from "guid-typescript";
import Serializable from "models/serializeable";

import IZone from "../interfaces/IZone";

export default class Zone extends Serializable implements IZone {
  public id: string;
  public name: string;
  public nfcTagCode: string;
  public floorId: string;

  public constructor() {
    super();
    this.id = Guid.createEmpty().toString();
    this.name = "";
    this.nfcTagCode = "";
    this.floorId = "";
  }
}