import produce from "immer";
import IExpandedRouteLogging from "interfaces/IExpandedRouteLogging";
import _, { cloneDeep } from "lodash";
import Dictionary from "utils/dictionary";

import { ActionTypes, ActionTypesCollection } from "../actionTypes";
import {
  IScheduleManagementStoreState,
  initialScheduleManagementStoreState,
} from "../state/scheduleManagementStoreState";

export default function scheduleManagementReducer(
  state: IScheduleManagementStoreState = initialScheduleManagementStoreState,
  action: ActionTypesCollection
): IScheduleManagementStoreState {
  switch (action.type) {
    case ActionTypes.FETCHED_ROUTES:
      return produce(state, draftState => {
        draftState.routes = action.payload;
      });
    case ActionTypes.FETCHED_SELECTED_ROUTE:
      return produce(state, draftState => {
        draftState.selectedRoute = action.payload;
      });
    case ActionTypes.FETCHED_CUSTOMER_ROUTE_LOGGING: {
      if (!_.isEqual(action.payload, state.customerRouteLogging)) {
        return {
          ...state,
          customerRouteLogging: action.payload,
        };
      }
      return state;
    }
    case ActionTypes.FETCHED_EXPANDED_CUSTOMER_ROUTE_LOGGING: {
      const updatedLoggings = cloneDeep(state.expandedLogs) ?? new Dictionary<IExpandedRouteLogging>();
      updatedLoggings[action.payload.loggingId] = action.payload.loggings;
      return {
        ...state,
        expandedLogs: updatedLoggings,
      };
    }
    default:
      return state;
  }
}
