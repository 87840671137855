import { Guid } from "guid-typescript";
import TranslationMapper from "i18n/mapper";
import ICleaningObjectContainer from "interfaces/ICleaningObjectContainer";
import ICleaningSpace from "interfaces/ICleaningSpace";
import _ from "lodash";
import LanguageProvider from "providers/languageProvider";
import React, { FormEvent } from "react";
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableResult,
  Droppable,
  DroppableProvided,
  DroppableResult,
  DropResult,
} from "react-beautiful-dnd";
import { NotificationManager } from "react-notifications";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ICleaningObject from "../../interfaces/ICleaningObject";
import ISelectedCleaningObjectsProps from "./interfaces/ISelectedCleaningObjectsProps";
import ISelectedCleaningObjectsState from "./interfaces/ISelectedCleaningObjectsState";

class SelectedCleaningObjects extends React.Component<ISelectedCleaningObjectsProps, ISelectedCleaningObjectsState> {
  private get selectedCleaningObjectsCount(): number {
    return this.props.selectedCleaningObjects.flatMap(x => x.cleaningObjects).length;
  }

  public constructor(props: ISelectedCleaningObjectsProps) {
    super(props);

    this.state = {
      selectedCleaningObjects: [],
    };

    this.removeAllCleaningObjects = this.removeAllCleaningObjects.bind(this);
    this.onDragFloorEnd = this.onDragFloorEnd.bind(this);
    this.onDragCleaningObjectEnd = this.onDragCleaningObjectEnd.bind(this);
  }

  public componentDidMount(): void {
    if (this.props.selectedCleaningObjects.some(x => x.id === Guid.EMPTY)) {
      NotificationManager.warning(
        LanguageProvider.t(TranslationMapper.pages.routewizard.cleaningobjects.floornameerror)
      );
    }
  }

  private onToggle(event: FormEvent<HTMLDivElement>): void {
    const allToggleEl = document.querySelectorAll('[id*="floortoggle_selected_"]');
    allToggleEl.forEach(t => {
      if (t.id === event.currentTarget.id && !t.className?.includes("expanded")) {
        t.className = "expanded";
      } else {
        t.className = "";
      }
    });
  }

  private removeAllCleaningObjects(container: ICleaningObjectContainer): void {
    const containers = _.cloneDeep(this.props.selectedCleaningObjects);
    const filteredObjects = containers.filter(c => c.id !== container.id);

    this.setStartZone(filteredObjects);
    this.emitOnChange(filteredObjects);
  }

  private removeCleaningObject(cleaningobject: ICleaningObject): void {
    const containers = _.cloneDeep(this.props.selectedCleaningObjects);
    containers.forEach((container, index) => {
      if (container.id === cleaningobject.parentId) {
        if (container.cleaningObjects && container.cleaningObjects.length > 1) {
          container.cleaningObjects = container.cleaningObjects.filter(x => x.id !== cleaningobject.id);
        } else {
          containers.splice(index, 1);
        }
      }
    });

    this.setStartZone(containers);
    this.emitOnChange(containers);
  }

  private emitOnChange(container: ICleaningObjectContainer[]): void {
    let sequence = 0;
    container.forEach(c => {
      c.cleaningObjects?.forEach(ob => {
        ob.sequence = sequence++;
      });
    });

    this.props.onChange({
      target: {
        name: this.props.name,
        value: container,
      },
    });
  }

  private getIsStartFloor(container: ICleaningObjectContainer): boolean {
    return container.cleaningObjects != null && container.cleaningObjects.some(c => c.isStartZone);
  }

  private onDragCleaningObjectEnd(result: DropResult, floorIndex: number): void {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    if (this.props.selectedCleaningObjects?.length > 0) {
      const draggedCleaningObject = this.props.selectedCleaningObjects[floorIndex].cleaningObjects!.splice(
        source.index,
        1
      )[0];

      const cleaningObjects = _.cloneDeep(this.props.selectedCleaningObjects[floorIndex].cleaningObjects!);
      cleaningObjects.splice(destination.index, 0, draggedCleaningObject);
      const containers = _.cloneDeep(this.props.selectedCleaningObjects);
      containers[floorIndex].cleaningObjects = cleaningObjects;

      this.emitOnChange(_.cloneDeep(containers));
    }
  }

  private onDragFloorEnd(result: DropResult): void {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    if (this.props.selectedCleaningObjects.length > 0) {
      const draggedCleaningObject = this.props.selectedCleaningObjects.splice(source.index, 1)[0];

      const containers = _.cloneDeep(this.props.selectedCleaningObjects);
      containers.splice(destination.index, 0, draggedCleaningObject);

      this.setStartZone(containers);
      this.emitOnChange(_.cloneDeep(containers));
    }
  }

  private setStartZone(containers: ICleaningObjectContainer[]): ICleaningObjectContainer[] {
    // set all other startzones to false first
    containers.forEach(container => {
      container.cleaningObjects?.forEach(cleaningObject => {
        cleaningObject.isStartZone = false;
      });
    });

    // set first zone as start zone
    if (containers[0]?.cleaningObjects != null && containers[0]?.cleaningObjects.length > 0) {
      containers[0].cleaningObjects[0].isStartZone = true;
    }

    return containers;
  }

  private getSortedCleaningObjects(cleaningObjects?: ICleaningSpace[]): ICleaningSpace[] {
    if (cleaningObjects == null) {
      return [];
    }

    return cleaningObjects.sort((c1, c2) => {
      if (c1.sequence > c2.sequence) {
        return 1;
      }

      if (c2.sequence > c1.sequence) {
        return -1;
      }

      return 0;
    });
  }

  private getSortedFloors(containers: ICleaningObjectContainer[]): ICleaningObjectContainer[] {
    return containers.sort((c1, c2) => {
      if (c1.cleaningObjects == null || c2.cleaningObjects == null) {
        return 0;
      }

      if (c1.cleaningObjects[0].sequence > c2.cleaningObjects[0].sequence) {
        return 1;
      }

      if (c2.cleaningObjects[0].sequence > c1.cleaningObjects[0].sequence) {
        return -1;
      }

      return 0;
    });
  }

  private renderCleaningObject(cleaningObject: ICleaningObject, index: number): JSX.Element {
    return (
      <Draggable key={cleaningObject.id} draggableId={"draggable-" + cleaningObject.id} index={index}>
        {(providedDraggable: DraggableProvided): DraggableResult => (
          <div
            ref={providedDraggable.innerRef}
            {...providedDraggable.draggableProps}
            {...providedDraggable.dragHandleProps}
          >
            <div className="accordion-body d-flex justify-content-between" key={cleaningObject.id}>
              <div className="d-flex justify-content-between">
                <button type="button" className="btn btn-link btn--accordion-dragger">
                  <FontAwesomeIcon icon={["fal", "grip-dots-vertical"]} size="lg" />
                </button>
                <h5>{cleaningObject.name}</h5>
              </div>
              <button
                type="button"
                className="btn btn-link pe-0 py-0"
                onClick={(): void => this.removeCleaningObject(cleaningObject)}
              >
                <FontAwesomeIcon
                  id={`delete_${cleaningObject.id}`}
                  className="btn__accordion-add"
                  icon={["fal", "trash"]}
                  fixedWidth
                  size="lg"
                />
              </button>
            </div>
          </div>
        )}
      </Draggable>
    );
  }

  private renderContainer(container: ICleaningObjectContainer, index: number): JSX.Element {
    return (
      <div key={container.id}>
        <Draggable key={container.id} draggableId={"draggable-" + container.id} index={index}>
          {(providedDraggable: DraggableProvided): DraggableResult => (
            <div
              ref={providedDraggable.innerRef}
              {...providedDraggable.draggableProps}
              {...providedDraggable.dragHandleProps}
            >
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed accordion-button--has-icon"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#collapse-selected${container.id}`}
                    aria-expanded="false"
                    aria-controls={`collapse${container.id}`}
                  >
                    <div className="d-flex justify-content-between">
                      <button type="button" className="btn btn-link btn--accordion-dragger">
                        <FontAwesomeIcon icon={["fal", "grip-dots-vertical"]} size="lg" />
                      </button>
                      {container.name}
                      {this.getIsStartFloor(container) && (
                        <span className="badge badge-sm bg-green">
                          {LanguageProvider.t(TranslationMapper.pages.routewizard.cleaningobjects.startfloor)}
                        </span>
                      )}
                    </div>

                    <div
                      onClick={(): void => this.removeAllCleaningObjects(container)}
                      className="btn btn-link accordion--btn-action"
                    >
                      <FontAwesomeIcon icon={["fal", "trash"]} fixedWidth size="lg" />
                    </div>
                  </button>
                </h2>
                <div
                  id={`collapse-selected${container.id}`}
                  className="accordion-collapse collapse"
                  aria-labelledby={container.id}
                  data-bs-parent="#accordionselected"
                >
                  <DragDropContext
                    onDragEnd={(result: DraggableResult): void => this.onDragCleaningObjectEnd(result, index)}
                  >
                    <Droppable droppableId="cleaning-object-droppable">
                      {(provided: DroppableProvided): DroppableResult => (
                        <div className="cleaning-objects" ref={provided.innerRef} {...provided.droppableProps}>
                          {provided.placeholder}
                          {this.getSortedCleaningObjects(container.cleaningObjects).map((cleaningObject, index) =>
                            this.renderCleaningObject(cleaningObject, index)
                          )}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>
              </div>
            </div>
          )}
        </Draggable>
      </div>
    );
  }

  public render(): JSX.Element {
    return (
      <>
        <label className="form-label">
          {LanguageProvider.t(TranslationMapper.pages.routewizard.cleaningobjects.selectedobjects)} (
          {this.selectedCleaningObjectsCount})
        </label>
        <div className="modal-cleaning-object__block-container">
          <DragDropContext onDragEnd={this.onDragFloorEnd}>
            <Droppable droppableId="floor-droppable">
              {(provided: DroppableProvided): DroppableResult => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  <div className="accordion" id="accordionselected">
                    {provided.placeholder}
                    {this.getSortedFloors(this.props.selectedCleaningObjects).map((container, index) =>
                      this.renderContainer(container, index)
                    )}
                  </div>
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </>
    );
  }
}

export default SelectedCleaningObjects;
