import React, { Component } from "react";
import { NotificationManager } from "react-notifications";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import IButton from "./interfaces/IButton";
import { IButtonProps } from "./interfaces/IButtonProps";

class Button extends Component<IButtonProps> implements IButton {
  private buttonRef: any;

  public constructor(props: IButtonProps) {
    super(props);

    this.buttonRef = React.createRef();

    this.onClick = this.onClick.bind(this);
    this.getDisabled = this.getDisabled.bind(this);
    this.keepOnClickActiveForErrorMessage = this.keepOnClickActiveForErrorMessage.bind(this);
  }

  private onClick(eventArgs: any): void {
    if (this.props.disableAfterClick && !this.keepOnClickActiveForErrorMessage(true)) {
      this.buttonRef.current.disabled = true;
    }

    this.props.onClick && this.props.onClick(eventArgs);
  }

  public static getClassName(value?: string): string {
    return value || "";
  }

  private getDisabled(value?: boolean): boolean {
    if (this.keepOnClickActiveForErrorMessage()) {
      return false;
    } else {
      return value || false;
    }
  }

  private keepOnClickActiveForErrorMessage(activatedByDisableAfterClick?: boolean): boolean {
    const hasMessageOnDisabled =
      this.props.messageOnDisabledButtonClick != null && this.props.messageOnDisabledButtonClick.length > 0;
    if (activatedByDisableAfterClick) {
      return hasMessageOnDisabled;
    }

    const keepActive = this.props.disabled === true && hasMessageOnDisabled;
    return keepActive;
  }

  public render(): JSX.Element {
    let className = this.props.className || "";
    if (this.props.iconStart) {
      className += " btn--element-start";
    }
    if (this.props.iconEnd) {
      className += " btn--element-end";
    }

    const onClick = this.keepOnClickActiveForErrorMessage()
      ? (): void => NotificationManager.error(this.props.messageOnDisabledButtonClick)
      : this.onClick;

    return (
      <button
        className={`btn ${className}`}
        disabled={this.getDisabled(this.props.disabled)}
        id={this.props.id}
        ref={this.buttonRef}
        onClick={onClick}
        type="button"
        data-test={this.props["data-test"]}
        data-dismiss={this.props["data-dismiss"]}
        data-toggle={this.props["data-toggle"]}
        data-target={this.props["data-target"]}
      >
        {this.props.iconStart && <FontAwesomeIcon icon={["fal", this.props.iconStart]} fixedWidth />}
        {this.props.resourceLabel}
        {this.props.iconEnd && <FontAwesomeIcon icon={["fal", this.props.iconEnd]} fixedWidth />}
      </button>
    );
  }
}

export default Button;
