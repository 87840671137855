import { CmApiAuthenticator } from "../authenticators/cmApiAuthenticator";
import { ApplicationConfig } from "../config";
import IFeatureFlag from "../interfaces/IFeatureFlag";
import BaseService from "./baseService";

export default class FlagService extends BaseService {
  private readonly endpoint: string = "Flag";

  public constructor() {
    super(ApplicationConfig.endpoints.winvisionCleaningManagementApi, new CmApiAuthenticator());
  }

  public async GetFlagsByNameAsync(flagNames: string[]): Promise<IFeatureFlag[]> {
    return this.postWithResponse<IFeatureFlag[]>(`${this.endpoint}`, flagNames);
  }
}