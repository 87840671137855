import { IInputChangeEvent } from "components/interfaces/IInputChangeEvent";
import Select from "components/material/select/select";
import IWizardStep from "components/wizard/interfaces/IWizardStep";
import LoaderTypes from "enums/loaderTypes";
import TranslationMapper from "i18n/mapper";
import IIncidentType from "interfaces/IIncidentType";
import IReactSelectValue from "interfaces/IReactSelectValue";
import LanguageProvider from "providers/languageProvider";
import * as React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { getCleaningObjectsForLocationAsync } from "store/actions/locationActions";
import { RootState } from "store/reducers/rootReducer";

import IconImageNotFound from "../../assets/img/image-not-found.svg";
import Loader from "../../components/loader/loader";
import INotificationCategoryStepProps, {
  INotificationCategoryDispatchProps,
  INotificationCategoryStateProps,
} from "./interfaces/INotificationCategoryStepProps";
import INotificationCategoryStepState from "./interfaces/INotificationCategoryStepState";
import NotificationValidator from "./notificationValidator";
import SelectCleaningObject from "./selectCleaningObject/selectCleaningObject";

class NotificationCategoryStep extends React.Component<INotificationCategoryStepProps, INotificationCategoryStepState> {
  public constructor(props: INotificationCategoryStepProps) {
    super(props);

    const state = this.props.value ?? {
      categoryId: "",
    };

    this.state = state;

    this.getNotificationImageUri = this.getNotificationImageUri.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onLocationChange = this.onLocationChange.bind(this);
    this.selectCategory = this.selectCategory.bind(this);
    this.propagateChange = this.propagateChange.bind(this);
  }

  private onChange(event: IInputChangeEvent<any>): void {
    const name = event.target.name;
    const value = event.target.value;

    this.setState(current => ({ ...current, [name]: value }), this.propagateChange);
  }

  private onLocationChange(event: IInputChangeEvent<any>): void {
    this.props.onLocationChanged(event.target.value);
    this.setState(
      current => ({ ...current, locationId: event.target.value, cleaningObject: undefined }),
      this.propagateChange
    );
  }

  private get locations(): IReactSelectValue[] {
    const locations: IReactSelectValue[] = [
      { value: undefined, label: LanguageProvider.t(TranslationMapper.searchable_select.placeholder) },
    ];
    return locations.concat(this.props.locations.map(loc => ({ value: loc.externalVenueId, label: loc.name })));
  }

  private selectCategory(category: IIncidentType): void {
    this.setState(current => ({ ...current, categoryId: category.id }), this.propagateChange);
  }

  private propagateChange(): void {
    const isValid = NotificationValidator.isCategoryValid(this.state);
    this.props.onChange({ target: { value: this.state, name: this.props.name }, isValid });
  }

  private getNotificationImageUri(notificationCategory: IIncidentType): string {
    if (notificationCategory.id === this.state.categoryId) {
      return notificationCategory.icons.activeImageExists ? notificationCategory.icons.active : IconImageNotFound;
    } else {
      return notificationCategory.icons.inactiveImageExists ? notificationCategory.icons.inActive : IconImageNotFound;
    }
  }

  public renderCategories(): JSX.Element {
    return (
      <div className="row row--btn-image-label">
        {this.props.categories.length > 0 &&
          this.props.categories.map((category, index) => (
            <button
              key={index}
              type="button"
              onClick={(): void => this.selectCategory(category)}
              className={`btn btn--image-label mb-3 ${category.id === this.state.categoryId ? "active" : "inactive"}`}
            >
              <img src={this.getNotificationImageUri(category)} alt="" />
              <div>{category.label}</div>
            </button>
          ))}
      </div>
    );
  }

  public render(): React.ReactNode {
    return (
      <Form data-testid="notification-wizard-category-step" id="notification-wizard-category-step">
        <Row>
          <Col md={6} className="modal__col-mb">
            <Form.Group>
              <Form.Label>{LanguageProvider.t(TranslationMapper.pages.notificationwizard.select_location)}</Form.Label>
              <Select
                id="notification-location"
                name="locationId"
                value={this.state.locationId}
                onChange={(event): void => this.onLocationChange(event)}
                options={this.locations}
              ></Select>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label>
                {LanguageProvider.t(TranslationMapper.pages.notificationwizard.select_cleaning_object)}
              </Form.Label>
              {this.props.isLoading && <Loader isLoading={this.props.isLoading} />}
              {!this.props.isLoading && (
                <SelectCleaningObject
                  id="notification-cleaning-object"
                  name="cleaningObject"
                  value={this.state.cleaningObject}
                  onChange={this.onChange}
                />
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group>
              <Form.Label>{LanguageProvider.t(TranslationMapper.pages.notificationwizard.select_category)}</Form.Label>
              {this.renderCategories()}
            </Form.Group>
          </Col>
        </Row>
      </Form>
    );
  }
}

const mapStateToProps = (state: RootState): INotificationCategoryStateProps => ({
  isLoading: state.generalState.loaders.some(l => l === LoaderTypes.CleaningObjects),
});

const mapDispatchToProps: INotificationCategoryDispatchProps = {
  onLocationChanged: getCleaningObjectsForLocationAsync,
};

const wizardStep: IWizardStep = {
  form: connect(mapStateToProps, mapDispatchToProps)(NotificationCategoryStep),
  titleResource: "",
  name: "category",
};

export default wizardStep;
