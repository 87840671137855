import ICleaningObjectContainer from "interfaces/ICleaningObjectContainer";

import IRouteDetails from "./interfaces/IRouteDetails";

export default class RouteValidator {
  private static readonly guidLength: number = 36;

  public static areDetailsValid(details: IRouteDetails): boolean {
    if (details.venue == null) {
      return false;
    }

    if (details.operators == null || details.operators.length === 0) {
      return false;
    }

    if (details.routeName == null || details.routeName === "") {
      return false;
    }

    if (details.timePeriod == null) {
      return false;
    }

    if (details.incidentTypes == null || details.incidentTypes.length === 0) {
      return false;
    }

    if (
      details.weekdays == null ||
      (!details.weekdays.monday &&
        !details.weekdays.tuesday &&
        !details.weekdays.wednesday &&
        !details.weekdays.thursday &&
        !details.weekdays.friday &&
        !details.weekdays.saturday &&
        !details.weekdays.sunday)
    ) {
      return false;
    }

    return true;
  }

  public static areCleaningObjectsValid(cleaningObjectsContainers: ICleaningObjectContainer[]): boolean {
    if (!cleaningObjectsContainers || cleaningObjectsContainers.length === 0) {
      return false;
    }

    const result = cleaningObjectsContainers.map(container => {
      if (!container || !container.cleaningObjects || container.cleaningObjects.length === 0) {
        return false;
      }

      if (!container.id || !container.name || !container.venueId) {
        return false;
      }

      if (container.id.length !== RouteValidator.guidLength || container.venueId.length !== RouteValidator.guidLength) {
        return false;
      }

      if (container.cleaningObjects.some(x => !x.id || !x.name)) {
        return false;
      }

      if (container.cleaningObjects.some(x => x.id.length !== RouteValidator.guidLength)) {
        return false;
      }

      return true;
    });

    const startZones = cleaningObjectsContainers
      .flatMap(container => container.cleaningObjects)
      .flatMap(x => x?.isStartZone);

    if (startZones.filter(x => x === true).length !== 1) {
      return false;
    }

    return result.every(x => x === true);
  }

  public static areActivitiesValid(cleaningObjectsContainers: ICleaningObjectContainer[]): boolean {
    let validZones = 0;
    cleaningObjectsContainers.forEach(floor => {
      floor.cleaningObjects?.forEach(zone => {
        if (zone.activityIds && zone.activityIds.length > 0) {
          validZones++;
        }
      });
    });
    return validZones === cleaningObjectsContainers.flatMap(x => x.cleaningObjects).length;
  }
}
