import { Col, Form, Row } from "react-bootstrap";

import { Component } from "react";
import ICustomerVenueDetailsProps from "../interfaces/ICustomerVenueDetailsProps";
import ICustomerVenueDetailsState from "../interfaces/ICustomerVenueDetailsState";
import LanguageProvider from "providers/languageProvider";
import { List } from "components/list/list";
import { TextInput } from "components/material/formElements/input/textInput";
import TranslationMapper from "i18n/mapper";

export class CustomerVenueDetails extends Component<ICustomerVenueDetailsProps, ICustomerVenueDetailsState> {
  public constructor(props: ICustomerVenueDetailsProps) {
    super(props);

    const state: ICustomerVenueDetailsState = {
      updatedVenue: this.props.venue,
    };

    this.state = state;

    this.handleCustomerIdChange = this.handleCustomerIdChange.bind(this);
    this.handleExternalVenueIdChange = this.handleExternalVenueIdChange.bind(this);
    this.saveForm = this.saveForm.bind(this);
    this.onVenueNameChange = this.onVenueNameChange.bind(this);
    this.onGroupByFloorsChange = this.onGroupByFloorsChange.bind(this);
  }

  public componentDidUpdate(prevProps: ICustomerVenueDetailsProps): void {
    if (this.props.venue.id !== this.state.updatedVenue.id) {
      this.setState({
        updatedVenue: this.props.venue,
      });
    }
  }

  private saveForm(): void {
    this.props.onSave(this.state.updatedVenue);
  }

  private handleCustomerIdChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const id = event.target.value;

    const venue = this.state.updatedVenue;
    venue.customerId = id;

    this.setState({
      updatedVenue: venue,
    });
  }

  private handleExternalVenueIdChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const id = event.target.value;

    const venue = this.state.updatedVenue;
    venue.externalVenueId = id.trim();

    this.setState({
      updatedVenue: venue,
    });
  }

  private onGroupByFloorsChange(checked: boolean): void {
    const venue = this.state.updatedVenue;
    venue.groupByFloors = checked;

    this.setState({
      updatedVenue: venue,
    });
  }

  private onVenueNameChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const name = event.target.value;

    const venue = this.state.updatedVenue;
    venue.name = name;

    this.setState({
      updatedVenue: venue,
    });
  }

  public render(): JSX.Element {
    const formName = "customer-venue-form";

    return (
      <>
        <tr>
          <td colSpan={1} />
          <td colSpan={2} className="td-content-pt-3-5 td-content-pb-3-5">
            <h2 className="mb-3">{LanguageProvider.t(TranslationMapper.pages.customeredit.general)}</h2>
            <Row>
              <Col lg={6}>
                <Form.Group>
                  <Form.Label className="h3" htmlFor={`${formName}-venue-name`}>
                    {LanguageProvider.t(TranslationMapper.pages.customeredit.buildingname)}
                  </Form.Label>
                  <TextInput
                    id={`${formName}-venue-name`}
                    value={this.state.updatedVenue.name}
                    onChange={this.onVenueNameChange}
                    disabled={true}
                  />
                </Form.Group>
              </Col>
            </Row>
          </td>
          <td colSpan={1} />
        </tr>

        <tr>
          <td colSpan={1} />
          <td colSpan={2} className="td-content-pt-3-5 td-content-pb-3-5">
            <h2 className="mb-3">{LanguageProvider.t(TranslationMapper.pages.customeredit.nfc_tags)}</h2>

            <Row>
              <Col lg={6}>
                <Form.Group>
                  <Form.Label className="h3">
                    {LanguageProvider.t(TranslationMapper.pages.customeredit.nfc_tag_id)}
                  </Form.Label>
                  <List
                    isMultipleCheck
                    items={this.state.updatedVenue.nfcTags}
                    noneAvailableMessage={LanguageProvider.t(
                      TranslationMapper.pages.customeredit.nfc_tag_none_available
                    )}
                  ></List>
                </Form.Group>
              </Col>
            </Row>
          </td>
          <td colSpan={1} />
        </tr>

        <tr>
          <td colSpan={1} />
          <td colSpan={2} className="td-content-pt-3-5 td-content-pb-3-5">
            <h2 className="mb-3">
              {LanguageProvider.t(TranslationMapper.pages.customeredit.customer_venue_keys_header)}
            </h2>

            <Row>
              <Col lg={6}>
                <Form.Group>
                  <Form.Label className="h3" htmlFor={`${formName}-core-venue-id`}>
                    {LanguageProvider.t(TranslationMapper.pages.customeredit.externalvenueid)}
                  </Form.Label>
                  <TextInput
                    id={`${formName}-core-venue-id`}
                    value={this.state.updatedVenue.externalVenueId}
                    onChange={this.handleExternalVenueIdChange}
                    disabled={true}
                  />
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group>
                  <Form.Label className="h3" htmlFor={`${formName}-external-project-reference`}>
                    {LanguageProvider.t(TranslationMapper.pages.customer_venue_edit.external_project_reference)}
                  </Form.Label>
                  <TextInput
                    id={`${formName}-external-project-reference`}
                    disabled={true}
                    value={this.state.updatedVenue.externalProjectReference}
                  />
                </Form.Group>
              </Col>
            </Row>
          </td>
          <td colSpan={1} />
        </tr>
      </>
    );
  }
}

export default CustomerVenueDetails;
