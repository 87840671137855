import ActiveSwitch from "components/material/activeSwitch/activeSwitch";
import { Button } from "components/material/buttons/buttons";
import Source from "constants/sources";
import LoaderTypes from "enums/loaderTypes";
import ObjectExistsStatus from "enums/objectExistsStatus";
import { Guid } from "guid-typescript";
import TranslationMapper from "i18n/mapper";
import IReactSelectValue from "interfaces/IReactSelectValue";
import _ from "lodash";
import Customer from "models/customer";
import LanguageProvider from "providers/languageProvider";
import React, { ReactNode } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import ReactDOM from "react-dom";
import { NotificationManager } from "react-notifications";
import { connect } from "react-redux";
import Select, { ValueType } from "react-select";
import { upsertCustomer, upsertCustomerIfNotExists } from "store/actions/customerActions";
import { RootState } from "store/reducers/rootReducer";
import SelectBoxUtils from "utils/selectBoxUtils";

import {
  ICustomerEditDispatchProps,
  ICustomerEditModalComponentProps,
  ICustomerEditModalProps,
  ICustomerEditStateProps,
} from "../interfaces/ICustomerEditProps";
import { ICustomerEditModalState } from "../interfaces/ICustomerEditState";

class CustomerEditModal extends React.Component<ICustomerEditModalProps, ICustomerEditModalState> {
  public constructor(props: ICustomerEditModalProps) {
    super(props);

    this.state = {
      updatedCustomer: _.cloneDeep(this.props.customer),
      isModalOpen: false,
      customerStatus: ObjectExistsStatus.IsNew,
      customerNameValid: true,
      coreIdValid: true,
    };

    this.handleDcpNameChange = this.handleDcpNameChange.bind(this);
    this.onIsConfigurableChange = this.onIsConfigurableChange.bind(this);
    this.cancelForm = this.cancelForm.bind(this);
    this.onSaveCustomer = this.onSaveCustomer.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  private get modalHook(): HTMLElement {
    let modalHook = document.getElementById("modal");
    if (!modalHook) {
      modalHook = document.createElement("div");
      modalHook.setAttribute("id", "portal");
      document.body.appendChild(modalHook);
    }

    return modalHook;
  }

  public componentDidUpdate(prevProps: ICustomerEditModalProps): void {
    if (prevProps.customer.id !== this.props.customer.id) {
      this.setState({
        updatedCustomer: _.cloneDeep(this.props.customer),
      });
    }
  }

  private get dcpOptions(): IReactSelectValue[] {
    return this.props.customerProspects.map(x => {
      return { label: `${x.name} (${x.externalCustomerReference})`, value: x.externalCustomerId } as IReactSelectValue;
    });
  }

  private get dcpOptionsValue(): IReactSelectValue | undefined {
    return this.props.customer?.source === Source.DigitalCleaningPlatform && this.props.customer?.name
      ? ({ label: this.props.customer.name, value: this.props.customer.externalCustomerId } as IReactSelectValue)
      : undefined;
  }

  private onSaveCustomer(): void {
    if (!this.isCustomerValid) {
      NotificationManager.error(LanguageProvider.t(TranslationMapper.pages.customeredit.noemptyfields));
      return;
    }

    if (this.state.updatedCustomer.source !== Source.DigitalCleaningPlatform) {
      const customer = this.state.updatedCustomer;
      customer.externalCustomerId = Guid.EMPTY;

      this.setState({
        updatedCustomer: customer,
      });
    }

    if (this.props.isNewCustomer && this.state.customerStatus !== ObjectExistsStatus.IsDeleted) {
      this.props.onGetCustomer(
        this.state.updatedCustomer,
        (status: ObjectExistsStatus) => {
          this.setState({
            customerStatus: status,
            customerNameValid: false,
            coreIdValid: false,
          });
        },
        () => this.closeModal()
      );
    } else {
      this.props.onUpsertCustomer(this.state.updatedCustomer);
      this.closeModal();
    }
  }

  private closeModal(): void {
    this.setState({}, () => this.props.onClose());
  }

  private cancelForm(): void {
    this.closeModal();
  }

  private handleDcpNameChange(selectedItem: ValueType<IReactSelectValue, boolean>): void {
    if (!selectedItem) {
      return;
    }

    const item = selectedItem as IReactSelectValue;
    const customer = this.state.updatedCustomer;
    customer.name = item.label;
    customer.externalCustomerId = item.value;

    this.setState({
      updatedCustomer: customer,
    });
  }

  private get isCustomerValid(): boolean {
    const areRequiredFieldsValid = this.state.coreIdValid && this.hasValidName;

    return (
      this.hasValidName &&
      (this.state.updatedCustomer.externalCustomerId === "" ||
        Guid.isGuid(this.state.updatedCustomer.externalCustomerId))
    );
  }

  private get hasValidName(): boolean {
    return this.state.updatedCustomer.name !== "";
  }

  private get isConfigurable(): boolean {
    return this.state.updatedCustomer.isConfigurable;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private onIsConfigurableChange(e: any): void {
    const customer = this.state.updatedCustomer;
    customer.isConfigurable = e.target.checked ? true : false;
    this.setState({
      updatedCustomer: customer,
      customerStatus: ObjectExistsStatus.IsNew,
    });
  }

  private renderCustomerForm(): JSX.Element {
    const formName = "customer-edit-form";

    const noOptionsMessage = (): string => LanguageProvider.t(TranslationMapper.buttons.dropdowns.no_options);

    return (
      <Form data-test={formName}>
        <Row>
          <Col className="modal__col-mb">
            <Form.Group>
              <Form.Label htmlFor={`${formName}-customer-name`}>
                {LanguageProvider.t(TranslationMapper.pages.customeredit.customername_label)}
              </Form.Label>
              <Select
                value={this.dcpOptionsValue}
                onChange={this.handleDcpNameChange}
                options={this.dcpOptions}
                placeholder={LanguageProvider.t(TranslationMapper.pages.customeredit.customername_placeholder)}
                noOptionsMessage={noOptionsMessage}
                styles={
                  this.props.isNewCustomer
                    ? SelectBoxUtils.getDefaultSelectStyles(40)
                    : SelectBoxUtils.getDisabledSelectStyles(40)
                }
                isDisabled={!this.props.isNewCustomer}
                className="single-select"
                classNamePrefix="single-select"
              />
              <div className="text-warning small">
                {this.state.customerStatus === ObjectExistsStatus.IsDeleted && (
                  <>{LanguageProvider.t(TranslationMapper.pages.customeredit.undocustomerdeletedescription)}</>
                )}
                {this.state.customerStatus === ObjectExistsStatus.IsActive && (
                  <>{LanguageProvider.t(TranslationMapper.pages.customeredit.customeralreadyactive)}</>
                )}
              </div>
            </Form.Group>
          </Col>
        </Row>
        {!this.props.isNewCustomer && (
          <Row>
            <Col md={10}>
              <Form.Group>
                <Form.Label htmlFor={`${formName}-isConfigurable`}>
                  {LanguageProvider.t(TranslationMapper.pages.customeredit.is_configurable)}
                </Form.Label>
                <p className="small mb-0">
                  {LanguageProvider.t(TranslationMapper.pages.customeredit.is_configurable_subtext)}
                </p>
              </Form.Group>
            </Col>
            <Col md={2}>
              <div className="d-flex">
                <ActiveSwitch
                  checked={this.isConfigurable}
                  onChange={this.onIsConfigurableChange}
                  id={`${formName}-isConfigurable`}
                />
              </div>
            </Col>
          </Row>
        )}
      </Form>
    );
  }

  public render(): ReactNode {
    const pageHeader = this.props.isNewCustomer
      ? LanguageProvider.t(TranslationMapper.pages.customeredit.newcustomer)
      : this.props.customer.name;

    return (
      <>
        {this.modalHook &&
          ReactDOM.createPortal(
            <Modal backdrop="static" show={true} onHide={this.cancelForm} centered>
              <Modal.Header closeButton>
                <div className="modal-header__info">
                  <h1 className="modal-title">{pageHeader}</h1>
                </div>
              </Modal.Header>
              <Modal.Body className="modal-body__height-lg">{this.renderCustomerForm()}</Modal.Body>
              <Modal.Footer className="d-flex justify-content-between">
                <Button
                  className="btn-outline-secondary"
                  onClick={this.cancelForm}
                  resourceLabel={LanguageProvider.t(TranslationMapper.buttons.cancel)}
                  iconEnd="times"
                />
                <Button
                  className="btn-primary"
                  disabled={!this.isCustomerValid}
                  onClick={(): void => this.onSaveCustomer()}
                  resourceLabel={LanguageProvider.t(
                    this.state.customerStatus === ObjectExistsStatus.IsDeleted
                      ? TranslationMapper.buttons.activate
                      : TranslationMapper.buttons.save_and_close
                  )}
                  iconEnd="floppy-disk"
                />
              </Modal.Footer>
            </Modal>,
            this.modalHook
          )}
      </>
    );
  }
}

const mapStateToProps = (state: RootState, props: ICustomerEditModalComponentProps): ICustomerEditStateProps => ({
  customer: state.customerState.customers.find(c => c.id === props.customerId) ?? new Customer(),
  customerProspects: state.customerState.customerProspects ?? [],
  isLoading: state.generalState.loaders.some(l => l === LoaderTypes.Customers),
  isNewCustomer: state.customerState.customers.find(c => c.id === props.customerId) ? false : true,
});

const mapDispatchToProps: ICustomerEditDispatchProps = {
  onUpsertCustomer: upsertCustomer,
  onGetCustomer: upsertCustomerIfNotExists,
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerEditModal);
