export const TranslationMapper = {
  global: {
    yes: "global.yes",
    no: "global.no",
    date_formats: {
      today: "global.date_formats.today",
      tomorrow: "global.date_formats.tomorrow",
      weekdays: {
        mon: "global.date_formats.weekdays.mon",
        tue: "global.date_formats.weekdays.tue",
        wed: "global.date_formats.weekdays.wed",
        thu: "global.date_formats.weekdays.thu",
        fri: "global.date_formats.weekdays.fri",
        sat: "global.date_formats.weekdays.sat",
        sun: "global.date_formats.weekdays.sun",
        monday: "global.date_formats.weekdays.monday",
        tuesday: "global.date_formats.weekdays.tuesday",
        wednesday: "global.date_formats.weekdays.wednesday",
        thursday: "global.date_formats.weekdays.thursday",
        friday: "global.date_formats.weekdays.friday",
        saturday: "global.date_formats.weekdays.saturday",
        sunday: "global.date_formats.weekdays.sunday",
      },
      time_unit: {
        minute: "global.date_formats.time_unit.minute",
        hour: "global.date_formats.time_unit.hour",
        day: "global.date_formats.time_unit.day",
        week: "global.date_formats.time_unit.week",
        month: "global.date_formats.time_unit.month",
        year: "global.date_formats.time_unit.year",
      },
      months: {
        january: "global.date_formats.months.january",
        february: "global.date_formats.months.february",
        march: "global.date_formats.months.march",
        april: "global.date_formats.months.april",
        may: "global.date_formats.months.may",
        june: "global.date_formats.months.june",
        july: "global.date_formats.months.july",
        august: "global.date_formats.months.august",
        september: "global.date_formats.months.september",
        october: "global.date_formats.months.october",
        november: "global.date_formats.months.november",
        december: "global.date_formats.months.december",
      },
      months_shorthand: {
        january: "global.date_formats.months_shorthand.january",
        february: "global.date_formats.months_shorthand.february",
        march: "global.date_formats.months_shorthand.march",
        april: "global.date_formats.months_shorthand.april",
        may: "global.date_formats.months_shorthand.may",
        june: "global.date_formats.months_shorthand.june",
        july: "global.date_formats.months_shorthand.july",
        august: "global.date_formats.months_shorthand.august",
        september: "global.date_formats.months_shorthand.september",
        october: "global.date_formats.months_shorthand.october",
        november: "global.date_formats.months_shorthand.november",
        december: "global.date_formats.months_shorthand.december",
      },
    },
  },
  controls: {
    placeholders: {
      select: "controls.placeholders.select",
      search_customer_name: "controls.placeholders.search_customer_name",
      search_name: "controls.placeholders.search_name",
      search: "controls.placeholders.search",
    },
  },
  buttons: {
    activate: "buttons.activate",
    close: "buttons.close",
    close_short: "buttons.close_short",
    execute: "buttons.execute",
    logout: "buttons.logout",
    ok: "buttons.ok",
    loading: "buttons.loading",
    save: "buttons.save",
    export: "buttons.export",
    save_and_close: "buttons.save_and_close",
    cancel: "buttons.cancel",
    next: "buttons.next",
    previous: "buttons.previous",
    previous_variation: "buttons.previous_variation",
    dropdowns: {
      venue: "buttons.dropdowns.venue",
      customer: "buttons.dropdowns.customer",
      company: "buttons.dropdowns.company",
      floor: "buttons.dropdowns.floor",
      asset: "buttons.dropdowns.asset",
      no_options: "buttons.dropdowns.no_options",
    },
  },
  settings: {
    user_settings_title: "settings.user_settings_title",
    account_title: "settings.account_title",
    language_title: "settings.language_title",
    language_option_nl: "settings.language_option_nl",
    language_option_en: "settings.language_option_en",
    notifications_title: "settings.notifications_title",
    notifications_description: "settings.notifications_description",
    visibility_title: "settings.visibility_title",
    visibility_description: "settings.visibility_description",
  },
  images: {
    namenotfound: "images.namenotfound",
  },
  navigation: {
    home: "navigation.home",
    planning: "navigation.planning",
    logging: "navigation.logging",
    notifications: "navigation.notifications",
    masterdata: "navigation.masterdata",
    customermanagement: "navigation.customermanagement",
    customerdata: "navigation.customerdata",
    activities: "navigation.activities",
    incidents: "navigation.incidents",
    logbook: "navigation.logbook",
  },
  datepicker: {
    startdate: "datepicker.startdate",
    enddate: "datepicker.enddate",
    today: "datepicker.today",
    hours: "datepicker.hours",
    minutes: "datepicker.minutes",
  },
  customerpicker: {
    choosecustomer: "customerpicker.choosecustomer",
  },
  searchable_select: {
    no_options: "searchable_select.no_options",
    placeholder: "searchable_select.placeholder",
  },
  searchable_multiselect: {
    selected: "searchable_multiselect.selected",
    clearsearch: "searchable_multiselect.clearsearch",
    no_options: "searchable_multiselect.no_options",
    search: "searchable_multiselect.search",
    selectall: "searchable_multiselect.selectall",
    selectallfiltered: "searchable_multiselect.selectallfiltered",
    emptystringlabel: "searchable_multiselect.emptystringlabel",
  },
  pagination: {
    showing: "pagination.showing",
    to: "pagination.to",
    of: "pagination.of",
    items: "pagination.items",
    shown_pages: "pagination.shown_pages",
  },
  footer: {
    save: "footer.save",
    saveandcopy: "footer.saveandcopy",
    cancel: "footer.cancel",
    previousstep: "footer.previousstep",
    nextstep: "footer.nextstep",
    verify: "footer.verify",
  },
  filtermenu: {
    no_options: "filtermenu.no_options",
  },
  reacttable: {
    nodata: "reacttable.nodata",
  },
  masterdata: {
    activities: "masterdata.activities",
    incidents: "masterdata.incidents",
    images: "masterdata.images",
  },
  activityactions: {
    not_all_results_shown: "activityactions.not_all_results_shown",
    small: "activityactions.small",
    refill: "activityactions.refill",
    cups: "activityactions.cups",
    remove_graffiti: "activityactions.remove_graffiti",
    complaint: "activityactions.complaint",
    wish: "activityactions.wish",
    malfunction: "activityactions.malfunction",
    information_request: "activityactions.information_request",
    select_answer: "activityactions.select_answer",
    feedback: {
      statuscode: "activityactions.feedback.statuscode",
      reasoncode: "activityactions.feedback.reasoncode",
      reviewrequest: "activityactions.feedback.reviewrequest",
      reason: "activityactions.feedback.reason",
      reasontext: "activityactions.feedback.reasontext",
      specialistrequired: "activityactions.feedback.specialistrequired",
      mothball: "activityactions.feedback.mothball",
      not_needed: "activityactions.feedback.not_needed",
      machine_issue: "activityactions.feedback.machine_issue",
      customer_issue: "activityactions.feedback.customer_issue",
      not_reachable: "activityactions.feedback.not_reachable",
      ingredients_out_of_stock_not_delivered___machine_only_cleaned:
        "activityactions.feedback.ingredients_out_of_stock_not_delivered___machine_only_cleaned",
      completed_at_location: "activityactions.feedback.completed_at_location",
      completed_no_error: "activityactions.feedback.completed_no_error",
      completed_customer_error: "activityactions.feedback.completed_customer_error",
      completed_operator_error: "activityactions.feedback.completed_operator_error",
      incomplete_specialist_required: "activityactions.feedback.incomplete_specialist_required",
      incomplete_customer_not_present: "activityactions.feedback.incomplete_customer_not_present",
      crockery_error: "activityactions.feedback.incomplete_specialist_required",
      ingredient_error: "activityactions.feedback.ingredient_error",
      not_applicable: "activityactions.feedback.not_applicable",
      no_cross_selling_error: "activityactions.feedback.no_cross_selling_error",
      drip_tray_error: "activityactions.feedback.drip_tray_error",
      no_stock_error: "activityactions.feedback.no_stock_error",
      machine_dirty_error: "activityactions.feedback.machine_dirty_error",
      true: "activityactions.feedback.true",
      false: "activityactions.feedback.false",
    },
  },
  checkouttriggers: {
    unknown: "checkouttriggers.unknown",
    nfctagscanned: "checkouttriggers.nfctagscanned",
    nfctagmanual: "checkouttriggers.nfctagmanual",
    clicked: "checkouttriggers.clicked",
    cleaningmanagement: "checkouttriggers.cleaningmanagement",
    externalapi: "checkouttriggers.externalapi",
  },
  pages: {
    route: {
      newroute: "pages.route.newroute",
      editroute: "pages.route.editroute",
      active: "pages.route.active",
      copy: "pages.route.copy",
      name: "pages.route.name",
      incidenttypes: "pages.route.incidenttypes",
      cancel: "pages.route.cancel",
      previousstep: "pages.route.previousstep",
      nextstep: "pages.route.nextstep",
      save: "pages.route.save",
      saveandcopy: "pages.route.saveandcopy",
      cancelroute: "pages.route.cancelroute",
      createnewroute: "pages.route.createnewroute",
      enterroutename: "pages.route.enterroutename",
      selectdays: "pages.route.selectdays",
      selectoperator: "pages.route.selectoperator",
      nooperatorsfound: "pages.route.nooperatorsfound",
      startroute: "pages.route.startroute",
      endroute: "pages.route.endroute",
      selectazone: "pages.route.selectazone",
      validation: "pages.route.validation",
      changevenue: "pages.route.changevenue",
      notificationcontact: "pages.route.notificationcontact",
      activities: {
        title: "pages.route.activities.title",
        "empty trash bins": "pages.route.activities.empty trash bins",
        vacuum: "pages.route.activities.vacuum",
        spray: "pages.route.activities.spray",
        "dust off": "pages.route.activities.dust off",
        "mop the floor": "pages.route.activities.mop the floor",
      },
      notifications: {
        title: "pages.route.notifications.title",
        catering: "pages.route.notifications.catering",
        security: "pages.route.notifications.security",
        spill: "pages.route.notifications.spill",
        electric: "pages.route.notifications.electric",
        extracleaning: "pages.route.notifications.extracleaning",
        lights: "pages.route.notifications.lights",
        "fire damage": "pages.route.notifications.fire damage",
        defect: "pages.route.notifications.defect",
      },
      summary: {
        selectedzonestitle: "pages.route.summary.selectedzonestitle",
        spacename: "pages.route.summary.spacename",
        spacetypename: "pages.route.summary.spacetypename",
        floor: "pages.route.summary.floor",
        mediumthreshold: "pages.route.summary.mediumthreshold",
        highthreshold: "pages.route.summary.highthreshold",
        routesaving: "pages.route.summary.routesaving",
        routesaveerror: "pages.route.summary.routesaveerror",
      },
    },
    routes: {
      title: "pages.routes.title",
      pagetitle: "pages.routes.pagetitle",
      routename: "pages.routes.routename",
      location: "pages.routes.location",
      starttime: "pages.routes.starttime",
      frequence: "pages.routes.frequence",
      operator: "pages.routes.operator",
      active: "pages.routes.active",
      inactive: "pages.routes.inactive",
      frequencies: {
        monday: "pages.routes.frequencies.monday",
        tuesday: "pages.routes.frequencies.tuesday",
        wednesday: "pages.routes.frequencies.wednesday",
        thursday: "pages.routes.frequencies.thursday",
        friday: "pages.routes.frequencies.friday",
        saturday: "pages.routes.frequencies.saturday",
        sunday: "pages.routes.frequencies.sunday",
      },
      activetrue: "pages.routes.activetrue",
      activefalse: "pages.routes.activefalse",
      deactivateconfirmation: "pages.routes.deactivateconfirmation",
      copyconfirmation: "pages.routes.copyconfirmation",
      norawdatafound: "pages.routes.norawdatafound",
      successfullydeactivated: "pages.routes.successfullydeactivated",
      routedeactivateerror: "pages.routes.routedeactivateerror",
      filternames: {
        location: "pages.routes.filternames.location",
        operator: "pages.routes.filternames.operator",
        status: "pages.routes.filternames.status",
        name: "pages.routes.filternames.name",
      },
    },
    activityplanning: {
      title: "pages.activityplanning.title",
      pagetitle: "pages.activityplanning.pagetitle",
      activityheader: "pages.activityplanning.activityheader",
      dateheader: "pages.activityplanning.dateheader",
      cleaningobjectheader: "pages.activityplanning.cleaningobjectheader",
      cleaningobjectreferenceheader: "pages.activityplanning.cleaningobjectreferenceheader",
      startsladatetimeheader: "pages.activityplanning.startsladatetimeheader",
      failuredescriptionheader: "pages.activityplanning.failuredescriptionheader",
      assignedto: "pages.activityplanning.assignedto",
      startedon: "pages.activityplanning.startedon",
      statusnames: {
        scheduled: "pages.activityplanning.statusnames.scheduled",
        started: "pages.activityplanning.statusnames.started",
      },
      statusheader: "pages.activityplanning.statusheader",
      locationheader: "pages.activityplanning.locationheader",
      externalidheader: "pages.activityplanning.externalidheader",
      selecttypeerror: "pages.activityplanning.selecttypeerror",
      activitysuccessfullyfinished: "pages.activityplanning.activitysuccessfullyfinished",
      activitysuccessfullyupdated: "pages.activityplanning.activitysuccessfullyupdated",
      finisherror: "pages.activityplanning.finisherror",
      updateerror: "pages.activityplanning.updateerror",
      multiplecheckouterror: "pages.activityplanning.multiplecheckouterror",
      filternames: {
        location: "pages.activityplanning.filternames.location",
        cleaningobject: "pages.activityplanning.filternames.cleaningobject",
        cleaningobjectreference: "pages.activityplanning.filternames.cleaningobjectreference",
        activityreference: "pages.activityplanning.filternames.activityreference",
      },
      editdialog: {
        title: "pages.activityplanning.editdialog.title",
        subtitle: "pages.activityplanning.editdialog.subtitle",
        selectedheader: "pages.activityplanning.editdialog.selectedheader",
        statusheader: "pages.activityplanning.editdialog.statusheader",
        assignoperatorheader: "pages.activityplanning.editdialog.assignoperatorheader",
        assignoperatorerror: "pages.activityplanning.editdialog.assignoperatorerror",
        unassignoperator: "pages.activityplanning.editdialog.unassignoperator",
        assignoperatormultiplebuildingserror: "pages.activityplanning.editdialog.assignoperatormultiplebuildingserror",
        reasonheader: "pages.activityplanning.editdialog.reasonheader",
      },
    },
    activitylogging: {
      title: "pages.activitylogging.title",
      pagetitle: "pages.activitylogging.pagetitle",
      activityheader: "pages.activitylogging.activityheader",
      dateheader: "pages.activitylogging.dateheader",
      checkoutoperatorheader: "pages.activitylogging.checkoutoperatorheader",
      checkouttriggerheader: "pages.activitylogging.checkouttriggerheader",
      cleaningobjectheader: "pages.activitylogging.cleaningobjectheader",
      cleaningobjectreferenceheader: "pages.activitylogging.cleaningobjectreferenceheader",
      objectlocation: "pages.activitylogging.objectlocation",
      activityreferenceheader: "pages.activitylogging.activityreferenceheader",
      statusheader: "pages.activitylogging.statusheader",
      locationheader: "pages.activitylogging.locationheader",
      feedbackheader: "pages.activitylogging.feedbackheader",
      failuredescriptionheader: "pages.activitylogging.failuredescriptionheader",
      realizedstartdateheader: "pages.activitylogging.realizedstartdateheader",
      deadlineheader: "pages.activitylogging.deadlineheader",
      activitystatus: {
        finished: "pages.activitylogging.activitystatus.finished",
        cancelled: "pages.activitylogging.activitystatus.cancelled",
        completed: "pages.activitylogging.activitystatus.completed",
        unknown: "pages.activitylogging.activitystatus.unknown",
      },
      filternames: {
        checkoutoperator: "pages.activitylogging.filternames.checkoutoperator",
        activity: "pages.activitylogging.filternames.activity",
        location: "pages.activitylogging.filternames.location",
        cleaningobject: "pages.activitylogging.filternames.cleaningobject",
        cleaningobjectreference: "pages.activitylogging.filternames.cleaningobjectreference",
        activityreference: "pages.activitylogging.filternames.activityreference",
        status: "pages.activitylogging.filternames.status",
        feedback: "pages.activitylogging.filternames.feedback",
      },
      exportmodal: {
        title: "pages.activitylogging.exportmodal.title",
        subtitle: "pages.activitylogging.exportmodal.subtitle",
        activities_label: "pages.activitylogging.exportmodal.activities_label",
        feedback_label: "pages.activitylogging.exportmodal.feedback_label",
      },
      csvheaders: {
        location: "pages.activitylogging.csvheaders.location",
        activity: "pages.activitylogging.csvheaders.activity",
        cleaningobjectname: "pages.activitylogging.csvheaders.cleaningobjectname",
        cleaningobjectreference: "pages.activitylogging.csvheaders.cleaningobjectreference",
        date: "pages.activitylogging.csvheaders.date",
        time: "pages.activitylogging.csvheaders.time",
        operator: "pages.activitylogging.csvheaders.operator",
        statusupdatedby: "pages.activitylogging.csvheaders.statusupdatedby",
        feedbackquestion: "pages.activitylogging.csvheaders.feedbackquestion",
        feedbackanswer: "pages.activitylogging.csvheaders.feedbackanswer",
        floorname: "pages.activitylogging.csvheaders.floorname",
        customerreference: "pages.activitylogging.csvheaders.customerreference",
        status: "pages.activitylogging.csvheaders.status",
      },
    },
    routewizard: {
      routeactive: "pages.routewizard.routeactive",
      venue: "pages.routewizard.venue",
      routename: "pages.routewizard.routename",
      operator: "pages.routewizard.operator",
      period: "pages.routewizard.period",
      weekdays: "pages.routewizard.weekdays",
      incidenttypes: "pages.routewizard.incidenttypes",
      incidenttypestooltip: "pages.routewizard.incidenttypestooltip",
      routedetails: {
        title: "pages.routewizard.routedetails.title",
        subtitle: "pages.routewizard.routedetails.subtitle",
      },
      cleaningobjects: {
        title: "pages.routewizard.cleaningobjects.title",
        subtitle: "pages.routewizard.cleaningobjects.subtitle",
        allobjects: "pages.routewizard.cleaningobjects.allobjects",
        selectedobjects: "pages.routewizard.cleaningobjects.selectedobjects",
        startfloor: "pages.routewizard.cleaningobjects.startfloor",
        floornameerror: "pages.routewizard.cleaningobjects.floornameerror",
      },
      cleaning_activities: {
        name: "pages.routewizard.cleaning_activities.name",
        name_plural: "pages.routewizard.cleaning_activities.name_plural",
        title: "pages.routewizard.cleaning_activities.title",
        subtitle: "pages.routewizard.cleaning_activities.subtitle",
        default_activities: "pages.routewizard.cleaning_activities.default_activities",
        general_activities: "pages.routewizard.cleaning_activities.general_activities",
        zone_activities: "pages.routewizard.cleaning_activities.zone_activities",
      },
      error_not_all_required_data: "pages.routewizard.error_not_all_required_data",
    },
    notifications: {
      building: "pages.notifications.building",
      routename: "pages.notifications.routename",
      datenotification: "pages.notifications.datenotification",
      datecompleted: "pages.notifications.datecompleted",
      notificationby: "pages.notifications.notificationby",
      categorie: "pages.notifications.categorie",
      status: "pages.notifications.status",
      images: "pages.notifications.images",
      description: "pages.notifications.description",
      floorname: "pages.notifications.floorname",
      cleaningobjectreference: "pages.notifications.cleaningobjectreference",
      replies: "pages.notifications.replies",
      reply: "pages.notifications.reply",
      zone: "pages.notifications.zone",
      notfinished: "pages.notifications.notfinished",
      nocleaningobjectreference: "pages.notifications.nocleaningobjectreference",
      nofloor: "pages.notifications.nofloor",
      nodescription: "pages.notifications.nodescription",
      notificationcategory: "pages.notifications.notificationcategory",
      statusnames: {
        open: "pages.notifications.statusnames.open",
        actionrequired: "pages.notifications.statusnames.actionrequired",
        signedoff: "pages.notifications.statusnames.signedoff",
        rejected: "pages.notifications.statusnames.rejected",
        none: "pages.notifications.statusnames.none",
        inprogress: "pages.notifications.statusnames.inprogress",
      },
      filternames: {
        type: "pages.notifications.filternames.type",
      },
      subtitle: "pages.notifications.subtitle",
      errormessageid: "pages.notifications.errormessageid",
      respond: "pages.notifications.respond",
      responsedialog: {
        title: "pages.notifications.responsedialog.title",
        placeholder: "pages.notifications.responsedialog.placeholder",
        sendresponse: "pages.notifications.responsedialog.sendresponse",
        responsesend: "pages.notifications.responsedialog.responsesend",
        responsefailed: "pages.notifications.responsedialog.responsefailed",
        close: "pages.notifications.responsedialog.close",
      },
    },
    notificationwizard: {
      created: "pages.notificationwizard.created",
      error: "pages.notificationwizard.error",
      error_image_file: "pages.notificationwizard.error_image_file",
      error_image_size: "pages.notificationwizard.error_image_size",
      error_cleaning_objects: "pages.notificationwizard.error_cleaning_objects",
      failed: "pages.notificationwizard.failed",
      invalid_data: "pages.notificationwizard.invalid_data",
      new: "pages.notificationwizard.new",
      select_category: "pages.notificationwizard.select_category",
      shown: "pages.notificationwizard.shown",
      try_again: "pages.notificationwizard.try_again",
      description: "pages.notificationwizard.description",
      take_pictures: "pages.notificationwizard.take_pictures",
      select_cleaning_object: "pages.notificationwizard.select_cleaning_object",
      select_location: "pages.notificationwizard.select_location",
    },
    logging: {
      title: "pages.logging.title",
      routename: "pages.logging.routename",
      startdate: "pages.logging.startdate",
      pausetime: "pages.logging.pausetime",
      plannedduration: "pages.logging.plannedduration",
      actualduration: "pages.logging.actualduration",
      deviation: "pages.logging.deviation",
      total: "pages.logging.total",
      operator: "pages.logging.operator",
      category: "pages.logging.category",
      status: "pages.logging.status",
      cleaningactivities: "pages.logging.cleaningactivities",
      zone: "pages.logging.zone",
      expectedpollution: "pages.logging.expectedpollution",
      actualpollution: "pages.logging.actualpollution",
      uncleaned_zone_reason: "pages.logging.uncleaned_zone_reason",
      filternames: {
        notification: "pages.logging.filternames.notification",
        status: "pages.logging.filternames.status",
        operator: "pages.logging.filternames.operator",
        routename: "pages.logging.filternames.routename",
      },
      statusnames: {
        scheduled: "pages.logging.statusnames.scheduled",
        started: "pages.logging.statusnames.started",
        finished: "pages.logging.statusnames.finished",
      },
      cleaningstatus: {
        clean: "pages.logging.cleaningstatus.clean",
        neutral: "pages.logging.cleaningstatus.neutral",
        dirty: "pages.logging.cleaningstatus.dirty",
        very_dirty: "pages.logging.cleaningstatus.very_dirty",
        cleaned: "pages.logging.cleaningstatus.cleaned",
        none: "pages.logging.cleaningstatus.none",
      },
      deleteconfirmationfirstpart: "pages.logging.deleteconfirmationfirstpart",
      deleteconfirmationsecondpart: "pages.logging.deleteconfirmationsecondpart",
      deleteerror: "pages.logging.deleteerror",
      delete_success: "pages.logging.delete_success",
      downloadstartedmessage: "pages.logging.downloadstartedmessage",
      downloaderrormessage: "pages.logging.downloaderrormessage",
      norecordsfound: "pages.logging.norecordsfound",
      downloadbeingpreparedmessage: "pages.logging.downloadbeingpreparedmessage",
      downloadlimitreached: "pages.logging.downloadlimitreached",
      expanded_logging_error: "pages.logging.expanded_logging_error",
    },
    masterdata: {
      searchactivity: "pages.masterdata.searchactivity",
      header: "pages.masterdata.header",
      deleteconfirmation: "pages.masterdata.deleteconfirmation",
      deleteconfirmationinuse: "pages.masterdata.deleteconfirmationinuse",
      saveerror: "pages.masterdata.saveerror",
      saving: "pages.masterdata.saving",
      deleteerror: "pages.masterdata.deleteerror",
      succesfullydeleted: "pages.masterdata.succesfullydeleted",
      activities: {
        choose_file: "pages.masterdata.activities.choose_file",
        no_file_chosen: "pages.masterdata.activities.no_file_chosen",
        no_image: "pages.masterdata.activities.no_image",
        multiplecheckout: "pages.masterdata.activities.multiplecheckout",
        singlecheckout: "pages.masterdata.activities.singlecheckout",
        name: "pages.masterdata.activities.name",
        title: "pages.masterdata.activities.title",
        create: "pages.masterdata.activities.create",
        edit: "pages.masterdata.activities.edit",
        imagesrequired: "pages.masterdata.activities.imagesrequired",
        details: "pages.masterdata.activities.details",
        checkoutsperday: "pages.masterdata.activities.checkoutsperday",
        feedback: "pages.masterdata.activities.feedback",
        addansweroption: "pages.masterdata.activities.addansweroption",
        addansweroptionplaceholder: "pages.masterdata.activities.addansweroptionplaceholder",
        addfeedbackrow: "pages.masterdata.activities.addfeedbackrow",
        feedbackrowtitle: "pages.masterdata.activities.feedbackrowtitle",
        feedbackrowquestion: "pages.masterdata.activities.feedbackrowquestion",
        feedbackrowanswertype: "pages.masterdata.activities.feedbackrowanswertype",
        feedbackrowrequired: "pages.masterdata.activities.feedbackrowrequired",
        feedbackrowwhenfinished: "pages.masterdata.activities.feedbackrowwhenfinished",
        feedbackrowwhencancelled: "pages.masterdata.activities.feedbackrowwhencancelled",
        text: "pages.masterdata.activities.text",
        number: "pages.masterdata.activities.number",
        single_select_pick_list: "pages.masterdata.activities.single_select_pick_list",
        slider: "pages.masterdata.activities.slider",
        columnheaders: {
          title: "pages.masterdata.activities.columnheaders.title",
          activeicon: "pages.masterdata.activities.columnheaders.activeicon",
          inactiveicon: "pages.masterdata.activities.columnheaders.inactiveicon",
          feedback: "pages.masterdata.activities.columnheaders.feedback",
          feedbackrowquestion: "pages.masterdata.activities.columnheaders.feedbackrowquestion",
          feedbackrowanswertype: "pages.masterdata.activities.columnheaders.feedbackrowanswertype",
          checkout: "pages.masterdata.activities.columnheaders.checkout",
          showwhen: "pages.masterdata.activities.columnheaders.showwhen",
          required: "pages.masterdata.activities.columnheaders.required",
        },
      },
      notificationcategories: {
        title: "pages.masterdata.notificationcategories.title",
        create: "pages.masterdata.notificationcategories.create",
        edit: "pages.masterdata.notificationcategories.edit",
        subtitle: "pages.masterdata.notificationcategories.subtitle",
        imagesrequired: "pages.masterdata.notificationcategories.imagesrequired",
      },
      save: "pages.masterdata.save",
      close: "pages.masterdata.close",
      name: "pages.masterdata.name",
      cleaningimage: "pages.masterdata.cleaningimage",
      image: "pages.masterdata.image",
      activeimage: "pages.masterdata.activeimage",
      inactiveimage: "pages.masterdata.inactiveimage",
      imagewrongformat: "pages.masterdata.imagewrongformat",
      bothimagesrequired: "pages.masterdata.bothimagesrequired",
      namerequired: "pages.masterdata.namerequired",
      savedchanges: "pages.masterdata.savedchanges",
      retrieve_pollutionlevels: "pages.masterdata.retrieve_pollutionlevels",
    },
    customermanagement: {
      customeroverview: "pages.customermanagement.customeroverview",
      customername: "pages.customermanagement.customername",
      buildings: "pages.customermanagement.buildings",
      manager: "pages.customermanagement.manager",
      contact: "pages.customermanagement.contact",
    },
    customeredit: {
      newcustomer: "pages.customeredit.newcustomer",
      save: "pages.customeredit.save",
      cancel: "pages.customeredit.cancel",
      cancelwarning: "pages.customeredit.cancelwarning",
      errormessage: "pages.customeredit.errormessage",
      errormessage_deletelocation: "pages.customeredit.errormessage_deletelocation",
      errormessage_updatelocation: "pages.customeredit.errormessage_updatelocation",
      multiplecustomersfounderror: "pages.customeredit.multiplecustomersfounderror",
      managername: "pages.customeredit.managername",
      locationbuilding: "pages.customeredit.locationbuilding",
      generaltab: "pages.customeredit.generaltab",
      buildingstab: "pages.customeredit.buildingstab",
      buildingname: "pages.customeredit.buildingname",
      general: "pages.customeredit.general",
      externalvenueid: "pages.customeredit.externalvenueid",
      noemptyfields: "pages.customeredit.noemptyfields",
      dcp_label: "pages.customeredit.dcp_label",
      dcp_sub_label: "pages.customeredit.dcp_sub_label",
      customername_label: "pages.customeredit.customername_label",
      is_configurable: "pages.customeredit.is_configurable",
      is_configurable_subtext: "pages.customeredit.is_configurable_subtext",
      customername_placeholder: "pages.customeredit.customername_placeholder",
      externalcustomerid_label: "pages.customeredit.externalcustomerid_label",
      customer_keys_header: "pages.customeredit.customer_keys_header",
      customer_venue_keys_header: "pages.customeredit.customer_venue_keys_header",
      nfc_tags: "pages.customeredit.nfc_tags",
      nfc_tag_id: "pages.customeredit.nfc_tag_id",
      nfc_tag_none_available: "pages.customeredit.nfc_tag_none_available",
      error_deleting_customer: "pages.customeredit.error_deleting_customer",
      error_upserting_customer: "pages.customeredit.error_upserting_customer",
      error_customer_not_found: "pages.customeredit.error_customer_not_found",
      customer_updated_message: "pages.customeredit.customer_updated_message",
      customer_time_offset: "pages.customeredit.customer_time_offset",
      customer_time_offset_sub_label1: "pages.customeredit.customer_time_offset_sub_label1",
      customer_time_offset_sub_label2: "pages.customeredit.customer_time_offset_sub_label2",
      go_back: "pages.customeredit.go_back",
      undocustomerdeletedescription: "pages.customeredit.undocustomerdeletedescription",
      customeralreadyactive: "pages.customeredit.customeralreadyactive",
      reactivate_building: "pages.customeredit.reactivate_building",
    },
    customerdata: {
      tabs: {
        activities: "pages.customerdata.tabs.activities",
        incidents: "pages.customerdata.tabs.incidents",
      },
      operatoractivities: "pages.customerdata.operatoractivities",
      incidenttypes: "pages.customerdata.incidenttypes",
      incidentcontact: "pages.customerdata.incidentcontact",
      toiletsettings: "pages.customerdata.toiletsettings",
      workspacesettings: "pages.customerdata.workspacesettings",
      meetingroomsettings: "pages.customerdata.meetingroomsettings",
      save: "pages.customerdata.save",
      nocustomerselected: "pages.customerdata.nocustomerselected",
      notificationcategorycontact: "pages.customerdata.notificationcategorycontact",
      invalidemail: "pages.customerdata.invalidemail",
      invalidemails: "pages.customerdata.invalidemails",
      max_amount_mail_addresses: "pages.customerdata.max_amount_mail_addresses",
      invalidconfiguration: "pages.customerdata.invalidconfiguration",
      activityerror: "pages.customerdata.activityerror",
      column_header_notification_category: "pages.customerdata.column_header_notification_category",
      column_header_notification_icon_active: "pages.customerdata.column_header_notification_icon_active",
      column_header_notification_icon_inactive: "pages.customerdata.column_header_notification_icon_inactive",
      column_header_notification_active: "pages.customerdata.column_header_notification_active",
      notification_active_toggle_header: "pages.customerdata.notification_active_toggle_header",
      notification_active_toggle_subtext: "pages.customerdata.notification_active_toggle_subtext",
      notifications: "pages.customerdata.notifications",
      number_of_buildings: "pages.customerdata.number_of_buildings",
      buildings_header: "pages.customerdata.buildings_header",
      buildings: "pages.customerdata.buildings",
      building: "pages.customerdata.building",
      all_buildings: "pages.customerdata.all_buildings",
      receiver: "pages.customerdata.receiver",
      add_receiver: "pages.customerdata.add_receiver",
      edit_receiver: "pages.customerdata.edit_receiver",
      add_receiver_subtext: "pages.customerdata.add_receiver_subtext",
      email_receiver: "pages.customerdata.email_receiver",
      receive_for_all_buildings: "pages.customerdata.receive_for_all_buildings",
    },
    customer_venue_edit: {
      create_title: "pages.customer_venue_edit.create_title",
      edit_title: "pages.customer_venue_edit.edit_title",
      name: "pages.customer_venue_edit.name",
      customer_id: "pages.customer_venue_edit.customer_id",
      external_venue_id: "pages.customer_venue_edit.external_venue_id",
      external_project_reference: "pages.customer_venue_edit.external_project_reference",
      undo_delete_title: "pages.customer_venue_edit.undo_delete_title",
      undo_building_delete_description: "pages.customer_venue_edit.undo_building_delete_description",
      building_already_active: "pages.customer_venue_edit.building_already_active",
      activate_deleted_building: "pages.customer_venue_edit.activate_deleted_building",
      connect_nfc_tags: "pages.customer_venue_edit.connect_nfc_tags",
      add_nfc_tag: "pages.customer_venue_edit.add_nfc_tag",
      wizard: {
        step_1: {
          groupbyfloors: "pages.customer_venue_edit.wizard.step_1.groupbyfloors",
          groupbyfloorssublabel: "pages.customer_venue_edit.wizard.step_1.groupbyfloorssublabel",
          nfc_required: {
            label: "pages.customer_venue_edit.wizard.step_1.nfc_required.label",
            venue: "pages.customer_venue_edit.wizard.step_1.nfc_required.venue",
            floor: "pages.customer_venue_edit.wizard.step_1.nfc_required.floor",
          },
        },
        step_3: {
          subtitle: "pages.customer_venue_edit.wizard.step_3.subtitle",
        },
      },
      error_fetching_venue: "pages.customer_venue_edit.error_fetching_venue",
    },
    externalvenue: {
      error_creating_floor: "pages.externalvenue.error_creating_floor",
      error_creating_zone: "pages.externalvenue.error_creating_zone",
      error_creating_zone_nfc: "pages.externalvenue.error_creating_zone_nfc",
      error_deleting_floor: "pages.externalvenue.error_deleting_floor",
      error_deleting_zone: "pages.externalvenue.error_deleting_zone",
      error_updating_floor: "pages.externalvenue.error_updating_floor",
      error_updating_zone: "pages.externalvenue.error_updating_zone",
      error_updating_zone_nfc: "pages.externalvenue.error_updating_zone_nfc",
      floor_created_message: "pages.externalvenue.floor_created_message",
      floor_deleted_message: "pages.externalvenue.floor_deleted_message",
      floor_updated_message: "pages.externalvenue.floor_updated_message",
      zone_created_message: "pages.externalvenue.zone_created_message",
      zone_deleted_message: "pages.externalvenue.zone_deleted_message",
      zone_updated_message: "pages.externalvenue.zone_updated_message",
      create_floor: "pages.externalvenue.create_floor",
      create_zone: "pages.externalvenue.create_zone",
      delete_floor: "pages.externalvenue.delete_floor",
      delete_zone: "pages.externalvenue.delete_zone",
      update_floor: "pages.externalvenue.update_floor",
      update_zone: "pages.externalvenue.update_zone",
      name_floor: "pages.externalvenue.name_floor",
      name_zone: "pages.externalvenue.name_zone",
      name_required: "pages.externalvenue.name_required",
      name_placeholder: "pages.externalvenue.name_placeholder",
      nfctag_code: "pages.externalvenue.nfctag_code",
      nfctag_zone: "pages.externalvenue.nfctag_zone",
      nfctag_required: "pages.externalvenue.nfctag_required",
      nfctag_placeholder: "pages.externalvenue.nfctag_placeholder",
      nfctag_floor_required: "pages.externalvenue.nfctag_floor_required",
      no_data_found: "pages.externalvenue.no_data_found",
      floors_zones: "pages.externalvenue.floors_zones",
      zone: "pages.externalvenue.zone",
      zones: "pages.externalvenue.zones",
      choose_file: "pages.externalvenue.choose_file",
      csv_file: "pages.externalvenue.csv_file",
      csv_uploaded: "pages.externalvenue.csv_uploaded",
      download_template: "pages.externalvenue.download_template",
      error_uploading_csv: "pages.externalvenue.error_uploading_csv",
      file_wrong_format: "pages.externalvenue.file_wrong_format",
      upload_file_modal_title: "pages.externalvenue.upload_file_modal_title",
      succeeded: "pages.externalvenue.succeeded",
      failed: "pages.externalvenue.failed",
      succeeded_text: "pages.externalvenue.succeeded_text",
      failed_text: "pages.externalvenue.failed_text",
      failed_rows: "pages.externalvenue.failed_rows",
      details: {
        name: "pages.externalvenue.details.name",
        nfctag_code: "pages.externalvenue.details.nfctag_code",
        zones_amount: "pages.externalvenue.details.zones_amount",
      },
    },
    logbook: {
      header: "pages.logbook.header",
      last_sender: "pages.logbook.last_sender",
      messages_component: {
        message_input_placeholder: "pages.logbook.messages_component.message_input_placeholder",
        error_sending_message: "pages.logbook.messages_component.error_sending_message",
        successfully_sent_message: "pages.logbook.messages_component.successfully_sent_message",
        error_fetch_messages: "pages.logbook.messages_component.error_fetch_messages",
      },
      single_message_component: {
        new_message_line: "pages.logbook.single_message_component.new_message_line",
      },
    },
  },
  usersettingsmenu: {
    logout: "usersettingsmenu.logout",
    language: "usersettingsmenu.language",
    feedback: "usersettingsmenu.feedback",
    help: "usersettingsmenu.help",
    settings: "usersettingsmenu.settings",
    your_account: "usersettingsmenu.your_account",
  },
  notifications: {
    invalidfloorspaces: "notifications.invalidfloorspaces",
  },
  errors: {
    general: "errors.general",
    browser_specific_error: "errors.browser_specific_error",
    reload_home: "errors.reload_home",
    uncaught_exception_title: "errors.uncaught_exception_title",
    get_locations: "errors.get_locations",
    fetching_user: "errors.fetching_user",
    fetching_venue: "errors.fetching_venue",
    fetching_notification_contacts: "errors.fetching_notification_contacts",
    fetching_floors: "errors.fetching_floors",
  },
};

export default TranslationMapper;
