import { Component } from "react";
import { Form } from "react-bootstrap";

import ITextInputProps from "./interfaces/ITextInputProps";

export class TextInput extends Component<ITextInputProps> {
  public render(): JSX.Element {
    return (
      <Form.Control
        size={this.props.size}
        plaintext={this.props.plaintext}
        readOnly={this.props.readOnly}
        disabled={this.props.disabled}
        value={this.props.value ?? ""}
        onChange={this.props.onChange}
        type="text"
        id={this.props.id}
        isValid={this.props.isValid}
        isInvalid={this.props.isInvalid}
        data-test={this.props["data-test"]}
      />
    );
  }
}
