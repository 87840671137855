import { FunctionComponent } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import IReactTableRowExpanderProps from "./interfaces/IReactTableRowExpanderProps";

export const ReactTableRowExpander: FunctionComponent<IReactTableRowExpanderProps> = props => {
  const isRowExpanded = props.row.isExpanded;

  const icon = isRowExpanded ? "chevron-up" : "chevron-down";
  return (
    <button className="btn btn-sm py-0 border-0" onClick={(): void => props.row.toggleRowExpanded()}>
      <FontAwesomeIcon icon={["fal", icon]} fixedWidth className="text-primary fa--1rem" />
    </button>
  );
};
