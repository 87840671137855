import { IInputChangeEvent } from "components/interfaces/IInputChangeEvent";
import { Button } from "components/material/buttons/buttons";
import TranslationMapper from "i18n/mapper";
import LanguageProvider from "providers/languageProvider";
import * as React from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import ReactDOM from "react-dom";
import { withTranslation } from "react-i18next";

import IBuildingObject from "../interfaces/IBuildingObject";
import IBuildingObjectModalProps from "../interfaces/IBuildingObjectModalProps";
import IBuildingObjectModalState from "../interfaces/IBuildingObjectModalState";

class BuildingObjectModal extends React.Component<IBuildingObjectModalProps, IBuildingObjectModalState> {
  private readonly formName: string = "venue-object-form";
  private readonly nfcTagMinLength: number = 6;

  public constructor(props: IBuildingObjectModalProps) {
    super(props);
    const state: IBuildingObjectModalState = {
      buildingObject: this.props.buildingObject,
      isFormValid: this.validateForm(this.props.buildingObject),
      show: true,
    };

    this.state = state;

    this.validateFormField = this.validateFormField.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.setNameValue = this.setNameValue.bind(this);
    this.setNfcTagValue = this.setNfcTagValue.bind(this);
    this.cancelForm = this.cancelForm.bind(this);
    this.clearForm = this.clearForm.bind(this);
  }

  private cancelForm(): void {
    this.clearForm();
    this.props.onClose();
  }

  private clearForm(): void {
    const emptyObject: IBuildingObject = {
      nameInputTitle: this.state.buildingObject.nameInputTitle,
      isZoneInput: this.state.buildingObject.isZoneInput,
      IsFloorNFCRequired: false,
    };
    this.setState({
      buildingObject: emptyObject,
      isFormValid: false,
      show: false,
    });
  }

  private setNameValue(event: IInputChangeEvent<string>): void {
    const buildingObjectToUpdate = this.state.buildingObject;
    buildingObjectToUpdate.nameValue = event.target.value;

    this.setState({
      buildingObject: buildingObjectToUpdate,
      isFormValid: this.validateForm(buildingObjectToUpdate),
    });
  }

  private setNfcTagValue(event: IInputChangeEvent<string>): void {
    const buildingObjectToUpdate = this.state.buildingObject;
    buildingObjectToUpdate.nfcTagValue = event.target.value;

    this.setState({
      buildingObject: buildingObjectToUpdate,
      isFormValid: this.validateForm(buildingObjectToUpdate),
    });
  }

  private validateForm(buildingObject: IBuildingObject): boolean {
    return (
      this.validateFormField(buildingObject.nameValue, buildingObject.minLength, true) &&
      this.validateFormField(buildingObject.nfcTagValue, this.nfcTagMinLength, buildingObject.IsFloorNFCRequired)
    );
  }

  private validateFormField(value?: string, minLength?: number, isRequired?: boolean): boolean {
    if (!isRequired || minLength == null || minLength === 0) {
      return true;
    } else {
      return (value?.length ?? 0) >= minLength;
    }
  }

  private handleSave(): void {
    this.props.onSubmit(this.state.buildingObject);
    this.clearForm();
  }

  private preventEnterDefault(e: React.KeyboardEvent<HTMLInputElement>): void {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  }

  private get modalHook(): HTMLElement {
    let modalHook = document.getElementById("modal");
    if (!modalHook) {
      modalHook = document.createElement("div");
      modalHook.setAttribute("id", "portal");
      document.body.appendChild(modalHook);
    }

    return modalHook;
  }

  public render(): JSX.Element {
    return (
      <>
        {this.modalHook &&
          ReactDOM.createPortal(
            <Modal backdrop="static" show={this.state.show} onHide={this.cancelForm} centered>
              <Modal.Header closeButton>
                <div className="modal-header__info">
                  <h1 className="modal-title">{LanguageProvider.t(this.props.modalTitle)}</h1>
                </div>
              </Modal.Header>
              <Modal.Body>
                <Form data-test="venue-object-form" id={this.formName}>
                  <Row>
                    <Col className="modal__col-mb">
                      <Form.Group>
                        <Form.Label htmlFor={this.state.buildingObject.nameInputTitle}>
                          {this.state.buildingObject.nameInputTitle}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          id="floorname"
                          name={this.state.buildingObject.nameInputTitle}
                          value={this.state.buildingObject.nameValue}
                          placeholder={LanguageProvider.t(TranslationMapper.pages.externalvenue.name_placeholder ?? "")}
                          onKeyPress={this.preventEnterDefault}
                          onChange={this.setNameValue}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label htmlFor={TranslationMapper.pages.externalvenue.nfctag_code}>
                          {LanguageProvider.t(TranslationMapper.pages.externalvenue.nfctag_code ?? "")}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          id="nfctag"
                          name={TranslationMapper.pages.externalvenue.nfctag_code}
                          value={this.state.buildingObject.nfcTagValue}
                          placeholder={LanguageProvider.t(
                            TranslationMapper.pages.externalvenue.nfctag_placeholder ?? ""
                          )}
                          onKeyPress={this.preventEnterDefault}
                          onChange={this.setNfcTagValue}
                        />
                        {this.props.buildingObject.IsFloorNFCRequired && (
                          <div className="invalid-feedback d-block">
                            {LanguageProvider.t(TranslationMapper.pages.externalvenue.nfctag_floor_required)}
                          </div>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                </Form>
              </Modal.Body>
              <Modal.Footer className="d-flex justify-content-between">
                <Button
                  className="btn-outline-secondary"
                  onClick={this.cancelForm}
                  resourceLabel={LanguageProvider.t(TranslationMapper.buttons.cancel)}
                  iconEnd="times"
                />
                <Button
                  className="btn-primary"
                  disabled={!this.state.isFormValid}
                  onClick={this.handleSave}
                  resourceLabel={LanguageProvider.t(TranslationMapper.buttons.save)}
                  iconEnd="floppy-disk"
                />
              </Modal.Footer>
            </Modal>,
            this.modalHook
          )}
      </>
    );
  }
}

export default withTranslation()(BuildingObjectModal);
