import ActivityStatus from "enums/activityStatus";
import CleaningStatus from "enums/cleaningStatus";
import NotificationStatus from "enums/notificationStatus";
import RouteStatus from "enums/routeStatus";
import { ZoneFeedbackLabels } from "enums/zoneFeedbackLabels";
import TranslationMapper from "i18n/mapper";
import IActivityAction from "interfaces/IActivityAction";
import IActivityExport from "interfaces/IActivityExport";
import IActivityFeedbackExport from "interfaces/IActivityFeedbackExport";
import IActivityLoggingExport from "interfaces/IActivityLoggingExport";
import ICsvHeader from "interfaces/ICsvHeader";
import IExpandedRouteLogging from "interfaces/IExpandedRouteLogging";
import INotificationOverview from "interfaces/INotificationOverview";
import INotificationResponse from "interfaces/INotificationResponse";
import INotificationsExport from "interfaces/INotificationsExport";
import IRouteLoggingExport from "interfaces/IRouteLoggingExport";
import ISimpleRouteLogging from "interfaces/ISimpleRouteLogging";
import moment from "moment";
import LanguageProvider from "providers/languageProvider";

export default class ExportFormatter {
  public static formatRouteLogging(
    expandedLogsForExport: IExpandedRouteLogging[],
    loggings: ISimpleRouteLogging[]
  ): IRouteLoggingExport[] {
    return expandedLogsForExport.flatMap((el) => {
      const currentLog = loggings.find((log) => log.routeDataId === el.routeDataId);
      let startDateFromSimpleLogging: string;
      let actualTimeFromSimpleLogging: number;
      let plannedTimeFromSimpleLogging: number;
      let pauseTimeFromSimpleLogging: number;

      if (currentLog) {
        startDateFromSimpleLogging = moment(currentLog.startDate).format("DD-MM-YYYY");
        actualTimeFromSimpleLogging = currentLog.realTime;
        plannedTimeFromSimpleLogging = currentLog.plannedTime;
        pauseTimeFromSimpleLogging = currentLog.pauseTime;
      }

      return el.zoneLogging.map((zl) => {
        const newExportRow: IRouteLoggingExport = {
          operatorName: this.sanitizeString(el.operatorName),
          pauseTimeInMinutes: pauseTimeFromSimpleLogging,
          plannedTimeInMinutes: plannedTimeFromSimpleLogging,
          realTimeInMinutes: actualTimeFromSimpleLogging,
          routeDataId: el.routeDataId,
          routeName: this.sanitizeString(el.routeName),
          routeStatus: RouteStatus[el.routeStatus],
          startDate: startDateFromSimpleLogging,
          zoneCalculatedStatus: CleaningStatus[zl.calculatedStatus],
          zoneFeedbackStatus: this.getZoneFeedbackLabel(zl.preciseCleaningStatusFeedback),
          zoneFinished: zl.finished,
          zoneName: this.sanitizeString(zl.name),
          zoneStarted: zl.started,
        };
        return newExportRow;
      });
    });
  }

  public static getActivityFeedbackLoggingHeaders(): ICsvHeader[] {
    return this.getActivityLoggingHeaders().concat([
      {
        label: LanguageProvider.t(TranslationMapper.pages.activitylogging.csvheaders.feedbackquestion),
        key: "feedbackQuestion",
      },
      {
        label: LanguageProvider.t(TranslationMapper.pages.activitylogging.csvheaders.feedbackanswer),
        key: "feedbackAnswer",
      },
    ]);
  }

  public static formatActivityFeedbackLogging(activityData: IActivityAction[]): IActivityFeedbackExport[] {
    const exports = activityData.flatMap((activity) => {
      return activity.activityActionFeedback?.map((el) => {
        const newExportRow: IActivityFeedbackExport = {
          location: activity.locationName,
          activity: activity.description,
          cleaningObjectName: activity.cleaningObjectName,
          cleaningObjectReference: activity.externalObjectId,
          date: moment(activity.date).format("DD-MM-YYYY").toString(),
          time: moment(activity.date).format("HH:mm").toString(),
          statusUpdatedBy: this.sanitizeString(activity.statusUpdatedBy ?? activity.operatorName),
          feedbackQuestion: el.question,
          feedbackAnswer: el.response,
          floorName: activity.floorName,
          customerReference: activity.externalActivityReference,
          status: this.getStatusLabel(activity.activityStatus),
        };
        return newExportRow;
      });
    });

    return exports.filter((x) => x !== undefined) as IActivityFeedbackExport[];
  }

  public static getActivityLoggingHeaders(): ICsvHeader[] {
    return [
      {
        label: LanguageProvider.t(TranslationMapper.pages.activitylogging.csvheaders.location),
        key: "location",
      },
      {
        label: LanguageProvider.t(TranslationMapper.pages.activitylogging.csvheaders.activity),
        key: "activity",
      },
      {
        label: LanguageProvider.t(TranslationMapper.pages.activitylogging.csvheaders.cleaningobjectname),
        key: "cleaningObjectName",
      },
      {
        label: LanguageProvider.t(TranslationMapper.pages.activitylogging.csvheaders.cleaningobjectreference),
        key: "cleaningObjectReference",
      },
      { label: LanguageProvider.t(TranslationMapper.pages.activitylogging.csvheaders.date), key: "date" },
      { label: LanguageProvider.t(TranslationMapper.pages.activitylogging.csvheaders.time), key: "time" },
      {
        label: LanguageProvider.t(TranslationMapper.pages.activitylogging.csvheaders.statusupdatedby),
        key: "statusUpdatedBy",
      },
      {
        label: LanguageProvider.t(TranslationMapper.pages.activitylogging.csvheaders.floorname),
        key: "floorName",
      },
      {
        label: LanguageProvider.t(TranslationMapper.pages.activitylogging.csvheaders.customerreference),
        key: "customerReference",
      },
      {
        label: LanguageProvider.t(TranslationMapper.pages.activitylogging.csvheaders.status),
        key: "status",
      },
    ];
  }

  public static getActivityPlanningHeaders(): ICsvHeader[] {
    return [
      {
        label: LanguageProvider.t(TranslationMapper.pages.activitylogging.csvheaders.location),
        key: "location",
      },
      {
        label: LanguageProvider.t(TranslationMapper.pages.activitylogging.csvheaders.activity),
        key: "activity",
      },
      {
        label: LanguageProvider.t(TranslationMapper.pages.activitylogging.csvheaders.cleaningobjectname),
        key: "cleaningObjectName",
      },
      {
        label: LanguageProvider.t(TranslationMapper.pages.activitylogging.csvheaders.cleaningobjectreference),
        key: "cleaningObjectReference",
      },
      { label: LanguageProvider.t(TranslationMapper.pages.activitylogging.csvheaders.date), key: "date" },
      {
        label: LanguageProvider.t(TranslationMapper.pages.activitylogging.csvheaders.customerreference),
        key: "customerReference",
      },
    ];
  }

  public static formatActivityLogging(activityData: IActivityAction[]): IActivityLoggingExport[] {
    return activityData.map((activity) => {
      const newExportRow: IActivityLoggingExport = {
        location: activity.locationName,
        activity: activity.description,
        cleaningObjectName: activity.cleaningObjectName,
        cleaningObjectReference: activity.externalObjectId,
        date: moment(activity.date).format("DD-MM-YYYY").toString(),
        time: moment(activity.date).format("HH:mm").toString(),
        statusUpdatedBy: this.sanitizeString(activity.statusUpdatedBy ?? activity.operatorName),
        floorName: activity.floorName,
        customerReference: activity.externalActivityReference,
        status: this.getStatusLabel(activity.activityStatus),
      };
      return newExportRow;
    });
  }

  public static formatActivityPlanning(activityData: IActivityAction[]): IActivityExport[] {
    return activityData.map((activity) => {
      const newExportRow: IActivityExport = {
        location: activity.locationName,
        activity: activity.description,
        cleaningObjectName: activity.cleaningObjectName,
        cleaningObjectReference: activity.externalObjectId,
        date: moment(activity.date).format("DD-MM-YYYY").toString(),
        customerReference: activity.externalActivityReference,
      };
      return newExportRow;
    });
  }

  public static getNotificationLoggingHeaders(): ICsvHeader[] {
    return [
      { label: LanguageProvider.t(TranslationMapper.pages.notifications.building), key: "building" },
      {
        label: LanguageProvider.t(TranslationMapper.pages.notifications.floorname),
        key: "floorBuidingArea",
      },
      { label: LanguageProvider.t(TranslationMapper.pages.notifications.zone), key: "cleaningObjectName" },
      {
        label: LanguageProvider.t(TranslationMapper.pages.notifications.cleaningobjectreference),
        key: "objectReference",
      },
      {
        label: LanguageProvider.t(TranslationMapper.pages.notifications.datenotification),
        key: "createdOn",
      },
      {
        label: LanguageProvider.t(TranslationMapper.pages.notifications.notificationby),
        key: "createdBy",
      },
      {
        label: LanguageProvider.t(TranslationMapper.pages.notifications.notificationcategory),
        key: "notificationCategory",
      },
      { label: LanguageProvider.t(TranslationMapper.pages.notifications.status), key: "status" },
      { label: LanguageProvider.t(TranslationMapper.pages.notifications.description), key: "description" },
      { label: LanguageProvider.t(TranslationMapper.pages.notifications.images), key: "hasPicture" },
      { label: LanguageProvider.t(TranslationMapper.pages.notifications.replies), key: "hasComments" },
      {
        label: LanguageProvider.t(TranslationMapper.pages.notifications.datecompleted),
        key: "finishedOn",
      },
    ];
  }

  public static formatNotificationLogging(
    overviewData: INotificationOverview[],
    additionalData: INotificationResponse[]
  ): INotificationsExport[] {
    const yes = LanguageProvider.t(TranslationMapper.global.yes);
    const no = LanguageProvider.t(TranslationMapper.global.no);

    const exports = overviewData.flatMap((overview) => {
      return additionalData.map((additional) => {
        if (overview.id === additional.id) {
          const newExportRow: INotificationsExport = {
            building: additional.buildingName,
            floorBuidingArea: additional.floorName,
            cleaningObjectName: overview.cleaningObjectName,
            objectReference: additional.cleaningObjectReference,
            createdOn: moment(overview.createdOn).format("DD-MM-YYYY").toString(),
            createdBy: overview.createdBy,
            notificationCategory: overview.notificationCategoryName,
            status: this.getNotificationStatusTranslation(overview.status),
            description: additional.description,
            hasPicture: additional.pictureUris && additional.pictureUris.length > 0 ? yes : no,
            hasComments: additional.comments && additional.comments.length > 0 ? yes : no,
            finishedOn: moment(overview.finishedOn).isValid()
              ? moment(overview.finishedOn).format("DD-MM-YYYY").toString()
              : "-",
          };
          return newExportRow;
        }

        return null;
      });
    });

    return exports.filter((notificationsExport) => !!notificationsExport) as INotificationsExport[];
  }

  private static getZoneFeedbackLabel(feedback?: number): string {
    const maxThresholdClean = 25;
    const maxThresholdNeutral = 50;
    const maxThresholdDirty = 75;

    const translateStatus = (status: string): string =>
      LanguageProvider.t(TranslationMapper.pages.logging.cleaningstatus[status]);

    if (!feedback) {
      return translateStatus(ZoneFeedbackLabels.none);
    }

    if (feedback <= maxThresholdClean) {
      return translateStatus(ZoneFeedbackLabels.clean);
    } else if (feedback <= maxThresholdNeutral) {
      return translateStatus(ZoneFeedbackLabels.neutral);
    } else if (feedback <= maxThresholdDirty) {
      return translateStatus(ZoneFeedbackLabels.dirty);
    } else {
      return translateStatus(ZoneFeedbackLabels.veryDirty);
    }
  }

  private static getStatusLabel(status: ActivityStatus): string {
    switch (status) {
      case ActivityStatus.Finished:
        return LanguageProvider.t(TranslationMapper.pages.activitylogging.activitystatus.finished);
      case ActivityStatus.Cancelled:
        return LanguageProvider.t(TranslationMapper.pages.activitylogging.activitystatus.cancelled);
      case ActivityStatus.Completed:
        return LanguageProvider.t(TranslationMapper.pages.activitylogging.activitystatus.completed);
      default:
        return LanguageProvider.t(TranslationMapper.pages.activitylogging.activitystatus.unknown);
    }
  }

  public static getNotificationStatusTranslation(status: NotificationStatus): string {
    switch (status) {
      case NotificationStatus.Open:
        return LanguageProvider.t(TranslationMapper.pages.notifications.statusnames.open);
      case NotificationStatus.ActionRequired:
        return LanguageProvider.t(TranslationMapper.pages.notifications.statusnames.actionrequired);
      case NotificationStatus.Rejected:
        return LanguageProvider.t(TranslationMapper.pages.notifications.statusnames.rejected);
      case NotificationStatus.SignedOff:
        return LanguageProvider.t(TranslationMapper.pages.notifications.statusnames.signedoff);
      case NotificationStatus.InProgress:
        return LanguageProvider.t(TranslationMapper.pages.notifications.statusnames.inprogress);
      default:
        return LanguageProvider.t("");
    }
  }

  private static sanitizeString(givenString: string): string {
    return givenString.replace(/[-+=@;]/g, "");
  }
}
