import React, { FunctionComponent } from "react";

import CustomCheckbox from "../checkbox/customCheckbox";
import { IReactTableData } from "./interfaces/IReactTableProps";
import IReactTableRowSelectorProps from "./interfaces/IReactTableRowSelectorProps";

export const ReactTableRowSelector: FunctionComponent<IReactTableRowSelectorProps> = props => {
  const onChange = (e: React.MouseEvent<HTMLDivElement>): void => {
    e.preventDefault();
    e.stopPropagation();

    props.onChange(rowInformation.key);
  };

  const rowInformation = props.row.original as IReactTableData;

  const isChecked = (): boolean => {
    if (props.selectedRowKey) {
      return rowInformation.key === props.selectedRowKey;
    }

    if (props.multiSelectRowKeys != null) {
      return props.multiSelectRowKeys.includes(rowInformation.key);
    }

    return false;
  };

  return <CustomCheckbox isChecked={isChecked()} className="td-checkbox" onClick={onChange} />;
};
