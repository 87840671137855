import Role from "enums/role";
import TranslationMapper from "i18n/mapper";
import * as React from "react";
import { connect } from "react-redux";
import { RootState } from "store/reducers/rootReducer";

import AsideNavItem from "./asideNavItem";
import { IAsideNavItem } from "./interfaces/IAsideNavItem";
import IAsideNavProps, { IAsideNavStateProps } from "./interfaces/IAsideNavProps";
import IAsideNavState from "./interfaces/IAsideNavState";
import links from "./links";

class Aside extends React.Component<IAsideNavProps, IAsideNavState> {
  public constructor(props: IAsideNavProps) {
    super(props);

    const state: IAsideNavState = {
      loading: false,
    };

    this.state = state;

    this.getSidebarItems = this.getSidebarItems.bind(this);
    this.isPageActive = this.isPageActive.bind(this);
    this.showItem = this.showItem.bind(this);
  }

  private isPageActive(url: string, urlsToExclude?: string[]): boolean {
    const path = window.location.pathname.trim().toLowerCase();

    const isActive = path.indexOf(url.toLocaleLowerCase()) !== -1;
    const containsNoExcludedUrl = !urlsToExclude?.find(u => path.indexOf(u.toLocaleLowerCase()) !== -1);

    return isActive && containsNoExcludedUrl;
  }

  private showItem(roles: Role[]): boolean {
    const hasRole = roles && roles.some(r => r === this.props.user.role);
    return hasRole;
  }

  private getSidebarItems(): IAsideNavItem[] {
    const items: IAsideNavItem[] = [
      {
        linkTo: links.home.planningRoutes,
        dataTestAttr: "sidebar-home",
        icon: ["fal", "house"],
        translateId: TranslationMapper.navigation.home,
        active:
          this.isPageActive(links.home.planningRoutes) ||
          this.isPageActive(links.home.planningActivities) ||
          this.isPageActive(links.home.loggingRoutes) ||
          this.isPageActive(links.home.loggingActivities) ||
          this.isPageActive(links.home.notifications),
        show: this.showItem([Role.FunctionalAdministrator, Role.Customer, Role.Employee]) && this.props.hasOperation,
        subItems: [
          {
            linkTo: links.home.planningRoutes,
            translateId: TranslationMapper.navigation.planning,
            dataTestAttr: "sidebar-home-routes",
            active: this.isPageActive(links.home.planningRoutes) || this.isPageActive(links.home.planningActivities),
            show: this.showItem([Role.FunctionalAdministrator, Role.Employee]) && this.props.hasOperation,
          },
          {
            linkTo: links.home.loggingRoutes,
            translateId: TranslationMapper.navigation.logging,
            dataTestAttr: "sidebar-home-logging",
            active: this.isPageActive(links.home.loggingRoutes) || this.isPageActive(links.home.loggingActivities),
            show:
              this.showItem([Role.FunctionalAdministrator, Role.Customer, Role.Employee]) && this.props.hasOperation,
          },
          {
            linkTo: links.home.notifications,
            translateId: TranslationMapper.navigation.notifications,
            dataTestAttr: "sidebar-home-notifications",
            active: this.isPageActive(links.home.notifications),
            show:
              this.showItem([Role.FunctionalAdministrator, Role.Customer, Role.Employee]) && this.props.hasOperation,
          },
        ],
      },
      {
        linkTo: links.customerManagement,
        dataTestAttr: "sidebar-customerManagement",
        icon: ["fal", "building"],
        translateId: TranslationMapper.navigation.customermanagement,
        active: this.isPageActive(links.customerManagement),
        show: this.showItem([Role.FunctionalAdministrator]),
      },
      {
        linkTo: links.customerData.activities,
        dataTestAttr: "sidebar-customerData",
        icon: ["fal", "user"],
        translateId: TranslationMapper.navigation.customerdata,
        active: this.isPageActive(links.customerData.activities) || this.isPageActive(links.customerData.notifications),
        show: this.showItem([Role.FunctionalAdministrator]),
        subItems: [
          {
            linkTo: links.customerData.activities,
            translateId: TranslationMapper.navigation.activities,
            dataTestAttr: "sidebar-customerData-activities",
            active: this.isPageActive(links.customerData.activities),
            show: this.showItem([Role.FunctionalAdministrator]),
          },
          {
            linkTo: links.customerData.notifications,
            translateId: TranslationMapper.navigation.incidents,
            dataTestAttr: "sidebar-customerData-activities",
            active: this.isPageActive(links.customerData.notifications),
            show: this.showItem([Role.FunctionalAdministrator]),
          },
        ],
      },
      {
        linkTo: links.masterData.activities,
        dataTestAttr: "sidebar-masterData",
        icon: ["fal", "users"],
        translateId: TranslationMapper.navigation.masterdata,
        active: this.isPageActive(links.masterData.activities) || this.isPageActive(links.masterData.notifications),
        show: this.showItem([Role.FunctionalAdministrator]),
        subItems: [
          {
            linkTo: links.masterData.activities,
            translateId: TranslationMapper.navigation.activities,
            dataTestAttr: "sidebar-masterData-activities",
            active: this.isPageActive(links.masterData.activities),
            show: this.showItem([Role.FunctionalAdministrator]),
          },
          {
            linkTo: links.masterData.notifications,
            translateId: TranslationMapper.navigation.incidents,
            dataTestAttr: "sidebar-masterData-activities",
            active: this.isPageActive(links.masterData.notifications),
            show: this.showItem([Role.FunctionalAdministrator]),
          },
        ],
      },
      {
        linkTo: links.logbook,
        dataTestAttr: "sidebar-logbook",
        icon: ["fal", "comments"],
        translateId: TranslationMapper.navigation.logbook,
        active: this.isPageActive(links.logbook),
        show:
          this.showItem([Role.FunctionalAdministrator, Role.FacilityManager, Role.Employee]) && this.props.hasLogbook,
      },
    ];

    return items;
  }

  public render(): JSX.Element {
    const sidebarItems = this.getSidebarItems();

    return (
      <aside className="aside">
        <ul className="nav nav-pills flex-column">
          {!this.state.loading &&
            sidebarItems.map(item => {
              return <AsideNavItem key={item.linkTo} sideBarItem={item} />;
            })}
        </ul>
      </aside>
    );
  }
}

const mapStateToProps = (state: RootState): IAsideNavStateProps => ({
  user: state.userState.user ?? { role: Role.None },
  hasLogbook: state.customerState.hasLogbook ?? false,
  hasOperation: state.customerState.hasOperation ?? false,
});

export default connect(mapStateToProps)(Aside);
