import { IInputChangeEvent } from "components/interfaces/IInputChangeEvent";
import { Component, ReactNode } from "react";
import { Form } from "react-bootstrap";

import IReactSelectValue from "../../../interfaces/IReactSelectValue";
import ISelectProps from "./interfaces/ISelectProps";

export default class Select extends Component<ISelectProps> {
  public constructor(props: ISelectProps) {
    super(props);

    this.onChange = this.onChange.bind(this);
  }

  public onChange(event: IInputChangeEvent<any>): void {
    if (this.props.onChange != null) {
      const value = event.target.value;

      this.props.onChange({
        target: {
          name: this.props["name"],
          value: value,
        },
      });
    }
  }

  private get options(): IReactSelectValue[] {
    return this.props.options;
  }

  private get value(): any {
    return this.props.value;
  }

  public render(): ReactNode {
    return (
      <Form.Select
        id={this.props.id}
        data-testid={this.props["testId"]}
        value={this.value}
        name={this.props.name}
        onChange={(event): void => this.onChange(event)}
      >
        {this.options.map((option, index) => {
          return (
            <option key={index} value={option.value} hidden={option.value === undefined}>
              {option.label}
            </option>
          );
        })}
      </Form.Select>
    );
  }
}
