import { IActivityStoreState, initialActivityStoreState } from "store/state/activityStoreState";

import { ActionTypes, ActionTypesCollection } from "../actionTypes";

export default function activityReducer(
  state: IActivityStoreState = initialActivityStoreState,
  action: ActionTypesCollection
): IActivityStoreState {
  switch (action.type) {
    case ActionTypes.FETCHED_CUSTOMERACTIVITIES:
      return {
        ...state,
        activityActions: action.payload.activityActionResponse,
        activityActionsTotalResultsCount: action.payload.totalResultsCount,
      };
    case ActionTypes.FETCHED_CUSTOMERACTIVITYACTION:
      return {
        ...state,
        selectedActivityAction: action.payload,
      };
    default:
      return state;
  }
}
