import Role from "enums/role";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { RootState } from "store/reducers/rootReducer";

import IAuthorizationRouteProps, {
    IAuthorizationRouteStateProps
} from "../interfaces/IAuthorizationRouteProps";

class AuthorizationRoute extends Component<IAuthorizationRouteProps> {
  private get redirect(): boolean {
    return this.props.roles && !this.props.roles.some(r => r === this.props.user.role);
  }

  public render(): JSX.Element {
    return (
      <>
        {this.redirect && <Redirect to={this.props.redirectTo} />}
        {!this.redirect && <Route exact path={this.props.path} component={this.props.component} />}
      </>
    );
  }
}

const mapStateToProps = (state: RootState): IAuthorizationRouteStateProps => ({
  user: state.userState.user ?? { role: Role.None },
});

export default connect(mapStateToProps)(AuthorizationRoute);