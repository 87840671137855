import ICustomer from "interfaces/ICustomer";

import { ApplicationConfig } from "../config";
import AppEventHub, { AppEvents } from "../utils/appEventHub";

export default class CustomerProvider {
  private static customerStorageKey: string = `bs.${ApplicationConfig.environment}.active.customer`;

  public static saveActiveCustomer(customer: ICustomer): void {
    if (!customer) {
      return;
    }

    localStorage.setItem(CustomerProvider.customerStorageKey, JSON.stringify(customer));
    AppEventHub.emit(AppEvents.CustomerSelected);
  }

  public static getActiveCustomer(): ICustomer | null {
    return CustomerProvider.getItem<ICustomer>(CustomerProvider.customerStorageKey);
  }

  private static getItem<T>(key: string): T | null {
    const json = localStorage.getItem(key);
    if (!json || json.length <= 0) {
      return null;
    }

    const customer = JSON.parse(json) as T;
    return customer;
  }

  public static clear(): void {
    localStorage.removeItem(CustomerProvider.customerStorageKey);
  }
}