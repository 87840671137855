import LoaderTypes from "../../enums/loaderTypes";
import { ActionTypes } from "../actionTypes";
import { actionCreator, AppAction } from "../appAction";

export const startLoading = actionCreator<ActionTypes.START_LOADING, LoaderTypes>(ActionTypes.START_LOADING);
export const finishLoading = actionCreator<ActionTypes.FINISH_LOADING, LoaderTypes>(ActionTypes.FINISH_LOADING);

export function setAsLoading(loader: LoaderTypes): AppAction {
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  return dispatch => {
    dispatch(startLoading(loader));
  };
}

export function unsetAsLoading(loader: LoaderTypes): AppAction {
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  return dispatch => {
    dispatch(finishLoading(loader));
  };
}