import { FilterMenu } from "components/filterMenu/filterMenu";
import IFilterValue from "components/filterMenu/interfaces/IFilterValue";
import ISingleSelectDropdownField from "components/filterMenu/interfaces/ISingleSelectDropdownField";
import PageHeader from "components/header/pageHeader";
import { IReactTableData } from "components/material/table/interfaces/IReactTableProps";
import { RoutingLinks } from "constants/routingLinks";
import LoaderTypes from "enums/loaderTypes";
import Role from "enums/role";
import RouteStatus from "enums/routeStatus";
import TranslationMapper from "i18n/mapper";
import ISimpleRouteLogging from "interfaces/ISimpleRouteLogging";
import { cloneDeep } from "lodash";
import PageHeaderManager from "models/pageHeaderManager";
import LanguageProvider from "providers/languageProvider";
import * as React from "react";
import { withTranslation } from "react-i18next";
import { NotificationManager } from "react-notifications";
import { connect } from "react-redux";
import { ValueType } from "react-select";
import { withTelemetry } from "services/telemetryService";
import { showCustomerSelection } from "store/actions/customerActions";
import {
  deleteRouteLogging,
  getCustomerRouteLogging,
  getExpandedCustomerRouteLogging,
} from "store/actions/scheduleActions";
import { RootState } from "store/reducers/rootReducer";
import { objectOrArrayPropertyResolver } from "store/storeUtils";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ILoggingOverviewProps, {
  ILoggingOverviewDispatchProps,
  ILoggingOverviewStateProps,
} from "./interfaces/ILoggingOverviewProps";
import ILoggingOverviewState from "./interfaces/ILoggingOverviewState";
import LoggingTable from "./loggingTable";
import { RoutesLoggingExport } from "./routesLoggingExport";

export type LoggingTableData = ISimpleRouteLogging & IReactTableData;

class LoggingOverview extends React.Component<ILoggingOverviewProps, ILoggingOverviewState> {
  // Names and types of the filters for component FilterMenu
  public filterNameOperator: string = "Operator";
  public filterNameStatus: string = "Status";
  public filterNameRouteName: string = "RouteName";
  public clearCurrentOption: string = "ClearCurrentOption";
  private readonly startStatusSelection: IFilterValue = {
    filterType: "Status",
    label: LanguageProvider.t(`pages.logging.statusnames.${[RouteStatus[2]]}`),
    value: "Finished",
  };

  public constructor(props: ILoggingOverviewProps) {
    super(props);

    const startDate = new Date();
    startDate.setHours(0, 0, 0, 0);

    const dateDiff = startDate.getDay() === 1 ? 7 : (startDate.getDay() + 6) % 7;
    startDate.setDate(startDate.getDate() - dateDiff);

    const endDate = new Date();
    endDate.setHours(23, 59, 59, 999);

    const state: ILoggingOverviewState = {
      endDate: endDate,
      startDate: startDate,
      selectedFilters: [],
      selectedRouteIds: [],
      loggingForExport: [],
    };

    this.state = state;

    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.changeFilter = this.changeFilter.bind(this);
    this.getCustomerLogging = this.getCustomerLogging.bind(this);
    this.onUpdateSelectedRouteIds = this.onUpdateSelectedRouteIds.bind(this);
    this.changeStatusFilter = this.changeStatusFilter.bind(this);
    this.changeOperatorFilter = this.changeOperatorFilter.bind(this);
    this.changeRouteNameFilter = this.changeRouteNameFilter.bind(this);
    this.clearSelectedRouteIds = this.clearSelectedRouteIds.bind(this);
    this.deleteLogging = this.deleteLogging.bind(this);

    this.setPageHeader();
  }

  public componentDidMount(): void {
    this.getCustomerLogging();
    this.props.showCustomerSelection(true);
    this.setPageHeader();
    this.changeStatusFilter(this.startStatusSelection);
  }

  public componentWillUnmount(): void {
    PageHeaderManager.clear();
  }

  private setPageHeader(): void {
    PageHeaderManager.clear();
    PageHeaderManager.add({
      pageName: "logging",
      tabs: [
        {
          id: "routes",
          relativeUrl: RoutingLinks.loggingRoutes,
          translationLabel: TranslationMapper.pages.logging.title,
          roles: [Role.FunctionalAdministrator, Role.Employee],
        },
        {
          id: "activities",
          relativeUrl: RoutingLinks.loggingActivities,
          translationLabel: TranslationMapper.pages.activitylogging.title,
          roles: [Role.FunctionalAdministrator, Role.Customer, Role.Employee],
        },
      ],
    });
  }

  private get filteredLoggings(): LoggingTableData[] {
    let filteredLoggings = cloneDeep(this.props.loggings);
    this.state.selectedFilters.forEach(filter => {
      switch (filter.filterType) {
        case this.filterNameOperator:
          filteredLoggings = filteredLoggings.filter(logging => logging.operatorName === filter.value);
          break;
        case this.filterNameStatus:
          filteredLoggings = filteredLoggings.filter(
            logging => RouteStatus[logging.routeStatus].toLowerCase() === filter.value.toLowerCase()
          );
          break;
        case this.filterNameRouteName:
          filteredLoggings = filteredLoggings.filter(logging => logging.routeName === filter.value);
          break;
        default:
          break;
      }
    });

    return filteredLoggings.map(fl => {
      const logging: LoggingTableData = {
        ...fl,
        key: fl.routeDataId,
      };

      return logging;
    });
  }

  public getCustomerLogging(): void {
    if (!this.props.selectedCustomerId) {
      NotificationManager.error(LanguageProvider.t(TranslationMapper.pages.notifications.errormessageid));
      return;
    }

    this.props.getCustomerRouteLogging(
      this.props.selectedCustomerId,
      this.state.startDate.toISOString(),
      this.state.endDate.toISOString()
    );
  }

  private get dropdownFields(): ISingleSelectDropdownField[] {
    const distinct = (value, index, self): boolean => {
      return self.indexOf(value) === index;
    };

    const filterOperatorSelectOptions = this.props.loggings.map(logging => logging.operatorName).filter(distinct);
    const dropdownListOperator = filterOperatorSelectOptions.map(option => ({
      value: option,
      label: option,
      filterType: this.filterNameOperator,
    }));

    const filterRouteNameSelectOptions = this.props.loggings.map(logging => logging.routeName).filter(distinct);
    const dropdownListRouteName = filterRouteNameSelectOptions.map(option => ({
      value: option,
      label: option,
      filterType: this.filterNameRouteName,
    }));

    const filterStatusSelectOptions = this.props.loggings.map(logging => logging.routeStatus).filter(distinct);
    const dropdownListStatus = filterStatusSelectOptions.map(option => ({
      value: RouteStatus[option],
      label: LanguageProvider.t(`pages.logging.statusnames.${[RouteStatus[option]]}`),
      filterType: this.filterNameStatus,
    }));

    return [
      {
        filterName: LanguageProvider.t(TranslationMapper.pages.logging.filternames.status),
        filterType: this.filterNameStatus,
        filterList: dropdownListStatus,
        onSelect: this.changeStatusFilter,
        defaultValue: this.startStatusSelection,
      },
      {
        filterName: LanguageProvider.t(TranslationMapper.pages.logging.filternames.routename),
        filterType: this.filterNameRouteName,
        filterList: dropdownListRouteName,
        onSelect: this.changeRouteNameFilter,
      },
      {
        filterName: LanguageProvider.t(TranslationMapper.pages.logging.filternames.operator),
        filterType: this.filterNameOperator,
        filterList: dropdownListOperator,
        onSelect: this.changeOperatorFilter,
      },
    ];
  }

  private onUpdateSelectedRouteIds(routeIds: string[]): void {
    this.setState({
      selectedRouteIds: routeIds,
    });
  }

  private clearSelectedRouteIds(): void {
    this.setState({
      selectedRouteIds: [],
    });
  }

  private changeStatusFilter(optionSelected: ValueType<IFilterValue, boolean>): void {
    let item: IFilterValue;
    if (optionSelected == null || optionSelected === undefined) {
      item = {
        filterType: this.filterNameStatus,
        label: "",
        value: this.clearCurrentOption,
      };
    } else {
      item = optionSelected as IFilterValue;
    }

    this.changeFilter(item);
  }

  private changeRouteNameFilter(optionSelected: ValueType<IFilterValue, boolean>): void {
    let item: IFilterValue;
    if (optionSelected == null || optionSelected === undefined) {
      item = {
        filterType: this.filterNameRouteName,
        label: "",
        value: this.clearCurrentOption,
      };
    } else {
      item = optionSelected as IFilterValue;
    }

    this.changeFilter(item);
  }

  private changeOperatorFilter(optionSelected: ValueType<IFilterValue, boolean>): void {
    let item: IFilterValue;
    if (optionSelected == null || optionSelected === undefined) {
      item = {
        filterType: this.filterNameOperator,
        label: "",
        value: this.clearCurrentOption,
      };
    } else {
      item = optionSelected as IFilterValue;
    }

    this.changeFilter(item);
  }

  private changeFilter(optionSelected: IFilterValue): void {
    this.clearSelectedRouteIds();
    const filterType = optionSelected.filterType;

    const currentActiveFilters = this.state.selectedFilters;
    const newActiveFilters = currentActiveFilters.filter(filter => filter.filterType !== filterType);

    if (optionSelected.value === this.clearCurrentOption) {
      newActiveFilters.filter(option => option.filterType === optionSelected.filterType);
    } else if (optionSelected) {
      newActiveFilters.push(optionSelected);
    }

    this.setState({
      selectedFilters: newActiveFilters,
    });
  }

  private handleStartDateChange(state: Date): void {
    this.setState(
      {
        startDate: state,
      },
      () => {
        this.handleDateChange();
      }
    );
  }

  private handleEndDateChange(state: Date): void {
    this.setState(
      {
        endDate: state,
      },
      () => {
        this.handleDateChange();
      }
    );
  }

  private handleDateChange(): void {
    this.getCustomerLogging();
    this.clearSelectedRouteIds();
  }

  private deleteLogging(): void {
    if (!this.props.activeCustomer) {
      return;
    }

    const confirmation = window.confirm(
      LanguageProvider.t(TranslationMapper.pages.logging.deleteconfirmationfirstpart) +
        `${this.state.selectedRouteIds.length}` +
        LanguageProvider.t(TranslationMapper.pages.logging.deleteconfirmationsecondpart)
    );

    if (!confirmation) {
      return;
    }

    const idsToDelete = this.state.selectedRouteIds;
    this.props.deleteRouteLogging(idsToDelete);

    this.props.getCustomerRouteLogging(
      this.props.activeCustomer.id,
      this.state.startDate.toISOString(),
      this.state.endDate.toISOString()
    );

    this.setState({ selectedRouteIds: [] });
  }

  private getRecordIds(): string[] {
    if (this.state.selectedRouteIds.length > 0) {
      return this.state.selectedRouteIds;
    } else {
      return this.filteredLoggings.map(logging => logging.routeDataId);
    }
  }

  public render(): JSX.Element {
    return (
      <>
        <header className="header-actions">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 header-actions__content">
                <h1>{this.props.activeCustomer?.name}</h1>
                <div className="header-actions__buttons">
                  <RoutesLoggingExport
                    recordIds={this.getRecordIds()}
                    logging={this.props.loggings}
                    startDate={this.state.startDate.toString()}
                    endDate={this.state.endDate.toString()}
                  />
                  {this.props.hasDeletePermission && (
                    <button
                      className="btn btn-primary btn--rounded"
                      disabled={!this.state.selectedRouteIds.length}
                      onClick={this.deleteLogging}
                    >
                      <FontAwesomeIcon icon={["fal", "trash"]} fixedWidth />
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </header>

        <PageHeader pageName="logging" />

        <FilterMenu
          startDate={this.state.startDate}
          endDate={this.state.endDate}
          handleEndDateChange={this.handleEndDateChange}
          handleStartDateChange={this.handleStartDateChange}
          filterFields={this.dropdownFields}
        />

        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 mb-2 block-content--px">
                <LoggingTable
                  isLoading={this.props.isLoading}
                  onUpdateSelectedRouteIds={this.onUpdateSelectedRouteIds}
                  selectedRouteIds={this.state.selectedRouteIds}
                  tableData={this.filteredLoggings}
                  onGetExpandedCustomerRouteLogging={this.props.getExpandedCustomerRouteLogging}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

/*
 * the resolver is defined outside the mapStateToProps function because
 *  it needs to memorize the routeLogging array so it can detect if a
 *  new version of the routeLogging property value is different than the
 *  memorized one
 */
const routeLoggingResolver = objectOrArrayPropertyResolver<ISimpleRouteLogging[]>();

const mapStateToProps = (state: RootState): ILoggingOverviewStateProps => {
  const routeLogging = routeLoggingResolver(state.scheduleManagementState.customerRouteLogging ?? []);
  return {
    activeCustomer: state.customerState.customers.find(c => c.id === state.customerState.selectedCustomerId),
    selectedCustomerId: state.customerState.selectedCustomerId,
    hasDeletePermission: state.userState.hasRouteLoggingDeletePermission ?? false,
    isLoading: state.generalState.loaders.some(l => l === LoaderTypes.RouteLogging),
    loggings: routeLogging,
    user: state.userState.user ?? { role: Role.None },
  };
};

const mapDispatchToProps: ILoggingOverviewDispatchProps = {
  deleteRouteLogging: deleteRouteLogging,
  getCustomerRouteLogging: getCustomerRouteLogging,
  getExpandedCustomerRouteLogging: getExpandedCustomerRouteLogging,
  showCustomerSelection: showCustomerSelection,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withTelemetry(LoggingOverview, "LoggingOverview")));
