import IExternalVenueProps, {
  IExternalVenueComponentProps,
  IExternalVenueDispatchProps,
  IExternalVenueStateProps,
} from "../interfaces/IExternalVenueProps";
import { createFloor, uploadFile } from "store/actions/externalVenueActions";

import BuildingObjectModal from "./buildingObjectModal";
import { Component } from "react";
import CsvUploadModal from "./csvUploadModal";
import ExternalVenue from "models/externalVenue";
import Floor from "models/floor";
import FloorDetails from "./floorDetails";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IBuildingObject from "../interfaces/IBuildingObject";
import IExternalVenueState from "../interfaces/IExternalVenueState";
import IFile from "interfaces/IFile";
import LanguageProvider from "providers/languageProvider";
import Loader from "components/loader/loader";
import LoaderTypes from "enums/loaderTypes";
import { RootState } from "store/reducers/rootReducer";
import TranslationMapper from "i18n/mapper";
import { connect } from "react-redux";
import { withRouter } from "react-router";

class ExternalVenueDetails extends Component<IExternalVenueProps, IExternalVenueState> {
  public constructor(props: IExternalVenueProps) {
    super(props);

    const state: IExternalVenueState = {
      isBuildingObjectModalOpen: false,
      isCsvUploadModalOpen: false,
    };

    this.openCreateFloorModal = this.openCreateFloorModal.bind(this);
    this.openUploadCsvModal = this.openUploadCsvModal.bind(this);
    this.createFloor = this.createFloor.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.uploadFile = this.uploadFile.bind(this);

    this.state = state;
  }

  private openCreateFloorModal(): void {
    this.setState({
      isBuildingObjectModalOpen: true,
      isCreate: true,
    });
  }

  private openUploadCsvModal(): void {
    this.setState({
      isCsvUploadModalOpen: true,
    });
  }

  private createFloor(buildingObject: IBuildingObject): void {
    if (!this.props.venue || this.props.venue.id === "" || !buildingObject || !buildingObject.nameValue) {
      return;
    }

    const floor = new Floor();
    floor.name = buildingObject.nameValue;
    floor.nfcTagCode = buildingObject.nfcTagValue;
    floor.venueId = this.props.venue.id;
    this.props.onCreateFloor(this.props.customerId, this.props.venue.id, floor);
    this.handleClose();
  }

  private uploadFile(file: IFile): void {
    if (!this.props.venue || this.props.venue.id === "" || !file || file.wrongFormat) {
      return;
    }

    this.props.onUploadFile(this.props.customerId, this.props.venue.id, file);
  }

  private handleClose(): void {
    this.setState({
      isBuildingObjectModalOpen: false,
      isCsvUploadModalOpen: false,
      isCreate: undefined,
    });
  }

  private get buildingObject(): IBuildingObject {
    return {
      nameInputTitle: LanguageProvider.t(TranslationMapper.pages.externalvenue.name_floor),
      nameValue: "",
      nfcTagValue: "",
      isZoneInput: false,
      minLength: 1,
      IsFloorNFCRequired: this.props.isFloorNFCRequired,
    };
  }

  private renderFloors(): JSX.Element {
    return (
      <table className="table table--bordered">
        <thead>
          <tr>
            <th>{LanguageProvider.t(TranslationMapper.pages.externalvenue.details.name)}</th>
            <th>{LanguageProvider.t(TranslationMapper.pages.externalvenue.details.zones_amount)}</th>
            <th>{LanguageProvider.t(TranslationMapper.pages.externalvenue.details.nfctag_code)}</th>
            <th></th>
          </tr>
        </thead>
        {this.props.venue.floors.map((floor) => {
          return (
            <>
              <tbody>
                <FloorDetails
                  customerId={this.props.customerId}
                  floor={floor}
                  key={floor.id}
                  disableActions={!this.props.activateEditButtons}
                  isFloorNFCRequired={this.props.isFloorNFCRequired}
                />
              </tbody>

              <tr className="tr-spacer">
                <td colSpan={4}></td>
              </tr>
            </>
          );
        })}
      </table>
    );
  }

  public render(): JSX.Element {
    return (
      <>
        <div className="d-flex justify-content-between">
          <h2>{LanguageProvider.t(TranslationMapper.pages.externalvenue.floors_zones)}</h2>

          <div className="header-actions__buttons">
            <button
              className="btn btn-primary btn--rounded"
              disabled={!this.props.activateEditButtons}
              onClick={this.props.activateEditButtons ? this.openUploadCsvModal : undefined}
            >
              <FontAwesomeIcon icon={["fal", "arrow-up-to-line"]} fixedWidth />
            </button>
            <button
              className="btn btn-primary btn--rounded"
              disabled={!this.props.activateEditButtons}
              onClick={this.props.activateEditButtons ? this.openCreateFloorModal : undefined}
            >
              <FontAwesomeIcon icon={["fal", "plus"]} fixedWidth />
            </button>
          </div>
        </div>
        {this.props.isLoading && (
          <div className="row justify-content-center">
            <Loader isLoading={this.props.isLoading} />
          </div>
        )}
        {(!this.props.isLoading && this.props.venue && this.props.venue.floors.length > 0 && this.renderFloors()) ||
          (!this.props.isLoading && (
            <div className="row justify-content-center">
              {LanguageProvider.t(TranslationMapper.pages.externalvenue.no_data_found)}
            </div>
          ))}
        {this.state.isBuildingObjectModalOpen && (
          <BuildingObjectModal
            modalTitle={TranslationMapper.pages.externalvenue.create_floor}
            buildingObject={this.buildingObject}
            onClose={this.handleClose}
            onSubmit={this.createFloor}
          />
        )}
        {this.state.isCsvUploadModalOpen && <CsvUploadModal onClose={this.handleClose} onSubmit={this.uploadFile} />}
      </>
    );
  }
}

const mapStateToProps = (state: RootState, props: IExternalVenueComponentProps): IExternalVenueStateProps => ({
  venue: state.locationState.externalVenues.find((e) => e.id === props.venueId) ?? new ExternalVenue(),
  isLoading: state.generalState.loaders.some((l) => l === LoaderTypes.ExternalLocation),
});

const mapDispatchToProps: IExternalVenueDispatchProps = {
  onCreateFloor: createFloor,
  onUploadFile: uploadFile,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ExternalVenueDetails));
