import IFilterValue from "components/filterMenu/interfaces/IFilterValue";
import IMultiSelectDropdownField from "components/filterMenu/interfaces/IMultiSelectDropdownField";
import ISearchField from "components/filterMenu/interfaces/ISearchField";
import TranslationMapper from "i18n/mapper";
import IFilterOption from "interfaces/IFilterOption";
import LanguageProvider from "providers/languageProvider";

export default class FilterUtils {
  public static createSearchFilter(filterName: string, onKeywordChange: (keyword: string) => void): ISearchField {
    const searchFilter: ISearchField = {
      filterList: [],
      filterName: filterName,
      onKeywordChange: (keyword): void => onKeywordChange(keyword),
    };

    return searchFilter;
  }

  public static createMultiSelect(
    options: IFilterOption[],
    dropdownName: string,
    onSelect: (options: IFilterValue[]) => void,
    selected?: IFilterValue[]
  ): IMultiSelectDropdownField {
    const filterOptions = options.map(option => ({
      value: option.value,
      label:
        option.label != null && option.label.trim() !== ""
          ? option.label
          : LanguageProvider.t(TranslationMapper.searchable_multiselect.emptystringlabel),
    }));

    const key = "value";
    const distinctOptions = [...new Map(filterOptions.map(item => [item[key], item])).values()];

    const multiSelect: IMultiSelectDropdownField = {
      filterName: dropdownName,
      filterList: distinctOptions,
      selected: selected,
      onSelectMulti: (selectOptions): void => onSelect(selectOptions),
    };
    return multiSelect;
  }

  public static getMultiSelectLocalisations(
    multiSelectName: string,
    selectedCount?: number
  ): { [key: string]: string } {
    return {
      allItemsAreSelected: `${selectedCount} ${LanguageProvider.t(TranslationMapper.searchable_multiselect.selected)}`,
      clearSearch: LanguageProvider.t(TranslationMapper.searchable_multiselect.clearsearch),
      noOptions: LanguageProvider.t(TranslationMapper.searchable_multiselect.no_options),
      search: LanguageProvider.t(TranslationMapper.searchable_multiselect.search),
      selectAll: LanguageProvider.t(TranslationMapper.searchable_multiselect.selectall),
      selectAllFiltered: LanguageProvider.t(TranslationMapper.searchable_multiselect.selectallfiltered),
      selectSomeItems: multiSelectName,
    };
  }
}
