import { ICustomerStoreState, initialCustomerStoreState } from "store/state/customerStoreState";

import { ActionTypes, ActionTypesCollection } from "../actionTypes";

export default function customerReducer(
  state: ICustomerStoreState = initialCustomerStoreState,
  action: ActionTypesCollection
): ICustomerStoreState {
  switch (action.type) {
    case ActionTypes.FETCHED_CUSTOMERS:
      return {
        ...state,
        customers: action.payload,
      };
    case ActionTypes.FETCHED_CUSTOMER_PROSPECTS:
      return {
        ...state,
        customerProspects: action.payload,
      };
    case ActionTypes.FETCHED_CUSTOMER_LOCATIONS:
      return {
        ...state,
        customerLocations: action.payload,
      };
    case ActionTypes.FETCHED_CUSTOMER_ACTIVITYTYPES:
      return {
        ...state,
        customerActivities: action.payload,
      };
    case ActionTypes.FETCHED_CUSTOMER_LOCATION_OVERVIEW:
      return {
        ...state,
        customerLocationOverview: action.payload,
      };
    case ActionTypes.FETCHED_CUSTOMER_NOTIFICATION_CATEGORIES:
      return {
        ...state,
        customerNotificationCategories: action.payload,
      };
    case ActionTypes.UPDATE_HAS_LOGBOOK:
      return {
        ...state,
        hasLogbook: action.payload,
      };
    case ActionTypes.UPDATE_HAS_OPERATION:
      return {
        ...state,
        hasOperation: action.payload,
      };
    case ActionTypes.UPDATE_SELECTED_CUSTOMER:
      return {
        ...state,
        selectedCustomerId: action.payload,
      };
    case ActionTypes.SHOW_CUSTOMER_SELECTION:
      return {
        ...state,
        showCustomerSelection: action.payload,
      };
    case ActionTypes.FETCHED_CUSTOMER_NOTIFICATION_CONTACTS: {
      return {
        ...state,
        customerNotificationCategoryContacts: action.payload,
      };
    }
    default:
      return state;
  }
}
