import Loader from "components/loader/loader";
import LoaderTypes from "enums/loaderTypes";
import { TranslationMapper } from "i18n/mapper";
import LanguageProvider from "providers/languageProvider";
import { Component } from "react";
import { connect } from "react-redux";
import {
  deleteNotificationCategory,
  getNotificationCategories,
  getNotificationCategory,
  resetNotificationCategory,
  upsertNotificationCategory,
} from "store/actions/masterDataActions";
import { RootState } from "store/reducers/rootReducer";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import NotificationCategoryItem from "../../../../components/categories/notificationCategoryItem";
import CleaningCategory from "../../../../enums/cleaningCategory";
import INotificationCategory from "../../../../interfaces/INotificationCategory";
import INotificationCategoryPost from "../../../../interfaces/INotificationCategoryPost";
import ImageUriProvider from "../../../../providers/imageUriProvider";
import { showCustomerSelection } from "../../../../store/actions/customerActions";
import INotificationCategoryMasterDataProps, {
  INotificationCategoryMasterdataDispatchProps,
  INotificationCategoryMasterdataStateProps,
} from "./interfaces/INotificationCategoryMasterDataProps";
import INotificationCategoryMasterDataState from "./interfaces/INotificationCategoryMasterDataState";
import NotificationCategoryModal from "./notificationCategoryModal";

class NotificationCategoryMasterData extends Component<
  INotificationCategoryMasterDataProps,
  INotificationCategoryMasterDataState
> {
  public constructor(props: INotificationCategoryMasterDataProps) {
    super(props);

    const state: INotificationCategoryMasterDataState = {
      loading: false,
      dialogOpen: false,
    };

    this.state = state;

    this.createNotificationCategory = this.createNotificationCategory.bind(this);
    this.editNotificationCategory = this.editNotificationCategory.bind(this);
    this.deleteNotificationCategory = this.deleteNotificationCategory.bind(this);
    this.selectNotificationCategory = this.selectNotificationCategory.bind(this);
    this.upsertNotificationCategory = this.upsertNotificationCategory.bind(this);
    this.onModalClose = this.onModalClose.bind(this);

    this.props.getNotificationCategories();
    this.props.reset();
  }

  public async componentDidMount(): Promise<void> {
    this.props.showCustomerSelection(false);
  }

  private createNotificationCategory(): void {
    this.props.reset();

    this.setState({
      dialogOpen: true,
    });
  }

  private async editNotificationCategory(): Promise<void> {
    if (!this.props.selectedNotificationCategory) {
      return;
    }

    this.setState({
      dialogOpen: true,
    });
  }

  private async deleteNotificationCategory(): Promise<void> {
    if (!this.props.selectedNotificationCategory) {
      return;
    }

    const confirmation = window.confirm(`${LanguageProvider.t(TranslationMapper.pages.masterdata.deleteconfirmation)}
            ${this.props.selectedNotificationCategory?.name}`);

    if (!confirmation) {
      return;
    }

    this.props.onDelete(this.props.selectedNotificationCategory.id);
  }

  private onModalClose(): void {
    this.setState({
      dialogOpen: false,
    });
  }

  private selectNotificationCategory(notificationCategory: INotificationCategory): void {
    this.props.get(notificationCategory.id);
  }

  private async upsertNotificationCategory(notificationCategory: INotificationCategoryPost): Promise<void> {
    this.props.onUpsert(notificationCategory);
  }

  public render(): JSX.Element {
    const selectedItems = this.props.selectedNotificationCategory ? true : false;
    const incidentCategoryItems = this.props.notificationCategories.map((cn: INotificationCategory) => {
      const active = cn.id === this.props.selectedNotificationCategory?.id;
      const selectedString = ImageUriProvider.getActiveImageUri(CleaningCategory.NotificationCategory, active);
      const imageUri = `${cn.imageUri}${selectedString}`;
      return (
        <NotificationCategoryItem
          selectingDisabled={false}
          notificationCategory={cn}
          active={active}
          key={cn.id}
          onItemClick={this.selectNotificationCategory}
          name={cn.name}
          imageUri={imageUri}
        />
      );
    });

    return (
      <>
        <header className="header-actions">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 header-actions__content">
                <div>
                  <h1>{LanguageProvider.t(TranslationMapper.masterdata.incidents)}</h1>
                  <h4>{LanguageProvider.t(TranslationMapper.pages.masterdata.notificationcategories.title)}</h4>
                </div>
                <div className="header-actions__buttons">
                  <button
                    className="btn btn-primary btn--rounded"
                    disabled={!selectedItems}
                    onClick={this.deleteNotificationCategory}
                  >
                    <FontAwesomeIcon icon={["fal", "trash"]} fixedWidth />
                  </button>
                  <button
                    className="btn btn-primary btn--rounded"
                    disabled={!selectedItems}
                    onClick={this.editNotificationCategory}
                  >
                    <FontAwesomeIcon icon={["fal", "pen"]} fixedWidth />
                  </button>
                  <button className="btn btn-primary btn--rounded" onClick={this.createNotificationCategory}>
                    <FontAwesomeIcon icon={["fal", "plus"]} fixedWidth />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </header>

        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 mb-2 block-content--px block-content--py">
                {this.props.isLoading && (
                  <div className="d-flex justify-content-center">
                    <Loader isLoading={this.props.isLoading} />
                  </div>
                )}
                {!this.props.isLoading && this.props.notificationCategories?.length > 0 && (
                  <div className="row row--btn-image-label">{incidentCategoryItems}</div>
                )}
              </div>
            </div>
          </div>
        </div>

        {this.state.dialogOpen && (
          <NotificationCategoryModal
            value={this.props.selectedNotificationCategory}
            onSave={this.upsertNotificationCategory}
            onClose={this.onModalClose}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state: RootState): INotificationCategoryMasterdataStateProps => ({
  isLoading: state.generalState.loaders.some(l => l === LoaderTypes.NotificationCategory),
  notificationCategories: state.notificationState.notificationCategories ?? [],
  selectedNotificationCategory: state.notificationState.selectedNotificationCategory,
});

const mapDispatchToProps: INotificationCategoryMasterdataDispatchProps = {
  getNotificationCategories: getNotificationCategories,
  onDelete: deleteNotificationCategory,
  onUpsert: upsertNotificationCategory,
  get: getNotificationCategory,
  reset: resetNotificationCategory,
  showCustomerSelection: showCustomerSelection,
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationCategoryMasterData);
