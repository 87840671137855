import { Button } from "components/material/buttons/buttons";
import { Guid } from "guid-typescript";
import TranslationMapper from "i18n/mapper";
import IReactSelectValue from "interfaces/IReactSelectValue";
import LanguageProvider from "providers/languageProvider";
import { Component, FormEvent, ReactNode } from "react";
import { Col, Form, Row } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { IInputChangeEvent } from "../../../../components/interfaces/IInputChangeEvent";
import CheckBox from "../../../../components/material/checkbox/checkbox";
import Select from "../../../../components/material/select/select";
import IWizardStep from "../../../../components/wizard/interfaces/IWizardStep";
import FeedbackRequestType from "../../../../enums/feedbackRequestType";
import IActivityFeedbackAnswerOption from "../../../../interfaces/IActivityFeedbackAnswerOption";
import IActivityFeedbackItem from "../../../../interfaces/IActivityFeedbackItem";
import ActivityValidator from "./activityValidator";
import IActivityFeedbackStepProps from "./interfaces/IActivityFeedbackStepProps";
import IActivityFeedbackStepState from "./interfaces/IActivityFeedbackStepState";

class ActivityFeedbackStep extends Component<IActivityFeedbackStepProps, IActivityFeedbackStepState> {
  public constructor(props: IActivityFeedbackStepProps) {
    super(props);

    const state = this.props.value?.feedbackRequests
      ? { feedbackRequests: this.props.value.feedbackRequests }
      : {
          feedbackRequests: [],
        };

    this.state = state;

    this.onChange = this.onChange.bind(this);
    this.propagateChange = this.propagateChange.bind(this);
    this.createFeedback = this.createFeedback.bind(this);
    this.deleteFeedback = this.deleteFeedback.bind(this);

    this.onChangeFeedbackRequest = this.onChangeFeedbackRequest.bind(this);
    this.singleSelectAnswerSelected = this.singleSelectAnswerSelected.bind(this);
    this.addAnswerOption = this.addAnswerOption.bind(this);
    this.findFeedbackItem = this.findFeedbackItem.bind(this);

    this.propagateChange();
  }

  public onChange(event: IInputChangeEvent<any>): void {
    const name = event.target.name;
    const value = event.target.value;

    this.setState(current => ({ ...current, [name]: value }), this.propagateChange);
  }

  private propagateChange(): void {
    const isValid = ActivityValidator.isFeedbackValid(this.state);
    this.props.onChange({ target: { value: this.state, name: this.props.name }, isValid });
  }

  private createFeedback(): void {
    let feedback = this.props.value?.feedbackRequests;
    if (!feedback) {
      feedback = [];
    }
    feedback?.push({
      id: Guid.createEmpty().toString(),
      request: "",
      requestType: 0,
      isActive: true,
      required: false,
      whenFinished: true,
      whenCancelled: true,
      feedbackRequestAnswerOptions: [],
    });

    this.setState(
      {
        feedbackRequests: feedback,
      },
      this.propagateChange
    );
  }

  private deleteFeedback(index: number): void {
    const feedback = this.props.value?.feedbackRequests;
    if (!feedback) {
      return;
    }

    if (index === -1) {
      return;
    }
    if (feedback[index].id === Guid.EMPTY) {
      feedback.splice(index, 1);
    } else {
      feedback[index].isActive = false;
    }

    this.setState(
      {
        feedbackRequests: feedback,
      },
      this.propagateChange
    );
  }

  private findFeedbackItem(index: number): IActivityFeedbackItem | null {
    const feedback = this.props.value?.feedbackRequests;
    if (!feedback) {
      return null;
    }

    return feedback[index];
  }

  private onChangeFeedbackRequest(event: IInputChangeEvent<any>, index: number, propertyName: string): void {
    const value = event.target.value;

    const feedbackItem = this.findFeedbackItem(index);
    if (!feedbackItem) {
      return;
    }
    feedbackItem[propertyName] = value;

    this.setState(
      current => ({ ...current, feedbackRequests: this.props.value?.feedbackRequests ?? [] }),
      this.propagateChange
    );
  }

  private singleSelectAnswerSelected(index: number): boolean {
    const feedback = this.state.feedbackRequests;
    if (!feedback) {
      return false;
    }
    // request type is never of type FeedbackRequestType:
    // eslint-disable-next-line
    return feedback[index].requestType == FeedbackRequestType["pages.masterdata.activities.single_select_pick_list"];
  }

  private addAnswerOption(index: number): void {
    const feedbackItem = this.findFeedbackItem(index);
    if (!feedbackItem) {
      return;
    }

    if (feedbackItem.feedbackRequestAnswerOptions.some(a => a.answer === "")) {
      return;
    }

    feedbackItem.feedbackRequestAnswerOptions.push({
      id: Guid.createEmpty().toString(),
      answer: "",
      feedbackQuestionId: Guid.createEmpty().toString(),
    });
    this.setState(
      current => ({ ...current, feedbackRequests: this.props.value?.feedbackRequests ?? [] }),
      this.propagateChange
    );
  }

  private onChangeAnswerOption(
    answerOption: IActivityFeedbackAnswerOption,
    event: IInputChangeEvent<any>,
    index: number
  ): void {
    const value = event.target.value;

    const feedbackItem = this.findFeedbackItem(index);
    if (!feedbackItem) {
      return;
    }

    const answerOptionIndex = feedbackItem.feedbackRequestAnswerOptions.indexOf(answerOption);
    feedbackItem.feedbackRequestAnswerOptions[answerOptionIndex].answer = value;

    this.setState(
      current => ({ ...current, feedbackRequests: this.props.value?.feedbackRequests ?? [] }),
      this.propagateChange
    );
  }

  private deleteAnswerOption(answerOption: IActivityFeedbackAnswerOption, index: number): void {
    const feedbackItem = this.findFeedbackItem(index);
    if (!feedbackItem) {
      return;
    }

    const answerOptionIndex = feedbackItem.feedbackRequestAnswerOptions.indexOf(answerOption);
    feedbackItem.feedbackRequestAnswerOptions.splice(answerOptionIndex, 1);
    this.setState(
      current => ({ ...current, feedbackRequests: this.props.value?.feedbackRequests ?? [] }),
      this.propagateChange
    );
  }

  private onToggle(event: FormEvent<HTMLDivElement>): void {
    const allToggleEl = document.querySelectorAll('[id*="feedbacktoggle_selected_"]');
    allToggleEl.forEach(t => {
      if (t.id === event.currentTarget.id && !t.className?.includes("expanded")) {
        t.className = "expanded";
      } else {
        t.className = "";
      }
    });
  }

  private get feedbackOptions(): IReactSelectValue[] {
    return this.feedbackRequestTypes.map(f => ({
      value: f.id,
      label: f.name as string,
    }));
  }

  private get feedbackRequestTypes(): { id: number; name: string | FeedbackRequestType }[] {
    return Object.entries(FeedbackRequestType)
      .filter(entry => !isNaN(entry[0] as any))
      .map(entry => ({ id: Number.parseInt(entry[0]), name: LanguageProvider.t(entry[1] as string) }));
  }

  private getFeedbackCheckoutLabel(translationKey: string, success: boolean): JSX.Element {
    const translation = LanguageProvider.t(TranslationMapper.pages.masterdata.activities[translationKey]);
    const replaceUrlKeyWord = "{replaceImage}";
    const translationSplit = translation.split(replaceUrlKeyWord);
    return (
      <>
        {translationSplit[0]}
        {success && <FontAwesomeIcon icon={["fas", "circle-check"]} fixedWidth size="lg" className="text-success" />}
        {!success && <FontAwesomeIcon icon={["fas", "circle-xmark"]} fixedWidth size="lg" className="text-warning" />}
        {translationSplit[1] ?? ""}
      </>
    );
  }

  private renderActivityFeedbackAnswerOptions(feedback: IActivityFeedbackItem, index: number): JSX.Element {
    return (
      <>
        <div className="mb-2">
          {feedback.feedbackRequestAnswerOptions.map((answerOption, answerIndex) => {
            return (
              <div
                className="d-flex justify-content-between align-items-center"
                key={`answer-option-${index}-${answerIndex}`}
              >
                <div className="activity-feedback-step__counter">{answerIndex + 1}.</div>
                <Form.Control
                  className="form-control--no-border"
                  name={answerOption.answer}
                  placeholder={LanguageProvider.t(
                    TranslationMapper.pages.masterdata.activities.addansweroptionplaceholder
                  )}
                  onChange={(event): void => this.onChangeAnswerOption(answerOption, event, index)}
                  value={answerOption.answer}
                />

                <button
                  type="button"
                  className="btn btn-link ms-2 pe-0"
                  onClick={(): void => this.deleteAnswerOption(answerOption, index)}
                >
                  <FontAwesomeIcon icon={["fal", "trash"]} fixedWidth size="lg" />
                </button>
              </div>
            );
          })}
        </div>
        <button className="btn btn-link btn--element-end ps-0" onClick={(): void => this.addAnswerOption(index)}>
          {LanguageProvider.t(TranslationMapper.pages.masterdata.activities.addansweroption)}
          <FontAwesomeIcon icon={["fal", "circle-plus"]} fixedWidth size="lg" />
        </button>
      </>
    );
  }

  private renderActivityFeedback(feedback: IActivityFeedbackItem, index: number): JSX.Element {
    return (
      <div className="accordion" id="activity-feedback-step" key={index}>
        <div className="accordion-item">
          <h2 className="accordion-header" id={`activity-feedback-step-heading-${index}`}>
            <button
              className="accordion-button accordion-button--has-icon"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#activity-feedback-step-${index}`}
              aria-expanded="false"
              aria-controls={`activity-feedback-step-${index}`}
            >
              <h2 className="mb-0">
                {LanguageProvider.t(TranslationMapper.pages.masterdata.activities.feedbackrowtitle)} {index + 1}.
              </h2>
              <div className="btn btn-link accordion--btn-action" onClick={(): void => this.deleteFeedback(index)}>
                <FontAwesomeIcon icon={["fal", "trash"]} fixedWidth size="lg" />
              </div>
            </button>
          </h2>
          <div
            id={`activity-feedback-step-${index}`}
            className="accordion-collapse collapse show"
            aria-labelledby={`activity-feedback-step-heading-${index}`}
            data-bs-parent="#activity-feedback-step"
          >
            <div className="accordion-body">
              <div className="container">
                <Row>
                  <Col className="modal__col-mb">
                    <Form.Group>
                      <Form.Label>
                        {LanguageProvider.t(TranslationMapper.pages.masterdata.activities.feedbackrowquestion)}
                      </Form.Label>
                      <Form.Control
                        id={"activity-feedback-request" + index}
                        name={"feedback-question-" + index}
                        onChange={(event): void => this.onChangeFeedbackRequest(event, index, "request")}
                        value={feedback.request}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col className="modal__col-mb">
                    <Form.Group>
                      <Form.Label>
                        {LanguageProvider.t(TranslationMapper.pages.masterdata.activities.feedbackrowanswertype)}
                      </Form.Label>
                      <Select
                        id={`activity-feedback-request-type-${index}`}
                        value={feedback.requestType}
                        name={`feedback-requesttype-${index}`}
                        onChange={(event): void => this.onChangeFeedbackRequest(event, index, "requestType")}
                        options={this.feedbackOptions}
                      ></Select>
                    </Form.Group>
                  </Col>
                </Row>
                {this.singleSelectAnswerSelected(index) && (
                  <Row>
                    <Col className="modal__col-mb">
                      <Form.Group>{this.renderActivityFeedbackAnswerOptions(feedback, index)}</Form.Group>
                      <hr />
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col className="modal__col-mb">
                    <Form.Group>
                      <Form.Label>
                        {LanguageProvider.t(TranslationMapper.pages.masterdata.activities.columnheaders.showwhen)}
                      </Form.Label>
                      <CheckBox
                        id={"activity-feedback-request-whenfinished" + index}
                        name={"activity-feedback-request-whenfinished-" + index}
                        description={this.getFeedbackCheckoutLabel("feedbackrowwhenfinished", true)}
                        onChange={(event): void => this.onChangeFeedbackRequest(event, index, "whenFinished")}
                        value={feedback.whenFinished}
                      />
                      <CheckBox
                        id={"activity-feedback-request-whencancelled-" + index}
                        name={"activity-feedback-request-whencancelled-" + index}
                        description={this.getFeedbackCheckoutLabel("feedbackrowwhencancelled", false)}
                        onChange={(event): void => this.onChangeFeedbackRequest(event, index, "whenCancelled")}
                        value={feedback.whenCancelled}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>
                        {LanguageProvider.t(TranslationMapper.pages.masterdata.activities.columnheaders.required)}
                      </Form.Label>
                      <CheckBox
                        id={"activity-feedback-request-required" + index}
                        name={"activity-feedback-request-required-" + index}
                        description={LanguageProvider.t(
                          TranslationMapper.pages.masterdata.activities.feedbackrowrequired
                        )}
                        onChange={(event): void => this.onChangeFeedbackRequest(event, index, "required")}
                        value={feedback.required}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  public render(): ReactNode {
    return (
      <>
        {this.state.feedbackRequests
          ?.filter(f => f.isActive)
          ?.map((feedback, index) => this.renderActivityFeedback(feedback, index))}
        <Button
          onClick={this.createFeedback}
          resourceLabel={LanguageProvider.t(TranslationMapper.pages.masterdata.activities.addfeedbackrow)}
          className="btn-outline-secondary w-100 mt-3"
          iconEnd="plus"
        />
      </>
    );
  }
}

const wizardStep: IWizardStep = {
  form: ActivityFeedbackStep,
  titleResource: "",
  subtitleResource: TranslationMapper.pages.masterdata.activities.feedback,
  name: "feedback",
};

export default wizardStep;
