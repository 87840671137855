import ValidationUtils from "utils/validationUtils";

import { INotificationCategory, INotificationDetails } from "./interfaces/INotification";

export default class NotificationValidator {
  public static isCategoryValid(notification: INotificationCategory): boolean {
    if (
      ValidationUtils.checkStringIsNullOrEmpty(notification.categoryId) ||
      ValidationUtils.checkStringIsNullOrEmpty(notification.locationId) ||
      notification.cleaningObject == null ||
      ValidationUtils.checkStringIsNullOrEmpty(notification.cleaningObject.id)
    ) {
      return false;
    }
    return true;
  }

  public static areDetailsValid(notification: INotificationDetails): boolean {
    if (
      notification == null ||
      ((notification.pictures == null || notification.pictures.length === 0) &&
        ValidationUtils.checkStringIsNullOrEmpty(notification.description))
    ) {
      return false;
    }
    return true;
  }
}