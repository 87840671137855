export default class RouteCleaningObjectsRequest {
  public id: string;
  public isStartZone: boolean;
  public sequence: number;
  public activityIds?: string[];

  public constructor(
    id: string,
    isStartZone: boolean,
    sequence: number,
    activityIds?: string[]
  ) {
    this.id = id;
    this.isStartZone = isStartZone;
    this.sequence = sequence;
    this.activityIds = activityIds;
  }
}