import SearchTextInput from "components/search/searchTextInput";
import { TranslationMapper } from "i18n/mapper";
import IChannelReadStatus from "interfaces/IChannelReadStatus";
import LanguageProvider from "providers/languageProvider";
import React from "react";
import { withTranslation } from "react-i18next";

import ChannelSummary from "./channelSummary";
import ILogbookSidebarProps from "./interfaces/ILogbookSidebarProps";
import ILogbookSidebarState from "./interfaces/ILogbookSidebarState";

class LogbookSidebar extends React.Component<ILogbookSidebarProps, ILogbookSidebarState> {
  public constructor(props: ILogbookSidebarProps) {
    super(props);

    const state: ILogbookSidebarState = {};

    this.state = state;
    this.onSearchTextChanged = this.onSearchTextChanged.bind(this);
    this.onSelectedChannelChanged = this.onSelectedChannelChanged.bind(this);
  }

  private onSearchTextChanged(searchText: string): void {
    this.setState({
      searchText,
    });
  }

  private onSelectedChannelChanged(topicId: string): void {
    this.props.onSelectChannel(topicId);
  }

  private get channels(): IChannelReadStatus[] {
    const searchText = this.state.searchText;
    if (searchText == null) {
      return this.props.channels;
    }

    return this.props.channels.filter(c => c.channelName?.toLowerCase().includes(searchText.toLowerCase()));
  }

  public render(): JSX.Element {
    return (
      <>
        <aside className="logbook__aside">
          <div className="logbook__aside__item logbook__aside__item--sticky">
            <div className="logbook-search">
              <SearchTextInput
                onChange={this.onSearchTextChanged}
                searchText={this.state.searchText}
                placeholder={LanguageProvider.t(TranslationMapper.controls.placeholders.search_name)}
              />
            </div>
          </div>
          {this.channels.map((c, index) => (
            <ChannelSummary
              channelReadStatus={c}
              key={index}
              isActive={this.props.selectedChannelTopicId === c.topicId}
              onChannelSelected={this.onSelectedChannelChanged}
            />
          ))}
        </aside>
      </>
    );
  }
}

export default withTranslation()(LogbookSidebar);
