import React from "react";
import { Form } from "react-bootstrap";

import ISearchTextInputProps from "./interfaces/ISearchTextInputProps";

export default class SearchTextInput extends React.Component<ISearchTextInputProps> {
  public constructor(props: ISearchTextInputProps) {
    super(props);

    this.onChange = this.onChange.bind(this);
  }

  private onChange(event: React.ChangeEvent<HTMLInputElement>): void {
    this.props.onChange(event.target.value);
  }

  public render(): JSX.Element {
    return (
      <Form.Control
        data-test="search-text-input"
        onChange={this.onChange}
        className="form-control--icon--end form-control--icon--magnifying-glass"
        placeholder={this.props.placeholder}
        value={this.props.searchText}
      ></Form.Control>
    );
  }
}
