import LoaderTypes from "enums/loaderTypes";
import TranslationMapper from "i18n/mapper";
import i18next from "i18next";
import IFile from "interfaces/IFile";
import IUploadResponse from "interfaces/IUploadResponse";
import ExternalVenue from "models/externalVenue";
import Floor from "models/floor";
import Zone from "models/zone";
import { NotificationManager } from "react-notifications";
import CustomerLocationService from "services/customerLocationService";
import ExceptionTypes from "services/exceptionTypes";

import { ActionTypes } from "../actionTypes";
import { actionCreator, AppAction } from "../appAction";
import { setAsLoading, unsetAsLoading } from "./loaderActions";

export const fetchedExternalVenue = actionCreator<ActionTypes.FETCHED_EXTERNAL_VENUE, ExternalVenue>(
  ActionTypes.FETCHED_EXTERNAL_VENUE
);
export const uploadedLocationFile = actionCreator<ActionTypes.UPLOADED_LOCATION_FILE, IUploadResponse | undefined>(
  ActionTypes.UPLOADED_LOCATION_FILE
);

export function getExternalLocation(customerId: string, externalLocationId: string): AppAction {
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  return dispatch => {
    dispatch(setAsLoading(LoaderTypes.ExternalLocation));
    const customerLocationService = new CustomerLocationService();

    customerLocationService
      .getExternalLocation(customerId, externalLocationId)
      .then(location => {
        dispatch(fetchedExternalVenue(location));
      })
      .finally(() => {
        dispatch(unsetAsLoading(LoaderTypes.ExternalLocation));
      });
  };
}

export function createFloor(customerId: string, venueId: string, floor: Floor): AppAction {
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  return dispatch => {
    const customerLocationService = new CustomerLocationService();

    customerLocationService
      .createFloor(customerId, venueId, floor)
      .then(() => {
        NotificationManager.success(i18next.t(TranslationMapper.pages.externalvenue.floor_created_message));
        dispatch(getExternalLocation(customerId, venueId));
      })
      .catch(() => {
        NotificationManager.error(i18next.t(TranslationMapper.pages.externalvenue.error_creating_floor));
      });
  };
}

export function updateFloor(customerId: string, venueId: string, floor: Floor): AppAction {
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  return dispatch => {
    const customerLocationService = new CustomerLocationService();

    customerLocationService
      .updateFloor(customerId, venueId, floor)
      .then(() => {
        NotificationManager.success(i18next.t(TranslationMapper.pages.externalvenue.floor_updated_message));
        dispatch(getExternalLocation(customerId, venueId));
      })
      .catch(() => {
        NotificationManager.error(i18next.t(TranslationMapper.pages.externalvenue.error_updating_floor));
      });
  };
}

export function deleteFloor(customerId: string, venueId: string, floorId: string): AppAction {
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  return dispatch => {
    const customerLocationService = new CustomerLocationService();

    customerLocationService
      .deleteFloor(customerId, venueId, floorId)
      .then(() => {
        NotificationManager.success(i18next.t(TranslationMapper.pages.externalvenue.floor_deleted_message));
        dispatch(getExternalLocation(customerId, venueId));
      })
      .catch(() => {
        NotificationManager.error(i18next.t(TranslationMapper.pages.externalvenue.error_deleting_floor));
      });
  };
}

export function createZone(customerId: string, venueId: string, zone: Zone): AppAction {
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  return dispatch => {
    const customerLocationService = new CustomerLocationService();

    customerLocationService
      .createZone(customerId, venueId, zone)
      .then(() => {
        NotificationManager.success(i18next.t(TranslationMapper.pages.externalvenue.zone_created_message));
        dispatch(getExternalLocation(customerId, venueId));
      })
      .catch((error: Error) => {
        if (error.message.includes(ExceptionTypes.NfcCodeExistsException.toString())) {
          NotificationManager.error(i18next.t(TranslationMapper.pages.externalvenue.error_creating_zone_nfc));
        } else {
          NotificationManager.error(i18next.t(TranslationMapper.pages.externalvenue.error_creating_zone));
        }
      });
  };
}

export function updateZone(customerId: string, venueId: string, zone: Zone): AppAction {
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  return dispatch => {
    const customerLocationService = new CustomerLocationService();

    customerLocationService
      .updateZone(customerId, venueId, zone)
      .then(() => {
        NotificationManager.success(i18next.t(TranslationMapper.pages.externalvenue.zone_updated_message));
        dispatch(getExternalLocation(customerId, venueId));
      })
      .catch((error: Error) => {
        if (error.message.includes("Nfc")) {
          NotificationManager.error(i18next.t(TranslationMapper.pages.externalvenue.error_updating_zone_nfc));
        } else {
          NotificationManager.error(i18next.t(TranslationMapper.pages.externalvenue.error_updating_zone));
        }
      });
  };
}

export function deleteZone(customerId: string, venueId: string, zoneId: string): AppAction {
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  return dispatch => {
    const customerLocationService = new CustomerLocationService();

    customerLocationService
      .deleteZone(customerId, venueId, zoneId)
      .then(() => {
        NotificationManager.success(i18next.t(TranslationMapper.pages.externalvenue.zone_deleted_message));
        dispatch(getExternalLocation(customerId, venueId));
      })
      .catch(() => {
        NotificationManager.error(i18next.t(TranslationMapper.pages.externalvenue.error_deleting_zone));
      });
  };
}

export function uploadFile(customerId: string, venueId: string, file: IFile): AppAction {
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  return dispatch => {
    dispatch(setAsLoading(LoaderTypes.UploadFile));
    const customerLocationService = new CustomerLocationService();

    customerLocationService
      .uploadFile(venueId, file)
      .then((uploadResponse: IUploadResponse) => {
        dispatch(getExternalLocation(customerId, venueId));
        dispatch(uploadedLocationFile(uploadResponse));
        if (uploadResponse.succeeded) {
          NotificationManager.success(i18next.t(TranslationMapper.pages.externalvenue.csv_uploaded));
        } else {
          NotificationManager.error(i18next.t(TranslationMapper.pages.externalvenue.error_uploading_csv));
        }
      })
      .catch(() => {
        dispatch(uploadedLocationFile({ succeeded: false, failedRowNumbers: [] }));
        NotificationManager.error(i18next.t(TranslationMapper.pages.externalvenue.error_uploading_csv));
      })
      .finally(() => {
        dispatch(unsetAsLoading(LoaderTypes.UploadFile));
      });
  };
}

export function clearUploadLocationResult(): AppAction {
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  return dispatch => {
    dispatch(uploadedLocationFile(undefined));
  };
}
