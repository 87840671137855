import LoaderTypes from "enums/loaderTypes";
import TranslationMapper from "i18n/mapper";
import ICleaningObjectContainer from "interfaces/ICleaningObjectContainer";
import ICoreSpace from "interfaces/ICoreSpace";
import { cloneDeep } from "lodash";
import { Component, ReactNode } from "react";
import { Form } from "react-bootstrap";
import { connect } from "react-redux";
import { getExternalLocation } from "store/actions/externalVenueActions";
import { RootState } from "store/reducers/rootReducer";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { IInputChangeEvent } from "../interfaces/IInputChangeEvent";
import SelectCleaningObjects from "../selectCleaningObjects/selectCleaningObjects";
import SelectedCleaningObjects from "../selectedCleaningObjects/selectedCleaningObjects";
import IWizardStep from "../wizard/interfaces/IWizardStep";
import IRouteCleaningObjectsStepProps, {
  IRouteCleaningObjectsStepDispatchProps,
  IRouteCleaningObjectsStepStateProps,
} from "./interfaces/IRouteCleaningObjectsStepProps";
import IRouteCleaningObjectsStepState from "./interfaces/IRouteCleaningObjectsStepState";
import RouteValidator from "./routeValidator";

class RouteCleaningObjectsStep extends Component<IRouteCleaningObjectsStepProps, IRouteCleaningObjectsStepState> {
  public coreSpaces: ICoreSpace[];
  public constructor(props: IRouteCleaningObjectsStepProps) {
    super(props);

    this.state = this.props.value ?? {
      cleaningObjectsContainers: [],
    };

    if (this.props.venue) {
      this.props.getExternalLocation(this.props.venue.customerId, this.props.venue.externalVenueId);
    }

    this.coreSpaces = [];

    this.onChange = this.onChange.bind(this);
    this.propagateChange = this.propagateChange.bind(this);
  }

  public onChange(event: IInputChangeEvent<ICleaningObjectContainer[]>): void {
    const name = event.target.name;
    const value = event.target.value;

    this.setState(current => ({ ...current, [name]: value ?? [] }), this.propagateChange);
  }

  private async propagateChange(): Promise<void> {
    const isValid = RouteValidator.areCleaningObjectsValid(this.state.cleaningObjectsContainers);

    this.props.onChange({ target: { value: this.state, name: this.props.name }, isValid });
  }

  public render(): ReactNode {
    return (
      <>
        {this.props.venue && (
          <Form data-testid="route-wizard-cleaning-objects-step" className="h-100">
            <div className="d-flex h-100">
              <div className="modal-cleaning-object__block">
                <SelectCleaningObjects
                  id="select-cleaning-objects"
                  name="cleaningObjectsContainers"
                  venue={this.props.venue}
                  cleaningObjects={cloneDeep(this.state.cleaningObjectsContainers)}
                  onChange={this.onChange}
                  totalNumberOfZones={0}
                  selectableCleaningObjects={[]}
                />
              </div>
              <div className="modal-cleaning-object__block-arrows">
                <FontAwesomeIcon icon={["fal", "arrow-left"]} fixedWidth />
                <FontAwesomeIcon icon={["fal", "arrow-right"]} fixedWidth />
              </div>
              <div className="modal-cleaning-object__block">
                <SelectedCleaningObjects
                  id="selected-cleaning-objects"
                  name="cleaningObjectsContainers"
                  venue={this.props.venue}
                  selectedCleaningObjects={cloneDeep(this.state.cleaningObjectsContainers)}
                  onChange={this.onChange}
                />
              </div>
            </div>
          </Form>
        )}
      </>
    );
  }
}

const mapDispatchToProps: IRouteCleaningObjectsStepDispatchProps = {
  getExternalLocation: getExternalLocation,
};

const mapStateToProps = (state: RootState): IRouteCleaningObjectsStepStateProps => ({
  isLoading: state.generalState.loaders.some(l => l === LoaderTypes.Locations),
  customer: state.customerState.customers.find(c => c.id === state.customerState.selectedCustomerId)!,
});

const wizardStep: IWizardStep = {
  form: connect(mapStateToProps, mapDispatchToProps)(RouteCleaningObjectsStep),
  titleResource: TranslationMapper.pages.routewizard.cleaningobjects.title,
  subtitleResource: TranslationMapper.pages.routewizard.cleaningobjects.subtitle,
  name: "cleaningObjects",
};

export default wizardStep;
