import TranslationMapper from "i18n/mapper";
import LanguageProvider from "providers/languageProvider";
import { Component } from "react";

import IconImageNotFound from "../../assets/img/image-not-found.svg";
import IActivityItemProps from "./interfaces/IActivityItemProps";

export default class ActivityItem extends Component<IActivityItemProps> {
  public constructor(props: IActivityItemProps) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  public render(): JSX.Element {
    const name = this.props.name !== "" ? this.props.name : LanguageProvider.t(TranslationMapper.images.namenotfound);
    const imageExists =
      (this.props.active && this.props.activity.activeImageExists) ||
      (!this.props.active && this.props.activity.inactiveImageExists);
    return (
      <button
        onClick={this.onClick}
        type="button"
        title={name}
        className={`btn btn--image-label mb-3 ${this.props.active ? "active" : "inactive"}`}
      >
        <img src={imageExists ? this.props.imageUri : IconImageNotFound} alt="" />
        <div>{name}</div>
      </button>
    );
  }

  private onClick(): void {
    if (this.props.selectingDisabled) {
      return;
    }

    this.props.onItemClick(this.props.activity);
  }
}
