import ErrorTypes from "enums/errorTypes";
import TranslationMapper from "i18n/mapper";
import INotificationCategoryContact from "interfaces/INotificationCategoryContact";
import INotificationOverview from "interfaces/INotificationOverview";
import INotificationRequest from "interfaces/INotificationRequest";
import { cloneDeep } from "lodash";
import LanguageProvider from "providers/languageProvider";
import { NotificationManager } from "react-notifications";
import NotificationService from "services/notificationService";
import TelemetryService from "services/telemetryService";
import { ActionTypes } from "store/actionTypes";

import LoaderTypes from "../../enums/loaderTypes";
import CleaningManagementService from "../../services/cleaningManagementService";
import { actionCreator, AppAction } from "../appAction";
import { resetError, setError } from "./errorActions";
import { getCustomerIncidentTypes } from "./incidentTypeActions";
import { finishLoading, startLoading, unsetAsLoading } from "./loaderActions";
import { clearCleaningObjects } from "./locationActions";

export const fetchedNotifications = actionCreator<ActionTypes.FETCHED_NOTIFICATIONS, INotificationOverview[]>(
  ActionTypes.FETCHED_NOTIFICATIONS
);
export const fetchedCustomerNotificationContacts = actionCreator<
  ActionTypes.FETCHED_CUSTOMER_NOTIFICATION_CONTACTS,
  Map<string, INotificationCategoryContact[]>
>(ActionTypes.FETCHED_CUSTOMER_NOTIFICATION_CONTACTS);

export function getNotificationsForCustomer(customerId: string, fromDate: string, toDate: string): AppAction {
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  return (dispatch) => {
    const cleaningManagementService = new CleaningManagementService();
    const getNotificationsLoader = LoaderTypes.Notifications;

    dispatch(startLoading(getNotificationsLoader));

    cleaningManagementService
      .getBasicCustomerNotifications(customerId, fromDate, toDate)
      .then((notifications: INotificationOverview[]) => {
        dispatch(fetchedNotifications(notifications));
      })
      .catch(() => {
        dispatch(fetchedNotifications([]));
        NotificationManager.error(LanguageProvider.t(TranslationMapper.pages.notifications.errormessageid));
      })
      .finally(() => dispatch(unsetAsLoading(getNotificationsLoader)));
  };
}

export function updateNotificationStatus(
  customerId: string,
  notificationId: string,
  notificationStatus: string,
  fromDate: string,
  toDate: string
): AppAction {
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  return (dispatch) => {
    const cleaningManagementService = new CleaningManagementService();
    const updateNotificationStatusLoader = LoaderTypes.Notifications;

    dispatch(startLoading(updateNotificationStatusLoader));

    cleaningManagementService
      .updateNotificationStatus(notificationId, notificationStatus)
      .then(() => {
        NotificationManager.success(LanguageProvider.t(TranslationMapper.pages.masterdata.saving));
        dispatch(getNotificationsForCustomer(customerId, fromDate, toDate));
      })
      .catch(() => NotificationManager.error(LanguageProvider.t(TranslationMapper.pages.masterdata.saveerror)))
      .finally(() => {
        dispatch(unsetAsLoading(updateNotificationStatusLoader));
      });
  };
}

export function createNotificationAsync(notification: INotificationRequest): AppAction {
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  return (dispatch) => {
    dispatch(startLoading(LoaderTypes.NotificationCreate));
    dispatch(resetError(ErrorTypes.NotificationCreate));
    const notificationService = new NotificationService();
    notificationService
      .createNotificationAsync(notification)
      .then((response: Response) => {
        if (!response.ok) {
          const error = LanguageProvider.t(TranslationMapper.pages.notificationwizard.error);
          dispatch(setError(ErrorTypes.NotificationCreate));
          TelemetryService.AppInsights?.trackException({
            exception: { name: "createNotification exception", message: error },
          });
        }
      })
      .catch(() => {
        dispatch(setError(ErrorTypes.NotificationCreate));
      })
      .finally(() => {
        dispatch(clearCleaningObjects);
        dispatch(finishLoading(LoaderTypes.NotificationCreate));
      });
  };
}

export function upsertNotificationCategoryContact(
  notificationCategoryContact: INotificationCategoryContact
): AppAction {
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  return (dispatch, getState) => {
    const selectedCustomerId = getState().customerState.selectedCustomerId;
    if (selectedCustomerId == null) {
      return;
    }

    dispatch(startLoading(LoaderTypes.NotificationContacts));

    const notificationService = new NotificationService();
    notificationService
      .upsertNotificationCategoryContactAsync(selectedCustomerId, notificationCategoryContact)
      .then(() => {
        dispatch(getNotificationCategoryContacts(notificationCategoryContact.notificationCategoryId));
        dispatch(getCustomerIncidentTypes(selectedCustomerId));
        NotificationManager.success(LanguageProvider.t(TranslationMapper.pages.masterdata.savedchanges));
      })
      .catch(() => {
        NotificationManager.error(LanguageProvider.t(TranslationMapper.pages.masterdata.saveerror));
      })
      .finally(() => {
        dispatch(finishLoading(LoaderTypes.NotificationContacts));
      });
  };
}

export function getNotificationCategoryContacts(categoryId: string): AppAction {
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  return (dispatch, getState) => {
    const selectedCustomerId = getState().customerState.selectedCustomerId;
    if (selectedCustomerId == null) {
      return;
    }

    dispatch(startLoading(LoaderTypes.NotificationContacts));

    const currentCustomerNotificationContacts: Map<string, INotificationCategoryContact[]> =
      cloneDeep(getState().customerState.customerNotificationCategoryContacts) ?? new Map();

    const notificationService = new NotificationService();
    notificationService
      .getNotificationCategoryContactsByCategoryIdAsync(selectedCustomerId, categoryId)
      .then((response) => {
        currentCustomerNotificationContacts.delete(categoryId);
        if (response && response.length > 0) {
          currentCustomerNotificationContacts.set(categoryId, response);
        }
        dispatch(fetchedCustomerNotificationContacts(currentCustomerNotificationContacts));
      })
      .catch(() => {
        NotificationManager.error(LanguageProvider.t(TranslationMapper.errors.fetching_notification_contacts));
      })
      .finally(() => {
        dispatch(finishLoading(LoaderTypes.NotificationContacts));
      });
  };
}

export function getAllNotificationCategoryContacts(customerId: string): AppAction {
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  return (dispatch, getState) => {
    dispatch(startLoading(LoaderTypes.NotificationContacts));

    const currentCustomerNotificationContacts: Map<string, INotificationCategoryContact[]> =
      cloneDeep(getState().customerState.customerNotificationCategoryContacts) ?? new Map();

    const notificationService = new NotificationService();
    notificationService
      .getCustomerNotificationCategoryContactsAsync(customerId)
      .then((response) => {
        currentCustomerNotificationContacts.clear();
        response.forEach((contact) => {
          currentCustomerNotificationContacts.set(contact.notificationCategoryId, contact.contacts);
        });
        dispatch(fetchedCustomerNotificationContacts(currentCustomerNotificationContacts));
      })
      .catch(() => {
        NotificationManager.error(LanguageProvider.t(TranslationMapper.errors.fetching_notification_contacts));
      })
      .finally(() => {
        dispatch(finishLoading(LoaderTypes.NotificationContacts));
      });
  };
}

export function deleteNotificationCategoryContact(categoryId: string, contactId: string): AppAction {
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  return (dispatch, getState) => {
    const selectedCustomerId = getState().customerState.selectedCustomerId;
    if (selectedCustomerId == null) {
      return;
    }

    dispatch(startLoading(LoaderTypes.NotificationContacts));

    const notificationService = new NotificationService();
    notificationService
      .deleteNotificationCategoryContactAsync(selectedCustomerId, contactId)
      .then(() => {
        dispatch(getNotificationCategoryContacts(categoryId));
        dispatch(getCustomerIncidentTypes(selectedCustomerId));
        NotificationManager.success(LanguageProvider.t(TranslationMapper.pages.masterdata.succesfullydeleted));
      })
      .catch(() => {
        NotificationManager.error(LanguageProvider.t(TranslationMapper.pages.masterdata.deleteerror));
      })
      .finally(() => {
        dispatch(finishLoading(LoaderTypes.NotificationContacts));
      });
  };
}
