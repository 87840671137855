import TranslationMapper from "i18n/mapper";
import LanguageProvider from "providers/languageProvider";
import { Component } from "react";
import { replaceStringPlaceholders } from "utils/stringUtils";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ICustomPaginationProps from "./interfaces/ICustomPaginationProps";

export default class CustomPagination extends Component<ICustomPaginationProps> {
  public constructor(props: ICustomPaginationProps) {
    super(props);

    this.goToFirstPage = this.goToFirstPage.bind(this);
    this.goToLastPage = this.goToLastPage.bind(this);
  }

  private get pageIndexDescription(): string {
    const translation = LanguageProvider.t(TranslationMapper.pagination.shown_pages);
    const currentPage = (this.props.pageIndex + 1).toString();
    const totalPages = this.props.pageOptions ? this.props.pageOptions.length.toString() : currentPage;

    return replaceStringPlaceholders(translation, currentPage, totalPages);
  }

  private get isLoading(): boolean {
    return this.props.isLoading === true;
  }

  private goToFirstPage(): void {
    this.props.onGoToPage(0);
  }

  private goToLastPage(): void {
    this.props.onGoToPage(this.props.pageCount - 1);
  }

  public render(): JSX.Element {
    return (
      <nav className="d-flex justify-content-end" aria-label="Page navigation">
        <ul className="pagination">
          <li className={`page-item${!this.props.isPreviousPageAvailable || this.isLoading ? " disabled" : ""}`}>
            <button className="page-link" aria-label="First" onClick={this.goToFirstPage}>
              <span aria-hidden="true">
                <FontAwesomeIcon icon={["fal", "chevrons-left"]} fixedWidth size="sm" />
              </span>
            </button>
          </li>
          <li className={`page-item${!this.props.isPreviousPageAvailable || this.isLoading ? " disabled" : ""}`}>
            <button className="page-link" aria-label="Previous" onClick={this.props.onGoToPreviousPage}>
              <span aria-hidden="true">
                <FontAwesomeIcon icon={["fal", "chevron-left"]} fixedWidth size="sm" />
              </span>
            </button>
          </li>
          <li className="page-item page-item--desc">{this.pageIndexDescription}</li>
          <li className={`page-item${!this.props.isNextPageAvailable || this.isLoading ? " disabled" : ""}`}>
            <button className="page-link" aria-label="Next" onClick={this.props.onGoToNextPage}>
              <span aria-hidden="true">
                <FontAwesomeIcon icon={["fal", "chevron-right"]} fixedWidth size="sm" />
              </span>
            </button>
          </li>
          <li className={`page-item${!this.props.isNextPageAvailable || this.isLoading ? " disabled" : ""}`}>
            <button className="page-link" aria-label="Last" onClick={this.goToLastPage}>
              <span aria-hidden="true">
                <FontAwesomeIcon icon={["fal", "chevrons-right"]} fixedWidth size="sm" />
              </span>
            </button>
          </li>
        </ul>
      </nav>
    );
  }
}
