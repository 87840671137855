import { FunctionComponent } from "react";

import IReactTableRowSubComponentProps, {
    IReactTableRowSubComponentRowProps
} from "./interfaces/IReactTableRowSubComponentProps";

export const ReactTableRowSubComponent: FunctionComponent<IReactTableRowSubComponentProps> = ({ rows }) => {
  const getClassName = (row: IReactTableRowSubComponentRowProps): string =>
    row.className ? "tr-content " + row.className : "tr-content";

  return (
    <>
      {rows.map((row, rowIndex) => (
        <tr className={getClassName(row)} key={rowIndex}>
          {row.columns.map((column, columnIndex) => (
            <td colSpan={column.colSpan} className={column.className} key={`${rowIndex}-${columnIndex}`}>
              {column.content}
            </td>
          ))}
        </tr>
      ))}
    </>
  );
};
