import IIncidentType from "interfaces/IIncidentType";
import { INotificationStoreState, initialNotificationStoreState } from "store/state/notificationStoreState";
import Dictionary from "utils/dictionary";

import { ActionTypes, ActionTypesCollection } from "../actionTypes";
import { cloneDeep } from "lodash";

export default function notificationReducer(
  state: INotificationStoreState = initialNotificationStoreState,
  action: ActionTypesCollection
): INotificationStoreState {
  switch (action.type) {
    case ActionTypes.FETCHED_CUSTOMER_INCIDENT_TYPES: {
      const incidentTypes = cloneDeep(state.incidentTypes) ?? new Dictionary<IIncidentType[]>();
      incidentTypes[action.payload.customerId] = action.payload.incidentTypes;

      return {
        ...state,
        incidentTypes: incidentTypes,
      };
    }
    case ActionTypes.FETCHED_NOTIFICATION_CATEGORIES:
      return {
        ...state,
        notificationCategories: action.payload,
      };
    case ActionTypes.FETCHED_SELECTED_NOTIFICATION_CATEGORY:
      return {
        ...state,
        selectedNotificationCategory: action.payload,
      };
    case ActionTypes.FETCHED_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload,
      };
    default:
      return state;
  }
}
