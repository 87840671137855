import { FilterMenu } from "components/filterMenu/filterMenu";
import IMultiSelectDropdownField from "components/filterMenu/interfaces/IMultiSelectDropdownField";
import { IReactTableData } from "components/material/table/interfaces/IReactTableProps";
import { IReactTableRowSubComponentRowProps } from "components/material/table/interfaces/IReactTableRowSubComponentProps";
import { ReactTable } from "components/material/table/reactTable";
import { ReactTableRowExpander } from "components/material/table/reactTableRowExpander";
import { ReactTableRowSelector } from "components/material/table/reactTableRowSelector";
import { ReactTableRowSubComponent } from "components/material/table/reactTableRowSubComponent";
import TranslationMapper from "i18n/mapper";
import { cloneDeep } from "lodash";
import LanguageProvider from "providers/languageProvider";
import * as React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Column, Row } from "react-table";
import { RootState } from "store/reducers/rootReducer";
import FilterUtils from "utils/filterUtils";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import FeedbackRequestType from "../../../../enums/feedbackRequestType";
import LoaderTypes from "../../../../enums/loaderTypes";
import IActivity from "../../../../interfaces/IActivity";
import IActivityFeedbackItem from "../../../../interfaces/IActivityFeedbackItem";
import IActivityPost from "../../../../interfaces/IActivityPost";
import { showCustomerSelection } from "../../../../store/actions/customerActions";
import {
  deleteActivity,
  getActivity,
  getMasterDataActivities,
  resetActivity,
  upsertActivity,
} from "../../../../store/actions/masterDataActions";
import ActivityWizard from "./activityWizard";
import IActivityMasterDataProps, {
  IActivityMasterDataDispatchProps,
  IActivityMasterDataStateProps,
} from "./interfaces/IActivityMasterDataProps";
import IActivityMasterDataState from "./interfaces/IActivityMasterDataState";

type activityMasterDataTableData = IReactTableData & IActivity;

export class ActivityMasterData extends React.Component<IActivityMasterDataProps, IActivityMasterDataState> {
  public constructor(props: IActivityMasterDataProps) {
    super(props);

    const state: IActivityMasterDataState = {
      loading: false,
      dialogOpen: false,
    };

    this.state = state;

    this.createActivity = this.createActivity.bind(this);
    this.editActivity = this.editActivity.bind(this);
    this.deleteActivity = this.deleteActivity.bind(this);
    this.upsertActivity = this.upsertActivity.bind(this);
    this.onModalClose = this.onModalClose.bind(this);
    this.onCloseWizard = this.onCloseWizard.bind(this);
    this.selectActivity = this.selectActivity.bind(this);
    this.updateFilter = this.updateFilter.bind(this);

    this.props.getMasterDataActivities();
    this.props.resetActivity();
  }

  public componentDidMount(): void {
    this.props.showCustomerSelection(false);
  }

  private createActivity(): void {
    this.props.resetActivity();

    this.setState({
      dialogOpen: true,
    });
  }

  private async editActivity(): Promise<void> {
    if (!this.props.selectedActivity) {
      return;
    }

    this.setState({
      dialogOpen: true,
    });
  }

  private async deleteActivity(rowInfo: any): Promise<void> {
    if (!this.props.selectedActivity) {
      return;
    }
    const confirmation = window.confirm(`${LanguageProvider.t(TranslationMapper.pages.masterdata.deleteconfirmation)}
            ${this.props.selectedActivity?.name}`);
    if (!confirmation) {
      return;
    }

    this.props.onDeleteActivity(this.props.selectedActivity.id);

    this.setState({
      selectedActivityId: undefined,
    });
  }

  private onModalClose(): void {
    this.setState({
      dialogOpen: false,
    });
  }

  private async upsertActivity(activity: IActivityPost): Promise<void> {
    this.props.onUpsertActivity(activity);

    this.setState({
      selectedActivityId: undefined,
    });
  }

  public onCloseWizard(): void {
    this.props.resetActivity();

    this.setState({
      dialogOpen: false,
      selectedActivityId: undefined,
    });
  }

  private get selectedActivity(): IActivity | undefined {
    return this.state.selectedActivityId != null
      ? this.props.activities.find(aa => aa.id === this.state.selectedActivityId)
      : undefined;
  }

  private selectActivity(activityId: string): void {
    const currentSelectedActivityId = this.state.selectedActivityId;
    const deselectActivity = currentSelectedActivityId != null && currentSelectedActivityId === activityId;

    if (!deselectActivity && activityId != null) {
      this.props.getActivity(activityId);
    }

    this.setState({
      selectedActivityId: deselectActivity ? undefined : activityId,
    });
  }

  private getCheckedOutText(canBeCheckedOutMultipleTimes: boolean): string {
    return canBeCheckedOutMultipleTimes
      ? LanguageProvider.t(TranslationMapper.pages.masterdata.activities.multiplecheckout)
      : LanguageProvider.t(TranslationMapper.pages.masterdata.activities.singlecheckout);
  }

  private get columns(): Column[] {
    return [
      {
        id: "table-row-selector",
        width: 36,
        disableSortBy: true,
        Cell: ({ row }): JSX.Element => (
          <ReactTableRowSelector
            onChange={this.selectActivity}
            row={row}
            selectedRowKey={this.state.selectedActivityId}
          />
        ),
      },
      {
        Header: LanguageProvider.t(TranslationMapper.pages.masterdata.activities.columnheaders.title),
        id: "name",
        accessor: "name",
        width: "40%",
      },
      {
        Header: LanguageProvider.t(TranslationMapper.pages.masterdata.activities.columnheaders.activeicon),
        id: "imageUriActive",
        accessor: (rowOriginal): JSX.Element =>
          rowOriginal.activeImageExists ? (
            // Date is added to prevent caching
            <img
              className="table__img-thumbnail"
              src={`${rowOriginal.imageUri}_Activity_active?${new Date().getTime()}`}
              alt=""
            />
          ) : (
            <span>{LanguageProvider.t(TranslationMapper.pages.masterdata.activities.no_image)}</span>
          ),
        width: "auto",
        disableSortBy: true,
      },
      {
        Header: LanguageProvider.t(TranslationMapper.pages.masterdata.activities.columnheaders.inactiveicon),
        id: "imageUriInactive",
        accessor: (rowOriginal): JSX.Element =>
          rowOriginal.inactiveImageExists ? (
            // Date is added to prevent caching
            <img
              className="table__img-thumbnail"
              src={`${rowOriginal.imageUri}_Activity_inactive?${new Date().getTime()}`}
              alt=""
            />
          ) : (
            <span>{LanguageProvider.t(TranslationMapper.pages.masterdata.activities.no_image)}</span>
          ),
        width: "auto",
        disableSortBy: true,
      },
      {
        Header: LanguageProvider.t(TranslationMapper.pages.masterdata.activities.columnheaders.checkout),
        id: "canBeCheckedOutMultipleTimes",
        accessor: (rowOriginal): string => this.getCheckedOutText(rowOriginal.canBeCheckedOutMultipleTimes),
        width: "auto",
        disableSortBy: true,
        Cell: ({ value }): JSX.Element => (
          <div>
            <span>{value}</span>
          </div>
        ),
      },
      {
        Header: LanguageProvider.t(TranslationMapper.pages.masterdata.activities.columnheaders.feedback),
        id: "feedbackRequests",
        accessor: (rowOriginal): boolean => this.getHasFeedback(rowOriginal.feedbackRequests),
        width: "auto",
        disableSortBy: true,
        Cell: ({ value }): JSX.Element => this.getFeedbackIcon(value),
      },
      {
        id: "expander",
        disableSortBy: true,
        width: 36,
        Cell: ({ row }): JSX.Element => {
          if ((row.original as activityMasterDataTableData).feedbackRequests?.length === 0) {
            return <></>;
          }
          return <ReactTableRowExpander row={row} />;
        },
      },
    ];
  }

  private getFeedbackIcon(value: boolean): JSX.Element {
    if (value) {
      return <FontAwesomeIcon icon={["fas", "circle-check"]} fixedWidth size="lg" className="text-success" />;
    } else {
      return <FontAwesomeIcon icon={["fas", "circle-check"]} fixedWidth size="lg" className="text-gray-300" />;
    }
  }

  private getHasFeedback(feedback: IActivityFeedbackItem[]): boolean {
    return feedback && feedback.length > 0;
  }

  private get searchBar(): IMultiSelectDropdownField[] {
    const searchBar = FilterUtils.createSearchFilter(
      LanguageProvider.t(TranslationMapper.pages.masterdata.searchactivity),
      (keyword: string) => {
        this.updateFilter(keyword);
      }
    );

    return [searchBar];
  }

  private updateFilter(value: string): void {
    this.setState({
      searchText: value,
      selectedActivityId: undefined,
    });
  }

  private renderHeaderWithButtons(): JSX.Element {
    return (
      <div className="header-actions__buttons">
        <button
          className="btn btn-primary btn--rounded"
          disabled={!this.selectedActivity}
          onClick={this.deleteActivity}
        >
          <FontAwesomeIcon icon={["fal", "trash"]} fixedWidth />
        </button>
        <button className="btn btn-primary btn--rounded" disabled={!this.selectedActivity} onClick={this.editActivity}>
          <FontAwesomeIcon icon={["fal", "pen"]} fixedWidth />
        </button>
        <button className="btn btn-primary btn--rounded" onClick={this.createActivity}>
          <FontAwesomeIcon icon={["fal", "plus"]} fixedWidth />
        </button>
      </div>
    );
  }

  private get filteredActivities(): IActivity[] {
    const searchText = this.state.searchText;
    if (!searchText || searchText == null) {
      return this.props.activities;
    }

    return this.props.activities.filter(s => s.name.toLowerCase().includes(searchText.toLowerCase())) ?? [];
  }

  private get activitiesTableData(): activityMasterDataTableData[] {
    return this.filteredActivities.map(fa => {
      const activity: activityMasterDataTableData = {
        ...fa,
        key: fa.id,
      };

      return activity;
    });
  }

  private renderActivitiesListExpandedContent(row: Row): JSX.Element {
    const rowData = row.original as activityMasterDataTableData;
    const activityToggleHeaderClass = "td-content-pt-3-5";

    if (rowData.feedbackRequests?.length === 0) {
      return <></>;
    }

    const subComponentRows: IReactTableRowSubComponentRowProps[] = [
      {
        columns: [
          {
            className: activityToggleHeaderClass,
            content: <>&nbsp;</>,
          },
          {
            className: activityToggleHeaderClass,
            content: (
              <h3>
                {LanguageProvider.t(TranslationMapper.pages.masterdata.activities.columnheaders.feedbackrowquestion)}
              </h3>
            ),
          },
          {
            className: activityToggleHeaderClass,
            colSpan: 2,
            content: (
              <h3>
                {LanguageProvider.t(TranslationMapper.pages.masterdata.activities.columnheaders.feedbackrowanswertype)}
              </h3>
            ),
          },
          {
            className: activityToggleHeaderClass,
            content: (
              <h3>{LanguageProvider.t(TranslationMapper.pages.masterdata.activities.columnheaders.showwhen)}</h3>
            ),
          },
          {
            className: activityToggleHeaderClass,
            content: (
              <h3>{LanguageProvider.t(TranslationMapper.pages.masterdata.activities.columnheaders.required)}</h3>
            ),
          },
          {
            className: activityToggleHeaderClass,
            content: <div></div>,
          },
        ],
      },
    ];

    rowData.feedbackRequests?.forEach((item: IActivityFeedbackItem, index: number) => {
      const activityToggleColumnClass = "td-content-pb-3-5";
      const subComponentRow: IReactTableRowSubComponentRowProps = {
        columns: [
          {
            className: activityToggleColumnClass,
            content: <>&nbsp;</>,
          },
          {
            className: activityToggleColumnClass,
            content: (
              <ul className="mb-0">
                <li>{item.request}</li>
              </ul>
            ),
          },
          {
            className: activityToggleColumnClass,
            colSpan: 2,
            content: <div>{LanguageProvider.t(FeedbackRequestType[item.requestType])}</div>,
          },
          {
            className: activityToggleColumnClass,
            content: (
              <div className="d-flex">
                {item.whenCancelled && (
                  <FontAwesomeIcon icon={["fas", "circle-check"]} fixedWidth size="lg" className="text-success" />
                )}
                {item.whenFinished && (
                  <FontAwesomeIcon icon={["fas", "circle-xmark"]} fixedWidth size="lg" className="text-warning" />
                )}
              </div>
            ),
          },
          {
            className: activityToggleColumnClass,
            content: (
              <div>
                {item.required
                  ? LanguageProvider.t(TranslationMapper.global.yes)
                  : LanguageProvider.t(TranslationMapper.global.no)}
              </div>
            ),
          },
          {
            className: activityToggleColumnClass,
            content: <div></div>,
          },
        ],
      };

      subComponentRows.push(subComponentRow);
    });

    return <ReactTableRowSubComponent rows={subComponentRows} />;
  }

  private renderActivitiesList(): JSX.Element {
    return (
      <>
        <header className="header-actions">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 header-actions__content">
                <h1>{LanguageProvider.t(TranslationMapper.masterdata.activities)}</h1>
                {this.renderHeaderWithButtons()}
              </div>
            </div>
          </div>
        </header>

        <FilterMenu filterFields={this.searchBar} />

        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 mb-2 block-content--px">
                <ReactTable
                  isLoading={this.props.isLoading}
                  data={this.activitiesTableData ?? []}
                  columns={this.columns}
                  renderRowSubComponent={this.renderActivitiesListExpandedContent}
                  noResultsMessage={LanguageProvider.t(TranslationMapper.pages.routes.norawdatafound)}
                  className="table--bordered table--has-checkbox table--has-expander"
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  public render(): JSX.Element {
    return (
      <>
        {this.renderActivitiesList()}
        {this.state.dialogOpen && (
          <ActivityWizard
            onSave={this.upsertActivity}
            onClose={this.onCloseWizard}
            activity={cloneDeep(this.props.selectedActivity)}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state: RootState): IActivityMasterDataStateProps => ({
  isLoading: state.generalState.loaders.some(l => l === LoaderTypes.Activities),
  activities: state.activityTypeState.activities ?? [],
  selectedActivity: state.activityTypeState.selectedActivity,
});

const mapDispatchToProps: IActivityMasterDataDispatchProps = {
  getMasterDataActivities: getMasterDataActivities,
  onDeleteActivity: deleteActivity,
  onUpsertActivity: upsertActivity,
  getActivity: getActivity,
  resetActivity: resetActivity,
  showCustomerSelection: showCustomerSelection,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ActivityMasterData));
