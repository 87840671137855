import TranslationMapper from "i18n/mapper";
import moment from "moment";
import LanguageProvider from "providers/languageProvider";
import { Component, ReactNode } from "react";
import DateUtils from "utils/dateUtils";

import { IInputChangeEvent } from "../../interfaces/IInputChangeEvent";
import ISelectTimePeriodProps from "./interfaces/ISelectTimePeriodProps";
import ISelectTimePeriodState from "./interfaces/ISelectTimePeriodState";
import SelectTime from "./selectTime";

export default class SelectTimePeriod extends Component<ISelectTimePeriodProps, ISelectTimePeriodState> {
  public constructor(props: ISelectTimePeriodProps) {
    super(props);

    this.state = this.props.value || {
      startTime: "",
      endTime: "",
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.onCalendarOpen = this.onCalendarOpen.bind(this);
    this.propagateChange = this.propagateChange.bind(this);
    this.getTimeDifference = this.getTimeDifference.bind(this);
  }

  private handleInputChange(event: IInputChangeEvent<string>): void {
    const nameTime = event.target.name;
    const newTimeValue = DateUtils.formatInputToTime(event.target.value);
    const state = this.state;

    let startTime = state.startTime;
    let endTime = state.endTime;

    startTime = DateUtils.formatInputToTime(startTime);
    endTime = DateUtils.formatInputToTime(endTime);

    if (nameTime === this.nameStartTime) {
      startTime = DateUtils.formatInputToTime(newTimeValue) ?? "";

      if (endTime == null || endTime === "") {
        endTime = "" + startTime;
      }
      const startDateTime = moment(startTime, "HH:mm");
      let endDateTime = moment(endTime, "HH:mm");

      if (startDateTime.isSameOrAfter(endDateTime) && state.startTime < state.endTime) {
        endDateTime = startDateTime.add(15, "minutes");

        endTime = endDateTime.format("HH:mm");
      }
    } else if (nameTime === this.nameEndTime) {
      endTime = DateUtils.formatInputToTime(newTimeValue) ?? "";

      if (startTime == null || startTime === "") {
        startTime = "" + endTime;
      }
    }

    startTime = DateUtils.formatInputToTime(startTime);
    endTime = DateUtils.formatInputToTime(endTime);

    this.setState(
      {
        startTime,
        endTime,
      },
      this.propagateChange
    );
  }

  private getTimeDifference(givenEndTime?: moment.Moment): string {
    const endTime = givenEndTime ? givenEndTime : moment(this.state.endTime, "HH:mm");
    const startTime = moment(this.state.startTime, "HH:mm");
    const timeDiff = moment.duration(endTime.diff(startTime)).asHours();

    let minutes: number;
    let hours: number;

    if (startTime >= endTime) {
      minutes = (timeDiff + 24 - Math.floor(timeDiff + 24)) * 60;
      hours = Math.floor(timeDiff + 24);
    } else {
      minutes = (timeDiff - Math.floor(timeDiff)) * 60;
      hours = Math.floor(timeDiff);
    }

    if (minutes && hours !== 0) {
      return (
        " (" +
        hours.toString() +
        LanguageProvider.t(TranslationMapper.datepicker.hours) +
        " " +
        minutes.toString() +
        LanguageProvider.t(TranslationMapper.datepicker.minutes) +
        ")"
      );
    }
    if (minutes) {
      return " (" + minutes.toString() + LanguageProvider.t(TranslationMapper.datepicker.minutes) + ")";
    }
    if (hours) {
      return " (" + hours.toString() + LanguageProvider.t(TranslationMapper.datepicker.hours) + ")";
    }
    return "";
  }

  private onCalendarOpen(): void {
    const items = document.getElementsByClassName("react-datepicker__time-list-item");

    for (const item of items) {
      if (this.state.startTime >= item.innerHTML) {
        item.setAttribute("data-time-order", "smaller");
      }
      const endTime = moment(item.innerHTML, "HH:mm");
      item.setAttribute("data-time-diff", this.getTimeDifference(endTime));
    }

    const elmContainer = document.querySelector(".react-datepicker__time-list");
    const elmSelected = document.querySelector(".react-datepicker__time-list-item--selected");
    if (elmContainer instanceof HTMLElement && elmSelected instanceof HTMLElement) {
      elmContainer.scrollTop = elmSelected.offsetTop - 3 * elmSelected.clientHeight;
    }
  }

  private propagateChange(): void {
    if (this.props.onChange != null) {
      this.props.onChange({
        target: {
          name: this.props["name"],
          value: {
            startTime: this.state.startTime,
            endTime: this.state.endTime,
          },
        },
      });
    }
  }

  private get nameStartTime(): string {
    return `${this.props.name}StartTime`;
  }

  private get idStartTime(): string {
    return `${this.props.id}StartTime`;
  }

  private get nameEndTime(): string {
    return `${this.props.name}EndTime`;
  }

  private get idEndTime(): string {
    return `${this.props.id}EndTime`;
  }

  public render(): ReactNode {
    const timeDifference = this.getTimeDifference();
    return (
      <div id={this.props.id} className="select-time-period d-flex justify-content-between row">
        <div className="col-6">
          <SelectTime
            id={this.idStartTime}
            name={this.nameStartTime}
            value={DateUtils.formatInputToTime(this.state.startTime)}
            onChange={this.handleInputChange}
            className="col-6"
          />
        </div>
        <div className="col-6">
          <SelectTime
            id={this.idEndTime}
            name={this.nameEndTime}
            value={DateUtils.formatInputToTime(this.state.endTime)}
            timeDifference={timeDifference}
            onChange={this.handleInputChange}
            className="col-6"
            onCalendarOpen={this.onCalendarOpen}
          />
        </div>
      </div>
    );
  }
}
