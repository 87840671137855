import React, { FunctionComponent, useReducer } from "react";
import { Row, useExpanded, usePagination, useRowSelect, useSortBy, useTable } from "react-table";
import useDeepCompareEffect from "use-deep-compare-effect";
import { compareIgnoreCase } from "utils/stringUtils";

import CustomPagination from "./customPagination";
import IReactTableProps, { IReactTableData } from "./interfaces/IReactTableProps";
import { ReactTableBody } from "./reactTableBody";
import { ReactTableHeader } from "./reactTableHeader";

export const ReactTable: FunctionComponent<IReactTableProps> = props => {
  // The react-table package uses hooks, so needs a functional component instead of class component to work.
  // Documentation package usage: https://react-table.tanstack.com/docs/overview
  const skipPageResetRef: React.MutableRefObject<boolean> = React.useRef(false);
  const skipExpandResetRef: React.MutableRefObject<boolean> = React.useRef(false);
  const skipSortByResetRef: React.MutableRefObject<boolean> = React.useRef(false);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    state: { pageIndex, sortBy },
    prepareRow,
    visibleColumns,
  } = useTable(
    {
      columns: props.columns,
      data: props.data,
      autoResetExpanded: !skipExpandResetRef.current,
      autoResetPage: !skipPageResetRef.current,
      autoResetSortBy: !skipSortByResetRef.current,
      initialState: {
        sortBy: props.defaultColumnToSortBy != null ? [props.defaultColumnToSortBy] : [],
        hiddenColumns: props.columns.filter(c => c.hideColumn === true).map(c => c.accessor) ?? [],
        pageIndex: 0,
        pageSize: props.pageSize ?? 25,
      },
      sortTypes: {
        alphanumeric: (row1: Row<object>, row2: Row<object>, columnName: string, desc: boolean | undefined) => {
          return compareIgnoreCase(row1.values[columnName], row2.values[columnName]);
        },
      },
    },
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );

  React.useEffect(() => {
    if (sortBy.length > 0) {
      if (props.onUpdateSorting != null) {
        props.onUpdateSorting(sortBy[0].id, sortBy[0].desc ?? false);
      }
    }
    // eslint-disable-next-line
  }, [sortBy]);

  const [, decidePageReset] = useReducer(manageCurrentPageReset, pageCount);

  function manageCurrentPageReset(currentPageCount: number, pageCountUpdate: number): number {
    // Because of state updates, react table wants to reset page on updates. Here we decide if we want to skip reset
    if (currentPageCount === pageCountUpdate) {
      skipPageResetRef.current = true;
      skipExpandResetRef.current = true;
      skipSortByResetRef.current = true;
    } else {
      // Default is to skip resetting of expanded and currentPage
      skipExpandResetRef.current = false;
      skipPageResetRef.current = false;
      skipSortByResetRef.current = false;
    }

    return pageCountUpdate;
  }

  // Decide if reset of selected page or expanded state is needed
  React.useEffect(() => {
    decidePageReset(pageCount);
  }, [pageCount, props.data]);

  // Check if table data has really changed with a deep compare
  useDeepCompareEffect(() => {
    // If data has changed, we want to reset pages (return to first), reset sorting and collapse rows
    skipPageResetRef.current = false;
    skipExpandResetRef.current = false;
    skipSortByResetRef.current = false;
  }, [props.data]);

  const onRowClicked = (e: React.MouseEvent<HTMLDivElement>, row: Row): void => {
    e.preventDefault();
    e.stopPropagation();

    if (props.onRowClick) {
      props.onRowClick((row.original as IReactTableData).key);
    }
  };

  const renderCustomRowSubComponent = React.useCallback(
    ({ row }) => props.renderRowSubComponent && props.renderRowSubComponent(row),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const renderColumnRowSubComponent = React.useCallback(
    ({ row, rowProps }) => (
      <tr {...rowProps} key={rowProps.key}>
        {row.cells.map(cell => {
          return (
            <td
              {...cell.getCellProps({
                style: {
                  width: cell.column.width,
                  maxWidth: cell.column.maxWidth,
                },
              })}
            >
              {cell.column.subCell &&
                cell.render(cell.column.subCell ? "subCell" : "Cell", {
                  value: cell.column.subCell,
                  row: { ...row },
                })}
            </td>
          );
        })}
      </tr>
    ),
    []
  );

  return (
    <>
      {headerGroups.map(headerGroup =>
        headerGroup.headers.map(column =>
          column.Filter ? (
            <div key={column.id}>
              <label htmlFor={column.id}>{column.render("Header")}: </label>
              {column.render("Filter")}
            </div>
          ) : null
        )
      )}

      <table {...getTableProps()} className={`table${props.className ? ` ${props.className}` : " table--bordered"}`}>
        <ReactTableHeader headerGroups={headerGroups} />

        <ReactTableBody
          page={page}
          getTableBodyProps={getTableBodyProps}
          onRowClicked={onRowClicked}
          prepareRow={prepareRow}
          renderColumnRowSubComponent={renderColumnRowSubComponent}
          renderCustomRowSubComponent={renderCustomRowSubComponent}
          renderRowSubComponent={props.renderRowSubComponent}
          visibleColumns={visibleColumns}
          isLoading={props.isLoading}
          noResultsMessage={props.noResultsMessage}
          onRowClick={props.onRowClick}
        />
      </table>

      {(canNextPage || canPreviousPage) && (
        <CustomPagination
          isNextPageAvailable={canNextPage}
          isPreviousPageAvailable={canPreviousPage}
          onGoToNextPage={nextPage}
          onGoToPreviousPage={previousPage}
          pageCount={pageCount}
          onGoToPage={gotoPage}
          pageIndex={pageIndex}
          pageOptions={pageOptions}
        />
      )}
    </>
  );
};
