import { Column, Row as RowInfo } from "react-table";
import ICustomerBuildingOverviewProps, {
  ICustomerBuildingOverviewDispatchProps,
  ICustomerBuildingOverviewStateProps,
} from "../interfaces/ICustomerBuildingOverviewProps";
import { RouteComponentProps, withRouter } from "react-router";
import { deleteVenue, updateVenue } from "../../../store/actions/customerActions";

import { Component } from "react";
import CustomerVenueDetails from "./customerVenueDetails";
import CustomerVenueWizard from "components/customerVenueWizard/customerVenueWizard";
import ExternalVenueDetails from "./externalVenueDetails";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GoToPage from "../../../components/goToPage/goToPage";
import ICustomerBuildingOverviewState from "../interfaces/ICustomerBuildingOverviewState";
import ICustomerVenue from "../../../interfaces/ICustomerVenue";
import { IReactTableData } from "components/material/table/interfaces/IReactTableProps";
import IVenue from "interfaces/IVenue";
import LanguageProvider from "providers/languageProvider";
import LoaderTypes from "../../../enums/loaderTypes";
import { NotificationManager } from "react-notifications";
import { ReactTable } from "components/material/table/reactTable";
import { ReactTableRowExpander } from "components/material/table/reactTableRowExpander";
import { ReactTableRowSelector } from "components/material/table/reactTableRowSelector";
import { RootState } from "store/reducers/rootReducer";
import { RoutingLinks } from "../../../constants/routingLinks";
import Source from "../../../constants/sources";
import TranslationMapper from "i18n/mapper";
import { connect } from "react-redux";
import { getCustomerLocationOverview } from "store/actions/locationActions";
import { getExternalLocation } from "../../../store/actions/externalVenueActions";
import { isNullOrEmpty } from "../../../utils/stringUtils";

type customerBuildingTableData = IVenue & IReactTableData;

export class CustomerBuildingOverview extends Component<
  ICustomerBuildingOverviewProps,
  ICustomerBuildingOverviewState
> {
  public constructor(props: ICustomerBuildingOverviewProps) {
    super(props);

    let newCustomer = true;
    const customerId = this.props.customer?.id ?? this.props.match.params["customerId"];

    if (customerId) {
      newCustomer = false;
      this.props.getCustomerLocations(customerId);
    }

    const state: ICustomerBuildingOverviewState = {
      newCustomer: newCustomer,
      activeItemLabel: LanguageProvider.t(TranslationMapper.navigation.customermanagement),
      dialogOpen: false,
    };

    this.state = state;
    this.cancelForm = this.cancelForm.bind(this);
    this.setActiveRow = this.setActiveRow.bind(this);
    this.deleteVenue = this.deleteVenue.bind(this);
    this.editVenue = this.editVenue.bind(this);
    this.renderOnRowToggle = this.renderOnRowToggle.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.onUpdateVenue = this.onUpdateVenue.bind(this);
    this.getLocationById = this.getLocationById.bind(this);
  }

  public componentDidUpdate(prevProps: ICustomerBuildingOverviewProps): void {
    if (!this.props.isLoading && !this.props.customer && !this.props.match.params["customerId"]) {
      NotificationManager.error(LanguageProvider.t(TranslationMapper.pages.customeredit.error_customer_not_found));
      this.props.history.push(RoutingLinks.customerManagement);
    }
  }

  private cancelForm(): void {
    const confirmation = window.confirm(LanguageProvider.t(TranslationMapper.pages.customeredit.cancelwarning));
    if (!confirmation) {
      return;
    }

    this.props.history.push(RoutingLinks.customerManagement);
  }

  private getLocationById(id: string): IVenue | undefined {
    return this.props.customerLocations.find((cl) => cl.id === id);
  }

  private setActiveRow(rowKey: string): void {
    if (this.state.selectedVenueId === rowKey) {
      this.setState({
        selectedVenueId: undefined,
      });
    } else {
      this.setState({
        selectedVenueId: rowKey,
      });
    }
  }

  private async editVenue(): Promise<void> {
    this.setState({
      dialogOpen: true,
    });
  }

  private async deleteVenue(): Promise<void> {
    if (this.state.selectedVenueId == null || !this.props.customer || isNullOrEmpty(this.state.selectedVenueId)) {
      return;
    }
    const confirmation = window.confirm(`${LanguageProvider.t(TranslationMapper.pages.masterdata.deleteconfirmation)}
            ${this.getLocationById(this.state.selectedVenueId)?.name}`);
    if (!confirmation) {
      return;
    }

    this.props.onVenueDelete(this.props.customer.id, this.state.selectedVenueId);

    this.setState({
      selectedVenueId: undefined,
    });
  }

  private get columns(): Column[] {
    return [
      {
        id: "table-row-selector",
        width: 36,
        disableSortBy: true,
        Cell: ({ row }): JSX.Element => (
          <ReactTableRowSelector onChange={this.setActiveRow} row={row} selectedRowKey={this.state.selectedVenueId} />
        ),
      },
      {
        Header: LanguageProvider.t(TranslationMapper.pages.customeredit.buildingname),
        id: "name",
        accessor: "name",
        width: "auto",
        Cell: ({ value }): JSX.Element => <div>{value}</div>,
      },
      {
        Header: LanguageProvider.t(TranslationMapper.pages.customeredit.externalvenueid),
        id: "externalVenueId",
        width: 300,
        accessor: "externalVenueId",
      },
      {
        id: "expander",
        disableSortBy: true,
        width: 36,
        Cell: ({ row }): JSX.Element => <ReactTableRowExpander row={row} />,
      },
    ];
  }

  private onUpdateVenue(venue: ICustomerVenue): void {
    if (!this.props.customer) {
      return;
    }

    this.props.onUpdateVenue(this.props.customer.id, venue);
  }

  private renderOnRowToggle(row: RowInfo): JSX.Element {
    const venue = row.original as ICustomerVenue;

    if (!venue || !venue.id) {
      return <></>;
    } else {
      this.props.getExternalLocation(venue.customerId, venue.externalVenueId);
    }

    return (
      <>
        <CustomerVenueDetails venue={venue} isLoading={this.props.isLoading} onSave={this.onUpdateVenue} />

        <tr>
          <td />
          <td colSpan={2} className="td-content-pt-3-5 td-content-pb-3-5">
            <ExternalVenueDetails
              customerId={venue.customerId}
              venueId={venue.externalVenueId}
              activateEditButtons={venue.showCustomLocationOverview ?? false}
              isFloorNFCRequired={venue.isFloorNFCRequired ?? false}
            />
          </td>
          <td />
        </tr>
      </>
    );
  }

  private get isDCPCustomer(): boolean {
    return this.props.customer?.source === Source.DigitalCleaningPlatform;
  }

  private get customerBuildingTableData(): customerBuildingTableData[] {
    const data: customerBuildingTableData[] = this.props.customerLocations.map((cl) => {
      const location: customerBuildingTableData = {
        ...cl,
        key: cl.id,
      };

      return location;
    });

    return data;
  }

  public render(): JSX.Element {
    return (
      <>
        <header className="header-actions">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 header-actions__content">
                <div className="d-flex align-items-center">
                  <GoToPage
                    titleResource={TranslationMapper.pages.customeredit.go_back}
                    page={RoutingLinks.customerManagement}
                  />
                  <h1>{LanguageProvider.t(TranslationMapper.pages.customeredit.buildingstab)}</h1>
                </div>
                <div className="header-actions__buttons">
                  <button
                    className="btn btn-primary btn--rounded"
                    disabled={this.state.selectedVenueId == null}
                    onClick={this.deleteVenue}
                  >
                    <FontAwesomeIcon icon={["fal", "trash"]} fixedWidth />
                  </button>
                  <button
                    className="btn btn-primary btn--rounded"
                    disabled={this.state.selectedVenueId == null}
                    onClick={this.editVenue}
                  >
                    <FontAwesomeIcon icon={["fal", "pen"]} fixedWidth />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </header>

        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 mb-2 block-content--px">
                <ReactTable
                  isLoading={this.props.isLoading}
                  columns={this.columns}
                  data={this.customerBuildingTableData}
                  renderRowSubComponent={this.renderOnRowToggle}
                  className="table--bordered table--has-checkbox table--has-expander"
                />
              </div>
            </div>
          </div>
        </div>

        {this.state.dialogOpen && this.props.customer && (
          <CustomerVenueWizard
            customer={this.props.customer}
            selectedVenueId={this.state.selectedVenueId}
            onClose={this.onCloseModal}
          />
        )}
      </>
    );
  }

  public onCloseModal(): void {
    this.setState({
      dialogOpen: false,
    });
  }
}

const mapStateToProps = (state: RootState, props: RouteComponentProps): ICustomerBuildingOverviewStateProps => ({
  customer: state.customerState.customers.find((c) => c.id === props.match.params["customerId"]),
  customerLocations: state.customerState.customerLocationOverview ?? [],
  isLoading: state.generalState.loaders.some((l) => l === LoaderTypes.Locations),
});

const mapDispatchToProps: ICustomerBuildingOverviewDispatchProps = {
  onUpdateVenue: updateVenue,
  onVenueDelete: deleteVenue,
  getExternalLocation: getExternalLocation,
  getCustomerLocations: getCustomerLocationOverview,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomerBuildingOverview));
