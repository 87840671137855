import { Guid } from "guid-typescript";
import ICustomer from "interfaces/ICustomer";
import ICustomerVenue from "interfaces/ICustomerVenue";
import ICustomerVenueDetails from "components/customerVenueWizard/interfaces/ICustomerVenueDetails";
import ICustomerVenueNfcTags from "components/customerVenueWizard/interfaces/ICustomerVenueNfcTags";
import IRequiredFloorNFCTag from "components/customerVenueWizard/interfaces/IRequiredFloorNFCTag";
import Serializable from "models/serializeable";

export default class CustomerVenueRequest extends Serializable implements ICustomerVenue {
  public venue: ICustomerVenue;
  public id: string;
  public customerId: string;
  public externalVenueId: string;
  public name: string;
  public customer?: ICustomer;
  public showCustomLocationOverview?: boolean;
  public externalProjectReference: string;
  public groupByFloors: boolean;
  public nfcTags: string[]; // nfc tags for venue
  public isCheckInRequired: boolean; // nfc check-in venue is required
  public isFloorNFCRequired?: boolean; // nfc check-in floor is required
  public customerFloorNfcTags?: IRequiredFloorNFCTag[]; // nfc tags for floors

  public constructor(
    venue: ICustomerVenueDetails,
    nfcTagDetails?: ICustomerVenueNfcTags,
    customerFloorNfcTags?: IRequiredFloorNFCTag[],
    venueId?: string
  ) {
    super();
    this.id = venueId ?? Guid.createEmpty().toString();
    this.customerId = venue.customerId;
    this.externalVenueId = venue.externalVenueId ?? "";
    this.name = venue.name;
    this.showCustomLocationOverview = false;
    this.externalProjectReference = "";
    this.groupByFloors = venue.groupByFloors;
    this.nfcTags = nfcTagDetails?.nfcTags ?? [];
    this.isCheckInRequired = venue?.isCheckInRequired ?? false;
    this.isFloorNFCRequired = venue.isFloorNFCRequired ?? false;
    this.customerFloorNfcTags = customerFloorNfcTags ?? [];
  }
}
