import SliderInput from "components/input/slider/sliderInput";
import CustomSelect from "components/material/select/customSelect";
import { ICustomSelectOption } from "components/material/select/interfaces/ICustomSelectProps";
import TranslationMapper from "i18n/mapper";
import IActivityFeedbackAnswerOption from "interfaces/IActivityFeedbackAnswerOption";
import _ from "lodash";
import LanguageProvider from "providers/languageProvider";
import { Component } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import ReactDOM from "react-dom";
import { withTranslation } from "react-i18next";
import { NotificationManager } from "react-notifications";
import { isNullOrEmpty, toLocaleString } from "utils/stringUtils";
import TranslationUtils from "utils/translationUtils";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import NumericInput from "../../../components/input/numericInput";
import TextInput from "../../../components/input/textInput";
import { Button } from "../../../components/material/buttons/buttons";
import ActivityStatus from "../../../enums/activityStatus";
import FeedbackRequestType from "../../../enums/feedbackRequestType";
import IActivityAction from "../../../interfaces/IActivityAction";
import IActivityActionFeedback from "../../../interfaces/IActivityActionFeedback";
import IActivityActionFeedbackDetails from "../../../interfaces/IActivityActionFeedbackDetails";
import { withTelemetry } from "../../../services/telemetryService";
import IActivityActionsModalProps from "./interfaces/IActivityActionsModalProps";
import IActivityActionsModalState from "./interfaces/IActivityActionsModalState";

class ActivityActionsModal extends Component<IActivityActionsModalProps, IActivityActionsModalState> {
  private initialState: IActivityActionsModalState;

  private readonly deselectOperator: string = "deselect-operator";

  public constructor(props: IActivityActionsModalProps) {
    super(props);

    this.initialState = {
      loading: false,
      selectedActivityActions: this.props.selectedActivityActions ?? [],
      isValid: false,
      selectedOperator: undefined,
      isFinishingActivity: false,
    };

    this.state = _.cloneDeep(this.initialState);

    this.onSave = this.onSave.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.onAnswerChange = this.onAnswerChange.bind(this);
    this.onSelectAnswerChange = this.onSelectAnswerChange.bind(this);
    this.getFeedbackResponse = this.getFeedbackResponse.bind(this);
    this.getFeedbackSelectResponse = this.getFeedbackSelectResponse.bind(this);
    this.getIsInvalidInput = this.getIsInvalidInput.bind(this);
    this.onSelectingOperator = this.onSelectingOperator.bind(this);
    this.canAssignOperatorToActivity = this.canAssignOperatorToActivity.bind(this);
    this.clearOperatorInfoOnActivity = this.clearOperatorInfoOnActivity.bind(this);
    this.areActivitiesAtSameLocation = this.areActivitiesAtSameLocation.bind(this);
    this.areActivitiesScheduled = this.areActivitiesScheduled.bind(this);
    this.getFeedbackAnswerOptionsforCustomSelectOptions =
      this.getFeedbackAnswerOptionsforCustomSelectOptions.bind(this);
  }

  private get isAllDataValid(): boolean {
    if (!this.state.selectedActivityActions || this.state.selectedActivityActions.length === 0) {
      return false;
    }

    if (this.canAssignOperatorToActivity(this.state.selectedActivityActions)) {
      return true;
    }

    const activityActionItem = this.state.selectedActivityActions[0];
    if (!activityActionItem.activityStatus) {
      return false;
    }

    let allRequiredFeedbackAnswered = true;
    this.props.activityDetails?.forEach((feedbackDetails) => {
      const feedbackAnswer = activityActionItem.activityActionFeedback?.find(
        (activityFeedback) => feedbackDetails.id === activityFeedback.id
      );
      if (
        feedbackDetails.required &&
        (!feedbackAnswer || !feedbackAnswer.response) &&
        this.showFeedback(activityActionItem, feedbackDetails)
      ) {
        allRequiredFeedbackAnswered = false;
      }
    });

    return allRequiredFeedbackAnswered;
  }

  private get modalHook(): HTMLElement {
    let modalHook = document.getElementById("modal");
    if (!modalHook) {
      modalHook = document.createElement("div");
      modalHook.setAttribute("id", "portal");
      document.body.appendChild(modalHook);
    }

    return modalHook;
  }

  private closeModal(): void {
    this.setState({ ...this.initialState }, () => this.props.onClose());
  }

  private onSave(): void {
    if (!this.isAllDataValid) {
      NotificationManager.error(TranslationMapper.pages.routewizard.error_not_all_required_data);
      return;
    }

    this.props.onSave(this.state.selectedActivityActions, this.state.isFinishingActivity);
    this.closeModal();
  }

  private onAnswerChange(input: string | number, id?: string): void {
    const activityActions = this.state.selectedActivityActions ?? [];
    activityActions.forEach((item) => {
      if (!item.activityActionFeedback || item.activityActionFeedback.length === 0) {
        item.activityActionFeedback = _.cloneDeep(this.props.activityDetails);
      }

      const feedback = item.activityActionFeedback?.find((activityFeedback) => activityFeedback.id === id);
      if (feedback) {
        feedback.response = input.toString();
      }
    });

    this.setState({
      selectedActivityActions: activityActions,
    });
  }

  private onSelectAnswerChange(answerId: string | number, id?: string): void {
    const feedbackDetails = this.props.activityDetails?.find((f) => f.id === id);
    if (!feedbackDetails) {
      return;
    }

    const selectedAnswer = feedbackDetails.feedbackQuestionAnswerOptions.find((a) => a.id === answerId)?.answer;
    if (!selectedAnswer) {
      return;
    }

    const activityActions = this.state.selectedActivityActions ?? [];
    activityActions.forEach((item) => {
      if (!item.activityActionFeedback || item.activityActionFeedback.length === 0) {
        item.activityActionFeedback = _.cloneDeep(this.props.activityDetails);
      }

      const feedback = item.activityActionFeedback?.find((f) => f.id === id);
      if (feedback) {
        feedback.response = selectedAnswer;
      }
    });

    this.setState({
      selectedActivityActions: activityActions,
    });
  }

  private setActivityActionsApproved(): void {
    const activityActions = this.state.selectedActivityActions ?? [];
    activityActions.forEach((item) => {
      if (item.activityStatus === ActivityStatus.Finished) {
        item.activityStatus = ActivityStatus.Scheduled;
        this.setState({
          selectedActivityActions: activityActions,
          isFinishingActivity: false,
        });
      } else {
        item.activityStatus = ActivityStatus.Finished;
        this.clearOperatorInfoOnActivity(item);
        this.clearFeedback(item.activityActionFeedback);
        this.setState({
          selectedActivityActions: activityActions,
          isFinishingActivity: true,
        });
      }
      this.clearOperatorInfoOnActivity(item);
      this.clearFeedback(item.activityActionFeedback);
    });
  }

  private setActivityActionsRejected(): void {
    const activityActions = this.state.selectedActivityActions ?? [];
    activityActions.forEach((item) => {
      if (item.activityStatus === ActivityStatus.Cancelled) {
        item.activityStatus = ActivityStatus.Scheduled;
        this.setState({
          selectedActivityActions: activityActions,
          isFinishingActivity: false,
        });
      } else {
        item.activityStatus = ActivityStatus.Cancelled;
        this.setState({
          selectedActivityActions: activityActions,
          isFinishingActivity: true,
        });
      }
      this.clearOperatorInfoOnActivity(item);
      this.clearFeedback(item.activityActionFeedback);
    });
  }

  private clearOperatorInfoOnActivity(activityAction: IActivityAction): void {
    if (activityAction.activityStatus !== ActivityStatus.Scheduled) {
      activityAction.operatorId = undefined;
      activityAction.operatorName = "";
      this.setState({
        selectedOperator: undefined,
      });
    }
  }

  private clearFeedback(activityActionFeedback?: IActivityActionFeedback[]): void {
    if (activityActionFeedback != null) {
      activityActionFeedback.forEach((item) => {
        item.response = "";
      });
    }
  }

  private get approvedButtonClassName(): string {
    const activityActionItem = this.state.selectedActivityActions[0];
    if (activityActionItem.activityStatus === ActivityStatus.Finished) {
      return "";
    }

    return " modal--activity__inactive";
  }

  private get rejectedButtonClassName(): string {
    const activityActionItem = this.state.selectedActivityActions[0];
    if (activityActionItem.activityStatus === ActivityStatus.Cancelled) {
      return "";
    }

    return " modal--activity__inactive";
  }

  private getIsInvalidInput(requestId: string): boolean {
    const feedbackAnswer = this.state.selectedActivityActions[0].activityActionFeedback?.find(
      (a) => a.id === requestId
    );
    const feedbackDetails = this.props.activityDetails?.find((activityFeedback) => requestId === activityFeedback.id);

    if (feedbackDetails == null) {
      return true;
    }

    if (
      feedbackDetails.required &&
      (!feedbackAnswer || !feedbackAnswer.response) &&
      this.showFeedback(this.state.selectedActivityActions[0], feedbackDetails)
    ) {
      return true;
    }

    return false;
  }

  private getFeedbackResponse(feedbackRequest: IActivityActionFeedback): string | undefined {
    const currentFeedback = this.state.selectedActivityActions;

    if (currentFeedback == null || currentFeedback.length === 0) {
      return undefined;
    }

    const responseAnswer = currentFeedback[0].activityActionFeedback?.find(
      (feedback) => feedback.id === feedbackRequest.id
    );

    return responseAnswer?.response;
  }

  private getFeedbackSelectResponse(feedbackRequest: IActivityActionFeedback): string | undefined {
    const currentFeedback = this.state.selectedActivityActions;

    if (currentFeedback == null || currentFeedback.length === 0) {
      return undefined;
    }

    const responseAnswer = currentFeedback[0].activityActionFeedback?.find(
      (feedback) => feedback.id === feedbackRequest.id
    );

    return responseAnswer?.feedbackQuestionAnswerOptions.find((option) => option.answer === responseAnswer?.response)
      ?.id;
  }

  private showFeedback(activityActionItem: IActivityAction, feedback: IActivityActionFeedbackDetails): boolean {
    return (
      activityActionItem.activityStatus != null &&
      activityActionItem.activityStatus !== ActivityStatus.Scheduled &&
      ((feedback.whenCancelled && feedback.whenFinished) ||
        (feedback.whenFinished && activityActionItem.activityStatus === ActivityStatus.Finished) ||
        (feedback.whenCancelled && activityActionItem.activityStatus === ActivityStatus.Cancelled))
    );
  }

  private getAnswerDescription(answer?: string | number): string {
    const answerText = toLocaleString(answer);

    if (isNullOrEmpty(answerText)) {
      return "";
    }

    const translationMapper = TranslationMapper.activityactions.feedback[LanguageProvider.keyToMapperKey(answerText)];

    if (translationMapper == null) {
      return answerText;
    }
    return LanguageProvider.t(translationMapper);
  }

  private getFeedbackAnswerOptionsforCustomSelectOptions(
    feedbackAnswerOptions: IActivityFeedbackAnswerOption[]
  ): ICustomSelectOption[] {
    const result = [] as ICustomSelectOption[];

    for (const feedbackAnswer of feedbackAnswerOptions) {
      result.push({
        key: feedbackAnswer.id,
        optionName: this.getAnswerDescription(feedbackAnswer.answer),
      } as ICustomSelectOption);
    }

    return result;
  }

  private getOperatorsCustomSelectOptions(): ICustomSelectOption[] {
    const customerVenueOperators = this.props.customerVenueOperators?.map((v) => ({
      key: v.id,
      optionName: v.name,
    }));

    customerVenueOperators?.unshift({
      key: this.deselectOperator,
      optionName: LanguageProvider.t(TranslationMapper.pages.activityplanning.editdialog.unassignoperator),
    });

    return customerVenueOperators as ICustomSelectOption[];
  }

  private renderFeedbackQuestion(
    activityActionItem: IActivityAction,
    feedbackDetails: IActivityActionFeedbackDetails,
    index: number
  ): JSX.Element {
    return (
      <div key={index}>
        {this.showFeedback(activityActionItem, feedbackDetails) && (
          <Row>
            <Col className="modal__col-mb">
              <Form.Group>
                <Form.Label>{TranslationUtils.getFeedbackQuestionDescription(feedbackDetails.question)}</Form.Label>
                {this.renderFeedbackInput(feedbackDetails)}
              </Form.Group>
            </Col>
          </Row>
        )}
      </div>
    );
  }

  private renderFeedbackInput(feedback: IActivityActionFeedback): JSX.Element {
    switch (feedback.questionType) {
      case FeedbackRequestType["pages.masterdata.activities.text"]:
        return (
          <TextInput
            id={feedback.id}
            inputDisabled={false}
            invalid={this.getIsInvalidInput(feedback.id)}
            onAnswerChange={(answer): void => this.onAnswerChange(answer, feedback.id)}
            answer={this.getFeedbackResponse(feedback)}
          />
        );
      case FeedbackRequestType["pages.masterdata.activities.number"]:
        return (
          <NumericInput
            id={feedback.id}
            inputDisabled={false}
            invalid={this.getIsInvalidInput(feedback.id)}
            onAnswerChange={(answer): void => this.onAnswerChange(answer, feedback.id)}
            answer={this.getFeedbackResponse(feedback)}
          />
        );
      case FeedbackRequestType["pages.masterdata.activities.single_select_pick_list"]:
        return (
          <select
            className={`form-select${this.getIsInvalidInput(feedback.id) ? " is-invalid" : ""}`}
            onChange={(event): void => this.onSelectAnswerChange(event.target.value, feedback.id)}
          >
            <option value="">{LanguageProvider.t(TranslationMapper.activityactions.select_answer)}</option>
            {this.getFeedbackAnswerOptionsforCustomSelectOptions(feedback.feedbackQuestionAnswerOptions).map((o) => (
              <option key={o.key} value={o.key} selected={this.getFeedbackSelectResponse(feedback) === o.key}>
                {o.optionName}
              </option>
            ))}
          </select>
        );
      case FeedbackRequestType["pages.masterdata.activities.slider"]:
        return (
          <div className="d-flex flex-column">
            <SliderInput
              onSelectionChange={(answer): void => this.onAnswerChange(answer, feedback.id)}
              onSliderChange={(answer): void => this.onAnswerChange(answer, feedback.id)}
              min={1}
              max={100}
              answer={
                this.getFeedbackResponse(feedback) ? (this.getFeedbackResponse(feedback) as unknown as number) : 1
              }
              sliderWidth="100%"
            />
          </div>
        );

      default:
        return <></>;
    }
  }

  private hasOnlySelectedAssignableActivities(activityActions: IActivityAction[]): boolean {
    if (
      this.props.customerVenueOperators == null ||
      this.props.customerVenueOperators?.length === 0 ||
      activityActions.some((a) => !a.isAssignable)
    ) {
      return false;
    }

    return true;
  }

  private canAssignOperatorToActivity(activityActions: IActivityAction[]): boolean {
    return this.areActivitiesScheduled(activityActions) && this.areActivitiesAtSameLocation(activityActions);
  }

  private areActivitiesScheduled(activityActions: IActivityAction[]): boolean {
    return activityActions.every((a) => a.activityStatus === ActivityStatus.Scheduled);
  }

  private areActivitiesAtSameLocation(activityActions: IActivityAction[]): boolean {
    if (activityActions.length === 1) {
      return true;
    }

    const uniqueIds = [...new Set(activityActions.map((obj) => obj.customerVenueId))];
    // User has not selected activities in the same building when multiple unique Ids are found.
    if (uniqueIds.length === 1) {
      return true;
    }

    return false;
  }

  private onSelectingOperator(operator: string): void {
    if (operator == null) {
      return;
    }

    const activityActions = this.state.selectedActivityActions ?? [];
    const value = this.props.customerVenueOperators?.find((x) => x.id === operator);
    if (value !== undefined) {
      activityActions.forEach((item) => {
        item.operatorId = operator;
      });
    } else {
      activityActions.forEach((item) => {
        item.operatorId = undefined;
      });
    }

    this.setState({
      selectedActivityActions: activityActions,
      selectedOperator: operator,
      isFinishingActivity: false,
    });
  }

  public render(): React.ReactNode {
    if (!this.state.selectedActivityActions || this.state.selectedActivityActions.length === 0) {
      return <></>;
    }

    const activityActionItem = this.state.selectedActivityActions[0];

    return (
      <>
        {this.modalHook &&
          ReactDOM.createPortal(
            <Modal backdrop="static" show={true} onHide={this.closeModal} centered>
              <Modal.Header closeButton>
                <div className="modal-header__info">
                  <div>
                    <h1 className="modal-title">
                      {LanguageProvider.t(TranslationMapper.pages.activityplanning.editdialog.title)}
                    </h1>
                    <h5 className="modal-title">
                      {LanguageProvider.t(TranslationMapper.pages.activityplanning.editdialog.subtitle)}
                    </h5>
                  </div>
                </div>
              </Modal.Header>
              <Modal.Body>
                <Form data-testid="activityactions-modal-form">
                  <Row>
                    <Col md={6} className="modal__col-mb">
                      <Form.Group>
                        <Form.Label>
                          {LanguageProvider.t(TranslationMapper.pages.activityplanning.activityheader)}
                        </Form.Label>
                        <div className="small">
                          {TranslationUtils.getActivityDescription(activityActionItem.description)}
                        </div>
                      </Form.Group>
                    </Col>
                    <Col md={6} className="modal__col-mb">
                      <Form.Group>
                        <Form.Label>
                          {LanguageProvider.t(TranslationMapper.pages.activityplanning.editdialog.selectedheader)}
                        </Form.Label>
                        <div className="small">{this.state.selectedActivityActions.length}</div>
                      </Form.Group>
                    </Col>
                  </Row>
                  {this.hasOnlySelectedAssignableActivities(this.state.selectedActivityActions) &&
                    this.props.customerVenueOperators != null &&
                    this.props.customerVenueOperators.length > 0 && (
                      <Row>
                        <Col className="modal__col-mb">
                          <Form.Group>
                            <div>
                              <Form.Label>
                                {LanguageProvider.t(
                                  TranslationMapper.pages.activityplanning.editdialog.assignoperatorheader
                                )}
                              </Form.Label>
                              <div>
                                {
                                  <CustomSelect
                                    isDisabled={!this.canAssignOperatorToActivity(this.state.selectedActivityActions)}
                                    onChange={(operator): void => this.onSelectingOperator(operator)}
                                    enableSearch={true}
                                    options={this.getOperatorsCustomSelectOptions()}
                                    placeholder={LanguageProvider.t(TranslationMapper.pages.route.selectoperator)}
                                    selectedKey={this.state.selectedOperator}
                                  />
                                }
                                {!this.areActivitiesScheduled(this.state.selectedActivityActions) && (
                                  <p className="small text-warning mb-0">
                                    {LanguageProvider.t(
                                      TranslationMapper.pages.activityplanning.editdialog.assignoperatorerror
                                    )}
                                  </p>
                                )}
                                {!this.areActivitiesAtSameLocation(this.state.selectedActivityActions) && (
                                  <p className="small text-warning mb-0">
                                    {LanguageProvider.t(
                                      TranslationMapper.pages.activityplanning.editdialog
                                        .assignoperatormultiplebuildingserror
                                    )}
                                  </p>
                                )}
                              </div>
                            </div>
                          </Form.Group>
                        </Col>
                      </Row>
                    )}
                  <Row>
                    <Col className="modal__col-mb">
                      <Form.Group>
                        <Form.Label>
                          {LanguageProvider.t(TranslationMapper.pages.activityplanning.editdialog.statusheader)}
                        </Form.Label>
                        <div>
                          <FontAwesomeIcon
                            icon={["fas", "circle-xmark"]}
                            size="5x"
                            className={`text-warning cursor--pointer${this.rejectedButtonClassName}`}
                            onClick={(): void => this.setActivityActionsRejected()}
                          />
                          <FontAwesomeIcon
                            icon={["fas", "circle-check"]}
                            size="5x"
                            className={`text-success cursor--pointer ms-4-5${this.approvedButtonClassName}`}
                            onClick={(): void => this.setActivityActionsApproved()}
                          />
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                  {this.props.activityDetails != null && this.props.activityDetails.length > 0 && (
                    <>
                      {this.props.activityDetails.map((feedback, index) =>
                        this.renderFeedbackQuestion(activityActionItem, feedback, index)
                      )}
                    </>
                  )}
                </Form>
              </Modal.Body>
              <Modal.Footer className="d-flex justify-content-between">
                <Button
                  className="btn-outline-secondary"
                  onClick={this.closeModal}
                  resourceLabel={LanguageProvider.t(TranslationMapper.buttons.cancel)}
                  iconEnd="times"
                ></Button>
                <Button
                  className="btn-primary"
                  disabled={!this.isAllDataValid}
                  onClick={this.onSave}
                  resourceLabel={LanguageProvider.t(TranslationMapper.buttons.save)}
                  iconEnd="floppy-disk"
                ></Button>
              </Modal.Footer>
            </Modal>,
            this.modalHook
          )}
      </>
    );
  }
}

export default withTranslation()(withTelemetry(ActivityActionsModal, "ActivityActionsModal"));
