import IFile from "interfaces/IFile";

export default class FileUtils {
  public static handleFileChange(
    event: any,
    allowedFileTypes: string[],
    onComplete: (file: IFile) => void,
    validateForm?: (fileData: string) => boolean
  ): void {
    const changedFile: File = event.target.files[0];
    const file: IFile = {
      data: "",
      url: "",
      wrongFormat: false,
      name: "",
      validated: false,
    };

    if (changedFile == null) {
      onComplete(file);
      return;
    }

    if (!allowedFileTypes.some(type => changedFile.type === type)) {
      file.wrongFormat = true;
      onComplete(file);
      return;
    }

    const myReader: FileReader = new FileReader();
    myReader.onloadend = (): void => {
      const fileData = myReader.result as string;
      let validated = false;
      if (validateForm) {
        validated = validateForm(fileData);
      }

      file.data = fileData;
      file.url = URL.createObjectURL(changedFile);
      file.wrongFormat = false;
      file.name = changedFile.name;
      file.validated = validated;
      onComplete(file);
    };
    myReader.readAsDataURL(changedFile);
  }
}