import TranslationMapper from "i18n/mapper";
import ICleaningObjectContainer from "interfaces/ICleaningObjectContainer";
import _ from "lodash";
import LanguageProvider from "providers/languageProvider";
import React from "react";
import { connect } from "react-redux";
import { RootState } from "store/reducers/rootReducer";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ICleaningObject from "../../interfaces/ICleaningObject";
import ISelectCleaningObjectsProps, {
  ISelectCleaningObjectsStateProps,
} from "./interfaces/ISelectCleaningObjectsProps";
import ISelectCleaningObjectsState from "./interfaces/ISelectCleaningObjectsState";

class SelectCleaningObjects extends React.Component<ISelectCleaningObjectsProps, ISelectCleaningObjectsState> {
  public constructor(props: ISelectCleaningObjectsProps) {
    super(props);
    this.addAllCleaningObjects = this.addAllCleaningObjects.bind(this);
  }

  private get isStartZone(): boolean {
    return this.props.cleaningObjects.length === 0;
  }

  private addAllCleaningObjects(container: ICleaningObjectContainer): void {
    const selectedCleaningObjects = _.cloneDeep(this.props.cleaningObjects);

    if (this.props.cleaningObjects.some(x => x.id === container.id)) {
      this.props.cleaningObjects.forEach((c, index) => {
        if (c.id === container.id) {
          selectedCleaningObjects[index].cleaningObjects = container.cleaningObjects;
        }
      });
    } else {
      selectedCleaningObjects.push(container);
    }

    const cleaningObjects = selectedCleaningObjects.flatMap(
      selectedCleaningObject => selectedCleaningObject.cleaningObjects
    );
    if (cleaningObjects && cleaningObjects.length > 0 && cleaningObjects[0]) {
      cleaningObjects[0].isStartZone = true;
    }

    this.emitOnChange(selectedCleaningObjects);
  }

  private addCleaningObject(cleaningObject: ICleaningObject, container: ICleaningObjectContainer): void {
    const selectedCleaningObjects = _.cloneDeep(this.props.cleaningObjects);

    if (this.props.cleaningObjects.some(x => x.id === container.id)) {
      this.props.cleaningObjects.forEach((c, index) => {
        if (c.id === container.id) {
          selectedCleaningObjects[index].cleaningObjects?.push(cleaningObject);
        }
      });
    } else {
      const newContainer: ICleaningObjectContainer = {
        id: container.id,
        name: container.name,
        venueId: container.venueId,
        cleaningObjects: [cleaningObject],
      };
      cleaningObject.isStartZone = this.isStartZone;
      selectedCleaningObjects.push(newContainer);
    }

    this.emitOnChange(selectedCleaningObjects);
  }

  private emitOnChange(container: ICleaningObjectContainer[]): void {
    this.props.onChange({
      target: {
        name: this.props.name,
        value: container,
      },
    });
  }

  private renderCleaningObject(cleaningObject: ICleaningObject, container: ICleaningObjectContainer): JSX.Element {
    return (
      <div className="accordion-body d-flex justify-content-between" key={cleaningObject.id}>
        <h5>{cleaningObject.name}</h5>
        {this.objectButtonIsChecked(cleaningObject) && (
          <button className="btn btn-link accordion--btn-action pe-none">
            <FontAwesomeIcon icon={["fas", "circle-check"]} fixedWidth size="lg" id={`added_${cleaningObject.id}`} />
          </button>
        )}
        {!this.objectButtonIsChecked(cleaningObject) && (
          <button
            type="button"
            className="btn btn-link accordion--btn-action"
            onClick={(): void => this.addCleaningObject(cleaningObject, container)}
          >
            <FontAwesomeIcon id={`add_${cleaningObject.id}`} icon={["fal", "circle-plus"]} fixedWidth size="lg" />
          </button>
        )}
      </div>
    );
  }

  private objectButtonIsChecked(cleaningObject: ICleaningObject): boolean {
    const cleaningObjects =
      this.props.cleaningObjects.find(x => x.id === cleaningObject.parentId)?.cleaningObjects ?? [];
    return cleaningObjects.map(x => x.id).includes(cleaningObject.id);
  }

  public renderContainer(container: ICleaningObjectContainer): JSX.Element {
    return (
      <div className="accordion-item">
        <h2 className="accordion-header" id={`heading${container.id}`}>
          <button
            className="accordion-button collapsed accordion-button--has-icon"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target={`#collapse${container.id}`}
            aria-expanded="false"
            aria-controls={`collapse${container.id}`}
          >
            {container.name}
            {this.containerButtonIsChecked(container) && (
              <div className="btn btn-link accordion--btn-action cursor--default">
                <FontAwesomeIcon icon={["fas", "circle-check"]} fixedWidth id={`added_${container.id}`} size="lg" />
              </div>
            )}
            {!this.containerButtonIsChecked(container) && (
              <div
                className="btn btn-link accordion--btn-action"
                onClick={(): void => this.addAllCleaningObjects(container)}
                id={`add_${container.id}`}
              >
                <FontAwesomeIcon icon={["fal", "circle-plus"]} size="lg" fixedWidth />
              </div>
            )}
          </button>
        </h2>
        <div
          id={`collapse${container.id}`}
          className="accordion-collapse collapse"
          aria-labelledby={container.id}
          data-bs-parent="#accordion-select"
        >
          {container.cleaningObjects?.map((cleaningObject: ICleaningObject) =>
            this.renderCleaningObject(cleaningObject, container)
          )}
        </div>
      </div>
    );
  }

  private containerButtonIsChecked(container: ICleaningObjectContainer): boolean {
    const selectedCleaningObjects = this.props.cleaningObjects.find(c => c.id === container.id);
    return selectedCleaningObjects?.cleaningObjects?.length === container.cleaningObjects?.length;
  }

  public render(): JSX.Element {
    return (
      <>
        <label className="form-label">
          {LanguageProvider.t(TranslationMapper.pages.routewizard.cleaningobjects.allobjects)} (
          {this.props.totalNumberOfZones})
        </label>
        <div className="modal-cleaning-object__block-container">
          <div className="accordion" id="accordion-select">
            {this.props.selectableCleaningObjects.map(container => this.renderContainer(container))}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: RootState, props: ISelectCleaningObjectsProps): ISelectCleaningObjectsStateProps => {
  const venue = state.locationState.externalVenues.find(e => e.id === props.venue.externalVenueId);
  const floors = venue?.floors ?? [];
  const totalNumberOfZones = floors.reduce((count, f) => count + f.zones.length, 0) ?? 0;

  const container = floors.map(floor => {
    return {
      id: floor.id,
      name: floor.name,
      venueId: floor.venueId,
      cleaningObjects: floor.zones.map(zone => {
        return {
          id: zone.id,
          name: zone.name,
          parentId: floor.id,
          sequence: 0,
          isStartZone: false,
        };
      }),
    };
  });

  return {
    selectableCleaningObjects: container.filter(x => x.cleaningObjects.length > 0),
    totalNumberOfZones,
  };
};

export default connect(mapStateToProps, null)(SelectCleaningObjects);
