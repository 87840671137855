import ErrorTypes from "enums/errorTypes";

export interface IErrorStoreState {
  errors: ErrorTypes[];
  errorCode?: string;
}

export const initialErrorStoreState: IErrorStoreState = {
  errors: [],
};
