import { Button } from "components/material/buttons/buttons";
import FileTypes from "enums/fileTypes";
import LoaderTypes from "enums/loaderTypes";
import TranslationMapper from "i18n/mapper";
import IFile from "interfaces/IFile";
import LanguageProvider from "providers/languageProvider";
import * as React from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import ReactDOM from "react-dom";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { clearUploadLocationResult } from "store/actions/externalVenueActions";
import { RootState } from "store/reducers/rootReducer";
import FileUtils from "utils/fileUtils";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import UploadFailed from "../../../assets/img/illustration/upload-failed.svg";
import UploadSucceeded from "../../../assets/img/illustration/upload-succeeded.svg";
import CsvTemplateFile from "../../../files/LocationUploadTemplate.csv";
import ICsvUploadModalProps, {
  ICsvUploadModalDispatchProps,
  ICsvUploadModalStateProps,
} from "../interfaces/ICsvUploadModalProps";
import ICsvUploadModalState from "../interfaces/ICsvUploadModalState";

class CsvUploadModal extends React.Component<ICsvUploadModalProps, ICsvUploadModalState> {
  private readonly formName: string = "upload-file-form";

  public CSVDownloadRef: React.RefObject<{ link: { click: () => void } }>;

  public constructor(props: ICsvUploadModalProps) {
    super(props);
    const state: ICsvUploadModalState = {
      isFormValid: false,
      show: true,
      uploadStarted: false,
    };

    this.state = state;

    this.handleSave = this.handleSave.bind(this);
    this.cancelForm = this.cancelForm.bind(this);
    this.clearForm = this.clearForm.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
    this.setFileState = this.setFileState.bind(this);
  }

  private get failedRowNumbers(): string {
    if (
      this.props.csvUploadResult?.failedRowNumbers == null ||
      this.props.csvUploadResult.failedRowNumbers.length === 0
    ) {
      return "-";
    }

    return this.props.csvUploadResult.failedRowNumbers.join(", ");
  }

  private cancelForm(): void {
    this.clearForm();
    this.props.onClearUploadResult();
    this.props.onClose();
  }

  private clearForm(): void {
    this.setState({
      isFormValid: false,
      show: false,
      uploadStarted: false,
    });
  }

  private handleSave(): void {
    if (!this.state.csvFile || !this.state.isFormValid) {
      return;
    }
    this.setState({
      uploadStarted: true,
    });

    this.props.onSubmit(this.state.csvFile);
  }

  private setFileState(csvFile: IFile): void {
    this.props.onClearUploadResult();
    this.setState({
      csvFile: csvFile,
      isFormValid: !csvFile.wrongFormat,
      uploadStarted: false,
    });
  }

  private onFileChange(event: any): void {
    FileUtils.handleFileChange(event, [FileTypes.CSV], this.setFileState);
  }

  private onFileClick(event: any): void {
    event.target.value = null;
  }

  private get modalHook(): HTMLElement {
    let modalHook = document.getElementById("modal");
    if (!modalHook) {
      modalHook = document.createElement("div");
      modalHook.setAttribute("id", "portal");
      document.body.appendChild(modalHook);
    }

    return modalHook;
  }

  public render(): JSX.Element {
    return (
      <>
        {this.modalHook &&
          ReactDOM.createPortal(
            <Modal backdrop="static" show={this.state.show} onHide={this.cancelForm} centered>
              <Modal.Header closeButton>
                <div className="modal-header__info">
                  <h1 className="modal-title">
                    {LanguageProvider.t(TranslationMapper.pages.externalvenue.upload_file_modal_title)}
                  </h1>
                </div>
              </Modal.Header>
              <Modal.Body>
                <Form data-test="upload-file-form" id={this.formName} className="upload-file-form">
                  <Row>
                    <Col className="modal__col-mb">
                      <label htmlFor="csvFileUpload" className="form-label">
                        {LanguageProvider.t(TranslationMapper.pages.externalvenue.csv_file)}
                      </label>
                      <input
                        className="form-control"
                        type="file"
                        id="csvFileUpload"
                        name="csvFileUpload"
                        accept=".csv"
                        multiple={false}
                        onChange={this.onFileChange}
                        onClick={this.onFileClick}
                        disabled={this.props.csvUploadResult?.succeeded}
                      />
                      {this.state.csvFile?.wrongFormat && (
                        <div className="invalid-feedback d-block">
                          {LanguageProvider.t(TranslationMapper.pages.externalvenue.file_wrong_format)}
                        </div>
                      )}
                      <div className="mt-2">
                        <a href={CsvTemplateFile} target="_blank" rel="noreferrer" className="small-fixed">
                          {LanguageProvider.t(TranslationMapper.pages.externalvenue.download_template)}
                          <FontAwesomeIcon icon={["fas", "arrow-down-to-line"]} fixedWidth />
                        </a>
                      </div>
                    </Col>
                  </Row>
                </Form>
                {this.state.uploadStarted && !this.props.isLoading && (
                  <div className="modal__message">
                    {this.props.csvUploadResult != null && this.props.csvUploadResult.succeeded && (
                      <>
                        <img src={UploadSucceeded} alt="Success" />
                        <div className="modal__message__block">
                          <h2 className="text-infographic">
                            {LanguageProvider.t(TranslationMapper.pages.externalvenue.succeeded)}
                          </h2>
                          <p>{LanguageProvider.t(TranslationMapper.pages.externalvenue.succeeded_text)}</p>
                        </div>
                      </>
                    )}
                    {this.props.csvUploadResult != null && !this.props.csvUploadResult.succeeded && (
                      <>
                        <img src={UploadFailed} alt="Failed" />
                        <div className="modal__message__block">
                          <h2 className="text-infographic">
                            {LanguageProvider.t(TranslationMapper.pages.externalvenue.failed)}
                          </h2>
                          <p>{LanguageProvider.t(TranslationMapper.pages.externalvenue.failed_text)}</p>
                        </div>
                        <div className="modal__message__block">
                          <h3>{LanguageProvider.t(TranslationMapper.pages.externalvenue.failed_rows)}</h3>
                          <p>{this.failedRowNumbers}</p>
                        </div>
                      </>
                    )}
                  </div>
                )}
              </Modal.Body>
              {(!this.state.uploadStarted ||
                this.props.isLoading ||
                this.props.csvUploadResult?.succeeded === false) && (
                <Modal.Footer className="justify-content-between">
                  <Button
                    onClick={this.cancelForm}
                    resourceLabel={LanguageProvider.t(TranslationMapper.buttons.cancel)}
                    className="btn-outline-secondary"
                    iconEnd="xmark"
                  />
                  <Button
                    disabled={!this.state.isFormValid || this.state.uploadStarted}
                    resourceLabel={LanguageProvider.t(TranslationMapper.buttons.execute)}
                    onClick={this.handleSave}
                    className="btn-primary"
                    iconEnd="arrow-up-to-line"
                  />
                </Modal.Footer>
              )}
              {this.props.csvUploadResult?.succeeded && (
                <Modal.Footer>
                  <Button
                    onClick={this.cancelForm}
                    resourceLabel={LanguageProvider.t(TranslationMapper.buttons.close_short)}
                    className="btn-primary"
                    iconEnd="xmark"
                  />
                </Modal.Footer>
              )}
            </Modal>,
            this.modalHook
          )}
      </>
    );
  }
}

const mapStateToProps = (state: RootState): ICsvUploadModalStateProps => ({
  csvUploadResult: state.locationState.uploadLocationResult,
  isLoading: state.generalState.loaders.some(l => l === LoaderTypes.UploadFile),
});

const mapDispatchToProps: ICsvUploadModalDispatchProps = {
  onClearUploadResult: clearUploadLocationResult,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CsvUploadModal));
