import React, { Component, ReactNode } from "react";
import { connect } from "react-redux";

import { IInputChangeEvent } from "../../components/interfaces/IInputChangeEvent";
import SearchableMultiSelect from "../../components/material/select/searchableMultiSelect";
import IOperator from "../../interfaces/IOperator";
import { RootState } from "../../store/reducers/rootReducer";
import { ISearchableMultiSelectOption, ISearchableSelectOption } from "../material/select/interfaces/ISearchableSelectProps";
import Select from "../material/select/searchableSelect";
import ISelectOperatorProps, { ISelectOperatorStateProps } from "./interfaces/ISelectOperatorProps";

class SelectOperator extends Component<ISelectOperatorProps> {
	public constructor(props: ISelectOperatorProps) {
		super(props);

		this.onSelectChange = this.onSelectChange.bind(this);
	}

	private getItemAsSelectOptionFn(operator: IOperator): ISearchableSelectOption {
		return {
			id: operator.id,
			label: operator.name,
		};
	}

	private getItemAsMultiSelectOptionFn(operator: IOperator): ISearchableMultiSelectOption {
		return {
			value: operator.id,
			label: operator.name,
		};
	}

	private onSelectChange(event: IInputChangeEvent<any>): void {
		if (this.props.onChange != null) {
			let value = event.target.value;

			if (!Array.isArray(value)) {
				value = [value];
			}

			this.props.onChange({
				target: {
					name: this.props.name,
					value,
				},
			});
		}
	}

	public render(): ReactNode {
		if (this.props.multiSelect) {
			return (
				<SearchableMultiSelect
					options={this.props.operators ?? []}
					value={this.props.value ?? []}
					name={this.props.name}
					onChange={this.onSelectChange}
					labelledBy="Operators"
					getItemAsSelectOptionFn={this.getItemAsMultiSelectOptionFn}
				/>
			);
		}

		const singleSelectValue = this.props.value?.length === 1 ? this.props.value[0] : undefined;
		return (
			<Select
				id={this.props.id}
				name={this.props.name}
				value={singleSelectValue}
				onChange={this.onSelectChange}
				options={this.props.operators ?? []}
				getItemAsSelectOptionFn={this.getItemAsSelectOptionFn}
			/>
		);
	}
}

const mapStateToProps = (state: RootState): ISelectOperatorStateProps => {
	const operators =
		state.operatorState.operators != null && state.customerState.selectedCustomerId != null
			? state.operatorState.operators[state.customerState.selectedCustomerId] ?? []
			: [];

	return {
		operators: operators,
	};
};

export default connect(mapStateToProps)(SelectOperator);