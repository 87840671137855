import { ActionTypes, ActionTypesCollection } from "store/actionTypes";
import { IGeneralStoreState, initialGeneralStoreState } from "store/state/generalStoreState";

export default function generalReducer(
  state: IGeneralStoreState = initialGeneralStoreState,
  action: ActionTypesCollection
): IGeneralStoreState {
  switch (action.type) {
    case ActionTypes.START_LOADING: {
      return {
        ...state,
        loaders: [...state.loaders, action.payload],
      };
    }
    case ActionTypes.FINISH_LOADING: {
      return {
        ...state,
        loaders: state.loaders.filter((l) => l !== action.payload),
      };
    }
    case ActionTypes.FETCHED_FEATUREFLAGS:
      return {
        ...state,
        featureFlags: action.payload,
      };
    default:
      return state;
  }
}

