import IIncidentType from "interfaces/IIncidentType";
import INotificationCategory from "interfaces/INotificationCategory";
import INotificationOverview from "interfaces/INotificationOverview";
import INotificationCategoryDetails from "pages/masterdata/components/notificationCategories/interfaces/INotificationCategoryDetails";
import Dictionary from "utils/dictionary";

export interface INotificationStoreState {
  incidentTypes?: Dictionary<IIncidentType[]>;
  notifications?: INotificationOverview[];
  notificationCategories?: INotificationCategory[];
  selectedNotificationCategory?: INotificationCategoryDetails;
}

export const initialNotificationStoreState: INotificationStoreState = {};
