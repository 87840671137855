import TranslationMapper from "i18n/mapper";
import LanguageProvider from "providers/languageProvider";
import { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { RootState } from "store/reducers/rootReducer";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { FilterMenu } from "../../../components/filterMenu/filterMenu";
import IFilterValue from "../../../components/filterMenu/interfaces/IFilterValue";
import IMultiSelectDropdownField from "../../../components/filterMenu/interfaces/IMultiSelectDropdownField";
import PageHeader from "../../../components/header/pageHeader";
import { RoutingLinks } from "../../../constants/routingLinks";
import ActivityStatus from "../../../enums/activityStatus";
import LoaderTypes from "../../../enums/loaderTypes";
import Role from "../../../enums/role";
import IActivityAction from "../../../interfaces/IActivityAction";
import IActivityFilter from "../../../interfaces/IActivityFilter";
import IVenue from "../../../interfaces/IVenue";
import PageHeaderManager from "../../../models/pageHeaderManager";
import { withTelemetry } from "../../../services/telemetryService";
import {
  getActivityFeedbackRequests,
  getCustomerActivities,
  getCustomerVenueOperators,
  updateCustomerPlannedActivityOperator,
  updateCustomerPlannedActivityStatus,
} from "../../../store/actions/activityActions";
import { showCustomerSelection } from "../../../store/actions/customerActions";
import { resetActivity } from "../../../store/actions/masterDataActions";
import { objectOrArrayPropertyResolver } from "../../../store/storeUtils";
import DateUtils from "../../../utils/dateUtils";
import FilterUtils from "../../../utils/filterUtils";
import TranslationUtils from "../../../utils/translationUtils";
import { ActivitiesExport } from "../components/activitiesExport";
import { ExportType } from "../components/interfaces/ExportType";
import ActivityActionsModal from "./activityActionsModal";
import IPlanningActivitiesOverviewProps, {
  IPlanningActivitiesOverviewDispatchProps,
  IPlanningActivitiesOverviewStateProps,
} from "./interfaces/IPlanningActivitiesOverviewProps";
import IPlanningActivitiesOverviewState, {
  IPlanningActivitiesFilter,
} from "./interfaces/IPlanningActivitiesOverviewState";
import PlanningActivitiesTable from "./planningActivitiesTable";

class PlanningActivitiesOverview extends Component<IPlanningActivitiesOverviewProps, IPlanningActivitiesOverviewState> {
  public constructor(props: IPlanningActivitiesOverviewProps) {
    super(props);

    const state: IPlanningActivitiesOverviewState = {
      selectedActivityIds: [],
      filter: this.defaultFilter,
      activityActionsDialogModalOpen: true,
    };

    this.state = state;

    this.onUpdateSelectedActivities = this.onUpdateSelectedActivities.bind(this);
    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
    this.updateFilter = this.updateFilter.bind(this);
    this.editActivityActions = this.editActivityActions.bind(this);
    this.onModalClose = this.onModalClose.bind(this);
    this.updateActivityActions = this.updateActivityActions.bind(this);
    this.hasOnlySelectedAssignableActivities = this.hasOnlySelectedAssignableActivities.bind(this);
    this.areActivitiesAtSameLocation = this.areActivitiesAtSameLocation.bind(this);
    this.getActivityById = this.getActivityById.bind(this);

    this.setPageHeader();
  }

  public componentDidMount(): void {
    if (this.props.activeCustomerId != null) {
      this.props.getCustomerActivities(this.props.activeCustomerId, this.activityFilter);
    }

    this.props.showCustomerSelection(true);
    this.setPageHeader();
  }

  public componentWillUnmount(): void {
    PageHeaderManager.clear();
  }

  public componentDidUpdate(prevProps: IPlanningActivitiesOverviewProps): void {
    const newActiveCustomerId = this.props.activeCustomerId;

    if (newActiveCustomerId && prevProps.activeCustomerId && prevProps.activeCustomerId !== newActiveCustomerId) {
      this.setState(
        {
          filter: this.defaultFilter,
          selectedActivityIds: [],
        },
        () => this.props.getCustomerActivities(newActiveCustomerId, this.activityFilter)
      );
    }
  }

  private get defaultFilter(): IPlanningActivitiesFilter {
    const startDate = new Date();
    startDate.setHours(0, 0, 0, 0);
    startDate.setDate(startDate.getDate());
    const endDate = new Date();
    endDate.setHours(23, 59, 59, 999);

    return {
      startDate: startDate,
      endDate: endDate,
    };
  }

  private setPageHeader(): void {
    PageHeaderManager.clear();
    PageHeaderManager.add({
      pageName: "planning",
      tabs: [
        {
          id: "routes",
          relativeUrl: RoutingLinks.planningRoutes,
          translationLabel: "pages.routes.title",
          roles: [Role.FunctionalAdministrator, Role.Employee],
        },
        {
          id: "activities",
          relativeUrl: RoutingLinks.planningActivities,
          translationLabel: "pages.activityplanning.title",
          roles: [Role.FunctionalAdministrator, Role.Employee],
        },
      ],
    });
  }

  private updateFilter(propertyName: string, value: Date | IFilterValue[] | undefined): void {
    const activeCustomerId = this.props.activeCustomerId;
    if (!activeCustomerId) {
      return;
    }

    const filter: IPlanningActivitiesFilter = {
      ...this.state.filter,
      [propertyName]: value,
    };

    this.setState(
      {
        selectedActivityIds: [],
        filter,
      },
      () => this.props.getCustomerActivities(activeCustomerId, this.activityFilter)
    );
  }

  private async handleStartDateChange(startDate: Date): Promise<void> {
    this.updateFilter("startDate", startDate);
  }

  private async handleEndDateChange(endDate: Date): Promise<void> {
    this.updateFilter("endDate", endDate);
  }

  private getActivityById(id: string): IActivityAction | undefined {
    return this.props.activityActions.find(aa => aa.activityId === id);
  }

  private get selectedActivities(): IActivityAction[] {
    const selected: IActivityAction[] = [];

    this.state.selectedActivityIds.forEach(sa => {
      const activity = this.getActivityById(sa);
      if (activity && activity != null) {
        selected.push(activity);
      }
    });

    return selected;
  }

  private onUpdateSelectedActivities(activityIds: string[]): void {
    this.setState({
      selectedActivityIds: activityIds,
    });
  }

  private get activityFilter(): IActivityFilter {
    return {
      activityIds: this.state.filter.activities?.map(a => a.value) ?? [],
      locationIds: this.state.filter.locations?.map(a => a.value) ?? [],
      statuses: [ActivityStatus.Scheduled],
      dateFrom: DateUtils.formatDateForApi(this.state.filter.startDate),
      dateTo: DateUtils.formatDateForApi(this.state.filter.endDate),
      activityReference:
        this.state.filter.activityReference != null ? this.state.filter.activityReference[0].value : "",
      cleaningObjectName:
        this.state.filter.cleaningObjectNames != null ? this.state.filter.cleaningObjectNames[0].value : "",
      cleaningObjectReference:
        this.state.filter.cleaningObjectReferences != null ? this.state.filter.cleaningObjectReferences[0].value : "",
      operatorIds: [],
      feedbackRequestIds: [],
      takeLimited: true,
    };
  }

  private get dropdownFields(): IMultiSelectDropdownField[] {
    const locationMultiSelect = FilterUtils.createMultiSelect(
      this.props.locations.map(a => ({ value: a.id, label: a.name })),
      LanguageProvider.t(TranslationMapper.pages.activityplanning.filternames.location),
      (selectedOptions: IFilterValue[]) => {
        this.updateFilter("locations", selectedOptions);
      },
      this.state.filter.locations ?? []
    );

    const activityMultiSelect = FilterUtils.createMultiSelect(
      this.props.activities.map(a => ({
        value: a.activityId,
        label: TranslationUtils.getActivityDescription(a.activity.name),
      })),
      LanguageProvider.t(TranslationMapper.pages.activitylogging.filternames.activity),
      (selectedOptions: IFilterValue[]) => {
        this.updateFilter("activities", selectedOptions);
      },
      this.state.filter.activities ?? []
    );

    const cleaningObjectNameSearch = FilterUtils.createSearchFilter(
      LanguageProvider.t(TranslationMapper.pages.activitylogging.filternames.cleaningobject),
      (keyword: string) => {
        const searchFilterValue: IFilterValue[] | undefined = [{ label: "", value: keyword }];
        this.updateFilter("cleaningObjectNames", searchFilterValue);
      }
    );

    const cleaningObjectReferenceSearch = FilterUtils.createSearchFilter(
      LanguageProvider.t(TranslationMapper.pages.activitylogging.filternames.cleaningobjectreference),
      (keyword: string) => {
        const searchFilterValue: IFilterValue[] | undefined = [{ label: "", value: keyword }];
        this.updateFilter("cleaningObjectReferences", searchFilterValue);
      }
    );

    const activityReferenceSearch = FilterUtils.createSearchFilter(
      LanguageProvider.t(TranslationMapper.pages.activitylogging.filternames.activityreference),
      (keyword: string) => {
        const searchFilterValue: IFilterValue[] | undefined = [{ label: "", value: keyword }];
        this.updateFilter("activityReference", searchFilterValue);
      }
    );

    return [
      locationMultiSelect,
      cleaningObjectNameSearch,
      cleaningObjectReferenceSearch,
      activityMultiSelect,
      activityReferenceSearch,
    ];
  }

  private async editActivityActions(): Promise<void> {
    if (this.state.selectedActivityIds.length === 0) {
      return;
    }

    const activityActions = this.selectedActivities;
    const activityAction = activityActions[0];

    if (!activityAction || !activityAction.activityId) {
      return;
    }

    this.props.getSelectedActivityFeedbackRequests(activityAction.activityTypeId);

    if (
      this.areActivitiesAtSameLocation(activityActions) &&
      this.hasOnlySelectedAssignableActivities(activityActions)
    ) {
      this.props.getSelectedActivityCustomerVenueOperators(activityAction.customerVenueId);
    }

    this.setState({
      activityActionsDialogModalOpen: true,
    });
  }

  private onModalClose(): void {
    this.props.resetSelectedActivity();

    this.setState({
      activityActionsDialogModalOpen: false,
    });
  }

  private areActivitiesAtSameLocation(activityActions: IActivityAction[]): boolean {
    if (activityActions.length === 1) {
      return true;
    }

    const uniqueIds = [...new Set(activityActions.map(obj => obj.customerVenueId))];
    // User has not selected activities in the same building when multiple unique Ids are found.
    if (uniqueIds.length === 1) {
      return true;
    }

    return false;
  }

  private hasOnlySelectedAssignableActivities(activityActions: IActivityAction[]): boolean {
    return activityActions.every(a => a.isAssignable);
  }

  private updateActivityActions(selectedActivityActions: IActivityAction[], isFinishingActivity: boolean): void {
    if (this.props.activeCustomerId == null) {
      return;
    }

    if (isFinishingActivity) {
      this.props.updateActivityStatus(this.props.activeCustomerId, this.activityFilter, selectedActivityActions);
    } else {
      this.props.updateActivityStatusOperator(
        this.props.activeCustomerId,
        this.activityFilter,
        selectedActivityActions
      );
    }

    this.setState({
      selectedActivityIds: [],
    });
  }

  private renderHeaderWithButtons(): JSX.Element {
    return (
      <div className="header-actions__buttons">
        <ActivitiesExport
          disabled={this.props.isLoading || this.props.activityActions.length === 0}
          selectedActivities={this.selectedActivities}
          startDate={this.state.filter.startDate.toString()}
          endDate={this.state.filter.endDate.toString()}
          activeCustomerId={this.props.activeCustomerId}
          filter={this.activityFilter}
          useModal={false}
          defaultExportType={ExportType.ActivityPlanning}
        />
        <button
          className="btn btn-primary btn--rounded"
          disabled={this.state.selectedActivityIds.length === 0}
          onClick={this.editActivityActions}
        >
          <FontAwesomeIcon icon={["fal", "pen"]} fixedWidth />
        </button>
      </div>
    );
  }

  public render(): JSX.Element {
    return (
      <>
        <header className="header-actions">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 header-actions__content">
                <h1>{LanguageProvider.t(TranslationMapper.pages.activityplanning.pagetitle)}</h1>
                {this.renderHeaderWithButtons()}
              </div>
            </div>
          </div>
        </header>

        <PageHeader pageName="planning" />

        <FilterMenu
          startDate={this.state.filter.startDate}
          endDate={this.state.filter.endDate}
          handleEndDateChange={this.handleEndDateChange}
          handleStartDateChange={this.handleStartDateChange}
          filterFields={this.dropdownFields}
        />

        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 block-content--px planning-activities--overview">
                <PlanningActivitiesTable
                  isLoading={this.props.isLoading}
                  activityActions={this.props.activityActions}
                  onUpdateSelectedActivities={this.onUpdateSelectedActivities}
                  selectedActivityIds={this.state.selectedActivityIds}
                />
              </div>
            </div>
          </div>
        </div>

        {this.state.activityActionsDialogModalOpen && !this.props.isSelectedActivityLoading && (
          <ActivityActionsModal
            selectedActivityActions={this.selectedActivities}
            customerVenueOperators={this.props.customerVenueOperators}
            activityDetails={this.props.selectedActivityAction}
            onSave={this.updateActivityActions}
            onClose={this.onModalClose}
          />
        )}
      </>
    );
  }
}

/*
 * the resolver is defined outside the mapStateToProps function because
 *  it needs to memorize the locations array so it can detect if a
 *  new version of the locations property value is different than the
 *  memorized one
 */
const locationsResolver = objectOrArrayPropertyResolver<IVenue[]>();

const mapStateToProps = (state: RootState): IPlanningActivitiesOverviewStateProps => {
  const locations = locationsResolver(state.customerState.customerLocations ?? []);

  return {
    isLoading: state.generalState.loaders.some(
      l => l === LoaderTypes.ActivityActions || LoaderTypes.CustomerActivities
    ),
    isSelectedActivityLoading: state.generalState.loaders.some(l => l === LoaderTypes.ActivityActions),
    activityActions: state.activityState.activityActions ?? [],
    activeCustomerId: state.customerState.selectedCustomerId,
    locations,
    activities: state.customerState.customerActivities ?? [],
    selectedActivityAction: state.activityState.selectedActivityAction,
    customerVenueOperators: state.locationState.customerVenueOperators,
  };
};

const mapDispatchToProps: IPlanningActivitiesOverviewDispatchProps = {
  getCustomerActivities: getCustomerActivities,
  showCustomerSelection: showCustomerSelection,
  updateActivityStatus: updateCustomerPlannedActivityStatus,
  updateActivityStatusOperator: updateCustomerPlannedActivityOperator,
  getSelectedActivityFeedbackRequests: getActivityFeedbackRequests,
  getSelectedActivityCustomerVenueOperators: getCustomerVenueOperators,
  resetSelectedActivity: resetActivity,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withTelemetry(PlanningActivitiesOverview, "PlanningActivitiesOverview")));
