import { RoutingLinks } from "../../constants/routingLinks";

const links = {
  home: {
    planningRoutes: RoutingLinks.planningRoutes,
    planningActivities: RoutingLinks.planningActivities,
    loggingRoutes: RoutingLinks.loggingRoutes,
    loggingActivities: RoutingLinks.loggingActivities,
    notifications: RoutingLinks.notifications,
  },
  customerManagement: RoutingLinks.customerManagement,
  customerData: {
    activities: RoutingLinks.customerDataActivities,
    notifications: RoutingLinks.customerDataNotifications,
  },
  masterData: {
    activities: RoutingLinks.masterDataActivities,
    notifications: RoutingLinks.masterDataNotifications,
  },
  logbook: RoutingLinks.logbook,
};

export default links;