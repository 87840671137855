import { IAuthenticator } from "../authenticators/interfaces/IAuthenticator";
import TelemetryService from "./telemetryService";

type CRUDOperation = "GET" | "PUT" | "PATCH" | "POST" | "DELETE";

export default abstract class BaseService {
  public constructor(protected readonly resource: string, protected readonly authenticator?: IAuthenticator) {}

  public async get<T>(query: string): Promise<T> {
    return this.execute(query, "GET").then(r =>
      r.json().then(result => {
        return result;
      })
    );
  }

  public async post(query: string, data: any): Promise<Response> {
    return this.execute(query, "POST", data);
  }

  public async postWithResponse<T>(query: string, data: any): Promise<T> {
    return this.execute(query, "POST", data).then(r => r.json());
  }

  public async put(query: string, data: any): Promise<Response> {
    return this.execute(query, "PUT", data);
  }

  public async patch(query: string, data: any): Promise<Response> {
    return this.execute(query, "PATCH", data);
  }

  public async delete(query: string): Promise<Response> {
    return this.execute(query, "DELETE");
  }

  private async execute(query: string, method: CRUDOperation, data?: any): Promise<Response> {
    const url = `${this.resource}/${query}`;
    let request: RequestInit = {
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
      method,
    };

    if (data) {
      request.body = JSON.stringify(data);
    }

    if (this.authenticator) {
      request = await this.authenticator.authenticateRequestAsync(request);
    }

    return fetch(url, request).then(async response => {
      if (!response.ok) {
        TelemetryService.AppInsights.trackException({
          exception: new Error(`Api call ${response.status} error on ${url}, ${response.statusText}`),
        });
        throw new Error(await response.text());
      }

      return response;
    });
  }
}