import TranslationMapper from "i18n/mapper";
import IActivityPost from "interfaces/IActivityPost";
import LanguageProvider from "providers/languageProvider";
import { Component, ReactNode } from "react";
import { Modal } from "react-bootstrap";
import ReactDOM from "react-dom";
import { NotificationManager } from "react-notifications";

import { Button } from "../../../../components/material/buttons/buttons";
import IWizardStep from "../../../../components/wizard/interfaces/IWizardStep";
import { IWizardStepOnChangeEvent } from "../../../../components/wizard/interfaces/IWizardStepOnChange";
import Dictionary from "../../../../utils/dictionary";
import ActivityDetailsStep from "./activityDetailsStep";
import ActivityFeedbackStep from "./activityFeedbackStep";
import ActivityValidator from "./activityValidator";
import IActivityWizardProps from "./interfaces/IActivityWizardProps";
import IActivityWizardState from "./interfaces/IActivityWizardState";

class ActivityWizard extends Component<IActivityWizardProps, IActivityWizardState> {
  private initialWizardState: IActivityWizardState = {
    isValid: new Dictionary<boolean>(),
    numberOfSteps: 2,
    activeStepIndex: 0,
  };

  private wizardSteps: IWizardStep[] = [ActivityDetailsStep, ActivityFeedbackStep];

  public constructor(props: IActivityWizardProps) {
    super(props);

    const isValid = new Dictionary<boolean>();

    for (let index = 0; index < this.wizardSteps.length; index++) {
      isValid.add(index.toString(), false); // by default all steps contain invalid data
    }

    this.state = {
      numberOfSteps: this.wizardSteps.length,
      activeStepIndex: 0,
      isValid,
      details: this.props.activity,
      feedback: this.props.activity,
    };

    this.updateSteps = this.updateSteps.bind(this);
    this.onChange = this.onChange.bind(this);
    this.goToNextStep = this.goToNextStep.bind(this);
    this.goToPreviousStep = this.goToPreviousStep.bind(this);
    this.onSaveActivity = this.onSaveActivity.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  public onChange(event: IWizardStepOnChangeEvent): void {
    const name = event.target.name;
    const value = event.target.value;

    const isValid = this.state.isValid;
    isValid.add(this.state.activeStepIndex.toString(), event.isValid);
    this.setState(current => ({ ...current, isValid, [name]: value }));
  }

  private goToNextStep(): void {
    const currentStep = this.state.activeStepIndex;

    if (currentStep < this.state.numberOfSteps - 1 && this.state.isValid.item(currentStep.toString())) {
      this.setState({
        activeStepIndex: currentStep + 1,
      });
    }
  }

  private goToPreviousStep(): void {
    const currentStep = this.state.activeStepIndex;

    if (currentStep > 0) {
      this.setState({
        activeStepIndex: currentStep - 1,
      });
    }
  }

  private updateSteps(): void {
    this.setState({
      isValid: this.isValidDictionary,
      numberOfSteps: this.wizardSteps.length,
    });
  }

  private get isValidDictionary(): Dictionary<boolean> {
    const isValidDictionary = new Dictionary<boolean>();

    isValidDictionary.add("0", this.state.details ? ActivityValidator.areDetailsValid(this.state.details) : false);
    isValidDictionary.add("1", ActivityValidator.isFeedbackValid(this.state.feedback));

    return isValidDictionary;
  }

  private getStepValue(): any {
    switch (this.state.activeStepIndex) {
      case 0:
        return this.state.details;
      case 1:
        return this.state.feedback;
    }
  }

  private get currentStep(): number {
    return this.state.activeStepIndex + 1;
  }

  private get totalNumberOfSteps(): number {
    return this.wizardSteps.length;
  }

  private get isAllDataValid(): boolean {
    const validationValues = this.state.isValid.getValues();
    return !validationValues.some(value => !value);
  }

  private get isCurrentStepValid(): boolean {
    return this.state.isValid.item(this.state.activeStepIndex.toString());
  }

  private get showPreviousButton(): boolean {
    return this.state.activeStepIndex > 0;
  }

  private get showNextButton(): boolean {
    return this.state.activeStepIndex < this.state.numberOfSteps - 1;
  }

  private get showSaveButton(): boolean {
    return this.state.activeStepIndex === this.state.numberOfSteps - 1;
  }

  private get modalHook(): HTMLElement {
    let modalHook = document.getElementById("modal");
    if (!modalHook) {
      modalHook = document.createElement("div");
      modalHook.setAttribute("id", "portal");
      document.body.appendChild(modalHook);
    }

    return modalHook;
  }

  private onSaveActivity(): void {
    if (!this.isAllDataValid) {
      NotificationManager.error(TranslationMapper.pages.routewizard.error_not_all_required_data);
      return;
    }

    this.upsertActivity();
    this.closeModal();
  }

  private closeModal(): void {
    this.setState({ ...this.initialWizardState }, () => this.props.onClose());
  }

  private upsertActivity(): void {
    if (this.isAllDataValid && this.state.details) {
      const activity: IActivityPost = {
        id: this.state.details.id,
        name: this.state.details.name,
        canBeCheckedOutMultipleTimes: this.state.details.canBeCheckedOutMultipleTimes,
        activeImageData: this.state.details.activeImageData ?? null,
        inActiveImageData: this.state.details.inactiveImageData ?? null,
        sequence: this.state.details.sequence,
        feedbackRequests: this.state.feedback?.feedbackRequests ?? [],
      };

      this.props.onSave(activity);
    }
  }

  private get title(): string {
    if (!this.props.activity) {
      return LanguageProvider.t(TranslationMapper.pages.masterdata.activities.create);
    } else {
      return LanguageProvider.t(TranslationMapper.pages.masterdata.activities.edit);
    }
  }

  public render(): ReactNode {
    const wizardStep = this.wizardSteps[this.state.activeStepIndex];

    return (
      <>
        {this.modalHook &&
          ReactDOM.createPortal(
            <Modal backdrop="static" show={true} onHide={this.closeModal} centered>
              <Modal.Header closeButton>
                <div className="modal-header__info">
                  <div>
                    <h1 className="modal-title">{this.title}</h1>
                    {wizardStep.subtitleResource && (
                      <h5 className="modal-title">{LanguageProvider.t(wizardStep.subtitleResource)}</h5>
                    )}
                  </div>
                  <div className="modal__steps" data-testid="activity-wizard-steps">
                    {this.currentStep}/{this.totalNumberOfSteps}
                  </div>
                </div>
              </Modal.Header>
              <Modal.Body>
                <wizardStep.form
                  onChange={this.onChange}
                  name={wizardStep.name}
                  value={this.getStepValue()}
                  object={this.props.activity}
                />
              </Modal.Body>
              <Modal.Footer className="d-flex justify-content-between">
                <div>
                  <Button
                    onClick={this.closeModal}
                    resourceLabel={LanguageProvider.t(TranslationMapper.buttons.cancel)}
                    className="btn-outline-secondary me-3"
                    iconEnd="xmark"
                  />
                  {this.showPreviousButton && (
                    <Button
                      onClick={this.goToPreviousStep}
                      resourceLabel={LanguageProvider.t(TranslationMapper.buttons.previous)}
                      className="btn-outline-secondary"
                      iconStart="arrow-left"
                    />
                  )}
                </div>
                <div>
                  {this.showNextButton && (
                    <Button
                      onClick={this.goToNextStep}
                      resourceLabel={LanguageProvider.t(TranslationMapper.buttons.next)}
                      className="btn-primary"
                      iconEnd="arrow-right"
                      disabled={!this.isCurrentStepValid}
                    />
                  )}
                  {this.showSaveButton && (
                    <Button
                      onClick={this.onSaveActivity}
                      resourceLabel={LanguageProvider.t(TranslationMapper.buttons.save_and_close)}
                      className="btn-primary"
                      iconEnd="floppy-disk"
                      disabled={!this.isAllDataValid}
                    />
                  )}
                </div>
              </Modal.Footer>
            </Modal>,
            this.modalHook
          )}
      </>
    );
  }
}

export default ActivityWizard;
