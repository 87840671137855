import SearchTextInput from "components/search/searchTextInput";
import TranslationMapper from "i18n/mapper";
import ICustomer from "interfaces/ICustomer";
import LanguageProvider from "providers/languageProvider";
import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { RootState } from "store/reducers/rootReducer";
import { isNullOrEmpty } from "utils/stringUtils";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { updateActiveCustomer } from "../../store/actions/customerActions";
import ICustomerPickerProps, {
  ICustomerPickerDispatchProps,
  ICustomerPickerStateProps,
} from "./interfaces/ICustomerPickerProps";
import ICustomerPickerState from "./interfaces/ICustomerPickerState";

class CustomerPicker extends React.Component<ICustomerPickerProps, ICustomerPickerState> {
  public constructor(props: any) {
    super(props);

    const state: ICustomerPickerState = {
      searchText: "",
    };

    this.state = state;

    this.renderDropdownItem = this.renderDropdownItem.bind(this);
    this.setActiveCustomer = this.setActiveCustomer.bind(this);
    this.onSearchTextChange = this.onSearchTextChange.bind(this);
  }

  private renderDropdownItem(customer: ICustomer): JSX.Element {
    const isActive = this.props.activeCustomer?.id === customer.id;

    return (
      <li key={customer.id}>
        <button
          key={customer.id}
          title={customer.name}
          onClick={(): void => this.setActiveCustomer(customer)}
          className={`dropdown-item ${isActive ? "selected" : ""}`}
        >
          <h3 className="mt-3 mb-3">{customer.name}</h3>
        </button>
      </li>
    );
  }

  private setActiveCustomer(selectedCustomer: ICustomer): void {
    if (selectedCustomer == null || selectedCustomer === undefined) {
      return;
    }

    this.onSearchTextChange("");

    this.props.updateActiveCustomer(selectedCustomer.id);
  }

  private onSearchTextChange(searchText: string): void {
    this.setState({
      searchText: searchText,
    });
  }

  private get filteredCustomers(): ICustomer[] {
    const searchText = this.state.searchText;
    if (searchText == null || !searchText || isNullOrEmpty(searchText)) {
      return this.props.customers;
    }

    return this.props.customers.filter(c => c.name.toLowerCase().includes(searchText.toLowerCase()));
  }

  public render(): JSX.Element {
    if (!this.props.show) {
      return <></>;
    }

    return (
      <div className="dropdown">
        <button
          className="btn btn-link btn--element-start"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <FontAwesomeIcon icon={["fal", "building"]} fixedWidth />
          <span className="d-none d-md-inline">
            {this.props.activeCustomer?.name
              ? this.props.activeCustomer.name
              : LanguageProvider.t(TranslationMapper.customerpicker.choosecustomer)}
          </span>
        </button>
        <ul className="dropdown-menu dropdown-menu--customer pt-0" aria-labelledby="customer-menu-button">
          <li className="dropdown-menu--customer__sticky-item">
            <SearchTextInput
              onChange={this.onSearchTextChange}
              searchText={this.state.searchText}
              placeholder={LanguageProvider.t(TranslationMapper.controls.placeholders.search_customer_name)}
            />
          </li>

          {this.filteredCustomers.map((c, index) => this.renderDropdownItem(c))}
        </ul>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): ICustomerPickerStateProps => ({
  show: state.customerState.showCustomerSelection,
  customers: state.customerState.customers.filter(c => c.hasOperation),
  activeCustomer: state.customerState.customers.find(c => c.id === state.customerState.selectedCustomerId),
});

const mapDispatchToProps: ICustomerPickerDispatchProps = {
  updateActiveCustomer: updateActiveCustomer,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CustomerPicker));
