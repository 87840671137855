import { IActivityTypeStoreState, initialActivityTypeStoreState } from "store/state/activityTypeStoreState";

import { ActionTypes, ActionTypesCollection } from "../actionTypes";

export default function activityTypeReducer(
  state: IActivityTypeStoreState = initialActivityTypeStoreState,
  action: ActionTypesCollection
): IActivityTypeStoreState {
  switch (action.type) {
    case ActionTypes.FETCHED_ACTIVITIES:
      return {
        ...state,
        activities: action.payload,
      };
    case ActionTypes.FETCHED_SELECTED_ACTIVITY:
      return {
        ...state,
        selectedActivity: action.payload
      };
    default:
      return state;
  }
}
