import RadioButton from "components/material/radiobutton/radiobutton";
import FileTypes from "enums/fileTypes";
import { Guid } from "guid-typescript";
import TranslationMapper from "i18n/mapper";
import LanguageProvider from "providers/languageProvider";
import { Component, ReactNode } from "react";
import { Col, Form, Row } from "react-bootstrap";
import FileUtils from "utils/fileUtils";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { IInputChangeEvent } from "../../../../components/interfaces/IInputChangeEvent";
import IWizardStep from "../../../../components/wizard/interfaces/IWizardStep";
import IFile from "../../../../interfaces/IFile";
import ActivityValidator from "./activityValidator";
import IActivityDetailsStepProps from "./interfaces/IActivityDetailsStepProps";
import IActivityDetailsStepState from "./interfaces/IActivityDetailsStepState";

class ActivityDetailsStep extends Component<IActivityDetailsStepProps, IActivityDetailsStepState> {
  public constructor(props: IActivityDetailsStepProps) {
    super(props);

    const state = this.props.value ?? {
      id: Guid.createEmpty().toString(),
      name: "",
      canBeCheckedOutMultipleTimes: false,
      imageUri: "",
      sequence: undefined,
      inactiveImageData: "",
      activeImageData: "",
      inactiveImageName: "",
      activeImageName: "",
      inactiveImageUrl: "",
      activeImageUrl: "",
      activeImageWrongFormat: false,
      inactiveImageWrongFormat: false,
    };

    this.state = state;

    this.onChange = this.onChange.bind(this);
    this.onCheckOutChange = this.onCheckOutChange.bind(this);
    this.propagateChange = this.propagateChange.bind(this);

    this.setActiveImageState = this.setActiveImageState.bind(this);
    this.setInactiveImageState = this.setInactiveImageState.bind(this);

    this.onActiveImageChange = this.onActiveImageChange.bind(this);
    this.onInactiveImageChange = this.onInactiveImageChange.bind(this);

    this.propagateChange();
  }

  private onCheckOutChange(value: boolean): void {
    this.setState(current => ({ ...current, canBeCheckedOutMultipleTimes: value }), this.propagateChange);
  }

  private onChange(event: IInputChangeEvent<any>): void {
    const name = event.target.name;
    const value = event.target.value;

    this.setState(current => ({ ...current, [name]: value }), this.propagateChange);
  }

  private propagateChange(): void {
    const isValid = ActivityValidator.areDetailsValid(this.state);
    this.props.onChange({ target: { value: this.state, name: this.props.name }, isValid });
  }

  private setActiveImageState(imageFile: IFile): void {
    this.setState(
      {
        activeImageData: imageFile.data,
        activeImageName: imageFile.name,
        activeImageUrl: imageFile.url,
        activeImageWrongFormat: imageFile.wrongFormat,
      },
      this.propagateChange
    );
  }

  private setInactiveImageState(imageFile: IFile): void {
    this.setState(
      {
        inactiveImageData: imageFile.data,
        inactiveImageName: imageFile.name,
        inactiveImageUrl: imageFile.url,
        inactiveImageWrongFormat: imageFile.wrongFormat,
      },
      this.propagateChange
    );
  }

  private onActiveImageChange(event: any): void {
    FileUtils.handleFileChange(event, [FileTypes.SVG, FileTypes.PNG], this.setActiveImageState);
  }

  private onInactiveImageChange(event: any): void {
    FileUtils.handleFileChange(event, [FileTypes.SVG, FileTypes.PNG], this.setInactiveImageState);
  }

  public render(): ReactNode {
    return (
      <Form data-testid="activity-wizard-details-step" id="activity-wizard-details-step">
        <Row>
          <Col md={6} className="modal__col-mb">
            <Form.Group>
              <Form.Label>{LanguageProvider.t(TranslationMapper.pages.masterdata.activities.name)}</Form.Label>
              <Form.Control id="activity-name" name="name" onChange={this.onChange} value={this.state.name} />
            </Form.Group>
          </Col>
          <Col md={6} className="modal__col-mb">
            <Form.Group>
              <Form.Label>
                {LanguageProvider.t(TranslationMapper.pages.masterdata.activities.checkoutsperday)}
              </Form.Label>
              <Row>
                <Col>
                  <RadioButton
                    id="activity-single-checkout"
                    name="canBeCheckedOutMultipleTimes"
                    checked={!this.state.canBeCheckedOutMultipleTimes}
                    label={LanguageProvider.t(TranslationMapper.pages.masterdata.activities.singlecheckout)}
                    onChange={(event): void => this.onCheckOutChange(false)}
                    value="single"
                  />
                </Col>
                <Col>
                  <RadioButton
                    id="activity-multiple-checkout"
                    name="canBeCheckedOutMultipleTimes"
                    checked={this.state.canBeCheckedOutMultipleTimes}
                    label={LanguageProvider.t(TranslationMapper.pages.masterdata.activities.multiplecheckout)}
                    onChange={(event): void => this.onCheckOutChange(true)}
                    value="multiple"
                  />
                </Col>
              </Row>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <label htmlFor="activeImageUpload" className="form-label">
              {LanguageProvider.t(TranslationMapper.pages.masterdata.activeimage)}
            </label>
            <div className="input-file--container d-flex">
              {this.state.activeImageUrl && (
                <div className="input-file--item input-file--item--sm rounded-0">
                  <img src={this.state.activeImageUrl} alt="" />
                  <FontAwesomeIcon icon={["fas", "circle-xmark"]} className="input-file--item--remove" />
                </div>
              )}
              <label htmlFor="activeImageUpload" className="input-file--item input-file--item--add">
                <FontAwesomeIcon icon={["fal", "images"]} fixedWidth size="3x" />
                <input
                  type="file"
                  className="form-control"
                  id="activeImageUpload"
                  name="activeImageUpload"
                  accept=".svg,.png"
                  multiple={false}
                  onChange={this.onActiveImageChange}
                />
              </label>
            </div>
            {this.state.activeImageWrongFormat && (
              <div className="invalid-feedback d-block">
                {LanguageProvider.t(TranslationMapper.pages.masterdata.imagewrongformat)}
              </div>
            )}
          </Col>
          <Col md={6}>
            <label htmlFor="inactiveImageUpload" className="form-label">
              {LanguageProvider.t(TranslationMapper.pages.masterdata.inactiveimage)}
            </label>
            <div className="input-file--container d-flex">
              {this.state.inactiveImageUrl && (
                <div className="input-file--item input-file--item--sm rounded-0">
                  <img src={this.state.inactiveImageUrl} alt="" />
                  <FontAwesomeIcon icon={["fas", "circle-xmark"]} className="input-file--item--remove" />
                </div>
              )}
              <label htmlFor="inactiveImageUpload" className="input-file--item input-file--item--add">
                <FontAwesomeIcon icon={["fal", "images"]} fixedWidth size="3x" />
                <input
                  type="file"
                  className="form-control"
                  id="inactiveImageUpload"
                  name="inactiveImageUpload"
                  accept=".svg,.png"
                  multiple={false}
                  onChange={this.onInactiveImageChange}
                />
              </label>
            </div>
            {this.state.inactiveImageWrongFormat && (
              <div className="invalid-feedback d-block">
                {LanguageProvider.t(TranslationMapper.pages.masterdata.imagewrongformat)}
              </div>
            )}
          </Col>
        </Row>
      </Form>
    );
  }
}

const wizardStep: IWizardStep = {
  form: ActivityDetailsStep,
  titleResource: "",
  subtitleResource: TranslationMapper.pages.masterdata.activities.details,
  name: "details",
};

export default wizardStep;
