import { Component, ReactNode } from "react";
import { connect } from "react-redux";
import { RootState } from "store/reducers/rootReducer";

import IVenue from "../../interfaces/IVenue";
import { ISearchableSelectOption } from "../material/select/interfaces/ISearchableSelectProps";
import Select from "../material/select/searchableSelect";
import ISelectVenueProps, { ISelectVenueStateProps } from "./interfaces/ISelectVenueProps";

class SelectVenue extends Component<ISelectVenueProps> {
  private getItemAsSelectOptionFn(venue: IVenue): ISearchableSelectOption {
    return {
      id: venue.id,
      label: venue.name,
    };
  }

  public render(): ReactNode {
    return (
      <>
        <Select
          id={this.props.id}
          name={this.props.name}
          value={this.props.value}
          onChange={this.props.onChange}
          options={this.props.venues ?? []}
          getItemAsSelectOptionFn={this.getItemAsSelectOptionFn}
        />
      </>
    );
  }
}

const mapStateToProps = (state: RootState): ISelectVenueStateProps => {
  return {
    venues: state.customerState.customerLocations ?? [],
  };
};

export default connect(mapStateToProps)(SelectVenue);
