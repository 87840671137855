import { ApplicationConfig } from "config";

import { Flags } from "../../featureFlags/flags";
import IFeatureFlag from "../../interfaces/IFeatureFlag";
import FlagService from "../../services/flagService";
import EnvironmentUtils from "../../utils/environmentUtils";
import { ActionTypes } from "../actionTypes";
import { actionCreator, AppAction } from "../appAction";

export const fetchedFeatureFlags = actionCreator<ActionTypes.FETCHED_FEATUREFLAGS, IFeatureFlag[]>(
  ActionTypes.FETCHED_FEATUREFLAGS
);

const storagePath: string = `be.cm.${ApplicationConfig.environment}.featureFlags`;
const timestampPath: string = `${storagePath}.timeStamp`;

export function checkFlagsExpiration(): AppAction {
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const result = dispatch => {
    const currentTime = new Date();
    const flagsTimeStamp = getFlagsTimeStamp();

    if (!flagsTimeStamp) {
      return;
    }

    const differenceInMinutes = (dateTimeA: Date, dateTimeB: Date): number =>
      Math.round((dateTimeA.getTime() - dateTimeB.getTime()) / 1000 / 60);
    const minutesSinceUpdate = differenceInMinutes(currentTime, flagsTimeStamp);

    let flagExpirationTimeInMinutes = 5;
    if (EnvironmentUtils.isTestOrDevEnvironment()) {
      flagExpirationTimeInMinutes = 30;
    }

    if (minutesSinceUpdate >= flagExpirationTimeInMinutes) {
      dispatch(getFeatureFlagsAsync(true));
    }
  };
  return result;
}

export function getFeatureFlagsAsync(shouldClearOldFlags: boolean): AppAction {
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  return dispatch => {
    const featureFlagNames = Object.values(Flags) as string[];
    const featureFlagsService = new FlagService();

    return featureFlagsService.GetFlagsByNameAsync(featureFlagNames).then(featureFlags => {
      if (shouldClearOldFlags) {
        featureFlagNames.forEach(flag => {
          removeFlagFromStorage(flag);
        });
      }

      const featureFlagsForStore: IFeatureFlag[] = updateAndStoreFeatureFlagsWithUserSetting(featureFlags);

      return dispatch(fetchedFeatureFlags(featureFlagsForStore));
    });
  };
}

export function updateFlagsWithStorageValue(storageUpdate: StorageEvent): AppAction {
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  return dispatch => {
    const flags = Object.values(Flags) as string[];
    const featureFlagsService = new FlagService();

    if (!storageUpdate.key || !storageUpdate.key.includes(storagePath)) {
      return;
    }

    featureFlagsService.GetFlagsByNameAsync(flags).then(featureFlags => {
      const featureFlagsForStore: IFeatureFlag[] = updateAndStoreFeatureFlagsWithUserSetting(featureFlags);
      dispatch(fetchedFeatureFlags(featureFlagsForStore));
    });
  };
}

function updateAndStoreFeatureFlagsWithUserSetting(featureFlags: IFeatureFlag[]): IFeatureFlag[] {
  const retFeatureFlags: IFeatureFlag[] = [];

  featureFlags.forEach(featureFlag => {
    const flagIsActive = getFlagFromStorage(featureFlag.flagName);

    if (flagIsActive != null) {
      retFeatureFlags.push({ flagName: featureFlag.flagName, active: flagIsActive });
    } else {
      saveFlagInStorage(featureFlag.flagName, featureFlag.active);
      retFeatureFlags.push(featureFlag);
    }
  });

  return retFeatureFlags;
}

function saveFlagInStorage(flagName: string, flagValue: boolean): void {
  if (!flagName) {
    return;
  }

  const flagTimeStamp = JSON.stringify(new Date());

  sessionStorage.setItem(`${storagePath}.${flagName}`, flagValue.toString());

  sessionStorage.setItem(timestampPath, flagTimeStamp);
}

function getFlagFromStorage(flagName: string | typeof Flags): boolean | null {
  const value = sessionStorage.getItem(`${storagePath}.${flagName}`);
  return value ? value === "true" : null;
}

function getFlagsTimeStamp(): Date | undefined {
  const storageItem = sessionStorage.getItem(timestampPath);
  const timestamp = storageItem ? new Date(JSON.parse(storageItem)) : undefined;
  return timestamp;
}

function removeFlagFromStorage(flagName: string): void {
  sessionStorage.removeItem(`${storagePath}.${flagName}`);
}