import { Component } from "react";

import ICustomCheckboxProps from "./interfaces/ICustomCheckboxProps";

export default class CustomCheckbox extends Component<ICustomCheckboxProps> {
  private get className(): string {
    const baseClass = "form-check-input";
    const propsClass = this.props.className ? ` ${this.props.className}` : "";
    return baseClass + propsClass;
  }

  private get isChecked(): boolean {
    return this.props.isChecked;
  }

  public render(): JSX.Element {
    return (
      <input
        className={this.className}
        type="checkbox"
        checked={this.isChecked}
        onClick={this.props.onClick}
        readOnly // Readonly is used to prevent the user from changing the value of the input field (by checkbox itself)
      />
    );
  }
}
