import { HFColorCodes } from "constants/colorCodes";
import { Component } from "react";
import { PulseLoader } from "react-spinners";

import ILoaderProps from "./interfaces/ILoaderProps";

export default class Loader extends Component<ILoaderProps> {
  public render(): JSX.Element {
    return (
      <PulseLoader
        color={HFColorCodes.Tertiary}
        size={this.props.size ?? 10}
        margin={"15px"}
        loading={this.props.isLoading}
      />
    );
  }
}
