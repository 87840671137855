import IIncidentType from "interfaces/IIncidentType";
import { Component, ReactNode } from "react";
import { connect } from "react-redux";
import { RootState } from "store/reducers/rootReducer";

import IncidentTypeItem from "./incidentTypeItem";
import { ISelectIncidentTypeProps, ISelectIncidentTypeStateProps } from "./interfaces/ISelectIncidentTypeProps";
import ISelectIncidentTypesState from "./interfaces/ISelectIncidentTypesState";

class SelectIncidentTypes extends Component<ISelectIncidentTypeProps, ISelectIncidentTypesState> {
  public constructor(props: ISelectIncidentTypeProps) {
    super(props);

    const initialValues = this.props.value || [];

    this.state = {
      incidentTypes: initialValues,
    };

    this.toggleNotification = this.toggleNotification.bind(this);
    this.propagateChange = this.propagateChange.bind(this);
  }

  private toggleNotification(incidentType: IIncidentType): void {
    this.setState((prevState) => {
      let incidentTypes = [...prevState.incidentTypes];

      if (incidentTypes.some((t) => t.id === incidentType.id)) {
        incidentTypes = prevState.incidentTypes.filter((t) => t.id !== incidentType.id);
      } else {
        incidentTypes.push(incidentType);
      }

      return {
        incidentTypes,
      };
    }, this.propagateChange);
  }

  private propagateChange(): void {
    this.props.onChange({
      target: {
        name: this.props["name"],
        value: this.state.incidentTypes,
      },
    });
  }

  public render(): ReactNode {
    const customerNotificationItems =
      this.props.incidentTypes?.map((cn: IIncidentType) => {
        const selectedType = this.state.incidentTypes.some((stateType) => stateType.id === cn.id);

        return (
          <IncidentTypeItem
            selectingDisabled={false}
            incidentType={cn}
            active={selectedType}
            key={cn.id}
            onItemClick={this.toggleNotification}
            name={cn.label}
          />
        );
      }) || [];

    return (
      <div className="row row--btn-image-label" id={this.props.id}>
        {customerNotificationItems}
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): ISelectIncidentTypeStateProps => {
  const incidentTypes =
    state.notificationState.incidentTypes != null && state.customerState.selectedCustomerId != null
      ? state.notificationState.incidentTypes[state.customerState.selectedCustomerId] ?? []
      : [];

  return {
    incidentTypes: incidentTypes ?? [],
  };
};

export default connect(mapStateToProps)(SelectIncidentTypes);
