import Select from "components/material/select/select";
import { TranslationMapper } from "i18n/mapper";
import INotificationOverview from "interfaces/INotificationOverview";
import INotificationResponse from "interfaces/INotificationResponse";
import moment from "moment";
import LanguageProvider from "providers/languageProvider";
import { Component } from "react";
import CleaningManagementService from "services/cleaningManagementService";
import DateUtils from "utils/dateUtils";

import INotificationOverviewSubcomponentProps from "./interfaces/INotificationOverviewSubcomponentProps";
import NotificationResponseModalButton from "./notificationResponseModalButton";
import { NotificationTableData } from "./notificationsTable";

export default class NotificationOverviewSubcomponent extends Component<INotificationOverviewSubcomponentProps> {
  private readonly cleaningManagementService: CleaningManagementService;

  public constructor(props: INotificationOverviewSubcomponentProps) {
    super(props);

    this.cleaningManagementService = new CleaningManagementService();

    this.getNotificationOverview = this.getNotificationOverview.bind(this);
  }

  private getNotificationOverview(
    notification: INotificationResponse,
    clickedNotificationId: string
  ): INotificationOverview | undefined {
    let notificationOverview = {} as INotificationOverview | undefined;

    if (!notification) {
      this.cleaningManagementService.getCustomerNotifications([clickedNotificationId]).then(result => {
        const clickedNotifications = this.props.clickedNotifications;
        clickedNotifications.add(clickedNotificationId, result[0]);
        this.setState({
          clickedNotifications: clickedNotifications,
        });
      });
    }

    if (notification) {
      notificationOverview = this.props.notifications.find(n => n.id === notification.id);
    }

    return notificationOverview;
  }

  public render(): JSX.Element {
    const rowData = this.props.row.original as NotificationTableData;
    const notification = this.props.clickedNotifications.item(rowData.id);
    const notificationOverview = this.getNotificationOverview(notification, rowData.id);

    return (
      <>
        <tr>
          <td colSpan={1} />
          <td colSpan={2} className="modal__col-mb">
            <div className="modal__col-mb">
              <h3>{LanguageProvider.t(TranslationMapper.pages.notifications.datecompleted)}</h3>
              {notificationOverview?.finishedOn
                ? DateUtils.getDateString(notificationOverview.finishedOn)
                : LanguageProvider.t(TranslationMapper.pages.notifications.notfinished)}
            </div>
            <div className="modal__col-mb">
              <h3>{LanguageProvider.t(TranslationMapper.pages.notifications.description)}</h3>
              {notification?.description ? notification.description : "-"}
            </div>
            <div>
              <h3>{LanguageProvider.t(TranslationMapper.pages.notifications.status)}</h3>
              {notification && (
                <Select
                  id={`select-${notification.id}`}
                  value={notification.status}
                  name={`select-${notification.id}`}
                  onChange={(event): void => this.props.onUpdateNotificationStatus(event, notification)}
                  options={this.props.notificationStatusOptions}
                />
              )}
            </div>
          </td>

          <td colSpan={2} className="modal__col-mb">
            <div className="modal__col-mb">
              <h3>{LanguageProvider.t(TranslationMapper.pages.notifications.cleaningobjectreference)}</h3>
              {notification?.cleaningObjectReference ? notification.cleaningObjectReference : "-"}
            </div>
            <div>
              <h3>{LanguageProvider.t(TranslationMapper.pages.notifications.floorname)}</h3>
              {notification?.floorName ? notification.floorName : "-"}
            </div>
          </td>

          <td colSpan={2} className="modal__col-mb">
            {notification?.pictureUris?.length > 0 && (
              <h3>{LanguageProvider.t(TranslationMapper.pages.notifications.images)}</h3>
            )}
            {notification &&
              notification.pictureUris.map(pu => (
                <img key={pu} src={pu} alt="" className="notification-overview-subcomponent__img" />
              ))}
          </td>
          <td colSpan={1} />
        </tr>

        <tr>
          <td colSpan={1} />
          <td colSpan={2}>
            <div>
              <h3>{LanguageProvider.t(TranslationMapper.pages.notifications.replies)}</h3>
              <div>
                {notification && notification.comments.length > 0
                  ? notification.comments
                      .sort((a, b) => {
                        return moment(b.createdOn).valueOf() - moment(a.createdOn).valueOf();
                      })
                      .map(c => (
                        <div className="mb-2 small-fixed" key={c.id}>
                          <div className="d-flex justify-content-between">
                            <div className="text-green">{c.createdBy}</div>
                            <div className="text-tertiary">{moment.utc(c.createdOn).local().format("DD MMM H:mm")}</div>
                          </div>
                          <div>{c.description}</div>
                        </div>
                      ))
                  : "-"}
              </div>
              <NotificationResponseModalButton notificationId={rowData.id} onClick={this.props.onResponseButtonClick} />
            </div>
          </td>
          <td colSpan={this.props.visibleColumnLength - 3} />
        </tr>
      </>
    );
  }
}
