export const RoutingLinks = {
  home: "/",
  null: "/null",
  planningRoutes: "/planning/routes",
  planningActivities: "/planning/activities",
  loggingRoutes: "/logging/routes",
  loggingActivities: "/logging/activities",
  notifications: "/notifications",
  route: "/route",
  masterData: "/masterdata/activities",
  masterDataActivities: "/masterdata/activities",
  masterDataNotifications: "/masterdata/notificationCategories",
  customerManagement: "/customerManagement",
  customerDetails: "/customerManagement/customerDetails",
  customerDetailsBuildings: "/customerManagement/customerDetails/:customerId/buildings",
  customerData: "/customerData/activities",
  customerDataActivities: "/customerData/activities",
  customerDataNotifications: "/customerData/notificationCategories",
  logbook: "/logbook",
  unauthorized: "/unauthorized",
};
