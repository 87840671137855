import IFilterValue from "components/filterMenu/interfaces/IFilterValue";
import CheckBox from "components/material/checkbox/checkbox";
import { TextInput } from "components/material/formElements/input/textInput";
import TranslationMapper from "i18n/mapper";
import LanguageProvider from "providers/languageProvider";
import { Component } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import ReactDOM from "react-dom";
import { withTranslation } from "react-i18next";
import { MultiSelect } from "react-multi-select-component";

import { Button } from "../../../components/material/buttons/buttons";
import ICreateEmailItemModalProps from "./interfaces/ICreateEmailItemModalProps";
import ICreateEmailItemModalState from "./interfaces/ICreateEmailItemModalState";

class CreateEmailItemModal extends Component<ICreateEmailItemModalProps, ICreateEmailItemModalState> {
  public constructor(props: ICreateEmailItemModalProps) {
    super(props);

    this.state = {
      show: true,
      email: this.props.selectedContact?.contactEmail ?? "",
      selectedProjectIds: this.props.selectedContact?.projectIds ?? [],
      allProjectsSelected: this.props.selectedContact?.hasAllProjects ?? false,
    };

    this.confirm = this.confirm.bind(this);
    this.handleMailChange = this.handleMailChange.bind(this);
    this.handleSelectedProjectIdsChange = this.handleSelectedProjectIdsChange.bind(this);
    this.handleAllProjectsSelectedChange = this.handleAllProjectsSelectedChange.bind(this);
  }

  private get modalHook(): HTMLElement {
    let modalHook = document.getElementById("modal");
    if (!modalHook) {
      modalHook = document.createElement("div");
      modalHook.setAttribute("id", "portal");
      document.body.appendChild(modalHook);
    }

    return modalHook;
  }

  private confirm(): void {
    this.props.onSubmit(this.state.email, this.state.selectedProjectIds, this.state.allProjectsSelected);
    this.props.onClose();
  }

  private handleMailChange(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({
      email: event.target.value,
    });
  }

  private handleSelectedProjectIdsChange(selectedItem: IFilterValue[]): void {
    this.setState({
      selectedProjectIds: selectedItem.map(e => e.value),
    });
  }

  private handleAllProjectsSelectedChange(): void {
    this.setState({
      selectedProjectIds: [],
      allProjectsSelected: !this.state.allProjectsSelected,
    });
  }

  private get title(): string {
    return this.props.selectedContact
      ? LanguageProvider.t(TranslationMapper.pages.customerdata.edit_receiver)
      : LanguageProvider.t(TranslationMapper.pages.customerdata.add_receiver);
  }

  private get projects(): IFilterValue[] {
    const projects = this.props.projects.map(a => ({ value: a.id, label: a.name }));

    const initialSelectedProjectIds = this.props.selectedContact?.projectIds ?? [];
    const initialSelectedProjects = projects.filter(e => initialSelectedProjectIds.indexOf(e.value) >= 0);
    const unselectedProjects = projects.filter(e => !initialSelectedProjectIds.includes(e.value));

    return initialSelectedProjects.concat(unselectedProjects);
  }

  private get selectedProjects(): IFilterValue[] {
    return this.projects.filter(e => this.state.selectedProjectIds.indexOf(e.value) >= 0);
  }

  private get isFormValid(): boolean {
    return this.isEmailValid && (this.state.selectedProjectIds.length > 0 || this.state.allProjectsSelected);
  }

  private get isEmailValid(): boolean {
    if (!this.state.email) {
      return true;
    }

    const re =
      // eslint-disable-next-line max-len
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(this.state.email).toLowerCase());
  }

  private renderMultiSelectValue(selected: IFilterValue[]): React.ReactNode {
    if (selected && selected.length > 0) {
      return <div>{`${selected.length} ${LanguageProvider.t(TranslationMapper.searchable_multiselect.selected)}`}</div>;
    }
    return <></>;
  }

  public render(): JSX.Element {
    return (
      <>
        {this.modalHook &&
          ReactDOM.createPortal(
            <Modal
              backdrop="static"
              show={this.state.show}
              onHide={this.props.onClose}
              dialogClassName="modal__email-contact"
              centered
            >
              <Modal.Header closeButton>
                <div className="modal-header__info">
                  <div>
                    <h1 className="modal-title">{this.title}</h1>
                    <h5 className="modal-title">
                      {LanguageProvider.t(TranslationMapper.pages.customerdata.add_receiver_subtext)}
                    </h5>
                  </div>
                </div>
              </Modal.Header>
              <Modal.Body className="modal-body__height-md">
                <Form data-testid="route-wizard-details-step">
                  <Row>
                    <Col className="modal__col-mb">
                      <Form.Label>{LanguageProvider.t(TranslationMapper.pages.customerdata.email_receiver)}</Form.Label>
                      <TextInput
                        id="email"
                        onChange={this.handleMailChange}
                        value={this.state.email}
                        isInvalid={!this.isEmailValid}
                        disabled={false}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="modal__col-mb">
                      <Form.Label>
                        {LanguageProvider.t(TranslationMapper.pages.customerdata.buildings_header)}
                      </Form.Label>
                      <div className="filter-container-select">
                        <MultiSelect
                          options={this.projects}
                          value={this.selectedProjects}
                          onChange={this.handleSelectedProjectIdsChange}
                          labelledBy={LanguageProvider.t(TranslationMapper.controls.placeholders.select)}
                          valueRenderer={this.renderMultiSelectValue}
                          disabled={this.state.allProjectsSelected}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <CheckBox
                        id="all-buildings-checkbox"
                        name="all-buildings-checkbox"
                        description={LanguageProvider.t(TranslationMapper.pages.customerdata.receive_for_all_buildings)}
                        onChange={this.handleAllProjectsSelectedChange}
                        value={this.state.allProjectsSelected}
                      />
                    </Col>
                  </Row>
                </Form>
              </Modal.Body>
              <Modal.Footer className="d-flex justify-content-between">
                <Button
                  onClick={this.props.onClose}
                  resourceLabel={LanguageProvider.t(TranslationMapper.buttons.cancel)}
                  className="btn-outline-secondary"
                  iconEnd="xmark"
                />
                <Button
                  className="btn-primary btn--element-end"
                  disabled={!this.isFormValid}
                  onClick={this.confirm}
                  resourceLabel={LanguageProvider.t(TranslationMapper.buttons.save)}
                  iconEnd="floppy-disk"
                />
              </Modal.Footer>
            </Modal>,
            this.modalHook
          )}
      </>
    );
  }
}

export default withTranslation()(CreateEmailItemModal);
