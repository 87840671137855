import IRouteCleaningObjects from "components/routeWizard/interfaces/IRouteCleaningObjects";
import IRouteDetails from "components/routeWizard/interfaces/IRouteDetails";
import ICleaningSpace from "interfaces/ICleaningSpace";

import RouteCleaningObjectsRequest from "./routeCleaningObjectsRequest";
import RouteDetailsRequest from "./routeDetailsRequest";

export default class RouteRequest {
  public id?: string;
  public customerId: string;
  public activityIds: string[];
  public routeDetails: RouteDetailsRequest;
  public cleaningObjects: RouteCleaningObjectsRequest[];

  public constructor(
    customerId: string,
    routeDetails: IRouteDetails,
    cleaningObjects: IRouteCleaningObjects,
    id?: string
  ) {
    this.id = id;
    this.customerId = customerId;
    this.routeDetails = new RouteDetailsRequest(
      routeDetails.isRouteActive,
      routeDetails.venue!.id,
      routeDetails.routeName,
      routeDetails.operators?.map(operator => operator.id) ?? [],
      routeDetails.weekdays!,
      routeDetails.timePeriod!,
      routeDetails.incidentTypes!.map(t => t.id)
    );
    const zones: ICleaningSpace[] = [];
    cleaningObjects.cleaningObjectsContainers.forEach(c => {
      if (c.cleaningObjects != null) {
        c.cleaningObjects.forEach(obj => {
          zones.push(obj);
        });
      }
    });
    this.cleaningObjects = zones.map(
      s =>
        new RouteCleaningObjectsRequest(
          s.id,
          s.isStartZone,
          s.sequence,
          s.activityIds
        )
    );
  }
}
