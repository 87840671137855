import moment from "moment";
import * as React from "react";
import DatePicker from "react-datepicker";

import ISelectTimeProps from "./interfaces/ISelectTimeProps";

export default class SelectTime extends React.Component<ISelectTimeProps> {
  public constructor(props: ISelectTimeProps) {
    super(props);

    this.onChange = this.onChange.bind(this);
  }

  private onChange(event: Date): void {
    if (this.props.onChange != null) {
      const timeString = moment(event);

      this.props.onChange({
        target: {
          name: this.props.name,
          value: timeString.format("HH:mm"),
        },
      });
    }
  }

  public render(): JSX.Element {
    let momentDate = moment(this.props.value, "HH:mm");
    if (!momentDate.isValid()) {
      momentDate = this.props.minTime ? moment(this.props.minTime, "HH:mm") : moment("00:00", "HH:mm");
    }

    const minTime = this.props.minTime
      ? moment(this.props.minTime, "HH:mm").add(15, "minutes").toDate()
      : moment("00:00", "HH:mm").toDate();
    const maxTime = this.props.maxTime
      ? moment(this.props.maxTime, "HH:mm").toDate()
      : moment("23:45", "HH:mm").toDate();
    const intervals = this.props.timeIntervals ? this.props.timeIntervals : 15;

    const date = momentDate.toDate();
    const dateAndDifference = this.props.timeDifference
      ? this.props.value + this.props.timeDifference
      : this.props.value;
    return (
      <DatePicker
        value={dateAndDifference}
        id={this.props.id}
        selected={date}
        onChange={this.onChange}
        className={this.props.className}
        showTimeSelect
        showTimeSelectOnly
        showPopperArrow={false}
        timeIntervals={intervals}
        dateFormat="HH:mm"
        timeFormat="HH:mm"
        timeCaption="Time"
        // Adds 15 minutes to the min time so the start and end-time can not be equal
        minTime={minTime}
        maxTime={maxTime}
        readOnly={this.props.selectingDisabled || false}
        onCalendarOpen={this.props.onCalendarOpen}
      />
    );
  }
}
