import { Component } from "react";
import { Link } from "react-router-dom";

import Logo from "../../assets/img/logo.svg";
import { RoutingLinks } from "../../constants/routingLinks";
import CustomerPicker from "../customerSelection/customerPicker";
import UserSettings from "../userSettings/userSettings";
import IMainHeaderProps from "./interfaces/IMainHeaderProps";

export default class MainHeader extends Component<IMainHeaderProps> {
  public render(): JSX.Element {
    return (
      <header className="header">
        <Link to={RoutingLinks.home}>
          <img src={Logo} className="logo" alt="Hi Five" />
        </Link>
        <ul className="nav nav-pills">
          {this.props.showCustomerPicker !== false && (
            <li className="nav-item">
              <CustomerPicker />
            </li>
          )}
          <li className="nav-item">
            <UserSettings />
          </li>
        </ul>
      </header>
    );
  }
}
