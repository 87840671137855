import ICustomerNotificationCategory from "interfaces/ICustomerNotificationCategory";
import ICustomerNotificationCategoryRequest from "interfaces/request/ICustomerNotificationCategoryRequest";

export default class CustomerNotificationCategoryRequest implements ICustomerNotificationCategoryRequest {
  public contactEmail: string;
  public notificationCategoryId: string;

  public constructor(customerNotificationCategory: ICustomerNotificationCategory) {
    this.notificationCategoryId = customerNotificationCategory.categoryId;
    this.contactEmail = this.parseEmailsArrayToString(customerNotificationCategory.contactEmailAddresses);
  }

  private parseEmailsArrayToString(emailAddresses: string[]): string {
    // Email can contain multiple emailaddresses separated by a semicolon
    const parsed = emailAddresses.join(";");
    return parsed ?? [];
  }
}