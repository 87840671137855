import "bootstrap/dist/js/bootstrap.bundle.js";
import "react-datepicker/dist/react-datepicker.css";
import "./i18n/config";
import "./main.scss";

import * as ReactDOM from "react-dom";
import { NotificationContainer } from "react-notifications";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { fetchInitialConfiguration } from "store/fetchInitialConfiguration";

import ReactNotification from "./components/reactNotification/reactNotification";
import ErrorBoundary from "./error/components/errorBoundary/errorBoundary";
import MsalFactory from "./factories/msalFactory";
import App from "./pages/app";
import FeatureFlagProvider from "./providers/featureFlagProvider";
import TelemetryProvider from "./providers/telemetryProvider";
import AccessService from "./services/accessService";
import StoreFactory from "./store/store";
import AppEventHub from "./utils/appEventHub";

AppEventHub.initialize();
MsalFactory.initialize();
ReactNotification.manageNotifications();

const store = StoreFactory.Create();
store.subscribe(handleStoreChangeSubscription);

const accessService = new AccessService();

function handleStoreChangeSubscription(): void {
  FeatureFlagProvider.manageFlagChanges(store.getState().generalState.featureFlags);
}

accessService.ensureAccessAsync().then((isAuthenticated: boolean) => {
  store.dispatch(fetchInitialConfiguration());

  if (isAuthenticated) {
    ReactDOM.render(
      <Router>
        <TelemetryProvider>
          <ErrorBoundary>
            <NotificationContainer />
            <Provider store={store}>
              <App location={window.location} />
            </Provider>
          </ErrorBoundary>
        </TelemetryProvider>
      </Router>,
      document.getElementById("root") as HTMLElement
    );
  } else {
    ReactDOM.render(<h1>No access.</h1>, document.getElementById("root") as HTMLElement);
  }
});
