import * as React from "react";

import IZoneDetailsProps, { IZoneDetailsDispatchProps, IZoneDetailsStateProps } from "../interfaces/IZoneDetailsProps";
import { deleteZone, updateZone } from "store/actions/externalVenueActions";

import BuildingObjectModal from "./buildingObjectModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IBuildingObject from "../interfaces/IBuildingObject";
import IZoneDetailsState from "../interfaces/IZoneDetailsState";
import LanguageProvider from "providers/languageProvider";
import { RootState } from "store/reducers/rootReducer";
import TranslationMapper from "i18n/mapper";
import Zone from "models/zone";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

class ZoneDetails extends React.Component<IZoneDetailsProps, IZoneDetailsState> {
  public constructor(props: IZoneDetailsProps) {
    super(props);
    const state: IZoneDetailsState = {
      isModalOpen: false,
    };

    this.openUpdateZoneModal = this.openUpdateZoneModal.bind(this);
    this.updateZone = this.updateZone.bind(this);
    this.deleteZone = this.deleteZone.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.state = state;
  }

  private openUpdateZoneModal(): void {
    this.setState({
      isModalOpen: true,
      isCreate: false,
      isFloor: false,
    });
  }

  private handleClose(): void {
    this.setState({
      isModalOpen: false,
      isCreate: undefined,
      isFloor: undefined,
    });
  }

  private async updateZone(fields: IBuildingObject): Promise<void> {
    if (!this.props.zone || this.props.zone.id === "" || !fields || !fields.nameValue) {
      return;
    }

    const zone = new Zone();
    zone.id = this.props.zone.id;
    zone.name = fields.nameValue;
    zone.nfcTagCode = fields.nfcTagValue ?? "";
    zone.floorId = this.props.zone.floorId;
    this.props.onUpdateZone(this.props.customerId, this.props.venueId, zone);
    this.handleClose();
  }

  private async deleteZone(): Promise<void> {
    if (!this.props.zone || this.props.zone.id === "") {
      return;
    }

    const confirmation = window.confirm(`${LanguageProvider.t(TranslationMapper.pages.masterdata.deleteconfirmation)}
            ${this.props.zone.name}`);
    if (!confirmation) {
      return;
    }
    this.props.onDeleteZone(this.props.customerId, this.props.venueId, this.props.zone.id);
  }

  private get nfcTagCodeLabel(): string {
    const label = LanguageProvider.t(TranslationMapper.pages.externalvenue.nfctag_code);

    return this.props.zone.nfcTagCode ? `${label} ${this.props.zone.nfcTagCode}` : "";
  }

  private get buildingObject(): IBuildingObject {
    return {
      nameInputTitle: LanguageProvider.t(TranslationMapper.pages.externalvenue.name_zone),
      nameValue: this.props.zone.name,
      nfcTagValue: this.props.zone.nfcTagCode,
      isZoneInput: true,
      IsFloorNFCRequired: false,
      minLength: 1,
    };
  }

  public render(): JSX.Element {
    return (
      <>
        <tr>
          <td className="td-connector"></td>
          <td className="col-3">{this.props.zone.name}</td>
          <td className="col-3"></td>
          <td className="col-3">{this.nfcTagCodeLabel}</td>
          <td className="col-3">
            <div className="d-flex justify-content-end">
              <button type="button" className="btn btn-link py-0" data-bs-toggle="dropdown" aria-expanded="false">
                <FontAwesomeIcon icon={["fal", "ellipsis-stroke"]} fixedWidth />
              </button>
              <ul className="dropdown-menu">
                <li>
                  <button className="dropdown-item" onClick={this.openUpdateZoneModal}>
                    {LanguageProvider.t(TranslationMapper.pages.externalvenue.update_zone)}
                  </button>
                </li>
                <li>
                  <button className="dropdown-item" onClick={this.deleteZone}>
                    {LanguageProvider.t(TranslationMapper.pages.externalvenue.delete_zone)}
                  </button>
                </li>
              </ul>
            </div>
          </td>
        </tr>
        {this.state.isModalOpen && (
          <BuildingObjectModal
            modalTitle={TranslationMapper.pages.externalvenue.update_zone}
            buildingObject={this.buildingObject}
            onClose={this.handleClose}
            onSubmit={this.updateZone}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state: RootState): IZoneDetailsStateProps => ({});

const mapDispatchToProps: IZoneDetailsDispatchProps = {
  onUpdateZone: updateZone,
  onDeleteZone: deleteZone,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ZoneDetails));
