import { configureStore } from "@reduxjs/toolkit";
import { Store } from "redux";
import { ActionTypesCollection } from "./actionTypes";
import rootReducer, { RootState } from "./reducers/rootReducer";
import { initialActivityStoreState } from "./state/activityStoreState";
import { initialActivityTypeStoreState } from "./state/activityTypeStoreState";
import { initialCleaningObjectStoreState } from "./state/cleaningObjectStoreState";
import { initialCustomerStoreState } from "./state/customerStoreState";
import { initialErrorStoreState } from "./state/errorStoreState";
import { initialGeneralStoreState } from "./state/generalStoreState";
import { initialLocationStoreState } from "./state/locationStoreState";
import { initialLogbookStoreState } from "./state/logbookStoreState";
import { initialNotificationStoreState } from "./state/notificationStoreState";
import { initialOperatorStoreState } from "./state/operatorStoreState";
import { initialScheduleManagementStoreState } from "./state/scheduleManagementStoreState";
import { initialUserStoreState } from "./state/userStoreState";

export const initialStates: RootState = {
  activityState: initialActivityStoreState,
  activityTypeState: initialActivityTypeStoreState,
  operatorState: initialOperatorStoreState,
  cleaningObjectState: initialCleaningObjectStoreState,
  customerState: initialCustomerStoreState,
  errorState: initialErrorStoreState,
  generalState: initialGeneralStoreState,
  locationState: initialLocationStoreState,
  logbookState: initialLogbookStoreState,
  notificationState: initialNotificationStoreState,
  scheduleManagementState: initialScheduleManagementStoreState,
  userState: initialUserStoreState,
};

class StoreFactory {
  public static Create(): Store<RootState, ActionTypesCollection> {
    return configureStore({
      reducer: rootReducer,
      preloadedState: initialStates,
      middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: [
          "FETCHED_CUSTOMER_NOTIFICATION_CONTACTS", //Map<string, INotificationCategoryContact[]>; is not serializable
        ],
        // Ignore these paths in the state
        ignoredPaths: [
          "customerState.customerNotificationCategoryContacts", // Map<string, INotificationCategoryContact[]>; is not serializable
          "scheduleManagementState.expandedLogs", // Dictionary<IExpandedRouteLogging>; is not serializable
          "notificationState.incidentTypes", // Dictionary<IIncidentType[]>; is not serializable
          "operatorState.operators" // Dictionary<IOperator[]>; is not serializable
        ],
      },
   }),

    });
  }
}

export default StoreFactory;
