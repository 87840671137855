import * as React from "react";

import IListProps from "./interfaces/IListProps";

export class List extends React.Component<IListProps> {
  public constructor(props: IListProps) {
    super(props);
  }

  public render(): JSX.Element {
    if (this.props.items == null || (this.props.items && !this.props.items.length)) {
      return <div>{this.props.noneAvailableMessage}</div>;
    }

    return (
      <ul
        className={`${
          this.props.isMultipleCheck && this.props.items && this.props.items.length > 1 ? "list-blue" : "list-unstyled"
        }`}
      >
        {this.props.items.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    );
  }
}