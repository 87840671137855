import TranslationMapper from "i18n/mapper";
import LanguageProvider from "providers/languageProvider";
import { Component } from "react";
import DateUtils from "utils/dateUtils";

import IChannelSummaryProps from "./interfaces/IChannelSummaryProps";

export default class ChannelSummary extends Component<IChannelSummaryProps> {
  public constructor(props: IChannelSummaryProps) {
    super(props);

    this.onChannelSelect = this.onChannelSelect.bind(this);
  }

  private get formattedDateTimeString(): string {
    if (this.props.channelReadStatus.dateTimeLastMessage == null) {
      return "-";
    }

    return DateUtils.getDateStringOrLabel(new Date(this.props.channelReadStatus.dateTimeLastMessage));
  }

  private onChannelSelect(): void {
    this.props.onChannelSelected(this.props.channelReadStatus.topicId);
  }

  public render(): JSX.Element {
    return (
      <div className="logbook__aside__item">
        <div className={`logbook-summary${this.props.isActive ? " active" : ""}`} onClick={this.onChannelSelect}>
          <div className="logbook-summary--top">
            <h3 title={this.props.channelReadStatus.channelName}>{this.props.channelReadStatus.channelName}</h3>
            {this.props.channelReadStatus.unreadMessagesCount === 0 && (
              <span className="small">{this.formattedDateTimeString}</span>
            )}
            {this.props.channelReadStatus.unreadMessagesCount > 0 && (
              <span className="ms-2 messages-unread">
                {this.props.channelReadStatus.unreadMessagesCount}
                <span className="visually-hidden">unread messages</span>
              </span>
            )}
          </div>
          <div className="logbook__last-sender">
            {`${LanguageProvider.t(TranslationMapper.pages.logbook.last_sender)}: ${
              this.props.channelReadStatus.lastMessageSenderName ?? "-"
            }`}
          </div>
        </div>
      </div>
    );
  }
}
