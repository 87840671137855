import FeedbackRequestType from "../../../../enums/feedbackRequestType";
import IActivityWizard, { IActivityFeedbackItems } from "./interfaces/IActivityWizard";

export default class activityValidator {
  public static areDetailsValid(activity?: IActivityWizard): boolean {
    if (!activity?.name) {
      return false;
    }
    return true;
  }

  public static isFeedbackValid(feedbackItems?: IActivityFeedbackItems): boolean {
    if (feedbackItems?.feedbackRequests && feedbackItems.feedbackRequests.length > 0) {
      if (feedbackItems.feedbackRequests.some(feedback => feedback.request === "")) {
        return false;
      }

      if (feedbackItems.feedbackRequests.some(feedback => !feedback.whenCancelled && !feedback.whenFinished)) {
        // if both when cancelled and when finished are false, this feedback will never be requested
        return false;
      }

      if (
        feedbackItems.feedbackRequests.some(
          feedback =>
            // request type is never of type FeedbackRequestType:
            // eslint-disable-next-line
            feedback.requestType == FeedbackRequestType["pages.masterdata.activities.single_select_pick_list"] &&
            (feedback.feedbackRequestAnswerOptions.length === 0 ||
              feedback.feedbackRequestAnswerOptions.some(a => a.answer == null || a.answer === ""))
        )
      ) {
        return false;
      }
    }
    return true;
  }
}