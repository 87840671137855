import { RoutingLinks } from "constants/routingLinks";
import * as React from "react";
import { connect } from "react-redux";
import { RootState } from "store/reducers/rootReducer";

import IUnreadMessagesProps, { IUnreadMessagesStateProps } from "./interfaces/IUnreadMessagesProps";

class UnreadMessages extends React.Component<IUnreadMessagesProps> {
  private get unreadMessagesCount(): string {
    if (!this.props.totalNumberOfUnreadMessages) {
      // Should not be possible
      return "";
    }

    const maxMessageCountToShow = 99;

    return this.props.totalNumberOfUnreadMessages > maxMessageCountToShow
      ? `${maxMessageCountToShow}+`
      : this.props.totalNumberOfUnreadMessages.toString();
  }

  public render(): JSX.Element {
    if (this.props.totalNumberOfUnreadMessages === 0) {
      return <></>;
    }
    return (
      <>
        {this.props.totalNumberOfUnreadMessages &&
          this.props.totalNumberOfUnreadMessages > 0 &&
          this.props.linkTo === RoutingLinks.logbook && (
            <span className="ms-2 messages-unread">
              {this.unreadMessagesCount}
              <span className="visually-hidden">unread messages</span>
            </span>
          )}
      </>
    );
  }
}

const mapStateToProps = (state: RootState): IUnreadMessagesStateProps => ({
  totalNumberOfUnreadMessages: state.logbookState.logbook?.totalNumberOfUnreadMessages,
});

export default connect(mapStateToProps)(UnreadMessages);
