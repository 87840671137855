import { Column } from "react-table";

export default class ReactTableHelper {
  public static readonly ContentHeader: string = "td-content-pt-3-5";
  public static readonly ContentField: string = "td-content-pb-3-5";

  public static getPropertyNameAsString<T extends object>(
    obj: T,
    selector: (x: Record<keyof T, keyof T>) => keyof T
  ): keyof T {
    // Provides a typesafe accessor to data property, also avoids using a string value that could cause crash on prop rename
    const keyRecord = Object.keys(obj).reduce((res, key) => {
      const typedKey = key as keyof T;
      res[typedKey] = typedKey;
      return res;
    }, {} as Record<keyof T, keyof T>);
    return selector(keyRecord);
  }

  public static getNumberOfVisibleColumns(columns: Column[]): number {
    return columns.filter(c => c.hideColumn !== true).length;
  }
}
