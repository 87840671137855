import Role from "enums/role";
import PageHeaderInfo from "models/pageHeaderInfo";
import LanguageProvider from "providers/languageProvider";
import * as React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { RootState } from "store/reducers/rootReducer";

import IPageHeaderItem from "../../interfaces/IPageHeaderItem";
import PageHeaderManager from "../../models/pageHeaderManager";
import IPageHeaderProps, { IPageHeaderStateProps } from "./interfaces/IPageHeaderProps";

class PageHeader extends React.Component<IPageHeaderProps> {
  public constructor(props: IPageHeaderProps) {
    super(props);

    this.toggleTab = this.toggleTab.bind(this);
  }

  private get pageHeaderInfo(): PageHeaderInfo | null {
    return PageHeaderManager.getHeadersForPage(this.props.pageName);
  }

  private get availableTabs(): IPageHeaderItem[] {
    const tabs = this.pageHeaderInfo?.tabs.filter(t => t.roles.some(r => r === this.props.user.role));
    return tabs ?? [];
  }

  private get activeTab(): IPageHeaderItem | null {
    if (this.pageHeaderInfo) {
      // to determine the current active tab we need to filter/clone the array and then match on url. The item with the longest relative URL is the closest match
      return this.pageHeaderInfo.tabs
        .filter(i => window.location.pathname.startsWith(i.relativeUrl.split("?")[0]))
        .sort((a, b) => b.relativeUrl.length - a.relativeUrl.length)[0];
    }
    return null;
  }

  private toggleTab(activeTab: IPageHeaderItem): void {
    if (this.props.onTabChange) {
      this.props.onTabChange(activeTab.id);
    }

    this.setState({ activeTab });
  }

  public render(): JSX.Element {
    if (!this.pageHeaderInfo?.tabs.length) {
      return <></>;
    }

    return (
      <header className="header-sub">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 header-sub__content">
              {this.availableTabs.length > 0 && (
                <ul className="nav nav-pills">
                  {this.availableTabs.map(item => (
                    <Item
                      key={item.id}
                      data={item}
                      onClick={this.toggleTab}
                      isActive={this.activeTab != null && this.activeTab.id === item.id}
                    />
                  ))}
                </ul>
              )}
            </div>
          </div>
        </div>
      </header>
    );
  }
}

const mapStateToProps = (state: RootState): IPageHeaderStateProps => ({
  user: state.userState.user ?? { role: Role.None },
});

export default connect(mapStateToProps)(withTranslation()(PageHeader));

interface IItem {
  onClick: (tab: IPageHeaderItem) => void;
  data: IPageHeaderItem;
  isActive: boolean;
}

// tslint:disable-next-line:max-classes-per-file
class Item extends React.Component<IItem, {}> {
  public constructor(props: any) {
    super(props);
    this.onClickHandler = this.onClickHandler.bind(this);
  }

  public render(): JSX.Element {
    return (
      <li className="nav-item">
        <Link
          className={`nav-link${this.props.isActive ? " active" : ""}`}
          to={this.props.data.relativeUrl}
          id={this.props.data.id}
          onClick={this.onClickHandler}
        >
          {LanguageProvider.t(this.props.data.translationLabel)}
        </Link>
      </li>
    );
  }

  private onClickHandler(): void {
    this.props.onClick(this.props.data);
  }
}
