import Loader from "components/loader/loader";
import TranslationMapper from "i18n/mapper";
import LanguageProvider from "providers/languageProvider";
import React, { FunctionComponent } from "react";

import IReactTableBodyProps from "./interfaces/IReactTableBodyProps";

export const ReactTableBody: FunctionComponent<IReactTableBodyProps> = props => {
  const noResultFoundMessage = props.noResultsMessage ?? LanguageProvider.t(TranslationMapper.reacttable.nodata);
  const isNoResult = (props.page == null || props.page.length === 0) && !props.isLoading;
  const isResult = props.page !== null && props.page.length !== 0 && !props.isLoading;

  return (
    <>
      {props.isLoading === true && (
        <tbody>
          <tr>
            <td colSpan={props.visibleColumns?.length}>
              <div className="d-flex justify-content-center">
                <Loader isLoading={props.isLoading} />
              </div>
            </td>
          </tr>
        </tbody>
      )}

      {isNoResult && (
        <tbody>
          <tr>
            <td colSpan={props.visibleColumns?.length}>
              <div className="d-flex justify-content-center">{noResultFoundMessage}</div>
            </td>
          </tr>
        </tbody>
      )}

      {isResult && (
        <tbody {...props.getTableBodyProps()}>
          {props.page?.length > 0 &&
            !props.isLoading &&
            props.page.map((row, i) => {
              props.prepareRow(row);
              const rowProps = row.getRowProps();
              return (
                <React.Fragment key={i}>
                  <tr
                    onClick={(e): void => props.onRowClicked(e, row)}
                    {...rowProps}
                    className={props.onRowClick ? "cursor--pointer" : ""}
                  >
                    {row.cells.map(cell => {
                      return (
                        <td
                          {...cell.getCellProps({
                            style: {
                              minWidth: cell.column.minWidth,
                              width: cell.column.width,
                              maxWidth: cell.column.maxWidth,
                            },
                          })}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>

                  {row.isExpanded &&
                    !props.renderRowSubComponent &&
                    props.renderColumnRowSubComponent({ row, rowProps, visibleColumns: props.visibleColumns })}

                  {row.isExpanded &&
                    props.renderRowSubComponent &&
                    props.renderCustomRowSubComponent({ row, rowProps, visibleColumns: props.visibleColumns })}
                </React.Fragment>
              );
            })}
        </tbody>
      )}
    </>
  );
};
