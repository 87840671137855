import * as React from "react";

import IFloorDetailsProps, {
  IFloorDetailsDispatchProps,
  IFloorDetailsStateProps,
} from "../interfaces/IFloorDetailsProps";
import { createZone, deleteFloor, updateFloor } from "store/actions/externalVenueActions";

import BuildingObjectModal from "./buildingObjectModal";
import Floor from "models/floor";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IBuildingObject from "../interfaces/IBuildingObject";
import IFloorDetailsState from "../interfaces/IFloorDetailsState";
import LanguageProvider from "providers/languageProvider";
import { RootState } from "store/reducers/rootReducer";
import TranslationMapper from "i18n/mapper";
import Zone from "models/zone";
import ZoneDetails from "./zoneDetails";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

class FloorDetails extends React.Component<IFloorDetailsProps, IFloorDetailsState> {
  private readonly node: React.RefObject<HTMLDivElement> = React.createRef();

  public constructor(props: IFloorDetailsProps) {
    super(props);
    const state: IFloorDetailsState = {
      areZonesOpen: false,
      isModalOpen: false,
    };

    this.openCreateZoneModal = this.openCreateZoneModal.bind(this);
    this.openUpdateFloorModal = this.openUpdateFloorModal.bind(this);
    this.createZone = this.createZone.bind(this);
    this.updateFloor = this.updateFloor.bind(this);
    this.deleteFloor = this.deleteFloor.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.toggleZonesOverview = this.toggleZonesOverview.bind(this);
    this.state = state;
  }

  private toggleZonesOverview(): void {
    this.setState({
      areZonesOpen: !this.state.areZonesOpen,
    });
  }

  private openCreateZoneModal(): void {
    this.buildingObject.nameInputTitle = LanguageProvider.t(TranslationMapper.pages.externalvenue.name_zone);
    this.setState({
      isModalOpen: true,
      isCreate: true,
      isFloor: false,
    });
  }

  private openUpdateFloorModal(): void {
    this.setState({
      isModalOpen: true,
      isCreate: false,
      isFloor: true,
    });
  }

  private createZone(fields: IBuildingObject): void {
    if (!this.props.floor || this.props.floor.id === "" || !fields || !fields.nameValue || !fields) {
      return;
    }

    const zone = new Zone();
    zone.name = fields.nameValue;
    zone.nfcTagCode = fields.nfcTagValue ?? "";
    zone.floorId = this.props.floor.id;
    this.props.onCreateZone(this.props.customerId, this.props.floor.venueId, zone);
  }

  private updateFloor(fields: IBuildingObject): void {
    if (!this.props.floor || this.props.floor.id === "" || !fields || !fields.nameValue) {
      return;
    }
    const floor = new Floor();
    floor.id = this.props.floor.id;
    floor.name = fields.nameValue;
    floor.nfcTagCode = fields.nfcTagValue;
    floor.venueId = this.props.floor.venueId;
    this.props.onUpdateFloor(this.props.customerId, this.props.floor.venueId, floor);
  }

  private async deleteFloor(): Promise<void> {
    if (!this.props.floor || this.props.floor.id === "") {
      return;
    }

    const confirmation = window.confirm(`${LanguageProvider.t(TranslationMapper.pages.masterdata.deleteconfirmation)}
            ${this.props.floor.name}`);
    if (!confirmation) {
      return;
    }
    this.props.onDeleteFloor(this.props.customerId, this.props.floor.venueId, this.props.floor.id);
  }

  private handleSave(fields: IBuildingObject): void {
    if (this.state.isCreate) {
      this.createZone(fields);
    } else {
      this.updateFloor(fields);
    }

    this.handleClose();
  }

  private handleClose(): void {
    this.setState({
      isModalOpen: false,
      isCreate: undefined,
      isFloor: undefined,
    });
  }

  private get modalTitle(): string {
    const action = this.state.isCreate ? "create" : "update";
    const object = this.state.isFloor ? "floor" : "zone";
    return `pages.externalvenue.${action}_${object}`;
  }

  private get inputNameTitle(): string {
    return this.state.isFloor
      ? LanguageProvider.t(TranslationMapper.pages.externalvenue.name_floor)
      : LanguageProvider.t(TranslationMapper.pages.externalvenue.name_zone);
  }

  private get zonesText(): string {
    if (this.props.floor.zones.length === 1) {
      return `1 ${LanguageProvider.t(TranslationMapper.pages.externalvenue.zone).toLowerCase()}`;
    }
    return `${this.props.floor.zones.length} ${LanguageProvider.t(
      TranslationMapper.pages.externalvenue.zones
    ).toLowerCase()}`;
  }

  private get buildingObject(): IBuildingObject {
    let nameValue = this.props.floor.name;
    let nfcTagValue = this.props.floor.nfcTagCode;
    if (!this.state.isFloor) {
      nameValue = "";
      nfcTagValue = "";
    }
    return {
      nameInputTitle: this.inputNameTitle,
      nameValue: nameValue,
      nfcTagValue: nfcTagValue,
      isZoneInput: !this.state.isFloor,
      // IsFloorNFCRequired does not apply when creating a zone, but creating a zone uses buildingObject of floorDetails
      IsFloorNFCRequired: this.state.isCreate ? false : this.props.isFloorNFCRequired,
      minLength: 1,
    };
  }

  private get nfcTagCodeLabel(): string {
    const label = LanguageProvider.t(TranslationMapper.pages.externalvenue.nfctag_code);

    return this.props.floor.nfcTagCode ? `${label} ${this.props.floor.nfcTagCode}` : "";
  }

  private renderZones(): JSX.Element {
    return (
      <tr>
        <td colSpan={4} className="p-0">
          <table className="table table--bordered table--bordered--radius-unset m-0">
            <tbody>
              {this.props.floor.zones.map((zone, index) => {
                return (
                  <ZoneDetails
                    customerId={this.props.customerId}
                    venueId={this.props.floor.venueId}
                    isLast={index + 1 === this.props.floor.zones.length}
                    zone={zone}
                    key={zone.id}
                    disableActions={this.props.disableActions}
                    isFloorNFCRequired={false}
                  />
                );
              })}
            </tbody>
          </table>
        </td>
      </tr>
    );
  }

  public render(): JSX.Element {
    const iconChevron = this.state.areZonesOpen ? "chevron-up" : "chevron-down";
    return (
      <>
        <tr>
          <td>{this.props.floor.name}</td>
          <td>{this.zonesText}</td>
          <td>{this.nfcTagCodeLabel}</td>
          <td className="d-flex justify-content-end">
            <div className="d-flex">
              <button type="button" className="btn btn-link pe-2 py-0" data-bs-toggle="dropdown" aria-expanded="false">
                <FontAwesomeIcon icon={["fal", "ellipsis-stroke"]} fixedWidth />
              </button>
              <ul className="dropdown-menu">
                <li>
                  <button className="dropdown-item" onClick={this.openUpdateFloorModal}>
                    {LanguageProvider.t(TranslationMapper.pages.externalvenue.update_floor)}
                  </button>
                </li>
                <li>
                  <button className="dropdown-item" onClick={this.deleteFloor}>
                    {LanguageProvider.t(TranslationMapper.pages.externalvenue.delete_floor)}
                  </button>
                </li>
                <li>
                  <button className="dropdown-item" onClick={this.openCreateZoneModal}>
                    {LanguageProvider.t(TranslationMapper.pages.externalvenue.create_zone)}
                  </button>
                </li>
              </ul>

              <button
                className={`btn btn-link ps-2 py-0${!this.props.floor.zones.length ? " invisible" : ""}`}
                onClick={this.toggleZonesOverview}
              >
                <FontAwesomeIcon icon={["fal", iconChevron]} fixedWidth />
              </button>
            </div>
          </td>
        </tr>
        {this.state.areZonesOpen && this.renderZones()}
        {this.state.isModalOpen && (
          <BuildingObjectModal
            modalTitle={this.modalTitle}
            buildingObject={this.buildingObject}
            onClose={this.handleClose}
            onSubmit={this.handleSave}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state: RootState): IFloorDetailsStateProps => ({});

const mapDispatchToProps: IFloorDetailsDispatchProps = {
  onCreateZone: createZone,
  onUpdateFloor: updateFloor,
  onDeleteFloor: deleteFloor,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FloorDetails));
