import Loader from "components/loader/loader";
import LoaderTypes from "enums/loaderTypes";
import { Guid } from "guid-typescript";
import TranslationMapper from "i18n/mapper";
import RouteRequest from "models/routeRequest";
import LanguageProvider from "providers/languageProvider";
import { Component, ReactNode } from "react";
import { Modal } from "react-bootstrap";
import ReactDOM from "react-dom";
import { withTranslation } from "react-i18next";
import { NotificationManager } from "react-notifications";
import { connect } from "react-redux";
import { getRoute, resetRoute, upsertRoute } from "store/actions/scheduleActions";
import { RootState } from "store/reducers/rootReducer";

import IVenue from "../../interfaces/IVenue";
import Dictionary from "../../utils/dictionary";
import Button from "../material/buttons/instances/button";
import IWizardStep from "../wizard/interfaces/IWizardStep";
import { IWizardStepOnChangeEvent } from "../wizard/interfaces/IWizardStepOnChange";
import IRouteWizardProps, { IRouteWizardDispatchProps, IRouteWizardStateProps } from "./interfaces/IRouteWizardProps";
import IRouteWizardState from "./interfaces/IRouteWizardState";
import RouteActivitiesStep from "./routeActivitiesStep";
import RouteCleaningObjectsStep from "./routeCleaningObjectsStep";
import RouteDetailsStep from "./routeDetailsStep";
import RouteValidator from "./routeValidator";

class RouteWizard extends Component<IRouteWizardProps, IRouteWizardState> {
  private readonly initialWizardState: IRouteWizardState = {
    isValid: new Dictionary<boolean>(),
    numberOfSteps: 0,
    activeStepIndex: 0,
    isInEditModus: false,
  };

  private readonly wizardSteps: IWizardStep[] = [RouteDetailsStep, RouteCleaningObjectsStep, RouteActivitiesStep];

  public constructor(props: IRouteWizardProps) {
    super(props);

    const isValid = new Dictionary<boolean>();

    for (let index = 0; index < this.wizardSteps.length; index++) {
      isValid.add(index.toString(), false); // by default all steps contain invalid data
    }
    if (props.selectedRouteId != null) {
      this.props.getRoute(props.selectedRouteId, props.isCopiedRoute);
    }

    const routeId = props.isCopiedRoute ? Guid.createEmpty().toString() : props.selectedRouteId;

    this.state = {
      numberOfSteps: this.wizardSteps.length,
      activeStepIndex: 0,
      isValid,
      isInEditModus: false,
      selectedRouteId: routeId,
    };

    this.updateSteps = this.updateSteps.bind(this);
    this.onChange = this.onChange.bind(this);
    this.goToNextStep = this.goToNextStep.bind(this);
    this.goToPreviousStep = this.goToPreviousStep.bind(this);
    this.onSaveRoute = this.onSaveRoute.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  public componentDidUpdate(prevProps: IRouteWizardProps, prevState: IRouteWizardState): void {
    if (prevProps.route !== this.props.route && this.props.route) {
      this.setState(
        {
          details: this.props.route.routeDetails,
          cleaningObjects: this.props.route.cleaningObjects,
          isInEditModus: true,
        },
        this.validateSteps
      );
    }

    // do not resetCleaningObjects when venue is set for the first time
    if (prevState.details?.venue !== this.state.details?.venue) {
      const resetCleaningObjects = prevState.details?.venue != null;
      this.updateSteps(resetCleaningObjects);
    }
  }

  public onChange(event: IWizardStepOnChangeEvent): void {
    const name = event.target.name;
    const value = event.target.value;

    this.setState((current) => ({ ...current, [name]: value }), this.validateSteps);
  }

  private goToNextStep(): void {
    const currentStep = this.state.activeStepIndex;

    if (currentStep < this.state.numberOfSteps - 1 && this.state.isValid.item(currentStep.toString())) {
      this.setState({
        activeStepIndex: currentStep + 1,
      });
    }
  }

  private goToPreviousStep(): void {
    const currentStep = this.state.activeStepIndex;

    if (currentStep > 0) {
      this.setState({
        activeStepIndex: currentStep - 1,
      });
    }
  }

  private updateSteps(resetCleaningObjects: boolean): void {
    const cleaningObjects = resetCleaningObjects ? undefined : this.state.cleaningObjects;

    this.setState(
      {
        numberOfSteps: this.wizardSteps.length,
        cleaningObjects: cleaningObjects,
      },
      this.validateSteps
    );
  }

  private validateSteps(): void {
    this.setState({
      isValid: this.validationStatusPerStep,
    });
  }

  private get validationStatusPerStep(): Dictionary<boolean> {
    const isValidDictionary = new Dictionary<boolean>();
    const cleaningObjects = this.state.cleaningObjects?.cleaningObjectsContainers ?? [];

    isValidDictionary.add("0", this.state.details ? RouteValidator.areDetailsValid(this.state.details) : false);
    isValidDictionary.add("1", RouteValidator.areCleaningObjectsValid(cleaningObjects));
    isValidDictionary.add("2", RouteValidator.areActivitiesValid(cleaningObjects));

    return isValidDictionary;
  }

  private getStepValue(): any {
    switch (this.state.activeStepIndex) {
      case 0:
        return this.state.details;
      case 1:
        return this.state.cleaningObjects;
      case 2:
        return this.state.cleaningObjects;
    }
  }

  private get venue(): IVenue | undefined {
    return this.state.details?.venue;
  }

  private get currentStep(): number {
    return this.state.activeStepIndex + 1;
  }

  private get totalNumberOfSteps(): number {
    return this.wizardSteps.length;
  }

  private get isAllDataValid(): boolean {
    return !this.state.isValid.getValues().some((value) => !value);
  }

  private get isCurrentStepValid(): boolean {
    return this.state.isValid.item(this.state.activeStepIndex.toString());
  }

  private get showPreviousButton(): boolean {
    return this.state.activeStepIndex > 0;
  }

  private get showNextButton(): boolean {
    return this.state.activeStepIndex < this.state.numberOfSteps - 1;
  }

  private get showSaveButton(): boolean {
    return this.state.activeStepIndex === this.state.numberOfSteps - 1 || this.props.route != null;
  }

  private get previousButtonText(): string {
    return this.state.activeStepIndex === this.state.numberOfSteps - 1
      ? TranslationMapper.buttons.previous_variation
      : TranslationMapper.buttons.previous;
  }

  private get saveButtonText(): string {
    return this.state.activeStepIndex === this.state.numberOfSteps - 1
      ? TranslationMapper.buttons.save_and_close
      : TranslationMapper.buttons.save;
  }

  private get modalHook(): HTMLElement {
    let modalHook = document.getElementById("modal");
    if (!modalHook) {
      modalHook = document.createElement("div");
      modalHook.setAttribute("id", "portal");
      document.body.appendChild(modalHook);
    }

    return modalHook;
  }

  private onSaveRoute(): void {
    if (!this.isAllDataValid) {
      NotificationManager.error(TranslationMapper.pages.routewizard.error_not_all_required_data);
      return;
    }

    const routeRequest = new RouteRequest(
      this.props.customerId,
      this.state.details!,
      this.state.cleaningObjects!,
      this.state.selectedRouteId
    );
    this.props.onSave(routeRequest);
    this.closeModal();
  }

  private closeModal(): void {
    this.props.resetRoute();
    this.setState({ ...this.initialWizardState }, () => this.props.onClose());
  }

  public render(): ReactNode {
    const wizardStep = this.wizardSteps[this.state.activeStepIndex];
    const onSaveAndCloseRoute = (): void => this.onSaveRoute();

    return (
      <>
        {this.modalHook &&
          ReactDOM.createPortal(
            <Modal
              backdrop="static"
              show={true}
              onHide={this.closeModal}
              dialogClassName="modal-lg modal--route-wizard"
              centered
            >
              <Modal.Header closeButton>
                <div className="flex-fill d-flex justify-content-between">
                  <div className="modal-header__info">
                    <div>
                      <h1 className="modal-title">{LanguageProvider.t(wizardStep.titleResource)}</h1>
                      {wizardStep.subtitleResource && (
                        <h5 className="modal-title">{LanguageProvider.t(wizardStep.subtitleResource)}</h5>
                      )}
                    </div>
                    <div className="modal__steps" data-testid="route-wizard-steps">
                      {this.currentStep}/{this.totalNumberOfSteps}
                    </div>
                  </div>
                </div>
              </Modal.Header>
              <Modal.Body className="modal-body__height-xl">
                {!this.props.isLoading && (
                  <wizardStep.form
                    onChange={this.onChange}
                    name={wizardStep.name}
                    value={this.getStepValue()}
                    venue={this.venue}
                    isInEditModus={this.state.isInEditModus}
                  />
                )}
                <div className="loader">
                  <Loader isLoading={this.props.isLoading} />
                </div>
              </Modal.Body>
              <Modal.Footer className="d-flex justify-content-between">
                <div className="d-flex flex-row">
                  <Button
                    onClick={this.closeModal}
                    resourceLabel={LanguageProvider.t(TranslationMapper.buttons.cancel)}
                    className="btn-outline-secondary"
                    iconEnd="xmark"
                    data-testid="cancel"
                  />
                  {this.showPreviousButton && (
                    <Button
                      className="btn-outline-secondary ms-3"
                      onClick={this.goToPreviousStep}
                      resourceLabel={LanguageProvider.t(this.previousButtonText)}
                      iconStart="arrow-left"
                    />
                  )}
                </div>
                <div className="d-flex">
                  {this.showNextButton && (
                    <Button
                      disabled={!this.isCurrentStepValid}
                      onClick={this.goToNextStep}
                      className={`${!this.showSaveButton ? " btn-primary" : " btn-outline-secondary"} me-3`}
                      iconEnd="arrow-right"
                      resourceLabel={LanguageProvider.t(TranslationMapper.buttons.next)}
                    />
                  )}
                  {this.showSaveButton && (
                    <Button
                      disabled={!this.isAllDataValid}
                      resourceLabel={LanguageProvider.t(this.saveButtonText)}
                      onClick={onSaveAndCloseRoute}
                      className="btn-primary"
                      iconEnd="floppy-disk"
                    />
                  )}
                </div>
              </Modal.Footer>
            </Modal>,
            this.modalHook
          )}
      </>
    );
  }
}

const mapStateToProps = (state: RootState): IRouteWizardStateProps => ({
  isLoading: state.generalState.loaders.some((l) => l === LoaderTypes.SelectedRoute),
  route: state.scheduleManagementState.selectedRoute,
  customerId: state.customerState.selectedCustomerId!,
});

const mapDispatchToProps: IRouteWizardDispatchProps = {
  resetRoute: resetRoute,
  getRoute: getRoute,
  onSave: upsertRoute,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(RouteWizard));
