import { ErrorCodes } from "constants/errorCodes";
import { ActionTypes, ActionTypesCollection } from "store/actionTypes";
import { IErrorStoreState, initialErrorStoreState } from "store/state/errorStoreState";

export default function errorReducer(
  state: IErrorStoreState = initialErrorStoreState,
  action: ActionTypesCollection
): IErrorStoreState {
  switch (action.type) {
    case ActionTypes.SET_ERROR: {
      const updatedErrors = [...state.errors, action.payload];
      return {
        ...state,
        errors: updatedErrors,
      };
    }
    case ActionTypes.RESET_ERROR: {
      const updatedErrors = state.errors.filter((e) => e !== action.payload);
      return {
        ...state,
        errors: updatedErrors,
      };
    }
    case ActionTypes.INTERCEPTED_ERROR:
      return {
        ...state,
        errorCode: action.payload ? ErrorCodes.General : "",
      };
    default:
      return state;
  }
}
