import Loader from "components/loader/loader";
import LoaderTypes from "enums/loaderTypes";
import { TranslationMapper } from "i18n/mapper";
import IMessage from "interfaces/IMessage";
import LanguageProvider from "providers/languageProvider";
import React, { ChangeEvent, Component } from "react";
import { Form } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { fetchLogbookChannelForUser, sendMessageForUser } from "store/actions/logbookActions";
import { RootState } from "store/reducers/rootReducer";
import { isNullOrEmpty } from "utils/stringUtils";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ILogbookMessagesProps, {
  ILogbookMessagesDispatchProps,
  ILogbookMessagesStateProps,
} from "./interfaces/ILogbookMessagesProps";
import ILogbookMessageState from "./interfaces/ILogbookMessagesState";
import LogbookMessage from "./logbookMessage";

class LogbookMessages extends Component<ILogbookMessagesProps, ILogbookMessageState> {
  private readonly initialFocusElementRef: React.RefObject<HTMLDivElement>;
  private readonly inputHeight = "40px";

  private messageRefreshInterval: NodeJS.Timeout;

  public constructor(props: ILogbookMessagesProps) {
    super(props);

    const state: ILogbookMessageState = {};

    this.state = state;

    this.sendMessage = this.sendMessage.bind(this);
    this.onMessageChange = this.onMessageChange.bind(this);
    this.scrollToLastMessage = this.scrollToLastMessage.bind(this);
    this.manageMessageRefreshInterval = this.manageMessageRefreshInterval.bind(this);

    this.initialFocusElementRef = React.createRef();
    this.onMessageSentSuccessfully = this.onMessageSentSuccessfully.bind(this);
  }

  public componentDidMount(): void {
    this.props.onFetchMessages(this.props.selectedTopicId);
    if (this.props.selectedChannelReadStatus.unreadMessagesCount === 0) {
      this.scrollToLastMessage();
    }
    this.manageMessageRefreshInterval(true);
  }

  public componentDidUpdate(prevProps: ILogbookMessagesProps): void {
    if (
      this.props.selectedTopicId !== prevProps.selectedTopicId &&
      this.props.selectedChannelMessages?.messages != null &&
      this.props.selectedTopicId !== this.props.selectedChannelMessages?.topicId
    ) {
      this.props.onFetchMessages(this.props.selectedTopicId);
      this.setState({
        newMessageContent: "",
      });
    }

    if (
      prevProps.selectedTopicId !== this.props.selectedTopicId ||
      prevProps.selectedChannelMessages?.messages.length !== this.props.selectedChannelMessages?.messages.length
    ) {
      this.scrollToLastMessage();
    }

    // Reset input size when switching between summary
    if (prevProps.selectedTopicId !== this.props.selectedTopicId) {
      this.inputResetSize();
    }
  }

  public componentWillUnmount(): void {
    this.manageMessageRefreshInterval(false);
  }

  private manageMessageRefreshInterval(startRefreshInterval: boolean): void {
    if (!startRefreshInterval) {
      clearInterval(this.messageRefreshInterval);
      return;
    }

    const intervalInMs = 60000;
    const fetchMessages = (): void => this.props.onFetchMessages(this.props.selectedTopicId);
    this.messageRefreshInterval = setInterval(fetchMessages, intervalInMs);
  }

  private onMessageSentSuccessfully(): void {
    this.props.onFetchMessages(this.props.selectedTopicId);

    this.setState({
      newMessageContent: undefined,
    });

    this.inputResetSize();
  }

  private async sendMessage(): Promise<void> {
    if (this.state.newMessageContent && this.hasNewMessageInput) {
      this.props.onSendMessage(
        this.props.selectedTopicId,
        this.state.newMessageContent,
        this.onMessageSentSuccessfully
      );
    }
  }

  private onMessageChange(event: ChangeEvent<HTMLTextAreaElement>): void {
    // Resizes the input field to the current input
    // state wont work because the state is updated after the resize
    const borderYWidth = 2;
    event.currentTarget.style.height = this.inputHeight;
    event.currentTarget.style.height = (event.currentTarget.scrollHeight + borderYWidth).toString() + "px";
    // -

    // Clear message content
    let newMessageContent = event.currentTarget.value;
    if (newMessageContent != null) {
      // Strip input of new lines (enter) in textarea
      newMessageContent = newMessageContent.replace(/[\r\n]/gm, "");
    }
    // -

    this.setState({ newMessageContent });
  }

  private get lastReadMessageIndex(): number {
    // When there is no lastReadMessage, we can assume everything is new, so we want to start at the first message
    return this.props.selectedChannelMessages?.messages.findIndex(m => m.isLastReadMessage === true) ?? 0;
  }

  private renderMessage(message: IMessage, index: number): JSX.Element {
    if (this.props.selectedChannelMessages == null) {
      return <></>;
    }

    const isLastMessage = this.props.selectedChannelMessages.messages.length === index + 1;
    const isLatestReadMessage = this.lastReadMessageIndex === index;
    const renderedMessage = (
      <div ref={isLatestReadMessage ? this.initialFocusElementRef : undefined} key={index}>
        <LogbookMessage
          dateTime={new Date(message.timeStamp)}
          senderName={message.senderName}
          messageContent={message.content}
          isFromCurrentUser={message.isCurrentUserSender}
          isLastReadMessage={message.isLastReadMessage}
          isLastMessage={isLastMessage}
        />
      </div>
    );

    return renderedMessage;
  }

  private scrollToLastMessage(): void {
    if (this.initialFocusElementRef.current != null) {
      this.initialFocusElementRef.current.scrollIntoView();
    }
  }

  private get hasNewMessageInput(): boolean {
    return this.state.newMessageContent != null && !isNullOrEmpty(this.state.newMessageContent);
  }

  private inputResetSize(): void {
    // state wont work because the state is updated after the resize
    const input = document.querySelector<HTMLTextAreaElement>("#logbook-message-input");

    if (input) {
      input.style.height = this.inputHeight;
    }
  }

  public render(): JSX.Element {
    return (
      <>
        <div className="logbook__messages-container">
          {this.props.isLoading && this.props.selectedChannelMessages?.messages.length === 0 && (
            <div className="d-flex justify-content-center my-3">
              <Loader isLoading={true} />
            </div>
          )}
          {this.props.selectedChannelMessages?.messages.map((c: IMessage, index: number) =>
            this.renderMessage(c, index)
          )}
        </div>
        <div className="logbook__add-message-container">
          <div className="logbook__add-message-input-container">
            <Form.Control
              maxLength={1000}
              placeholder={LanguageProvider.t(
                TranslationMapper.pages.logbook.messages_component.message_input_placeholder
              )}
              aria-label="Message"
              as="textarea"
              data-testid="message-input"
              value={this.state.newMessageContent ?? ""}
              onChange={this.onMessageChange}
              disabled={this.props.isLoading}
              id="logbook-message-input"
            />
          </div>
          <button
            className="btn btn-primary btn--rounded"
            data-test="send-message-button"
            disabled={this.props.isLoading || !this.hasNewMessageInput}
            type="button"
            onClick={this.sendMessage}
          >
            <FontAwesomeIcon icon={["fal", "paper-plane-top"]} fixedWidth className="fa-rotate-by" />
          </button>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: RootState): ILogbookMessagesStateProps => ({
  selectedChannelMessages: state.logbookState.selectedLogbookChannel,
  isLoading: state.generalState.loaders.some(l => l === LoaderTypes.LogbookChannel),
});

const mapDispatchToProps: ILogbookMessagesDispatchProps = {
  onFetchMessages: fetchLogbookChannelForUser,
  onSendMessage: sendMessageForUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LogbookMessages));
