import ITimePeriod from "../components/material/select/interfaces/ITimePeriod";
import IWeekdays from "../interfaces/IWeekdays";

export default class RouteDetailsRequest {
  public isRouteActive: boolean;
  public venueId: string;
  public routeName: string;
  public operatorIds: string[];
  public weekdays: IWeekdays;
  public timePeriod: ITimePeriod;
  public incidentTypes: string[];

  public constructor(
    active: boolean,
    venueId: string,
    name: string,
    operatorIds: string[],
    weekdays: IWeekdays,
    timePeriod: ITimePeriod,
    incidentTypes: string[]
  ) {
    this.isRouteActive = active;
    this.venueId = venueId;
    this.routeName = name;
    this.operatorIds = operatorIds;
    this.weekdays = weekdays;
    this.timePeriod = timePeriod;
    this.incidentTypes = incidentTypes;
  }
}
