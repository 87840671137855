import { IInputChangeEvent } from "components/interfaces/IInputChangeEvent";
import Select from "components/material/select/select";
import CleaningStatus from "enums/cleaningStatus";
import RouteStatus from "enums/routeStatus";
import { ZoneFeedbackLabels } from "enums/zoneFeedbackLabels";
import TranslationMapper from "i18n/mapper";
import IReactSelectValue from "interfaces/IReactSelectValue";
import IZoneLogging from "interfaces/IZoneLogging";
import LanguageProvider from "providers/languageProvider";
import { Component } from "react";
import { connect } from "react-redux";
import { RootState } from "store/reducers/rootReducer";

import IRouteLoggingSubComponentProps, {
  IRouteLoggingSubComponentInternalProps,
  IRouteLoggingSubComponentStateProps,
} from "./interfaces/IRouteLoggingSubComponentProps";
import IRouteLoggingSubComponentState from "./interfaces/IRouteLoggingSubComponentState";

class RouteLoggingSubComponent extends Component<IRouteLoggingSubComponentProps, IRouteLoggingSubComponentState> {
  public constructor(props: IRouteLoggingSubComponentProps) {
    super(props);

    const state: IRouteLoggingSubComponentState = {};

    this.state = state;

    this.onZoneSelection = this.onZoneSelection.bind(this);
  }

  private onZoneSelection(event: IInputChangeEvent<string>): void {
    if (!this.props.expandedLog || event == null) {
      return;
    }

    const selectedZoneSpaceId = event.target.value;
    const selectedZone = this.props.expandedLog.zoneLogging.find(zl => zl.zoneSpaceId === selectedZoneSpaceId);

    this.setState({
      selectedZone,
    });
  }

  private get selectedZone(): IZoneLogging | undefined {
    return this.state.selectedZone ?? this.props.expandedLog?.zoneLogging.find(z => z.isStartZone);
  }

  private get selectedZoneId(): string | undefined {
    return this.selectedZone?.zoneSpaceId;
  }

  private get zonesForSelectList(): IReactSelectValue[] {
    if (!this.props.expandedLog) {
      return [];
    }

    return this.props.expandedLog.zoneLogging.map(z => ({
      value: z.zoneSpaceId,
      label: z.name,
    }));
  }

  private get activitiesToRender(): JSX.Element[] {
    if (!this.props.expandedLog) {
      return [];
    }

    return this.props.expandedLog.activities.map(activity => {
      return (
        <button key={activity.id} title={`${activity.name}`} className="btn btn--image-label pe-none" type="button">
          <img className="category-icon" src={`${activity.imageUri}_Activity_active`} alt="" />
          <div>{activity.name}</div>
        </button>
      );
    });
  }

  private get zoneFeedbackLabel(): string {
    const maxThresholdClean = 25;
    const maxThresholdNeutral = 50;
    const maxThresholdDirty = 75;

    if (!this.selectedZone) {
      return "";
    }
    const feedback = this.selectedZone.preciseCleaningStatusFeedback;

    const translateStatus = (status: string): string =>
      LanguageProvider.t(TranslationMapper.pages.logging.cleaningstatus[status]);

    if (!feedback) {
      return translateStatus(ZoneFeedbackLabels.none);
    }

    if (feedback <= maxThresholdClean) {
      return translateStatus(ZoneFeedbackLabels.clean);
    } else if (feedback <= maxThresholdNeutral) {
      return translateStatus(ZoneFeedbackLabels.neutral);
    } else if (feedback <= maxThresholdDirty) {
      return translateStatus(ZoneFeedbackLabels.dirty);
    } else {
      return translateStatus(ZoneFeedbackLabels.veryDirty);
    }
  }

  public render(): JSX.Element {
    return (
      <tr>
        <td colSpan={1} />
        <td colSpan={2}>
          <h3>{LanguageProvider.t(TranslationMapper.pages.logging.zone)}</h3>
          <div className="modal__col-mb">
            {this.props.expandedLog && (
              <Select
                name="selectedZone"
                onChange={this.onZoneSelection}
                options={this.zonesForSelectList}
                value={this.selectedZoneId}
              />
            )}
          </div>
          <h3>{LanguageProvider.t(TranslationMapper.pages.logging.status)}</h3>
          <div className="modal__col-mb">
            {this.props.expandedLog && RouteStatus[this.props.expandedLog.routeStatus]}
          </div>
          {this.props.expandedLog?.activeZoneLogging && this.props.expandedLog.activeZoneLogging.uncleanedReason && (
            <>
              <h3>{LanguageProvider.t(TranslationMapper.pages.logging.uncleaned_zone_reason)}</h3>
              <div className="modal__col-mb"> {this.props.expandedLog.activeZoneLogging.uncleanedReason}</div>
            </>
          )}
        </td>
        <td colSpan={2}>
          <h3>{LanguageProvider.t(TranslationMapper.pages.logging.expectedpollution)}</h3>
          <div className="modal__col-mb">
            {this.props.expandedLog && this.selectedZone
              ? LanguageProvider.t(
                  TranslationMapper.pages.logging.cleaningstatus[
                    CleaningStatus[this.selectedZone.calculatedStatus].toLowerCase()
                  ]
                )
              : ""}
          </div>
          <h3>{LanguageProvider.t(TranslationMapper.pages.logging.actualpollution)}</h3>
          <div className="modal__col-mb">{this.zoneFeedbackLabel}</div>
          <h3>{LanguageProvider.t(TranslationMapper.pages.logging.operator)}</h3>
          <div className="modal__col-mb"> {this.props.expandedLog && this.props.expandedLog.operatorName}</div>
        </td>
        <td colSpan={3}>
          <h3>{LanguageProvider.t(TranslationMapper.pages.logging.cleaningactivities)}</h3>
          <div>
            {this.props.expandedLog && this.props.expandedLog.activities.length > 0 ? this.activitiesToRender : ""}
          </div>
        </td>
        <td colSpan={1} />
      </tr>
    );
  }
}

const mapStateToProps = (
  state: RootState,
  props: IRouteLoggingSubComponentInternalProps
): IRouteLoggingSubComponentStateProps => ({
  expandedLog: state.scheduleManagementState.expandedLogs
    ? state.scheduleManagementState.expandedLogs[props.routeDataId]
    : undefined,
});

export default connect(mapStateToProps)(RouteLoggingSubComponent);
