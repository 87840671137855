import FileTypes from "enums/fileTypes";
import { TranslationMapper } from "i18n/mapper";
import LanguageProvider from "providers/languageProvider";
import Resizer from "react-image-file-resizer";
import { NotificationManager } from "react-notifications";

export default class ImageUtils {
  private static compressToBase64Async(imageFile: File): Promise<string> {
    switch (imageFile.type) {
      case FileTypes.JPG:
      case FileTypes.JPEG:
      case FileTypes.PNG:
        return ImageUtils.compressImageToMaximizedBase64Async(imageFile);
      default:
        throw new Error("File type not allowed");
    }
  }

  private static compressImageToMaximizedBase64Async(image: File): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      try {
        Resizer.imageFileResizer(
          image,
          1024,
          1024,
          "JPEG",
          100,
          0,
          resizedImage => {
            return resolve(resizedImage as string);
          },
          "base64"
        );
      } catch (error) {
        throw new Error(error as string);
      }
    });
  }

  public static async convertImageFileToBase64(imageFile: File): Promise<string | null> {
    const maxUncompressedSize = 1024 * 1024 * 15; // = 15Mb

    if (imageFile.size > maxUncompressedSize) {
      NotificationManager.error(LanguageProvider.t(TranslationMapper.pages.notificationwizard.error_image_size));
      return null;
    } else {
      try {
        return ImageUtils.compressToBase64Async(imageFile);
      } catch (error) {
        NotificationManager.error(LanguageProvider.t(TranslationMapper.pages.notificationwizard.error_image_file));
        return null;
      }
    }
  }
}
