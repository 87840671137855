import { Component } from "react";

import { IInputProps } from "./interfaces/IInputProps";

export default class BaseInput extends Component<IInputProps> {
  public constructor(props: IInputProps) {
    super(props);

    this.onChange = this.onChange.bind(this);
  }

  public get className(): string {
    return this.props.invalid ? "invalid" : "";
  }

  public onChange(event: any): void {
    this.props.onAnswerChange(event.target.value);
  }
}